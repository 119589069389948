import React, { useReducer, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import Helpers from '../../helpers/helpers'

import Header from './CompHeader'
import NoResults from './CompNoResults'
import { ModalContext } from '../../context/modal'
import ShortcodesAPI from '../../models/shortcode'
import ShortCodesModal from '../modals/ShortCodesModal'
import DeleteModal from '../modals/DeleteModal'

import ListInputFilter from '../../components/ListInputFilter'
import ResponseMessage from '../../components/ResponseMessage'

const initialState = {
  defaultData: [],
  data: [],
  search: '',
  error: '',
  success: false,
  submitted: false,
  page: 1,
  pageMax: 10,
  pageTotal: 1
};

function reducer(state, action) {
  const defaults = [...state.defaultData];
  const current = [...state.data];
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        defaultData: action.payload,
        data: action.payload,
        pageTotal: Math.ceil(action.payload.length/state.pageMax),
      };
    case 'setRemove':
      const removeIndexDefaults = defaults.findIndex(o => o.cltId === action.id);
      const removeIndexCurrent = current.findIndex(o => o.cltId === action.id);
      defaults.splice(removeIndexDefaults, 1);
      current.splice(removeIndexCurrent, 1);
      return {
        ...state,
        defaultData: defaults,
        data: current,
        pageTotal: Math.ceil(current.length/state.pageMax),
      };
    case 'getSearch':
      let clients = defaults.filter((e) => {
        return e.number.toString().toLowerCase().includes(action.term) || e.country.toLowerCase().includes(action.term) ;
      });
      return {
        ...state,
        data: clients,
        search: action.term,
        pageTotal: Math.ceil(clients.length/state.pageMax),
      };
    case 'setError':
      return {
        ...state,
        error: action.payload
      };
    case 'setPage':
      return {
        ...state,
        page: action.page
      };
    default:
      throw new Error();
  }
}

export default function SettingsShortCodes() {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { toggleModal, setModalContent, setModalWidth } = React.useContext(ModalContext);
  
  /** Get Data **/
  const getData = useCallback(() => {
    ShortcodesAPI().findAll()
    .then((data) => {
      dispatch({type: 'setData', payload: data.data.data.shortCodes});
    })
    .catch(err => {
      dispatch({type: 'setError', payload: 'SERVICE ERROR'});
    });
  }, [])

  useEffect(() => {
    getData();
  }, [getData]);

  /** Add */
  const addData = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toggleModal();
    setModalWidth("modal-lg");
    renderAddEdit({new: true});
  };

  /** Edit */
  const editData = (params) => {
    params.event.stopPropagation();
    params.event.preventDefault();
    let payloadKeywords = [];
    if (params.data.mandatoryKeywords) {
      params.data.mandatoryKeywords.map(e => {
        return payloadKeywords.push(e.text)
      });
    }
    toggleModal();
    setModalWidth("modal-lg");
    renderAddEdit({data: params.data, keywords: payloadKeywords});
  };

  const renderAddEdit = (data) => {
    setModalContent(ShortCodesModal({
      new: (data && data.new) ? true : false,
      typemessage: (data && data.new) ? "Create New " : "Edit ",
      hide: () => toggleModal(false),
      data: (data && data.data) ? data.data : '',
      keywords: (data && data.keywords) ? data.keywords : [],
      error: (data && data.error) ? data.error : '',
      submitted: (data && data.submitted) ? true : false,
      success: () => {
        toggleModal(false);
        getData();
      },
      rerender: (p) => renderAddEdit(p),
      action: (params) => {
        params.event.preventDefault();
        let payload = params.data;
        payload.mandatoryKeywords = [];
        params.keywords.map(k => {
          return payload.mandatoryKeywords.push({text: k, message: 'Mandatory message.'});
        })
        return ShortcodesAPI().save(payload)
      }
    }));
  }

  /** Delete Client */
  const deleteData = (params) => {
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalWidth("modal-md");
    setModalContent(DeleteModal({
      message: `Are you sure you want to delete the '${params.data.number}' Short Code?`,
      hide: () => toggleModal(false),
      action: () => initDelete(params)
    }));
  };

  const initDelete = (params) => {
    ShortcodesAPI().destroy(params.data.shortCodeId)
    .then((data) => {
      if(data.data.statusCode === 200) {
        dispatch({type: 'setRemove', id: params.data.shortCodeId});
      } else {
        dispatch({type: 'setError', payload: 'ERROR'});
      }
    })
    .catch(err => {
      dispatch({type: 'setError', payload: 'SERVICE ERROR'});
    })
    .finally(() => {
      toggleModal(false);
    });
  }

  //Checks if index is within the current SMS page view
  const listingInRange = (index) => {
    return (index >= ((state.page - 1) * state.pageMax) && index < (state.page * state.pageMax))
  }

  //Change current page view
  const changePage = (page) => {
    dispatch({type:'setPage', page: (page.selected + 1)});
  }

  /** Show Search Results **/
  const filterResults = (event) => {
    event.preventDefault();
    dispatch({type:'getSearch', term: event.target.value.toLowerCase()})
  }

	return (
    <React.Fragment>

      <Header
        title="Short Codes"
        add="CREATE NEW SHORT CODE"
        click={addData}
      >
        <ListInputFilter
          change={filterResults}
          placeholder="Search for a short code"
        />
      </Header>

      {state.error && 
        <ResponseMessage
          validation={state.error}
          message={state.error}
          className="error-field" />
      }

      <div className="row table-header">
        <div className="col-md-3 col-xs-4">Code</div>
        <div className="col-md-3 col-xs-4">Country</div>
        <div className="col-md-4 hidden-sm hidden-xs text-right">Date Range</div>
        <div className="col-md-2 col-xs-4 text-right"></div>
      </div>

      <div className="panel-group accordion" id="collapse-panel" data-testid="data-list">
        {state.data && state.data.map((data, key) => (listingInRange(key)) ? (
          <div className="panel panel-default" key={key}>
            <div data-testid={`data-list-${key}`} className="panel-heading" onClick={(event) => editData({event: event, data: data})}>
                <div className="row record">
                  <div data-testid={`data-list-${key}-code`}  className="col-md-3 col-xs-4 highligted-text">{data.number}</div>
                  <div className="col-md-3 col-xs-4">{data.country}</div>
                  <div className="col-md-4 hidden-sm hidden-xs text-right">{Helpers().FormatDate(data.startDate)} - {Helpers().FormatDate(data.endDate)}</div>
                  <div className="col-md-2 col-xs-4 text-right">
                    <span className="fa-wrap"><i data-testid={`data-list-${key}-delete`} className="fa fa-trash-o fa-lg" onClick={(event) => deleteData({event: event, data: data})} title="Delete User"></i></span>
                  </div>
                </div>
            </div>
          </div>
          ) : ''
        )}
        {state.data.length === 0 &&
          <NoResults
            message="There are no short codes to display yet." />
        }
      </div>

      {state.data.length !== 0 &&
        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={state.pageTotal}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={changePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
      }

    </React.Fragment>
	)
}
