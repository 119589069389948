import React from 'react'
import ContestForm from './ContestForm'

export default function Contest(props) {
    const campaignId = parseInt(props.campaignId);
    const campaignInd = parseInt(props.campaignInd);
    const contestId = parseInt(props.contestId);

    return (
        <ContestForm 
            campaignId={campaignId} 
            campaignInd={campaignInd}
            contestId ={contestId || null} 
            hide={props.hide} 
            action={props.action}/>
    )
}
