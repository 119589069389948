import API from '../services/api'

export default function ShortcodesAPI() {

    let Trivia = {};

    Trivia.findAll = function () {
        return API.findAll("getAllTrivia");
    };

    Trivia.save = function (triggerData) {
        return API.save('saveTrivia', triggerData);
    };

    Trivia.updateStatus = function (triggerData) {
        return API.save('setTriviaStatus', triggerData);
    };

    Trivia.destroy = function (triviaId) {
        return API.destroy('deleteTrivia', 'triviaId', triviaId);
    };

    return Trivia;

}
