import API from '../services/api'

export default function GatewayProgramsAPI() {
    const GatewayPrograms = {};

    GatewayPrograms.findAll = () => {
        return API.findAll('getGatewayPrograms');
    };

    GatewayPrograms.save = (params) => {
        return API.save('saveGatewayProgram', params);
    };

    GatewayPrograms.destroy = (id) => {
        return API.destroy('deleteGatewayProgram', '_id', id);
    };

    GatewayPrograms.download = (data) => {
        const download = {
          action: 'downloadGatewayPrograms',
          params: {
            startDate: data.startDate,
            endDate: data.endDate,
            rateType: data.rateType,
            hostingCharge: data.hostingCharge,
            codeType: data.codeType,
            messageType: data.messageType
          }
        };
        return API.download(download, 'application/zip', 'download');
      };        

    return GatewayPrograms;
}
