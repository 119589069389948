import settings from "../config/settings";

const axios = require("axios");

const ls = window.localStorage;
const user = ls.getItem(
  `CognitoIdentityServiceProvider.${settings.userPoolWebClientId}.LastAuthUser`
);
let jwt;
if (user) {
  const item = `CognitoIdentityServiceProvider.${settings.userPoolWebClientId}.${user}.idToken`;
  jwt = ls.getItem(item);
}


const API = {
  call: (action, params) => {
    let postAttributes =
      settings.name === "development"
        ? {
            action: action,
            clientId: settings.clientId,
            userId: settings.userId,
            params: params,
          }
        : {
            action: action,
            params: params,
          };

    return axios({
      method: "post",
      url: settings.apiBaseUrl + settings.apiService,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        // "Access-Control-Allow-Origin": "*",
      },
      data: JSON.stringify(postAttributes),
    });
  },

  findAll: (action, params) => {
    return API.call(action, params);
  },

  save: (action, params) => {
    return API.call(action, params);
  },

  destroy: (action, idName, id) => {
    let params = {};
    params[idName] = id;
    return API.call(action, params);
  },

  download: (params, type, endpoint) => {
    if (settings.name === "development") {
      params.clientId = settings.clientId;
      params.userId = settings.userId;
    }
    return axios({
      method: "post",
      url: settings.apiBaseUrl + endpoint,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
        // "Access-Control-Allow-Origin": "*",
      },
      data: JSON.stringify(params),
    });
  },

  //TODO - SMS LIBRARY UPLOAD
  upload: (file, type, endpoint) => {
    const fd = new FormData();
    if (settings.name === "development") {
      fd.append("clientId", settings.clientId);
      fd.append("userId", settings.userId);
    }
    fd.append("file", file);

    return axios({
      method: "post",
      url: settings.apiBaseUrl + endpoint,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${fd._boundary}`,
        Authorization: `Bearer ${jwt}`,
        // "Access-Control-Allow-Origin": "*",
      },
      data: fd,
    });
  },
};

export default API;
