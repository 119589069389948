import API from '../services/api'

export default function EmailsAPI() {

    var Email = {};

    Email.findAll = function () {
        return API.findAll('getEmails');
    };

    Email.findClientEmails = function () {
        return API.findAll('getClientEmails');
    };

    Email.save = function (emailData) {
        return API.save('saveEmail', emailData);
    };

    Email.destroy = function (emailId) {
        return API.destroy('deleteEmail', 'emailId', emailId);
    };

    return Email;

}
