import React from 'react'
import KeywordInput from '../../components/KeywordInput'

export default function GWClientsModal(props) {

  const updateField = (event) => {
    props.rerender({...props, client: {...props.client, [event.target.name]:event.target.value}});
  }

  const updateAccountNums = (nums) => {
    props.rerender({...props, client: {...props.client, gatewayAccounts: nums} });
  }

  const validFields = () => {
    if (
      (
        props.client.name && 
        props.client.name.length >= 3 && 
        props.client.name.length <= 30 
      ) &&
      props.client.code &&
      props.client.gatewayAccounts
    ) return true;
    return false
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (validFields()) {
      props.action({event: event, data: props.client})
        .then((data) => {
          if (data.data.statusCode === 200) {
            props.success();
          } else {
            props.rerender({...props, client: props.client, error: data.data.message});
          }
        })
        .catch(err => {
          props.rerender({...props, client: props.client, error: err.message});
        });
    } else {
      props.rerender({...props, client: props.client, submitted: true});
    }
  }

  return (
    <React.Fragment>
      <div className="modal-header" data-testid="modal-window">
        <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
        <h3>{props.typemessage} Gateway Account</h3>
        { props.error && <div data-testid="modal-error-message" className="error-field">{props.error}</div> }
      </div>

      <form noValidate name="settingsClientsForm" onSubmit={submitForm}>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12 form-group">
              <label>Name</label>
              <input
                required
                name="name"
                className="form-control"
                minLength="3"
                placeholder="Enter an account name"
                value={props.client.name}
                onChange={updateField}
                data-testid="modal-field-name" />
              { 
                props.submitted && props.client.name.length < 3 && 
                  <div data-testid="modal-error-name" className="error-field">Please fill out this field (at least 3 characters)</div> 
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>ICF Billing Code</label>
              <input
                required
                name="code"
                className="form-control"
                placeholder="Enter a billing code"
                value={props.client.code}
                onChange={updateField} />
              {
                props.submitted && !props.client.code.length &&
                  <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <KeywordInput
                label="Add Account #"
                labelAdded="Account #s"
                classAdded="fullwidth"
                placeholder="14 digit number"
                errorMatch="This Account # already exists"
                errorRule="Invalid Account #"
                rule="^(\d(-)*){14}$"
                readonly={false} 
                keywords={props.client.gatewayAccounts} 
                formSubmitted={false}
                onUpdatedKeywords={updateAccountNums} 
                hideHelper={true}
              /> 
              {
                props.submitted && (props.client.gatewayAccounts === undefined || props.client.gatewayAccounts.length === 0) &&
                  <div className="error-field">At least one account required.</div>
              }
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
          <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
        </div>
      </form>
    </React.Fragment>
  )
}
