import API from '../services/api'

export default function ShortcodesAPI() {
    var Shortcode = {};

    Shortcode.findAll = function () {
        return API.findAll('getShortCodes');
    };

    Shortcode.save = function (shortCodeData) {
        return API.save('saveShortCode', shortCodeData);
    };

    Shortcode.destroy = function (shortCodeId) {
        return API.destroy('deleteShortCode', 'shortCodeId', shortCodeId);
    };

    return Shortcode;
}
