import API from '../services/api'

export default function ClientsAPI() {

  var Clients = {};

  Clients.findAll = function () {
    return API.findAll('getClients');
  };

  Clients.save = function (clientData) {
    return API.save('saveClient', clientData);
  };

  Clients.destroy = function (clientId) {
    return API.destroy('deleteClient', 'cltId', clientId);
  };

  return Clients;

};