import React, { useState, useEffect } from 'react'
import Helpers from '../../helpers/helpers'

import { ModalContext } from '../../context/modal'
import ReportsAPI from '../../models/reports'
import ConfirmModal from '../modals/ConfirmModal'

import ResponseMessage from '../../components/ResponseMessage'
import DateRange from '../../components/DateRange'

export default function Reports() {
  const defaultChoice = 6;
  const [state, setState] = useState({
    start: Helpers().FormatDate(new Date(new Date().setDate(new Date().getDate() - parseInt(defaultChoice)) + 1).getTime()),
    end: Helpers().FormatDate(new Date().getTime()),
    choice: defaultChoice,
    error: ''
  });
  const [analytics, setAnalytics] = useState([]);
  let { toggleModal, setModalContent } = React.useContext(ModalContext);

  useEffect(() => {
    ReportsAPI().getPlugins()
    .then((data) => {
      setAnalytics(data.data.data.analytics);
    })
    .catch(err => {
      setState(s => s.error = err);
    });
  }, []);

  //Set Select Date Range
	const setDays = (days) => {
    setState({
      start: Helpers().FormatDate(new Date(new Date().setDate(new Date().getDate() - parseInt(days)) + 1).getTime()),
      end: Helpers().FormatDate(new Date().getTime()),
      choice: days,
      error: ''
    });
	}

  //Set Date Picked
	const dateSelected = (evt) => {
    if (evt.target.name === 'startDate') {
      setState({
        start: Helpers().FormatDate(new Date(evt.target.value + 'T00:00:00').getTime()),
        end: state.end,
        choice: 'other',
        error: ''
      });
		} else {
      setState({
        start: state.start,
        end: Helpers().FormatDate(new Date(evt.target.value + 'T00:00:00').getTime()),
        choice: 'other',
        error: ''
      });
    }
  }
  
  const downloadModal = (elem) => {
    elem.event.stopPropagation();
    elem.event.preventDefault();
    toggleModal();
    setModalContent(ConfirmModal({
      message: `Are you sure you want to download this report?`,
      hide: () => toggleModal(false),
      action: () => downloadReport(elem.data)
    }));
  };

  const downloadReport = (data) => {
    let params = data;
    params.startDate = new Date(state.start + 'T00:00:00').getTime();
    params.endDate = new Date(state.end + 'T00:00:00').getTime();
    ReportsAPI().download(params)
    .then((data) => {
      let file = new Blob([data.data], {type: 'application/zip'})
      let fileName = data.headers['file-name'];
      let url = URL.createObjectURL(file);
      let download = document.createElement('a');
      download.href = url;
      download.download = fileName;
      download.click();
      toggleModal(false);
    })
    .catch(err => {
      setState(s => s.error = err);
      toggleModal(false);
    });
  }  

	return (
    <React.Fragment>

      <div className="row row-top">
        <div className="col-md-4">
          <h3>Reports</h3>
        </div>
      </div>

      <ResponseMessage
        validation={state.error}
        message={state.error}
        className="error-field" />

      <DateRange
        start={state.start}
        end={state.end}
        choice={state.choice}
        setChoice={setDays}
        setDate={dateSelected}
      />

      <div className="accordion panel-group" id="collapse-panel" data-testid="analytics-list">
        {analytics.length > 0 && analytics.map((item, key) =>
          <div className="panel panel-default" key={key}>
            {item.type === 'download' &&
            <div className="panel-heading" onClick={(evt) => downloadModal({event:evt, data: item})}>
              <div className="row record">
                <div className="col-md-10 col-xs-8">{item.label}</div>
                <div className="col-md-2 col-xs-4 text-right">
                  <span className="fa-wrap"><i title="Download Report" className="fa fa-download fa-lg"></i></span>
                </div>
              </div>
            </div>
            }
          </div>
        )}
        {analytics.length === 0 &&
          <div className="panel panel-default" data-testid="no-analytics">
            <div className="panel-heading empty">
              <div className="row record">
                <div className="col-xs-12">There are no analytics to display.</div>
              </div>
            </div>
          </div>
        }
      </div>

    </React.Fragment>
	)
}
