import React, { useReducer, useEffect } from 'react'
import settings from '../../config/settings'
import { Constants } from '../../helpers/constants'

import TriggerAPI from '../../models/trigger'
import SmartlistAPI from '../../models/smartlist'
import ShortcodeAPI from '../../models/shortcode'
import EmailsAPI from '../../models/email'
import SmsAPI from '../../models/sms'
import CampaignAPI from '../../models/campaign'

import MultiSelect from 'react-multi-select-component'
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader'

const initialState = {
    error: '',
    success: '',
    form: {
        name: '',
        weekdays: Constants.calendarSettings.weekdays.reduce((acc, current) => ({...acc, [current]: false}), {}), // create object with day as key and value set to false
        frequency: '',
        submitted: false,
        id: 0,
        status: "IN-ACTIVE",
        date: '',
        hours: '',
        minutes: '',
        meridian: '',
        monthlyDetails: '',
        monthlyDetailsOther: '',
        startDate: '',
        endDate: '',
        shortCodeId: [],
        smsId: [],
        smartListIds: [],
        weeklyDetails: [],
        dateDetails: ''
    },
    config: {
        frequencies: [],
        shortCodes: [],
        smsTemplates: [],
        smartLists: [],
        weekdays: Constants.calendarSettings.weekdays,
        monthlyDay: Constants.calendarSettings.monthlyDay,
    },
    campaign: {
        startDate: '',
        endDate: '',
        campaignId: 0,
        campaignInd: 0
    },
    correspondenceLogData: [],
    isNew: true,
    redirect: '',
    tabState: true,
    log: {
        startDate: '',
        endDate: ''
    },
    loader: true
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'setNew':
            return {
                ...state,
                config: {
                    ...state.config,
                    frequencies: action.payload.frequencies,
                    smartLists: action.payload.smartLists,
                    shortCodes: action.payload.shortCodes,
                    smsTemplates: action.payload.smsTemplates,
                    emailTemplates: action.payload.emailTemplates,
                },
                // clientEmails: resolves[3].data.data.emails,
                // minDate: action.payload.minDate,
                // endMin: action.payload.endMin,
                // startMax: action.payload.startMax,
                sms: action.payload.sms,
                campaign: {
                    campaignId: action.payload.campaign.id,
                    campaignInd: action.payload.campaign.ind,
                    startDate: action.payload.min,
                    endDate: action.payload.max
                },
                isNew: action.payload.isNew,
                loader: false
            }
        case 'setEdit':
            return {
                ...state,
                config: {
                    ...state.config,
                    frequencies: action.payload.frequencies,
                    smartLists: action.payload.smartLists,
                    shortCodes: action.payload.shortCodes,
                    smsTemplates: action.payload.smsTemplates,
                    emailTemplates: action.payload.emailTemplates,
                },
                sms: action.payload.sms,
                campaign: {
                    campaignId: action.payload.campaign.id,
                    campaignInd: action.payload.campaign.ind,
                    startDate: action.payload.min,
                    endDate: action.payload.max
                },
                form: {
                    ...state.form,
                    name: action.payload.trigger.name,
                    frequency: action.payload.trigger.frequency,
                    startDate: action.payload.trigger.startDate,
                    endDate: action.payload.trigger.endDate,
                    shortCodeId: action.payload.trigger.shortCodeId,
                    smsId: action.payload.trigger.smsId,
                    smartListIds: action.payload.trigger.smartListIds,
                    hours: action.payload.trigger.time.hours,
                    minutes: action.payload.trigger.time.minutes,
                    meridian: action.payload.trigger.time.meridian,
                    monthlyDetails: action.payload.trigger.time.monthlyDetails,
                    monthlyDetailsOther: action.payload.trigger.time.monthlyDetailsOther,
                    date: (new Date(action.payload.trigger.dateDetails)).toISOString().substring(0, 10),
                    id: action.payload.id,
                    status: action.payload.trigger.status
                },
                isNew: false,
                loader: false
            }
        case 'updateForm':
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.name]: action.payload.value
                }
            }
        case 'updateUrl':
            return {
                ...state,
                redirect: action.payload
            }
        case 'setSubmitStatus':
            return {
                ...state,
                form: {
                    ...state.form,
                    submitted: action.payload
                }
            }
        case 'updateMultiSelect':
            const selectedInArray = action.payload.selectedInArray;

            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.parameter]: (selectedInArray.length === 0) ? [] : [selectedInArray[selectedInArray.length - 1]]
                }
            }
        case 'updateWeekday':
            return {
                ...state,
                form: {
                    ...state.form,
                    weekdays: {
                        ...state.form.weekdays,
                        [action.payload.day]: action.payload.checked
                    }
                }
            }
        case 'savedNewTrigger':
            return {
                ...state,
                isNew: false,
                success: 'Correspondence successfully saved!', //action.payload.success,
                //redirect: `${settings.subPath}/correspondences/${action.payload.id}/${state.campaign.campaignId}/${state.campaign.campaignInd}`,
                form: {
                    ...state.form,
                    id: action.payload.id,
                    submitted: true
                }
            }
        case 'setError':
            return {
                ...state,
                error: action.payload,
                form: {
                    ...state.form,
                    submitted: true
                }
            }
        case 'setLog':
            return {
                ...state,
                correspondenceLogData: action.payload
            }
        case 'updateTabState':
            return {
                ...state,
                tabState: action.payload.tabState
            }
        case 'updateExecutionForm':
            return {
                ...state,
                log: {
                    ...state.log,
                    [action.payload.name]: action.payload.value
                }
            }
        case 'filterError':
            return {
                ...state,
                log: {
                    ...state.log,
                    error: action.payload
                }
            }
        default:
            return {
                ...state
            }
    }
}

export default function CorrespondenceForm(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    /** Get all supplementary data for triggers, then call getTrigger */
    useEffect(() => {
        const campaignId = props.campaignId;
        const campaignInd = props.campaignIndex;
        const triggerId = props.triggerId;
        const isNew = triggerId === null || triggerId === 0;

        Promise.all([
            TriggerAPI().findAllMetadata(), 
            SmartlistAPI().findAll(), 
            ShortcodeAPI().findAll(), 
            EmailsAPI().findClientEmails(), 
            SmsAPI().findAll(), 
            EmailsAPI().findAll(), 
            TriggerAPI().findAll({campaignId}), 
            CampaignAPI().findAll()
        ]).then((resolves) => {
            // get campaign start and end date
            const [matchedCampaign] = resolves[7].data.data.campaigns.filter(campaign => (parseInt(campaignId) === parseInt(campaign.campaignId)));
            let campaignStartDate, campaignEndDate;
            if (matchedCampaign) {
                campaignStartDate = new Date(matchedCampaign.startDate);
                campaignEndDate = new Date(matchedCampaign.endDate);
            }

            let today = new Date();
            let minDate = new Date(campaignStartDate);
            minDate = minDate > today ? minDate : today;
            minDate = formatDate(minDate);
            let maxDate = new Date(campaignEndDate);
            maxDate = maxDate > today ? maxDate : today;
            maxDate = formatDate(maxDate);

            let sms = isNew ? {
                    messages: {},
                    isNew: true
                } : undefined;
            let smsTemplates = resolves[4].data.data.smsTemplate.map((sms) => ({...sms, label: sms.name, value: sms.smsId}));
            let smartLists = resolves[1].data.data.smartLists.map((sl) => ({...sl, label: sl.name, value: sl.smartListId}));
            let shortCodes = resolves[2].data.data.shortCodes.map((sc) => ({...sc, label: sc.number, value: sc._id}));

            if (!isNew) {
                const trigger = getTrigger(resolves[6].data.data.triggers, smsTemplates, smartLists, shortCodes, triggerId);

                dispatch({
                    type: 'setEdit', 
                    payload: {
                        trigger: trigger,
                        frequencies: resolves[0].data.data.frequencies,
                        smartLists: smartLists,
                        shortCodes: shortCodes,
                        clientEmails: resolves[3].data.data.emails,
                        smsTemplates: smsTemplates,
                        emailTemplates: resolves[5].data.data.emails,
                        sms: sms,
                        campaign: {
                            startDate: campaignStartDate,
                            endDate: campaignEndDate,
                            id: campaignId,
                            ind: campaignInd,
                        },
                        isNew: isNew,
                        min: minDate,
                        max: maxDate,
                        id: triggerId,
                        startDate: "",
                        endDate: ""
                    }
                });
                executeLog();
            } else {
                dispatch({
                    type: 'setNew', 
                    payload: {
                        frequencies: resolves[0].data.data.frequencies,
                        smartLists: smartLists,
                        shortCodes: shortCodes,
                        clientEmails: resolves[3].data.data.emails,
                        smsTemplates: smsTemplates,
                        emailTemplates: resolves[5].data.data.emails,
                        sms: sms,
                        campaign: {
                            startDate: campaignStartDate,
                            endDate: campaignEndDate,
                            id: campaignId,
                            ind: campaignInd,
                        },
                        isNew: isNew,
                        min: minDate,
                        max: maxDate
                    }
                });
            }

            
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** Get Triggers and then compare to find specific Trigger */
    const getTrigger = (data, smsTemplates, smartLists, shortCodes, triggerId) => {
        let trigger = data.filter(trigger => trigger._id === triggerId);
        trigger = (trigger.length > 0) ? trigger[0] : false;

        if (trigger) {
            trigger.smsId = selectValue(smsTemplates, trigger.templateId, "smsId");
            trigger.smartListIds = selectValue(smartLists, trigger.smartListIds[0], "smartListId");
            trigger.shortCodeId = selectValue(shortCodes, trigger.shortCodeId, "shortCodeId");

            trigger.time = {
                hours: trigger.time.substr(0,trigger.time.indexOf(':')).replace(/\s+/g, ''),
                minutes: trigger.time.substr(trigger.time.indexOf(':') + 1, trigger.time.indexOf(' ') - 2).replace(/\s+/g, ''),
                meridian: trigger.time.substr(trigger.time.indexOf(' ') + 1, trigger.time.length).replace(/\s+/g, '')
            };

            if (trigger.monthlyDetails !== 0) {
                trigger.monthlyDetails = trigger.monthlyDetails.toString();
                if(trigger.monthlyDetails !== 1 && trigger.monthlyDetails !== 15 && trigger.monthlyDetails !== 31) {
                    trigger.monthlyDetailsOther = trigger.monthlyDetails;
                    trigger.monthlyDetails = Number(-1).toString();
                }
            } else {
                delete trigger.monthlyDetails;
            }

            if (trigger.dateDetails) {
                trigger.dateDetails = new Date(trigger.dateDetails).toISOString().substr(0, 10);
            }
            if (trigger.startDate) {
                trigger.startDate = new Date(trigger.startDate).toISOString().substr(0, 10);
            }else {
                trigger.startDate = "";
            }
            if (trigger.endDate) {
                trigger.endDate = new Date(trigger.endDate).toISOString().substr(0, 10);
            }else {
                trigger.endDate = "";
            }
            if (trigger.weeklyDetails) {
                trigger.weeklyDetails.forEach(day => updateWeekday(day, true));
            }

            return trigger;
        } else {
            return false;
        }
    }

    const formatDate = (timestamp) => {
        let dateObject = new Date(timestamp);
        
        let date = dateObject.getUTCDate();
        date = `${date}`.padStart(2, 0);

        let month = dateObject.getUTCMonth() + 1;
        month = `${month}`.padStart(2, 0);

        const year = dateObject.getUTCFullYear();

        return `${year}-${month}-${date}`;
    }

    /** Find selected item for MultiSelect */
    const selectValue = (list, id, key) => list.filter(item => item[key] === id) || '';

    const isActiveOrComplete = () => ["ACTIVE", "COMPLETED"].includes(state.form.status);

    const getPayload = (form) => {
        const params = {
            name: form.name,
            campaignId: state.campaign.campaignId,
            frequency: form.frequency,
            time: `${form.hours}:${form.minutes} ${form.meridian}`,
            task: 'Send SMS',
            templateId: form.smsId[0].value,
            smartListIds: [form.smartListIds[0].value],
            shortCodeId: form.shortCodeId.length ? form.shortCodeId[0].value : null
        }

        if (form.id) {
            params.triggerId = form.id;
        }

        const isDateObject = (date) => typeof date === 'object'

        if (form.frequency === 'Once') {
            params.dateDetails = isDateObject(form.date) ? form.date.getTime() : (new Date(form.date)).getTime();
        } else if (form.frequency === 'Weekly') {
            var filteredByValue = Object.fromEntries(Object.entries(form.weekdays).filter(([key, value]) => value === true))
            params.weeklyDetails = Object.keys(filteredByValue);
        } else if (form.frequency === 'Monthly') {
            params.monthlyDetails = form.monthlyDetails  === "-1" ? form.monthlyDetailsOther : form.monthlyDetails;
        }

        if (form.frequency !== 'Once') {
            if (isDateObject(form.startDate)) form.startDate = form.startDate.getTime();
            if (isDateObject(form.endDate)) form.endDate = form.endDate.getTime();
            params.startDate = (isDateObject(form.startDate)) ? form.startDate.getTime() : (new Date(form.startDate)).getTime();
            params.endDate = (isDateObject(form.endDate)) ? form.endDate.getTime() : (new Date(form.endDate)).getTime();
        }

        return params;
    };

    const isValid = () => {
        const f = state.form;
        const common = f.name.length && f.hours.length && f.minutes.length && f.meridian.length && f.shortCodeId.length && f.smsId.length && f.smartListIds.length;
        const once = f.frequency === 'Once' &&  f.date.length;
        const daily = f.frequency === 'Daily' && f.startDate.length && f.endDate.length;
        const weekly = f.frequency === 'Weekly' && f.startDate.length && f.endDate.length && (Object.values(f.weekdays).filter(w => w).length > 0);
        const monthly = f.frequency === 'Monthly' && f.startDate.length && f.endDate.length && ((f.monthlyDetails !== '-1' && f.monthlyDetails.length) || (f.monthlyDetails === '-1' && f.monthlyDetails.length));
        
        return common && (once || daily || weekly || monthly);
    }

    /** Save Trigger */
    const ok = (evt) => {
        evt.preventDefault();

        if (isValid()) {
            const params = getPayload(state.form);
    
            TriggerAPI().save(params)
                .then((data) => {
                    if (data.data.statusCode === 200) {
                        dispatch({
                            type: 'savedNewTrigger',
                            payload: {
                                isNew: false,
                                success: data.data.data.message,
                                id: data.data.data.triggerId
                            }
                        });
                    } else {
                        dispatch({type: 'setError', payload: data.data.data.message})
                    }
                }, (data) => {
                    dispatch({ type: 'setError', payload: data.data.data.message })
                });
        } else {
            dispatch({type: 'setSubmitStatus', payload: true})
        }
    }

    const updateWeekday = (day, checked) => dispatch({type: 'updateWeekday', payload: {day, checked}});

    const executeLog = (params) => {
        const triggerId = state.form.id.toString();
        const requestParams = (params) ? {...params, sourceCode: triggerId} : {sourceCode: triggerId};

        TriggerAPI().getCorrespondenceExecutionLog(requestParams)
            .then((data) => {
                dispatch({type: 'setLog', payload: data.data.data.output});
            }, (data) => {
                dispatch({type: 'setError', payload: data.data.data.message});
            });
    }

    const downloadLog = (evt, id) => {
        evt.stopPropagation();
        TriggerAPI().downloadLog(id)
        .then(rsp => {
            console.log(rsp.data);
            let file = new Blob([rsp.data], { type: 'application/zip' })
            let fileName = rsp.headers['file-name'];
            let url = URL.createObjectURL(file);
            let download = document.createElement('a');
            download.href = url;
            download.download = fileName;
            download.click();
        })
        .catch(rsp => {
            dispatch({ type: 'setError', payload: rsp });
        })
    }

    const filterLogs = (evt) => {
        evt.preventDefault();

        const timestamp = date => (new Date(date)).getTime();
        
        if (state.log.startDate && state.log.endDate) {
            const params = {
                startDate: timestamp(state.log.startDate),
                endDate: timestamp(state.log.endDate)
            };

            executeLog(params);
        }

        dispatch({type: 'filterError', payload: !(state.log.startDate && state.log.endDate)})
    }

    const updateMultiSelect = (selectedInArray, parameter) => {
        dispatch({
            type: 'updateMultiSelect',
            payload: {
                selectedInArray,
                parameter
            }
        })
    }

    const updateTabState = (evt) => {
        evt.preventDefault();

        if (evt.target.name === 'CorrespondenceRules' && !state.tabState) {
            dispatch({type: 'updateTabState', payload: {tabState: true}})
        }
        if (evt.target.name === 'ExecutionLog' && state.tabState) {
            dispatch({type: 'updateTabState', payload: {tabState: false}})
        }
    }

    return (
        <div data-testid="CorrespondenceForm">
            {state.redirect && <Redirect to={state.redirect} />}
            {(state.loader) && <Loader />}

            <h1 data-testid="CorrespondenceForm.Title" className="text-center">
                {state.isNew ? 'Create New' : (isActiveOrComplete() ? 'View': 'Edit')} Correspondence
            </h1>

            { state.error && state.form.submitted && <div className="error-field form-group">{state.error}</div> }
            { state.success && state.form.submitted && <div className="success-field form-group">{state.success}</div> }

            <form noValidate name="form" onSubmit={ok}>

                <div className="record-title">
                    <div className="row">
                        <div className="col-xs-12 form-group">
                            <label>Name</label>
                            <input 
                                required 
                                name="name"
                                value={state.form.name}
                                onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                maxLength="30"
                                className="form-control" 
                                placeholder="Enter a correspondence name"
                                disabled={isActiveOrComplete()} />
                            { 
                                (state.form.submitted && !state.form.name.length) && 
                                    <div className="error-field">Please fill out this field</div> 
                            }
                        </div>
                    </div>
                </div>

                {
                    (!state.isNew) && (
                        <div className="row row-smartListNav">
                            <ul className={["nav nav-tabs nav-justified", (window.innerWidth < 768) ? 'nav-stacked' : 'nav-justified'].join(" ")}>
                                <li className={state.tabState ? 'active' : ''}>
                                    <a href="/" name='CorrespondenceRules' onClick={updateTabState}>Correspondence Rules</a>
                                </li>
                                <li className={!state.tabState ? 'active' : ''}>
                                    <a href="/" name='ExecutionLog' onClick={updateTabState}>Execution Log</a>
                                </li>
                            </ul>
                        </div>
                    )
                }

                <div className="row row-smartListNav" justified="true">
                    {
                        (state.isNew || state.tabState) && (
                            <div heading="Correspondence Rules">
                                <div className="row row-bordered">
                                    <div className="col-sm-6 form-group">
                                        <label>Frequency</label>
                                        <div className={["styled-select", isActiveOrComplete() && 'disabled'].join(" ")}>
                                            <select 
                                                required 
                                                name="frequency" 
                                                value={state.form.frequency} 
                                                onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                disabled={isActiveOrComplete()}>
                                                <option value='' disabled>Select a frequency</option>
                                                {
                                                    state.config.frequencies.map(frequency => <option key={frequency} value={frequency}>{frequency}</option>)
                                                }
                                                
                                            </select>
                                            <div className="arrow"></div>
                                        </div>
                                        {(state.form.submitted && !state.form.frequency.length) && (
                                            <div><div className="error-field">Please fill out this field</div></div>
                                        )}
                                        
                                    </div>
                                    
                                    {
                                        (state.form.frequency.length > 0) && (
                                            <span>
                                                <div className="col-sm-2 col-xs-4 form-group">
                                                    <label>Hours</label>
                                                    <div className={["styled-select", isActiveOrComplete() && 'disabled'].join(" ")}>
                                                        <select 
                                                            required
                                                            name="hours"
                                                            disabled={isActiveOrComplete()}
                                                            value={state.form.hours}
                                                            onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}>
                                                            <option value='' disabled>Select an hour</option>
                                                            {
                                                                (new Array(12)).fill(0).map((item, index) => <option key={index} value={index + 1}>{index + 1}</option>)
                                                            }
                                                        </select>
                                                        <div className="arrow"></div>
                                                    </div>
                                                    {
                                                        (state.form.submitted && !state.form.hours.length) ?
                                                            <div className="error-field">Please fill out this field</div> : ''
                                                    }
                                                </div>
                                                <div className="col-sm-2 col-xs-4 form-group">
                                                    <label>Minutes</label>
                                                    <div className={["styled-select", isActiveOrComplete() && 'disabled'].join(" ")}>
                                                        <select 
                                                            required 
                                                            name="minutes" 
                                                            value={state.form.minutes} 
                                                            disabled={isActiveOrComplete()} 
                                                            onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}>
                                                            <option value='' disabled>Select a minute</option>
                                                            <option value="00">00</option>
                                                            {
                                                                (new Array(5)).fill(0).map((item, index) => <option key={index} value={(index + 1) * 10}>{(index + 1) * 10}</option>)
                                                            }
                                                        </select>
                                                        <div className="arrow"></div>
                                                    </div>
                                                    {
                                                        (state.form.submitted && state.form.minutes === '') ? <div className="error-field">Please fill out this field</div> : ''
                                                    }
                                                </div>
                                                <div className="col-sm-2 col-xs-4 form-group">
                                                    <label>AM/PM</label>
                                                    <div className={["styled-select", isActiveOrComplete() ? 'disabled':''].join(" ")}>
                                                        <select 
                                                            required 
                                                            name="meridian" 
                                                            value={state.form.meridian} 
                                                            disabled={isActiveOrComplete()} 
                                                            onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}>
                                                            <option value='' disabled>Select AM or PM</option>
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                        <div className="arrow"></div>
                                                    </div>
                                                    {
                                                        (state.form.meridian.length === 0) && state.form.submitted && <div className="error-field">Please fill out this field</div>
                                                    }
                                                </div>
                                            </span>
                                        )
                                    }

                                    {
                                        (state.form.frequency === "Once") && (
                                            <div className="col-sm-6">
                                                <label>Date</label>
                                                <div className="form-group">
                                                    {
                                                        (state.form.minDate) ? (
                                                            <input 
                                                                required={state.form.frequency === 'Once'} 
                                                                name="date" 
                                                                min={state.campaign.startDate} 
                                                                max={state.campaign.endDate}
                                                                placeholder="Select a date" 
                                                                type="date" 
                                                                className="form-control" 
                                                                value={state.form.date}
                                                                onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                                disabled={isActiveOrComplete()} />
                                                        ) : (
                                                            <input 
                                                                required={state.form.frequency === 'Once'} 
                                                                name="date" 
                                                                min={state.campaign.startDate}
                                                                max={state.campaign.endDate}
                                                                placeholder="Select a date" 
                                                                type="date" 
                                                                className="form-control" 
                                                                value={state.form.date}
                                                                onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                                disabled={isActiveOrComplete()} />
                                                        )
                                                    }
                                                    
                                                    {
                                                        state.form.submitted && !state.form.date.length && <div className="error-field">Please fill out this field</div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        (state.form.frequency === "Weekly") && (
                                            <div className="col-sm-12 form-group">
                                                {
                                                    state.config.weekdays.map(weekday => (
                                                        <label className="checkbox-inline" key={weekday}>
                                                            <input 
                                                                required={state.form.frequency === "Weekly"} 
                                                                name="weekday" 
                                                                type="checkbox" 
                                                                disabled={isActiveOrComplete()} 
                                                                value={weekday} 
                                                                checked={state.form.weekdays[weekday]} 
                                                                onChange={(evt) => updateWeekday(evt.target.value, evt.target.checked) } /> {weekday}
                                                        </label>
                                                    ))
                                                }
                                                {
                                                    (state.form.submitted && Object.values(state.form.weekdays).filter(d => d).length === 0) ? 
                                                        <div className="error-field">Please fill out this field</div> : ''
                                                    }
                                            </div>
                                        )
                                    }
                                    
                                    {
                                        (state.form.frequency === "Monthly") && (
                                            <div>
                                                <div className="col-sm-6 form-group">
                                                    <label>Month</label>
                                                    <div className={["styled-select", isActiveOrComplete() && 'disabled'].join(" ")}>
                                                        <select 
                                                            required={state.form.frequency === 'Monthly'} 
                                                            name="monthlyDetails" 
                                                            value={state.form.monthlyDetails} 
                                                            disabled={isActiveOrComplete()} 
                                                            onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}>
                                                            <option value='' disabled>Select a point in the month</option>
                                                            { state.config.monthlyDay.map(day => <option key={day.numeric} value={day.numeric}>{day.relative}</option>) }
                                                        </select>
                                                        <div className="arrow"></div>
                                                    </div>
                                                    { (state.form.submitted && !state.form.monthlyDetails.length) && <div className="error-field">Please fill out this field</div> }
                                                </div>
                                                {
                                                    (state.form.frequency === "Monthly") && (state.form.monthlyDetails === "-1") && (
                                                        <div className="col-sm-6">
                                                            <label>Day</label>
                                                            <div className={['styled-select', isActiveOrComplete() ? 'disabled' : ''].join(" ")}>
                                                                <select 
                                                                    required={state.form.monthlyDetails === "-1"} 
                                                                    name="monthlyDetailsOther" 
                                                                    value={state.form.monthlyDetailsOther} 
                                                                    disabled={isActiveOrComplete()} 
                                                                    onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}>
                                                                    <option value='' disabled>Select a day of the month</option>
                                                                    { (new Array(27)).fill(0).map((i, ind) => <option key={ind} value={ind + 2}>{ind + 2}</option>) }
                                                                </select>
                                                                <div className="arrow"></div>
                                                            </div>
                                                            { state.form.submitted && !state.form.monthlyDetailsOther.length && <div className="error-field">Please fill out this field</div> }
                                                            
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                        )
                                    }
                                </div>

                                {
                                    (state.form.frequency !== 'Once') && (
                                        <div className="row row-bordered">
                                            <div className="col-sm-6">
                                                <label>Start Date</label>
                                                <div className="form-group">
                                                    <input 
                                                        required={state.form.frequency !== 'Once'} 
                                                        name="startDate" 
                                                        placeholder="Select a start date" 
                                                        type="date" 
                                                        className="form-control" 
                                                        value={state.form.startDate} 
                                                        onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                        disabled={isActiveOrComplete()}
                                                        min={state.campaign.startDate} 
                                                        max={state.campaign.endDate} /> 
                                                    {
                                                        !state.form.startDate.length && state.form.submitted && 
                                                            <div className="error-field">Please fill out this field</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>End Date</label>
                                                <div className="form-group">
                                                    <input 
                                                        required={state.form.frequency !== 'Once'} 
                                                        name="endDate" 
                                                        placeholder="Select an end date" 
                                                        type="date" 
                                                        className="form-control" 
                                                        value={state.form.endDate} 
                                                        onChange={evt => dispatch({type: 'updateForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                        disabled={isActiveOrComplete()} 
                                                        min={state.campaign.startDate} 
                                                        max={state.campaign.endDate} /> 
                                                    {
                                                        (state.form.submitted && !state.form.endDate.length) ? <div className="error-field">Please fill out this field</div> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                                <div className="row row-bordered">
                                    <div className="form-group col-sm-6">
                                        <label>Short Code</label>
                                        <MultiSelect
                                            disabled={isActiveOrComplete()}
                                            options={state.config.shortCodes}
                                            value={state.form.shortCodeId}
                                            onChange={(a) => updateMultiSelect(a, 'shortCodeId')}
                                            labelledBy={"Select a short code"}
                                            hasSelectAll={false}
                                            required
                                            name="shortCodeId"
                                        />
                                        {
                                            (state.form.submitted && state.form.shortCodeId.length === 0) &&
                                                <div className="error-field">Please fill out this field</div>
                                        }
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>Template</label>
                                        <MultiSelect
                                            disabled={isActiveOrComplete()}
                                            options={state.config.smsTemplates}
                                            value={state.form.smsId}
                                            onChange={(a) => updateMultiSelect(a, 'smsId')}
                                            labelledBy={"Select an SMS template code"}
                                            hasSelectAll={false}
                                            required
                                            name="smsId"
                                        />
                                        { state.form.submitted && (state.form.smsId.length === 0) && <div className="error-field">Please fill out this field</div> }
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label>Smart List</label>

                                        <MultiSelect
                                            disabled={isActiveOrComplete()}
                                            options={state.config.smartLists}
                                            value={state.form.smartListIds}
                                            onChange={(a) => updateMultiSelect(a, 'smartListIds')}
                                            labelledBy={"Select a smart list"}
                                            hasSelectAll={false}
                                            required
                                            name="smartListId"
                                        />
                                        { (state.form.submitted && state.form.smartListIds.length === 0) && <div className="error-field">Please fill out this field</div> }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (!state.isNew && !state.tabState) && (
                            <div heading="Execution Log">
                                <div className="row row-bordered">
                                    <div className="col-sm-4 form-group">
                                        <label>Start Date</label>
                                        <p className="form-group">
                                            <input 
                                                name="startDate" 
                                                placeholder="Select a start date" 
                                                type="date" 
                                                className="form-control" 
                                                value={state.log.startDate}
                                                onChange={evt => dispatch({type: 'updateExecutionForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                min={state.form.startDate} 
                                                max={state.log.endDate} />
                                        </p>
                                    </div>
                                    <div className="col-sm-4 form-group">
                                        <label>End Date</label>
                                        <p className="form-group">
                                            <input name="endDate" 
                                                placeholder="Select an end date" 
                                                type="date" 
                                                className="form-control" 
                                                value={state.log.endDate}
                                                onChange={evt => dispatch({type: 'updateExecutionForm', payload: {name: evt.target.name, value: evt.target.value}})}
                                                min={state.log.startDate} 
                                                max={state.campaign.endDate} />
                                        </p>
                                    </div>
                                    <div className="col-sm-4 form-group">
                                        <label className="col-xs-12 hidden-xs">&nbsp;</label>
                                        <div className="full-btn btn btn-primary btn-lg" onClick={filterLogs}>
                                            <i className="fa fa-filter fa-lg"></i> Filter
                                        </div>
                                    </div>
                                </div>
                                {
                                    (state.log.error) && <div className="error-field col-xs-12 form-group">Please fill out both the start and end date.</div>
                                }
                                <div className="no-pager">
                                    <div className="row table-header">
                                        <div className="col-md-4 col-xs-4">Source Name</div>
                                        <div className="col-md-3 hidden-sm hidden-xs"># of Recipients</div>
                                        <div className="col-md-3 col-xs-4 text-right">Date Range</div>
                                        <div className="col-md-2 col-xs-4 text-right"></div>
                                    </div>

                                    <div className="panel-group accordion" id="collapse-panel" data-test-id="CorrespondenceForm.NoLogs">
                                        {
                                            (state.correspondenceLogData.length > 0) && (
                                                <div className="panel panel-default">
                                                    {
                                                        // TODO: paginate log results
                                                        state.correspondenceLogData.map(log => (
                                                            <div className="panel-heading no-click">
                                                                <div className="row record">
                                                                    <div className="col-md-4 col-xs-4">{log.SMART_LIST_NAME}</div>
                                                                    <div className="col-md-3 hidden-sm hidden-xs">{log.NUMBER_OF_RECIPIENTS}</div>
                                                                    {
                                                                        (log.START_TIME === log.END_TIME) ? 
                                                                            <div className="col-md-3 col-xs-4 text-right">{log.START_TIME}</div> : 
                                                                            <div className="col-md-3 col-xs-4 text-right">{log.START_TIME} &#8211; {log.END_TIME}</div>
                                                                    }
                                                                    
                                                                    <div className="col-md-2 col-xs-4 text-right">
                                                                        <span className="fa-wrap"><i title="Download Log" className="fa fa-download fa-lg" onClick={evt => downloadLog(evt, log.BLAST_ID)}></i></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            (state.correspondenceLogData && state.correspondenceLogData.length === 0) && (
                                                <div data-test-id="CorrespondenceForm.NoLogs" className="panel panel-default">
                                                    <div className="panel-heading empty">
                                                        <div className="row record">
                                                            <div className="col-xs-12">This execution log contains no records since you last saved.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                {
                    (isActiveOrComplete()) ? (
                        <div className="row button-row">
                            <div className="col-xs-12 form-group text-center">
                                {/* <button type="button" className="btn btn-default btn-lg" onClick={_ => dispatch({type: 'updateUrl', payload: `${settings.subPath}/campaigns`})}>Back</button> */}
                                <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Back</button>
                            </div>
                        </div>
                    ) : (
                        <div className="row button-row">
                            <div className="col-xs-12 form-group">
                                <div className="col-xs-6 text-right pull-left">
                                    <button type="submit" className="btn btn-primary btn-lg">Save</button>
                                </div>
                                <div className="col-xs-6 text-left pull-left">
                                    {/* <button type="button" className="btn btn-default btn-lg" onClick={_ => dispatch({type: 'updateUrl', payload: `${settings.subPath}/campaigns`})}>Cancel</button> */}
                                    <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </form>
        </div>
    )
}
