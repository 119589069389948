import React from 'react'
import CampaignsAPI from '../../../models/campaign'

export default function CampaignModal(props) {
    const updateField = (key, value) => {
        props.rerender({
            ...props,
            form: {
                ...props.form,
                [key]: value
            }
        });
    }

    const valid = () => {
        return props.form.name.length && props.form.start.length && props.form.end.length;
    }

    const ok = (evt) => {
        evt.preventDefault();

        if (valid()) {
            if (props.formType === 'edit') {
                const params = {...props.campaign};
                params.endDate = (new Date(props.form.end)).getTime();
                params.name = props.form.name;
                params.startDate = (new Date(props.form.start)).getTime();

                CampaignsAPI().save(params).then((data) => {
                    if (data.data.statusCode === 200) {
                        const updatedCampaign = data.data.data.campaign;
                        props.action("updateCampaign", {
                            id: updatedCampaign.campaignId,
                            startDate: updatedCampaign.startDate,
                            endDate: updatedCampaign.endDate,
                            name: updatedCampaign.name
                        });
                        props.hide();
                    } else {
                        props.rerender({
                            ...props,
                            error: data.data.message,
                            form: {
                                ...props.form,
                                submitted: true
                            }
                        });
                    }
                }, (data) => {
                    props.action('setError', data.data.message);
                });

            } else {
                const params = {
                    endDate: (new Date(props.form.end).getTime()),
                    name: props.form.name,
                    startDate: (new Date(props.form.start)).getTime()
                };
                CampaignsAPI().save(params).then((data) => {
                    if (data.data.statusCode === 200) {
                        const newCampaign = data.data.data.campaign;
                        newCampaign.communications = {
                            SIMPLE: [],
                            JOURNEY: [],
                            CONTEST: [],
                            TRIVIA: [],
                            TRIGGER: []
                        };
                        newCampaign.key = (new Date()).getTime();
                        props.action("addNewCampaign", newCampaign);
                        props.hide();
                    } else {
                        props.rerender({
                            ...props,
                            error: data.data.message,
                            form: {
                                ...props.form,
                                submitted: true
                            }
                        });
                    }
                }, (data) => {
                    props.action('setError', data.data.message);
                });
            } 
        } else {
            props.rerender({
                ...props,
                form: {
                    ...props.form,
                    submitted: true
                },
                error: ''
            });
        }
    } 

    return (
        <div>
            <div className="modal-header">
                <i onClick={props.hide} className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close"></i>
                <h3>{ (props.formType === 'edit') ? "Edit" : "Create New" } Campaign</h3>
                { (props.error) ? <div className="error-field">{props.error}</div> : "" }
            </div>

            <form noValidate name="form" onSubmit={ok}>
                <div className="modal-body">
                    <div className="form-horizontal">
                        <div className="form-group">
                            <div className="col-sm-12">
                                <label>Name</label>
                                <input type="text" required name="name" value={props.form.name} onChange={evt => updateField('name', evt.target.value)} minLength="30" className="form-control" placeholder="Enter a campaign name" />
                                { (props.form.submitted && !props.form.name.length) ? <div className="error-field">Please fill out this field (maximum 30 characters)</div> : null }
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-sm-6">
                                <label>Start Date</label>
                                <input type="date" className="form-control" value={props.form.start} onChange={(evt) => updateField('start', evt.target.value)} />
                                { (props.form.submitted && !props.form.start.length) ? <div className="error-field">Please fill out this field</div> : "" }
                                
                            </div>
                            <div className="form-group visible-xs"></div>
                            <div className="col-sm-6">
                                <label>End Date</label>
                                <input type="date" className="form-control" value={props.form.end} onChange={(evt) => updateField('end', evt.target.value)} />
                                { (props.form.submitted && !props.form.end.length) ? <div className="error-field">Please fill out this field</div> : "" }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
                    <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
                </div>
            </form>

        </div>
    );    
    
}
