import React, { useReducer, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import NoResults from '../settings/CompNoResults'
import { ModalContext } from '../../context/modal'
import { AuthContext } from '../../context/auth'
import GatewayClientsAPI from '../../models/gwclients'
import GatewayClientsModal from '../modals/GatewayClientsModal'
import DeleteModal from '../modals/DeleteModal'
import Loader from '../../components/Loader'

// import ListInputFilter from '../../components/ListInputFilter'
import ResponseMessage from '../../components/ResponseMessage'

const initialState = {
  defaultClients: [],
  clients: [],
  search: '',
  filter: 'All',
  error: '',
  success: false,
  submitted: false,
  page: 1,
  forcedPage: 0,
  pageMax: 10,
  pageTotal: 1,
  showLoader: true
};

function reducer(state, action) {
  let defaults = [...state.defaultClients];
  let current = [...state.clients];
  switch (action.type) {
    case 'setData':
      let clientAdded = (action.payload.length - defaults.length) === 1;
      let totalPages = Math.ceil(action.payload.length / state.pageMax);
      let currentControlPage = clientAdded ? (totalPages - 1) : (state.page - 1);
      let currentPage = clientAdded ? totalPages : state.page;
      return {
        ...state,
        defaultClients: action.payload,
        clients: action.payload,
        pageTotal: totalPages,//Math.ceil(action.payload.length / state.pageMax),
        page: currentPage,
        forcedPage: currentControlPage,
        filter: 'All',
        showLoader: false
      };
    case 'setStatus':
      defaults = defaults.map(d => (d._id === action.id) ? { ...d, status: action.status } : { ...d })
      current = current.map(d => (d._id === action.id) ? { ...d, status: action.status } : { ...d })
      let filteredCurrent = (state.filter !== 'All') ? current.filter((e) => e.status === state.filter) : current;
      return {
        ...state,
        defaultClients: defaults,
        clients: filteredCurrent,
        pageTotal: Math.ceil(filteredCurrent.length / state.pageMax),
      };
    case 'setRemove':
      defaults = defaults.filter(d => d._id !== action.payload);
      current = current.filter(c => c._id !== action.payload);
      return {
        ...state,
        defaultClients: defaults,
        clients: current,
        pageTotal: Math.ceil(current.length / state.pageMax),
      };
    case 'setFilter':
      let updatedList = defaults.filter((e) => e.status === action.payload);
      if (action.payload === 'All') {
        updatedList = defaults;
      }
      return {
        ...state,
        clients: updatedList,
        page: 1,
        forcedPage: 0,
        pageTotal: Math.ceil(updatedList.length / state.pageMax),
        filter: action.payload
      };
    case 'getSearch':
      let clients = defaults.filter(e => (e.name && e.name.toLowerCase().includes(action.term)) ||
        (e.email && e.email.toLowerCase().includes(action.term)));
      return {
        ...state,
        clients: clients,
        search: action.term,
        page: 1,
        forcedPage: 0,
        pageTotal: Math.ceil(clients.length / state.pageMax),
      };
    case 'sortPrograms':
      const sortMethod = field => {
          return (a, b) => {
              const a1 = field === 'gatewayAccounts' ? a[field][0] : a[field].toLowerCase();
              const b1 = field === 'gatewayAccounts' ? b[field][0] : b[field].toLowerCase();

              if (a1 <= b1) {
                  return -1
              } else if (b1 < a1) {
                  return 1
              } else {
                  return 0
              }
          };
      }

      const sortReverseMethod = field => {
          return (b, a) => {
              const a1 = field === 'gatewayAccounts' ? a[field][0] : a[field].toLowerCase();
              const b1 = field === 'gatewayAccounts' ? b[field][0] : b[field].toLowerCase();

              if (a1 <= b1) {
                  return -1
              } else if (b1 < a1) {
                  return 1
              } else {
                  return 0
              }
          };
      }

      let sortedClients = (action.payload.sortOrder === 'A') 
                              ? [...state.clients].sort(sortMethod(action.payload.sortField)) 
                              : [...state.clients].sort(sortReverseMethod(action.payload.sortField));

      return {
          ...state,
          clients: sortedClients
      }      
    case 'setError':
      return {
        ...state,
        error: action.payload
      };
    case 'setPage':
      return {
        ...state,
        page: action.page,
        forcedPage: action.page - 1
      };
    default:
      throw new Error();
  }
}

export default function GatewayClients() {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { toggleModal, setModalContent, setModalWidth } = React.useContext(ModalContext);
  let { currentUser } = React.useContext(AuthContext);

  /** Get Client Data **/
  const getClientData = useCallback(() => {
    GatewayClientsAPI().findAll()
      .then((data) => {
        dispatch({ type: 'setData', payload: data.data.data.gatewayClients });
      })
      .catch(err => {
        dispatch({ type: 'setError', payload: err });
      });
  }, []);

  useEffect(() => {
    getClientData();
  }, [getClientData]);

  const changeFilter = (event) => {
    event.preventDefault();
    dispatch({ type: 'setFilter', payload: event.target.value });
  }

  const setClientStatus = (params) => {
    params.event.preventDefault();
    params.event.stopPropagation();
    let param = params.client;
    param.status = params.status ? "ACTIVE" : "DISABLED";
    GatewayClientsAPI().save(param)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({ type: 'setStatus', id: param._id, status: param.status });
        } else {
          dispatch({ type: 'setError', payload: 'ERROR' });
        }
      })
      .catch(err => {
        dispatch({ type: 'setError', payload: 'SERVICE ERROR' });
      });
  };

  const removeClient = (evt, id, name) => {
    evt.stopPropagation();

    setModalContent(DeleteModal({
      hide: () => toggleModal(false),
      message: `Are you sure you want to delete the gateway account ${name}?`,
      action: () => {
        GatewayClientsAPI().destroy(id)
          .then((data) => {
            if (data.data.statusCode === 200) {
              dispatch({ type: 'setRemove', payload: id });
              toggleModal(false);
            } else {
              dispatch({ type: 'setError', payload: 'ERROR' });
            }
          })
          .catch(_ => {
            dispatch({ type: 'setError', payload: 'SERVICE ERROR' });
          });
      }
    }));
    toggleModal(true);
  }

  /** Add Client */
  const addClient = (params) => {
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalWidth("modal-lg");
    renderAddEditClient({ client: '', isNew: true });
  };

  const isCurrentUserAdmin = () => currentUser.roles.toLowerCase() === 'admin';

  /** Edit Client */
  const editClient = (params) => {
    if(!isCurrentUserAdmin())
        return;
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalWidth("modal-lg");
    renderAddEditClient({ client: params.client, isNew: false });
  };

  const renderAddEditClient = (data) => {
    setModalContent(GatewayClientsModal({
      typemessage: data.isNew ? "Add New " : "Edit ",
      hide: () => toggleModal(false),
      client: (data && data.client) ? data.client : { name: '', code: '', accounts: [] },
      error: (data && data.error) ? data.error : '',
      submitted: (data && data.submitted) ? true : false,
      success: () => {
        toggleModal(false);
        getClientData();
      },
      rerender: (p) => renderAddEditClient(p),
      action: (params) => {
        params.event.preventDefault();
        return GatewayClientsAPI().save(params.data)
      },
      isNew: (data && data.isNew) || true
    }));
  }

  //Checks if index is within the current SMS page view
  const listingInRange = (index) => {
    return (index >= ((state.page - 1) * state.pageMax) && index < (state.page * state.pageMax))
  }

  //Change current SMS page view
  const changeSmsPage = (page) => {
    dispatch({ type: 'setPage', page: (page.selected + 1) });
  }

  const downloadList = evt => {
    evt.preventDefault();

    GatewayClientsAPI().download()
      .then(rsp => {
        let file = new Blob([rsp.data], { type: 'application/zip' })
        let fileName = rsp.headers['file-name'];
        let url = URL.createObjectURL(file);
        let download = document.createElement('a');
        download.href = url;
        download.download = fileName;
        download.click();
      })
      .catch(rsp => {
        dispatch({ type: 'setError', payload: rsp });
      })
  }

  const sortPrograms = (sortOrder, sortField) => {
    dispatch({ type: 'sortPrograms', payload: { sortField, sortOrder } });
  }

  return (
    <React.Fragment>
      {state.showLoader && <Loader />}

      <div className="row row-top">
        <div className="col-md-6">
          <h3>Gateway Accounts</h3>
        </div>
        <div className="col-md-3">
          <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={(event) => addClient({ event: event })}>
            CREATE GATEWAY ACCOUNT
          </button>
        </div>
        <div className="col-md-2">
          <div className="form-horizontal">
            <label className="sr-only control-label" htmlFor="clientsFilter">Status:</label>
            <div className="styled-select">
              <select data-testid="clients-filter" name="clientsFilter" value={state.filter} onChange={changeFilter}>
                <option value="All">All</option>
                <option value="ACTIVE">Active</option>
                <option value="DISABLED">Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-1 text-right">
          <button type="button" title="Download Accounts" onClick={downloadList}>
            <i className="fa fa-fw fa-download font-lg" data-testid="clients-download"></i>
          </button>
          {/* <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={downloadList}>
              Export
          </button> */}
        </div>
      </div>

      {state.error &&
        <ResponseMessage
          validation={state.error}
          message={state.error}
          class="error-field" />
      }

      <div className="row table-header">
        <div className="col-md-4 col-xs-6">
          Name
          <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'name')}>
            <i className="up-arrow">&larr;</i>
          </span>
          <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'name')}>
            <i className="down-arrow">&larr;</i>
          </span>
        </div>
        <div className="col-md-4 hidden-sm hidden-xs">
          Account Numbers
          <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'gatewayAccounts')}>
            <i className="up-arrow">&larr;</i>
          </span>
          <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'gatewayAccounts')}>
            <i className="down-arrow">&larr;</i>
          </span>
        </div>
        <div className="col-md-3 hidden-sm hidden-xs">
          Billing Code
          <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'code')}>
            <i className="up-arrow">&larr;</i>
          </span>
          <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'code')}>
            <i className="down-arrow">&larr;</i>
          </span>
        </div>
        <div className="col-md-1 col-xs-6 text-right"></div>
      </div>

      <div className="panel-group accordion" id="collapse-panel" data-testid="data-list">
        {state.clients && state.clients.map((client, key) => (listingInRange(key)) ? (
          <div className="panel panel-default" key={key}>
            <div data-testid={`data-list-${key}`} className={`${client.status === 'DISABLED' ? 'record-draft' : ''} ${client.status === 'ACTIVE' ? 'record-active' : ''} panel-heading`} onClick={(event) => editClient({ event: event, client: client })}>
              <div className="row record">
                <div data-testid={`data-list-${key}-name`} className="col-md-4 col-xs-6">{client.name}</div>
                <div className="col-md-4 hidden-sm hidden-xs">{client.gatewayAccounts && client.gatewayAccounts.join(", ")}</div>
                <div className="col-md-3 hidden-sm hidden-xs">{client.code}</div>
                <div className="col-md-1 col-xs-6 text-right">
                  { isCurrentUserAdmin() && client.status === 'ACTIVE' &&
                    <span className="fa-wrap highligted-text-red">
                      <i data-testid={`data-list-${key}-active`} className="fa fa-stop fa-lg" onClick={(event) => setClientStatus({ event: event, status: false, client: client })} title="Deactivate Account"></i>
                    </span>
                  }
                  { isCurrentUserAdmin() && client.status === 'DISABLED' &&
                    <span className="fa-wrap highligted-text-green">
                      <i data-testid={`data-list-${key}-disabled`} className="fa fa-play fa-lg" onClick={(event) => setClientStatus({ event: event, status: true, client: client })} title="Activate Account"></i>
                    </span>
                  }
                  { isCurrentUserAdmin() && <span className="fa-wrap">
                    <i className="fa fa-trash-o fa-lg" onClick={evt => removeClient(evt, client._id, client.name)} title="Delete Account"></i>
                  </span>
}
                </div>
              </div>
            </div>
          </div>
        ) : ''
        )}
        {state.clients.length === 0 &&
          <NoResults
            message="There are no accounts to display yet." />
        }
      </div>

      {state.clients.length !== 0 &&
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={state.pageTotal}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={state.forcedPage}
          onPageChange={changeSmsPage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      }

    </React.Fragment>
  )
}
