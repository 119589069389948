import API from '../services/api'

export default function TriggerAPI() {
    let Trigger = {};

    Trigger.findAllMetadata = function () {
        return API.findAll("getTriggerMetadata");
    };

    Trigger.findAll = function (params) {
        return API.findAll("getTriggers", params);
    };

    Trigger.save = function (triggerData) {
        return API.save('saveTrigger', triggerData);
    };

    Trigger.updateStatus = function (triggerData) {
        return API.save('setTriggerStatus', triggerData);
    };

    Trigger.destroy = function (triggerId) {
        return API.destroy('deleteTrigger', 'triggerId', triggerId);
    };
    
    Trigger.getCorrespondenceExecutionLog = function (triggerData) {
        return API.save('getCorrespondenceExecutionLog', triggerData);
    };

    Trigger.downloadLog = function(id) {
        var download = {
            action: "getCorrespondenceReport",
            params: {
                blastId: id
            }
        };

        return API.download(download, 'application/zip', 'download');
    };

    return Trigger;

}
