import API from '../services/api'

export default function KeywordsAPI() {
    var Keyword = {};

    Keyword.findAll = function (params) {
        return API.findAll("getAllKeywords", params);
    };

    Keyword.removeKeyword = function (param) {
        return API.findAll("removeKeyword", param);
    };

    return Keyword;
}
