import React from 'react'

import Helpers from '../helpers/helpers'

export default function DateRange(props) {
  const maxEndDate = Helpers().FormatDate(new Date().getTime());
	return (
    <div className="record-title">
      <div className="row">
        <div className="col-sm-4 form-group">
          <label>Start Date</label>
          <input
            data-testid="startDate" 
            name="startDate" 
            type="date" 
            className="form-control" 
            value={props.start} 
            max={props.end} 
            onChange={(event) => props.setDate(event)}
          />
        </div>
        <div className="col-sm-4 form-group">
          <label>End Date</label>
          <input
            data-testid="endDate" 
            name="endDate" 
            type="date" 
            className="form-control" 
            value={props.end} 
            min={props.start} 
            max={maxEndDate}
            onChange={(event) => props.setDate(event)}
          />
        </div>
        <div className="col-sm-4 form-group">
          <label>Date Range</label>
          <div className="styled-select">
            <select
              data-testid="setDays" 
              name="chart"
              value={props.choice} 
              onChange={(event) => props.setChoice(event.target.value)}
              >
              <option value="6">Last 7 days</option>
              <option value="29">Last 30 days</option>
              <option value="59">Last 60 days</option>
              <option value="other" disabled>Other</option>
            </select>
          </div>
        </div>
      </div>
    </div>
	)
}
