import API from '../services/api'

export default function SmsAPI() {
    var SMS = {};

    SMS.findAll = function (isLibrary) {
        var params = {
            "isLibrary": isLibrary
        };
        return API.findAll('getSMSs', params);
    };

    SMS.save = function (smsData) {
        return API.save('saveSMS', smsData);
    };

    SMS.destroy = function (smsId) {
        return API.destroy('deleteSMS', 'smsId', smsId);
    };

    SMS.findAllFolder = function () {
        return API.findAll('getUniqueFolderNames');
    };

    SMS.saveFolder = function (smsData) {
        return API.save('saveFolder', smsData);
    };

    SMS.destroyFolder = function (smsData) {
        return API.save('deleteFolder', smsData);
    };

    SMS.uploadLibrary = function(file) {
        return API.upload(file, 'multipart/form-data', 'uploadSMS');

        //return API.upload('uploadSMS', file);

        // var deferred = $q.defer(),
            // fd = new FormData();
        
        // fd.append('file', file);

        // $http.post(settings.apiBaseUrl + 'uploadSMS', fd, {
        //     clientId: 1,
        //     userId: 1,
        //     transformRequest: angular.identity,
        //     headers: {'Content-Type': undefined}
        // })
        // .success(function (res) {
        //     if(res.statusCode >= 200 && res.statusCode < 300)
        //         deferred.resolve(res.data);
        //     else
        //         deferred.reject(res.message);
        // })
        // .error(function (res) {
        //     deferred.reject(res);
        // });

        // return deferred.promise;
    };

    return SMS;
}
