import React, { useReducer, useEffect } from 'react'
import MultiSelect from 'react-multi-select-component'
import KeywordInput from '../../components/KeywordInput'
import ShortcodesAPI from '../../models/shortcode'
import SmsAPI from '../../models/sms'
import JourneyAPI from '../../models/journey'
import CampaignsAPI from '../../models/campaign'
import { Redirect } from 'react-router-dom';
import KeywordsAPI from '../../models/keyword'
import settings from '../../config/settings'

const initialState = {
    form: {
        name: '',
        startDate: '',
        endDate: '',
        optIn: false,
        submitted: false,
        smsSelected: [],
        shortCodeSelected: []
    },
    config: {
        minDate: '',
        maxDate: '',
        shortCodes: [],
        responseSMS: [],
        smsOptions: [],
        shortCodesOptions: []
    },
    status: '',
    journeyType: '',
    error: '',
    success: '',
    redirect: '',
    addedKeywords: [],
    removingKeywords: []
}

const reducer = (state, action) => {
    console.log(action);
    switch(action.type) {
        case 'setNew':
            return {
                ...state,
                form: {
                    ...state.form,
                    status: 'IN-ACTIVE',
                },
                config: {
                    ...state.config,
                    smsOptions: action.payload.smsOptions,
                    shortCodesOptions: action.payload.shortCodesOptions,
                    minDate: action.payload.minDate,
                    maxDate: action.payload.maxDate
                },
                journeyType: action.payload.journeyType
            }
        case 'setEdit':
            return {
                ...state,
                status: action.payload.journey.status,
                form: {
                    ...state.form,
                    name: action.payload.journey.name,
                    status: action.payload.journey.status,
                    startDate: (new Date(action.payload.journey.startDate)).toISOString().substring(0,10),
                    endDate: (new Date(action.payload.journey.endDate)).toISOString().substring(0,10),
                    smsSelected: action.payload.smsSelected,
                    shortCodeSelected: action.payload.shortCodeSelected,
                    optIn: action.payload.isOptIn
                },
                config: {
                    ...state.config,
                    smsOptions: action.payload.smsOptions,
                    shortCodesOptions: action.payload.shortCodesOptions,
                    minDate: action.payload.minDate,
                    maxDate: action.payload.maxDate
                },
                journeyType: action.payload.journeyType,
                addedKeywords: action.payload.journey.shortcodes[0].keywords
            }
        case 'updateForm':
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.key]: action.payload.value
                }
            }
        case 'updateUrl':
            return {
                ...state,
                redirect: action.payload
            }
        case 'updateMultiSelect':
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.key]: action.payload.value
                }
            }
        case 'setError':
            return {
                ...state,
                error: action.payload,
                success: '',
                form: {
                    ...state.form,
                    submitted: true
                }
            }
        case 'updateKeywords':
            return {
                ...state,
                addedKeywords: action.payload
            }
        case 'setCreate':
            return {
                ...state,
                form: {
                    ...state.form,
                    submitted: true
                },
                success: action.payload.success,
                error: '',
                redirect: action.payload.redirect
            }
        case 'updateOptin':
            return {
                ...state,
                form: {
                    ...state.form,
                    optIn: action.payload
                }
            }
        default:
            return {
                ...state
            }
    }
}

export default function Journey(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        setup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setup = () => {
        let apis = [
            CampaignsAPI().findAll(), 
            SmsAPI().findAll(), 
            ShortcodesAPI().findAll()
        ];
        apis = (props.mode === 'edit') ? [...apis, JourneyAPI().findAll()] : apis;

        Promise.all(apis)
            .then(rsps => {
                // check if any promise returned an error
                const errors = rsps.filter(rsp => rsp.data.statusCode !== 200);
                if (errors.length) {
                    dispatch({type: 'setError', payload: errors[0].data.message});
                    return;
                }
                const [campaignsRsp, smsRsp, shortcodesRsp, journeysRsp] = rsps;
                // get options for MultiSelect fields
                const smsOptions = smsRsp.data.data.smsTemplate.map(sc => ({label: sc.name, value: sc.code || sc.groupCode, id: sc._id}));
                const shortcodes = shortcodesRsp.data.data.shortCodes.filter(sc => sc.status === 'ACTIVE').map(sc => ({label: sc.number.toString(), value: sc.number.toString(), mandatoryKeywords: sc.mandatoryKeywords, country: sc.country, shortCodeId: sc.shortCodeId, number: sc.number}));
                
                // retrieve campaign start and end date
                let currentCampaign = campaignsRsp.data.data.campaigns
                    .filter(campaign => campaign._id === props.campaignId)
                    .map(campaign => ({
                        startDate: new Date(campaign.startDate), 
                        endDate: new Date(campaign.endDate)
                    }))[0];
                
                const today = (new Date()).getTime();
                const minDate = Math.max(currentCampaign.startDate, today);
                const maxDate = Math.max(currentCampaign.endDate, today);

                if (props.mode === 'edit') {
                    // get journey information
                    let currentJourney = journeysRsp.data.data.journeys;
                    currentJourney = currentJourney.filter(({campaignId, journeyId}) => (campaignId === props.campaignId) && journeyId === props.journeyId)[0];
                    dispatch({type: 'setEdit', payload: {
                        journey: currentJourney,
                        journeyType: (props.type === 'Journey') ? 'MULTI_STEP' : 'ONE_STEP',
                        smsOptions: smsOptions,
                        shortCodesOptions: shortcodes,
                        minDate: formatDate(minDate),
                        maxDate: formatDate(maxDate),
                        smsSelected: smsOptions.filter(sms => sms.id === currentJourney.responseSMS),
                        shortCodeSelected: shortcodes.filter(shortcode => currentJourney.shortcodes[0].shortCodeId === shortcode.shortCodeId),
                        keywords: currentJourney.shortcodes[0].keywords,
                        isOptIn: currentJourney.isOptIn
                    }});
                    dispatch({type: 'updateKeywords', payload: currentJourney.shortcodes[0].keywords})
                } else {
                    dispatch({type: 'setNew', payload: {
                        journeyType: (props.type === 'Journey') ? 'MULTI_STEP' : 'ONE_STEP',
                        smsOptions: smsOptions,
                        shortCodesOptions: shortcodes,
                        minDate: formatDate(minDate),
                        maxDate: formatDate(maxDate)
                    }})
                }
            })
            .catch(_ => dispatch({type: 'setError', payload: 'SERVICE ERROR'}))
    }

    const updatedKeywords = value => dispatch({type: 'updateKeywords', payload: value});

    const handleSubmit = (event) => {
        event.preventDefault();

        if (state.form.name.length && state.form.startDate.length && state.form.endDate.length && state.form.shortCodeSelected.length) {

            const params = {
                name: state.form.name,
                journeyType: state.journeyType,
                shortcodes: [
                    {
                        shortCodeId: state.form.shortCodeSelected[0].shortCodeId,
                        number: state.form.shortCodeSelected[0].number,
                        country: state.form.shortCodeSelected[0].country,
                        keywords: state.addedKeywords || []
                    }
                ],
                startDate: (new Date(state.form.startDate)).getTime(),
                endDate: (new Date(state.form.endDate)).getTime(),
                campaignId: props.campaignId,
                status: (props.mode === 'new') ? 'IN-ACTIVE' : props.status
            };

            if (state.journeyType === "MULTI_STEP") {
                params.isOptIn = state.form.optIn;
            } else {
                params.responseSMS = state.form.smsSelected[0].id;
            }

            if (props.mode === 'edit') {
                params.journeyId = props.journeyId;
            }
    
            runKeywordRemove().then(() => {
                JourneyAPI().save(params).then((rsp) => {
                    if (rsp.data.statusCode === 200) {
                        const {campaignId, journeyId} = rsp.data.data.journey;
                        const type = (state.journeyType === "MULTI_STEP") ? 'journey' : 'simpleresponse';
                        dispatch({type: 'setCreate', payload: {
                            success: props.type + " successfully created!",                            
                            //redirect: `${settings.subPath}/${type}/${journeyId}/${campaignId}/${props.campaignInd}`
                        }});
                    } else {
                        dispatch({type: 'setError', payload: rsp.data.message})
                    }
                });
            });
            
        } else {
            dispatch({type: 'setError', payload: ''});
        }
    }

    const shortcodeOnChange = (val) => {
        dispatch({type: 'updateMultiSelect', payload: {
            key: 'shortCodeSelected',
            value: (val.length === 0) ? [] : [val[val.length - 1]]
        }});
    }

    const smsOnChange = (val) => {
        dispatch({type: 'updateMultiSelect', payload: {
            key: 'smsSelected',
            value: (val.length === 0) ? [] : [val[val.length - 1]]
        }});
    }

    const onChangeOptIn = (val) => {
        dispatch({type: 'updateOptin', payload: val});
    }

    const goToDashboard = () => {
        dispatch({type: 'updateUrl', payload: `${settings.subPath}/campaigns`});
    }

    const runKeywordRemove = () => {
        let promise = new Promise((resolve, reject) => {
            if (state.removingKeywords.length > 0) {
                const removeParams = {
                    keywords: state.removingKeywords
                }
                KeywordsAPI().removeKeyword(removeParams)
                    .then(() => {
                        resolve(200);
                    }).catch(() => {
                        reject(400);
                    });
            } else {
                resolve(200);
            }
        });
        
        return promise;
    }

    const formatDate = (input) => {
        let d = (input instanceof Date) ? input : new Date(input);
        const year = d.getUTCFullYear();
        const month = (d.getUTCMonth()+1).toString().padStart(2, "0");
        const date = d.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${date}`
    }

    const isActiveOrComplete = _ => ["ACTIVE", "COMPLETED"].includes(state.status);
    

    return (
        <div data-testid="JourneyComponent">
            {state.redirect && <Redirect to={state.redirect} /> }

            <h1 data-testid="Journey.Title" className="text-center">
                {props.mode === 'new' ? 'Create New' : (isActiveOrComplete() ? 'View' : 'Edit')} {props.type}
            </h1>

            { state.form.submitted && state.success && <div className="success-field form-group">{state.success}</div> }
            { state.form.submitted && state.error && <div className="error-field form-group">{state.error}</div> }

            <form noValidate name="form" onSubmit={handleSubmit}>
                <div className="record-title">
                    <div className="row">
                        <div className="col-xs-12 form-group">
                            <label>Name</label>
                            <input 
                                disabled={isActiveOrComplete()}
                                required 
                                name="name" 
                                value={state.form.name}
                                onChange={evt => dispatch({type: 'updateForm', payload: {key: evt.target.name, value: evt.target.value}})}
                                maxLength="30" 
                                className="form-control" 
                                placeholder={state.journeyType === 'ONE_STEP'? "Enter a simple response name" : "Enter a journey name"} />
                            {
                                (state.form.submitted && (state.form.name.length === 0 || state.form.name.length > 30)) &&
                                    <div className="error-field">Please fill out this field (maximum 30 characters)</div>
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="row row-bordered">
                    <div className="col-sm-6 form-group">
                        <label>Start Date</label>
                        <input 
                            disabled={isActiveOrComplete()}
                            data-testid="startDate" 
                            name="startDate" 
                            type="date" 
                            className="form-control" 
                            value={state.form.startDate} 
                            onChange={evt => dispatch({type: 'updateForm', payload: {key: evt.target.name, value: evt.target.value}})}
                            min={state.config.minDate} 
                            //max={state.config.maxDate} 
                            />
                        {
                            (state.form.submitted && !state.form.startDate.length) && 
                                <div className="error-field">Please fill out this field</div>
                        }
                        
                    </div>
                    <div className="col-sm-6 form-group">
                        <label>End Date</label>
                        <input 
                            disabled={isActiveOrComplete()}
                            data-testid="endDate" 
                            name="endDate" 
                            type="date" 
                            className="form-control" 
                            value={state.form.endDate} 
                            onChange={evt => dispatch({type: 'updateForm', payload: {key: evt.target.name, value: evt.target.value}})}
                            min={state.config.minDate} 
                            //max={state.config.maxDate} 
                            />
                        {
                            (state.form.submitted && !state.form.endDate.length) && 
                                <div className="error-field">Please fill out this field</div>
                        }
                    </div>
                    
                    <div className="col-sm-6 form-group">
                        <label>Short Code</label>
                        <MultiSelect
                            disabled={isActiveOrComplete()}
                            options={state.config.shortCodesOptions}
                            value={state.form.shortCodeSelected}
                            onChange={shortcodeOnChange}
                            labelledBy={"Select"}
                            hasSelectAll={false}
                        />
                        {
                            (state.form.submitted && !state.form.shortCodeSelected.length) &&
                                <div className="error-field">Please fill out this field</div>
                        }
                    </div>
                    {
                        (state.journeyType === "MULTI_STEP") ? 
                            <div className="col-sm-6 form-group">
                                <label className="visible-lg-block visible-md-block visible-sm-block hidden-xs">&nbsp;</label>
                                <label className="checkbox-inline">
                                    <input type="checkbox" name="emailFlag" checked={state.form.optIn} value={state.form.optIn} onChange={evt => onChangeOptIn(evt.target.checked)} />
                                    Allow Opt-In
                                </label>
                            </div> : (
                                <div className="col-sm-6 form-group">
                                    <label>SMS Response</label>
                                    <MultiSelect
                                        disabled={isActiveOrComplete()}
                                        options={state.config.smsOptions}
                                        value={state.form.smsSelected}
                                        onChange={smsOnChange}
                                        labelledBy={"Select"}
                                        hasSelectAll={false}
                                        required={state.journeyType === 'ONE_STEP'} />
                                    { (state.form.submitted && (state.form.smsSelected.length === 0)) && <div className="error-field">Please fill out this field</div> }
                                </div>
                            )
                    }

                    <div className="col-xs-12">
                        {
                            (state.form.shortCodeSelected.length > -1) && (
                                <KeywordInput 
                                    readonly={state.form.shortCodeSelected.length === 0 || isActiveOrComplete()} 
                                    keywords={props.mode === 'edit' ? state.addedKeywords : ''}
                                    shortcode={state.form.shortCodeSelected} 
                                    mandatoryKeywords={(state.form.shortCodeSelected.length === 1) ? state.form.shortCodeSelected[0].mandatoryKeywords: []} 
                                    formSubmitted={state.form.submitted} 
                                    communicationId={props.journeyId} 
                                    onUpdatedKeywords={updatedKeywords} /> 
                                )
                        }
                    </div>
                </div>
                
                {
                    (isActiveOrComplete()) ? (
                        <div className="row button-row">
                            <div className="col-xs-12 form-group text-center">
                                {/* <button type="button" className="btn btn-default btn-lg" onClick={goToDashboard}>Back</button> */}
                                <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Back</button>
                            </div>
                        </div> ) : (
                            <div className="row button-row">
                                <div className="col-xs-12 form-group">
                                    <div className="col-xs-6 text-right pull-left">
                                        <button type="submit" className="btn btn-primary btn-lg">Save</button>
                                    </div>
                                    <div className="col-xs-6 text-left pull-left">
                                        <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        )
                }
                
            </form>
        </div>
    )
}
