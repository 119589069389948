import React, { useReducer, useEffect } from 'react';
import { ModalContext } from '../../context/modal'
import CampaignsAPI from '../../models/campaign'
import ShortcodesAPI from '../../models/shortcode'
import CampaignPanel from './CampaignPanel'
import CampaignFormModal from '../modals/campaigns/CampaignFormModal'
import DateRangeModal from '../modals/DateRangeModal'
import Loader from '../../components/Loader'

const initialState = {
    allCampaigns: [],
    campaigns: [],
    shortcodes: [],
    campaignStatusFilter: 'Active',
    error: '',
    filter: {
        startDate: '',
        endDate: '',
        communicationSearch: '',
        status: 'All'
    },
    isLoading: true,
    filterApplied: false
};

function reducer(state, action) {
    switch (action.type) {
        case 'setData':
            return {
                ...state,
                allCampaigns: action.payload.allCampaigns,
                campaigns: action.payload.campaigns,
                shortcodes: action.payload.shortcodes,
                isLoading: false
            }
        case 'updateCampaignStatus':
            return {
                ...state,
                campaignStatusFilter: action.payload.filter,
                campaigns: action.payload.filter === 'All' ? [...state.allCampaigns] : [...state.allCampaigns].filter(cam => cam.status === action.payload.filter)
            }
        case 'setFilters':
            // filter campaigns based on campaign status
            let setFiltersNewCampaigns = [...state.allCampaigns].filter(cam => cam.status === state.campaignStatusFilter || state.campaignStatusFilter === 'All');

            const filterNewObject = (campaigns, filterCallback) => {
                const ALLCOMS = ['SIMPLE', 'JOURNEY', 'CONTEST', 'TRIVIA', 'TRIGGER'];
                return campaigns.map(campaign => {
                    const newCampaignObj = {...campaign};
                    const communications = {};
                    ALLCOMS.forEach(item => {
                        communications[item] = newCampaignObj.communications[item].filter(filterCallback);
                    });
                    newCampaignObj.communications = communications;
                    return newCampaignObj;
                }).filter(campaign => ALLCOMS.reduce((acc, com) => acc + campaign.communications[com].length, 0) > 0);
            }

            switch(action.payload.key) {
                case 'startDate': 
                    setFiltersNewCampaigns = filterNewObject(
                        setFiltersNewCampaigns, 
                        com => com.startDate >= new Date(action.payload.value)
                    )
                    break;
                case 'endDate': 
                    setFiltersNewCampaigns = filterNewObject(
                        setFiltersNewCampaigns, 
                        com => com.endDate <= new Date(action.payload.value)
                    )
                    break;
                case 'communicationSearch': 
                    setFiltersNewCampaigns = action.payload.value.length ? filterNewObject(
                        setFiltersNewCampaigns, 
                        com => com.name.includes(action.payload.value)
                    ) : setFiltersNewCampaigns;
                    break;
                case 'status': 
                    setFiltersNewCampaigns = action.payload.value !== 'All' ? filterNewObject(
                        setFiltersNewCampaigns, 
                        com => (com.status === action.payload.value)
                    ) : setFiltersNewCampaigns;
                    break;
                default:
                    setFiltersNewCampaigns = [...setFiltersNewCampaigns];
            }

            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: action.payload.value
                },
                campaigns: setFiltersNewCampaigns,
                filterApplied: (action.payload.key === 'communicationSearch') ?
                                ((action.payload.value.length) ? true : false) : state.filterApplied
            }
        case 'clearFilters':
            return {
                ...state,
                campaigns: state.campaignStatusFilter === 'All' ? [...state.allCampaigns] : [...state.allCampaigns].filter(cam => cam.status === state.campaignStatusFilter),
                filter: {
                    startDate: '',
                    endDate: '',
                    communicationSearch: '',
                    status: 'All'
                },
                filterApplied: false
            }
        case 'setError':
            return {
                ...state,
                error: action.payload
            }
        case 'addNewCampaign':
            let setNewCampaignResult = action.payload;
            setNewCampaignResult = ['All', 'Active'].includes(state.campaignStatusFilter) ? setNewCampaignResult : null;
            setNewCampaignResult = (state.filter.startDate.length || state.filter.endDate.length || state.filter.communicationSearch.length || state.filter.status !== 'All') ? null : setNewCampaignResult;
            return (setNewCampaignResult !== null) ? {
                ...state,
                allCampaigns: [...state.allCampaigns, action.payload],
                campaigns: [...state.campaigns, action.payload]
            } : {
                ...state,
                allCampaigns: [...state.allCampaigns, action.payload]
            }
        case 'updateCampaign' :
            return {
                ...state,
                allCampaigns: [...state.allCampaigns].map(ac => (ac._id === action.payload.id) ? {
                    ...ac, 
                    name: action.payload.name,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate
                } : ac),
                campaigns: [...state.campaigns].map(ac => (ac._id === action.payload.id) ? {
                    ...ac, 
                    name: action.payload.name,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate
                } : ac)
            }
        case 'deleteCampaign':
            return {
                ...state,
                allCampaigns: [...state.allCampaigns].filter(ac => ac._id !== action.payload),
                campaigns: [...state.campaigns].filter(ac => ac._id !== action.payload)
            }
        case 'setLoader':
            return {
                ...state,
                isLoading: action.payload
            }
        case 'updateCommunicationStatus': 
            return {
                ...state,
                allCampaigns: [...state.allCampaigns].map(ac => {
                    if (ac.campaignId === action.payload.campaignId) {
                        return {
                            ...ac, 
                            communications: {
                                ...ac.communications, 
                                [action.payload.type]: ac.communications[action.payload.type].map(co => (co.id === action.payload.communicationId) ?  co.status = action.payload.status : co)
                            }
                        }
                    } else {
                        return ac;
                    }
                }),
                campaigns: [...state.campaigns].map(ac => {
                    if (ac.campaignId === action.payload.campaignId) {
                        return {
                            ...ac, 
                            communications: {...ac.communications, [action.payload.type]: ac.communications[action.payload.type].map(co => {
                                if (co._id === action.payload.communicationId) {
                                    return {...co, status: action.payload.status}
                                } else {
                                    return co
                                };
                            })}
                        }
                    } else {
                        return ac;
                    }
                })
            }
        case 'deleteCommunication':
            return {
                ...state,
                allCampaigns: [...state.allCampaigns].map(ac => {
                    if (ac._id === action.payload.campaignId) {
                        return {
                            ...ac,
                            communications: {
                                ...ac.communications,
                                [action.payload.type]: [...ac.communications[action.payload.type].filter(comm => comm._id !== action.payload.communicationId)]
                            }
                        }
                    } else return ac;
                }),
                campaigns: [...state.campaigns].map(ac => {
                    if (ac._id === action.payload.campaignId) {
                        return {
                            ...ac,
                            communications: {
                                ...ac.communications,
                                [action.payload.type]: [...ac.communications[action.payload.type].filter(comm => comm._id !== action.payload.communicationId)]
                            }
                        }
                    } else return ac;
                })
            }
        default:
            return state;
    }
}

export default function Campaigns() {
    const [state, dispatch] = useReducer(reducer, initialState);
    let { toggleModal, setModalContent, setModalWidth} = React.useContext(ModalContext);

    useEffect(() => {
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCampaigns = () => {
            
        Promise.all([CampaignsAPI().findAll(), ShortcodesAPI().findAll()])
            .then((rsp) => {
                const allCampaigns = rsp[0].data.data.campaigns;
                const shortcodes = rsp[1].data.data.shortCodes;
                
                allCampaigns.forEach((campaign, index) => {
                    campaign.key = 'campaign' + Math.round((Math.random() * 100000));
                    campaign.communications = {};
                    ["JOURNEY", "TRIVIA", "TRIGGER"].forEach(item => changeInactiveStatus(campaign.communications, item));
                    ["TRIVIA", "TRIGGER"].forEach(item => getShortcodeMatch(campaign.communications, item));
                    createNewArrayForCommunication(campaign.communications, "SIMPLE");
                    createNewArrayForCommunication(campaign.communications, "CONTEST");                     
                })
        
                dispatch({type: 'setData', payload: {
                    allCampaigns, 
                    campaigns: allCampaigns.filter(cam => cam.status === state.campaignStatusFilter),
                    shortcodes
                }});                
            })
            .catch(err => {
                console.log(err);
            });
    }

    const createNewArrayForCommunication = (communications, type) => {
        if (type === "SIMPLE") {
            communications.SIMPLE = communications.JOURNEY.filter(journ => journ.journeyType === 'ONE_STEP');
            communications.JOURNEY = communications.JOURNEY.filter(journ => journ.journeyType === 'MULTI_STEP');
        } else if (type === "CONTEST") {
            communications.CONTEST = communications.TRIVIA.filter(journ => journ.type === 'CONTEST');
            communications.TRIVIA = communications.TRIVIA.filter(journ => journ.type === 'TRIVIA');
        } 
    }

    /** change IN-ACTIVE status to DISABLED */
    const changeInactiveStatus = (communications, type) => {
        if (communications[type] === undefined || communications[type] === null) communications[type] = [];
        communications[type] = communications[type].map(type => (type.status === 'IN-ACTIVE') ? {...type, status: 'DISABLED'} : type);
    }

    const getShortcodeMatch = (communication, type) => {
        const communicationByType = communication[type];
        const shortcodes = state.shortCodes;
        for (let comm in communicationByType) {
            for (let sc in shortcodes) {
                if (communicationByType[comm].shortCodeId === shortcodes[sc].shortCodeId) {
                    communicationByType[comm].shortCodeNumber = shortcodes[sc].number;
                }
            }
        }
    }

    const updateFilters = evt => {
        dispatch({type: 'setFilters', payload: {
            key: evt.target.name, 
            value: evt.target.value
        }})
    }

    const renderCampaignFormModal = (formType, data) => {
        setModalContent(CampaignFormModal({
            hide: () => toggleModal(false),
            success: () => {toggleModal(false);},
            rerender: p => renderCampaignFormModal(formType, p),
            action: (type, payload) => {
                switch(type) {
                    case 'setError':
                        dispatch({type, payload});
                        break;
                    case 'addNewCampaign':
                        dispatch({type, payload});
                        break;
                    case 'updateCampaign':
                        dispatch({type, payload});
                        break;
                    default:
                        console.log(type, payload);
                        break;
                }
            },
            formType: formType,
            form: {
                name: (data && data.form && data.form.name) || '',
                start: (data && data.form && data.form.start) || '',
                end: (data && data.form && data.form.end) || '',
                submitted: (data && data.form && data.form.submitted) || false
            },
            error: (data && data.error) || '',
            campaign: (data && data.campaign) || {}
        }));
        toggleModal(true);
    }

    const updateCommunicationStatus = (campaignId, communicationId, status, type) => {
        dispatch({type: 'updateCommunicationStatus', payload: {campaignId, communicationId, status, type}});
    } 

    const renderdownloadProgram = (data) => {
        setModalContent(DateRangeModal({
            hide: () => toggleModal(false),
            error: '',
            success: () => {
                toggleModal(false);
            },
            rerender: (p) => renderdownloadProgram(p),
            action: (payload) => CampaignsAPI().download(payload.startDate, payload.endDate),
            form: {
                startDate: (data && data.form && data.form.startDate) || '',
                endDate: (data && data.form && data.form.endDate) || '',

            },
          }));
        setModalWidth("modal-lg");
        toggleModal(true);
    }    

    return (
        <div data-testid="Campaigns.Root">
            {state.isLoading && <Loader />}

            {/* Header */}
            <div className="row row-top">
                <div className="col-md-6">
                    <h3>Campaigns</h3>
                </div>
                <div className="col-md-2">
                    <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={_ => renderCampaignFormModal('new', null)}>CREATE NEW CAMPAIGN</button>
                </div>
                <div className="col-md-3 text-right">                    
                    <div className="form-horizontal">
                        <label className="sr-only control-label" htmlFor="campaignsFilter">Campaign Status:</label>
                        <div className="styled-select">
                            <select name="campaignsFilter" id="campaignsFilter" onChange={evt => dispatch({type: 'updateCampaignStatus', payload: {filter: evt.target.value}})} value={state.campaignStatusFilter}>
                                <option value="All">All Campaigns</option>
                                <option value="Active">Active Campaigns</option>
                                <option value="Completed">Completed Campaigns</option>
                                <option value="Draft">Upcoming Campaigns</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-1">
                    <button type="button" title="Download Interactions" onClick={renderdownloadProgram}>
                        <i className="fa fa-fw fa-download font-lg" data-testid="campaigns-download"></i>
                    </button>                    
                </div>                
            </div>

            {/* Communication Filters */}
            <div className="record-title">
                <div className="row">
                    <div className="col-sm-3 form-group">
                        <label>Communication Start Date</label>
                        <input 
                            data-testid="filterStartDate" 
                            name="startDate" 
                            type="date" 
                            className="form-control" 
                            value={state.filter.startDate} 
                            onChange={updateFilters} 
                        />
                    </div>
                    <div className="col-sm-3 form-group">
                        <label>Communication End Date</label>
                        <input 
                            data-testid="filterEndDate" 
                            name="endDate" 
                            type="date" 
                            className="form-control" 
                            value={state.filter.endDate} 
                            onChange={updateFilters} />
                    </div>

                    <div className="col-lg-3 form-group">
                        <label>Communication Search Criteria</label>
                        <p className="input-group">
                            <input type="text"
                                value={state.filter.communicationSearch}
                                onChange={updateFilters} 
                                name="communicationSearch" 
                                className="form-control pull-right" 
                                placeholder="Search Communications"></input>
                            <span className="input-group-btn">
                                <button type="button" className="btn btn-default">
                                    <i className="fa fa-lg fa-search"></i>
                                </button>
                            </span>
                        </p>
                    </div>
                    <div className="col-lg-2 form-group">
                        <label>Communication Status</label>
                        <div className="form-horizontal">
                            <label className="sr-only control-label" htmlFor="communicationsFilter">Communication Status:</label>
                            <div className="styled-select">
                                <select name="status" value={state.filter.status} onChange={updateFilters}>
                                    <option value="All">All</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="COMPLETED">Completed</option>
                                    <option value="DISABLED">In-Active</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 form-group">
                        <label className="hidden-md hidden-sm hidden-xs">&nbsp;</label>
                        <button type="button" className="full-btn btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={_ => dispatch({type: 'clearFilters'})}>
                            <i className="fa fa-fw fa-close fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>

            {/* error */}
            { state.error && <div className="error-field form-group">{ state.error }</div> }

            {/* campaign header */}
            <div className="row table-header">
                <div className="col-md-4 col-sm-8 col-xs-8">Name</div>
                <div className="col-md-2 hidden-sm hidden-xs text-right">Communications</div>
                <div className="col-md-4 text-right hidden-sm hidden-xs">Date Range</div>
                <div className="col-md-2 col-sm-4 col-xs-4 text-right"></div>
            </div>

            {/* Campaigns contain Communications */}
            <div>
                {
                    (state.campaigns.length === 0) ? (
                        <div className="panel-group accordion" id="collapse-panel">
                            <div className="panel panel-default">
                                <div className="panel-heading empty">
                                    <div className="row record">
                                        <div className="col-xs-12">There are no campaigns to display.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : state.campaigns.map((campaign, index) => 
                        <CampaignPanel 
                            key={campaign.key} 
                            index={index} 
                            campaign={campaign} 
                            shortcodes = {state.shortcodes}
                            changeInactiveStatus = {changeInactiveStatus}
                            getShortcodeMatch = {getShortcodeMatch}
                            createNewArrayForCommunication = {createNewArrayForCommunication}
                            renderCampaignFormModal={renderCampaignFormModal}
                            setError={error => dispatch({type: 'setError', payload: error})} 
                            deleteCampaign={id => dispatch({type: 'deleteCampaign', payload: id})}
                            filterApplied={state.filterApplied} 
                            updateCommunicationStatus={updateCommunicationStatus} 
                            deleteCommunication={(campaignId, communicationId, type) => dispatch({type: 'deleteCommunication', payload: {campaignId, communicationId, type}})}/>
                    )
                }
            </div>
        </div>
    );
}
