import React, {useEffect} from 'react';
import JourneyAPI from '../../models/journey'
import settings from '../../config/settings'
import { ModalContext } from '../../context/modal'
import DeleteCommunicationModal from '../modals/campaigns/DeleteCommunicationModal'

export default function SimpleResponsePanel(props) {
    let { toggleModal, setModalContent } = React.useContext(ModalContext);

    useEffect(() => {
        // console.log('SimpleResponsePanel', props.expand);
    }, [props.expand]);

    const renderDeleteSimpleModal = (data) => {
        setModalContent(DeleteCommunicationModal({
            hide: () => toggleModal(false),
            success: () => {toggleModal(false);},
            rerender: p => renderDeleteSimpleModal(p),
            action: (type, payload) => {
                JourneyAPI().destroy(data.id)
                    .then(res => {
                        if (res.data.statusCode === 200) {
                            toggleModal(false);
                            props.deleteCommunication(data.cId, data.id, 'SIMPLE');
                        } else {
                            props.setError(res.data.message);
                        }
                    })
                    .catch(res => {
                        props.setError(res.data.message);
                    });
            },
            name: (data && data.name) || '',
            type: 'journey',
            error: (data && data.error) || '',
            campaign: (data && data.campaign) || {}
        }));
        toggleModal(true);
    }

    return (
        <div className="panel-section-content">
            <div className="panel panel-default panel-section-header">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-md-4 col-xs-12">Simple Response</div>
                        <div className="col-md-8 hidden-xs"></div>
                    </div>
                </div>
            </div>
            <div className="panel panel-default panel-section-records row">
                {
                    props.data.map(journey => (
                        <div key={'journey' + journey._id} className={["row panel-section-record", props.communicationStatusClasses[journey.status], props.expand].join(" ")}>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 panel-section-record-row">
                                {/* <div className="panel-heading panel-heading-campaign row record" onClick={_ => props.setRedirectTo(`${settings.subPath}/simpleresponse/${journey.journeyId}/${props.campaignId}/${props.index}`)}> */}
                                <div className="panel-heading panel-heading-campaign row record" onClick={_ => props.renderCommunicationModal(props.campaignId, props.index, journey.journeyId, 'simpleresponse')}>
                                    <div className="col-md-3 col-xs-8 highligted-text">{journey.name}</div>
                                    <div className="col-md-2 hidden-sm hidden-xs nowrap">
                                        <span>{journey.shortcodes[0].keywords.join(", ")}</span>
                                    </div>
                                    <div className="col-md-2 hidden-sm hidden-xs">
                                        {journey.shortcodes[0].number || journey.shortcodes[0].shortCodeId}
                                    </div>
                                    <div className="col-md-3 hidden-sm hidden-xs">
                                        {props.formatDateString(journey.startDate)} &#8211; {props.formatDateString(journey.endDate)}
                                    </div>
                                    <div className="col-md-2 col-xs-4 text-right">
                                        {(journey.status === 'DISABLED') && (
                                            <span className="fa-wrap highligted-text-green">
                                                <i className="fa fa-play fa-lg" onClick={(evt) => props.setCommunicationStatus(evt, journey, 'SIMPLE')} title="Activate Simple Response"></i>
                                            </span>
                                        )}
                                        {(journey.status === 'ACTIVE') && (
                                            <span className="fa-wrap highligted-text-red">
                                                <i className="fa fa-stop fa-lg" onClick={evt => props.setCommunicationStatus(evt, journey, 'SIMPLE')} title="Deactivate Simple Response"></i>
                                            </span>
                                        )}
                                        {(journey.status === 'DISABLED') && (
                                            <span className="fa-wrap">
                                                <i className="fa fa-trash-o fa-lg" onClick={evt => {evt.stopPropagation(); renderDeleteSimpleModal({name: journey.name, id:journey._id, cId: journey.campaignId})}} title="Delete Simple Response"></i>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
