import React, { useEffect, useRef, useReducer } from 'react'
import SmartlistAPI from '../../models/smartlist'

const axios = require('axios');

const initialState = {
  attrList: [],
  dataList: {}
};

function reducer(state, action) {
  switch (action.type) {
    case 'setAll':
      return {
        ...state, 
        attrList: action.attr,
        dataList: action.data
      };
    case 'setData':
      return {
        ...state,
        dataList: action.data
      };
    default:
      throw new Error();
  }
}

export default function SmartListPreview(props) {
  const attributes = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (props.show) {
      const params = {
        smartListId: parseInt(props.id),
        page: 1,
        items: 1000
      };
      let calls = [];
      calls.push(SmartlistAPI().findPreview(params));
      if (attributes.current === null) {
        calls.push(SmartlistAPI().findSubscriberAttributes());
      }

      axios.all(calls).then((data) => {
        if (attributes.current === null) {
          const subList = data[1].data.data.subscriberAttributes.attributes;
          let getSubAttrList = [];
          for (var attr in subList) {
            if (subList[attr].table_VIEW) {
              getSubAttrList.push(subList[attr]);
            }
          }
          getSubAttrList.sort(function (a, b) {
            if (a.sequence > b.sequence)
              return 1;
            if (a.sequence < b.sequence)
              return -1;
            return 0;
          });
          dispatch({type:'setAll', attr: getSubAttrList, data: data[0].data.data});
          attributes.current = true;
        } else {
          dispatch({type:'setData', data: data[0].data.data});
        }
      })
      .catch(err => {
        props.errorFunc(err);
      });
    }
  }, [props]);

  return (
    <span>
      {state.attrList.length > 0 &&
        <span>
          <span className="no-pager smart-list-table">
            <div className="table-responsive">
              <table className="table table-striped table-smartlist">
                <thead>
                  <tr data-testid="smartlist-preview-header">
                    {state.attrList.length > 0 && state.attrList.map((item, key) => 
                    <th data-testid={`smartlist-preview-header-attr-${key}`} key={key}><span>{item.ui_LABEL}</span></th>
                    )}
                  </tr>
                </thead>
                <tbody data-testid="smartlist-preview-data">
                  {state.dataList && state.dataList.subscribers && state.dataList.subscribers.length > 0 && state.dataList.subscribers.map((i, k) =>
                  <tr key={k}>
                    {state.dataList && state.attrList.length > 0 && state.attrList.map((item, key) => 
                    <td key={key}>{(i[item.db_LABEL]) ? i[item.db_LABEL] : ''}</td>
                    )}
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </span>

          {state.dataList &&
            <span>
              {state.dataList.totalSubscribers > 0 &&
                <div className="preview-note">This is a preview, this list has {state.dataList.totalSubscribers} record{(state.dataList.totalSubscribers > 1) ? 's' : ''} since you last saved. You can download the entire list by clicking the <i className="fa fa-fw fa-download"></i> icon above.</div>
              }
              {!state.dataList.totalSubscribers &&
                <div className="preview-note empty-list">This smart list contains no records since you last saved.</div>
              }
            </span>
          }
        </span>
      }
      {state.attrList.length === 0 &&
        <div data-testid="smartlist-preview-header-not-available">LOADING</div>
      }
    </span>
	)
}