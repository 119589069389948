import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import settings from '../../config/settings'
import SubscriberAPI from '../../models/subscriber'
import SmartlistAPI from '../../models/smartlist'
import { ModalContext } from '../../context/modal'
import DeleteModal from '../modals/DeleteModal'
import ResponseMessage from '../../components/ResponseMessage'

export default function SubscriptionDetails(props) {

  const { id } = props.match.params;
  const pageId = parseInt(id);
  const { toggleModal, setModalContent } = React.useContext(ModalContext);
 	const [redirect, setRedirect] = useState(false);
 	const [subData, setSubData] = useState([]);
 	const [subAttr, setSubAttr] = useState({empty:true});
 	const [error, setError] = useState('');

 	useEffect(() => {
 		let mounted = true;
 		if (mounted) {
 			init();
 		}
 		return () => mounted = false;
 		// eslint-disable-next-line react-hooks/exhaustive-deps
 	}, []);

 	//Init
  const init = () => {
    if (Number.isInteger(pageId)) {
      if (props.location.data) {
        setupData(props.location.data);
      } else {
        getSubData();
      }
    } else {
      setRedirect(true);
    }  	
  }
  
  //Get Subscription Attributes
  const getSubAttr = () => {
  	return SmartlistAPI().findSubscriberAttributes();
  }

  //Setup Subscription Attributes
  const setupData = (details) => {
    getSubAttr()
  	.then((data) => {
			if (data.data.data.subscriberAttributes && data.data.data.subscriberAttributes.attributes) {
				const attributes = data.data.data.subscriberAttributes.attributes;
				let sortedAttr = [];
				Object.entries(attributes).map((item, key) => {
					return sortedAttr.push(item[1]);
				})
				sortedAttr.sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));
  			setSubAttr(sortedAttr);
  			setSubData(details);
  		}
  	})
    .catch(err => {
      setError(err);
    });
  }

  //Get Subscription Data
  const getSubData = () => {
  	var params = {
      subscriberCriteria: pageId
    };
  	SubscriberAPI().findAll(params)
    .then((data) => {
      if(data.data.data && data.data.data.subscribers && data.data.data.subscribers.length === 1) {
      	setupData(data.data.data.subscribers);
      } else {
      	setRedirect(true);
      }
    })
    .catch(err => {
      setError(err);
    });
  }

  //Show Confirm Opt Out Modal
  const confirmOptOut = (params) => {
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalContent(DeleteModal({
      message: `Are you sure you want to opt this user out of '${params.name}'?"`,
      hide: () => toggleModal(false),
      action: () => initOptOut(params)
    }));
  }

  //init Opt Out
  const initOptOut = (params) => {
    let data = {
        subscriberId:params.id,
        type:params.type
    };

    SubscriberAPI().optOut(data)
    .then((data) => {
	    let currentData = [...subData];
	    currentData[0].CATEGORY.splice(params.key,1);
	    setSubData(currentData);
	    toggleModal(false);
    })
    .catch(err => {
      setError(err);
    });
  }

	return (
		<div>
      {redirect &&
        <Redirect to={`${settings.subPath}/subscribers/subscriptions`} />
      }

			<h1 className="text-center">View Subscription</h1>

      <ResponseMessage
        validation={error}
        message={error}
        class="error-field" />

			{subData.length === 1 &&
				<div>
					<div className="record-title">
				    <div
				    	data-testid="data-attributes" 
				    	className="row">
				    	{subAttr.map((item, key) => {
					    		if (item.table_VIEW) {
						    		return (
							        <div className="col-sm-6 form-group" key={key}>
						            <label>{item.default_LABEL}</label>
						            <input
						            	data-testid={item.db_LABEL} 
						            	type="text" 
						            	value={subData[0][item.db_LABEL]} 
						            	className="form-control" 
						            	disabled />

						            {/*
						            <input type="text" ng-hide="attribute.type === 'DATE'" className="form-control" disabled />
						            <div ng-show="attribute.type === 'DATE'" className="form-control" disabled>**attribute.value | date:'MMM dd, yyyy'**</div>
						          	*/}
							        </div>
							      )
						    	}
						    	return ''
				    		}
			        )}
				    </div>
					</div>

					<div className="row table-header">
				    <div className="col-md-5 col-xs-4">Category Name</div>
				    <div className="col-md-5 col-xs-4">Category Type</div>
				    <div className="col-md-2 col-xs-4"></div>
					</div>

					<div className="panel-group accordion">
						{subData[0].CATEGORY && subData[0].CATEGORY.length > 0 && subData[0].CATEGORY.map((item, key) =>
						    <div className="panel panel-default" key={key}>
					        <div className="panel-heading no-click" ng-class="{'toDelete': category.toDelete === true}">
				            <div className="row record">
				              <div className="col-md-5 col-xs-4">{item.name}</div>
				              <div className="col-md-5 col-xs-4">{item.type}</div>
				              <div className="col-md-2 col-xs-4 text-right">
				                <button
				                	onClick={(event) => confirmOptOut({
		                        event: event,
		                        type: item.type, 
		                        name: item.name,
		                        id: subData[0]._id,
		                        key: key
		                      })}
				                	className="fa-wrap remove"><i title="Remove Category" className="fa fa-trash-o fa-lg"></i>
				                </button>
				              </div>
				            </div>
					        </div>
						    </div>
						  )
					  }
					  {(!subData[0].CATEGORY || subData[0].CATEGORY.length === 0) &&
					    <div className="panel panel-default">
				        <div className="panel-heading empty">
			            <div className="row record">
			              <div className="col-xs-12">There are no categories for this subscriber.</div>
			            </div>
				        </div>
					    </div>
					  }
					</div>

					<div className="row button-row">
				    <div className="col-xs-12 form-group text-center">
			        <Link 
			        	to={`${settings.subPath}/subscribers/subscriptions`}
			        	className="btn btn-default btn-lg">Back</Link>
				    </div>
					</div>
				</div>
			}
			{subData.length === 0 &&
				<div data-testid="no-details">Error</div>
			}
		</div>
	)
}
