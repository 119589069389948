import API from '../services/api'

export default function SubscriberAPI() {

  let Subscriber = {};

  Subscriber.findAll = function (params) {
    return API.save("getAllSubscribers", params);
  };

  Subscriber.optOut = function(params) {
  	return API.save("setCategoryStatus", params);
  };

  Subscriber.download = function(id) {
    var download = {
      action: "downloadSmartListData",
      params: {
          smartListId: id
      }
    };

    return API.download(download, 'application/zip', 'download');
  };

  return Subscriber;

}
