import React from 'react'
import { Constants } from '../../helpers/constants'

export default function UsersModal(props) {
  const constantUsers = Constants.Users;

  const updateField = (event) => {
    props.rerender({new: props.new, data: {...props.data, [event.target.name]:event.target.value}});
  }

  const validFields = () => {
    if (
      props.data.email &&
      props.data.roles
    ) return true;
    return false
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (validFields()) {
      const payload = {
        user: {
          email: props.data.email,
          roles: props.data.roles,
          userId: props.data.userId
        }
      }
      props.action({event: event, data: payload})
      .then((data) => {
        if (data.data.statusCode === 200) {
          props.success();
        } else {
          props.rerender({new: props.new, data: props.data, error: data.data.message});
        }
      })
      .catch(err => {
        props.rerender({new: props.new, data: props.data, error: 'SERVICE ERROR'});
      });
    } else {
      props.rerender({new: props.new, data: props.data, submitted: true});
    }
  }

  return (
    <React.Fragment>

      <div className="modal-header" data-testid="modal-window">
        <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
        <h3>{props.typemessage}User</h3>
        {props.error &&
          <div data-testid="modal-error-message" className="error-field">{props.error}</div>
        }
      </div>

      <form noValidate name="form" autoComplete="off" onSubmit={submitForm}>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Email</label>
              <input data-testid="modal-field-email" required name="email" maxLength="30" className="form-control" placeholder="Enter an email" autoComplete="off" value={props.data.email} onChange={updateField}   />
              <input type="text" style={{display: 'none'}} name="emailAutoCompleteDisable" />
              {props.submitted && !props.data.email &&
                <div data-testid="modal-error-email" className="error-field">Please fill out this field (maximum 30 digits)</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Role</label>
              <div className="styled-select">
                <select required name="roles" value={(props.data) ? props.data.roles : ""} onChange={updateField}>
                  <option value="" disabled>Select a role</option>
                  {Object.keys(constantUsers).map((role, key) => 
                    <option key={key} value={role}>{constantUsers[role]}</option>
                  )}
                </select>
              </div>
              {props.submitted && !props.data.roles &&
                <div data-testid="modal-error-role" className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
          <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
        </div>

      </form>

    </React.Fragment>
  )
}