let settings;

if (window.env.REACT_APP_REGION === 'us-east-1') {
    switch (window.env.REACT_APP_ENV) {
        case 'production':
            settings = {
                name: 'production',
                apiBaseUrl: '/',
                apiService: 'momentum/service',
                subPath: '',
                region: 'us-east-1',
                userPoolId: 'us-east-1_AvwuapCEi',
                userPoolWebClientId: '2acpti30op7i47hgfk7d65ikhn'
            };
            break;
        case 'staging': // clone of development
            settings = {
                name: 'staging',
                apiBaseUrl: 'https://mmt-qa.icfmomentum.io/',
                apiService: 'momentum/service',
                subPath: '',
                clientId: 1,
                userId: 1084,
                region: 'us-east-1',
                userPoolId: 'us-east-1_AQUCPXMVN',
                userPoolWebClientId: '17ahapqr1b56519khk79md0n7h'
            };
            break;
        case 'development':
            settings = {
                name: 'development',
                apiBaseUrl: 'https://mmt-qa.icfmomentum.io/',
                apiService: 'momentum/service',
                subPath: '',
                clientId: 1,
                userId: 1084,
                region: 'us-east-1',
                userPoolId: 'us-east-1_AQUCPXMVN',
                userPoolWebClientId: '17ahapqr1b56519khk79md0n7h'
            };
            break;
        default:
            settings = {};
    }
} else if (window.env.REACT_APP_REGION === 'ca-central-1') {
    switch (window.env.REACT_APP_ENV) {
        case 'production':
            settings = {
                name: 'production',
                apiBaseUrl: '/',
                apiService: 'momentum/service',
                subPath: '',
                region: 'ca-central-1',
                userPoolId: 'ca-central-1_PvECsvAWZ',
                userPoolWebClientId: '2acpti30op7i47hgfk7d65ikhn'
            };
            break;
        case 'staging': // clone of development
            settings = {
                name: 'staging',
                apiBaseUrl: 'https://mmt-qa.icfmomentum.ca/',
                apiService: 'momentum/service',
                subPath: '',
                clientId: 1,
                userId: 1084,
                region: 'ca-central-1',
                userPoolId: 'ca-central-1_SMj73xPEY',
                userPoolWebClientId: '1mf0bf8cc0qh94pt5jtpoq8n7q'
            };
            break;
        case 'development':
            settings = {
                name: 'development',
                apiBaseUrl: 'https://mmt-qa.icfmomentum.ca/',
                apiService: 'momentum/service',
                subPath: '',
                clientId: 1,
                userId: 1084,
                region: 'ca-central-1',
                userPoolId: 'ca-central-1_SMj73xPEY',
                userPoolWebClientId: '1mf0bf8cc0qh94pt5jtpoq8n7q'
            };
            break;
        default:
            settings = {};
    }
}

export default settings
