import React, { useState } from 'react';
import { ModalContext } from '../../context/modal'
import { Redirect } from "react-router-dom";

import CampaignsAPI from '../../models/campaign'

import CreateCommunicationModal from '../modals/CreateCommunicationModal'
import DeleteCampaignModal from '../modals/campaigns/DeleteCampaignModal'

import SimpleResponsePanel from './SimpleResponsePanel';
import TriggerPanel from './TriggerPanel';
import TriviaPanel from './TriviaPanel';
import ContestPanel from './ContestPanel';
import JourneyPanel from './JourneyPanel';

import JourneyTypeForm from '../journey/JourneyTypeForm'
import Correspondence from '../correspondence/Correspondence'
import Contest from '../contest/Contest'
import Trivia from '../trivia/Trivia'

export default function CampaignPanel(props) {
    let { toggleModal, setModalContent, setModalWidth } = React.useContext(ModalContext);

    const [redirectTo, setRedirectTo] = useState("");
    const [panelCollapse, setPanelCollapse] = useState("collapse");

    const communicationStatusClasses = {
        'ACTIVE': 'record-active',
        'DRAFT': 'record-draft',
        'COMPLETED': 'record-completed',
        'DISABLED': 'record-draft'
    };

    const loadCampaignData = () => {
        //
    }

    const onPanelClick = (flag) => {
        console.log(flag);
        if(flag === 'collapse') {
            setPanelCollapse(flag);
        } else {
            loadCommunications(props.campaign.campaignId);
            //setPanelCollapse(flag);

            // CampaignsAPI().getCommunications({ campaignId: props.campaign.campaignId })
            // .then((res) => {
            //     const nshortcodes = props.shortcodes.reduce((acc, sc) => ({...acc, [sc._id]: sc.number}), {});
            //     const communications = res.data.data || {};

            //     if (res.data.data) {
            //         communications.JOURNEY.forEach(com => {
            //             const sID = com.shortcodes[0].shortCodeId;
            //             com.shortcodes[0].shortCodeId = nshortcodes[sID] || sID;
            //         });
            //         communications.TRIGGER.forEach(com => {
            //             com.shortCodeId = nshortcodes[com.shortCodeId] || com.shortCodeId;
            //         });
            //         communications.TRIVIA.forEach(com => {
            //             com.shortCodeId = nshortcodes[com.shortCodeId] || com.shortCodeId;
            //         });
            //     }
            //     props.campaign.communications = communications;
            //     ["JOURNEY", "TRIVIA", "TRIGGER"].forEach(item => props.changeInactiveStatus(props.campaign.communications, item));
            //     ["TRIVIA", "TRIGGER"].forEach(item => props.getShortcodeMatch(props.campaign.communications, item));
            //     props.createNewArrayForCommunication(props.campaign.communications, "SIMPLE");
            //     props.createNewArrayForCommunication(props.campaign.communications, "CONTEST");                  
            //     setPanelCollapse(flag);
            // })
        }
    }

    const loadCommunications = (campaignId) => {
        CampaignsAPI().getCommunications({ campaignId: props.campaign.campaignId })
        .then((res) => {
            const nshortcodes = props.shortcodes.reduce((acc, sc) => ({...acc, [sc._id]: sc.number}), {});
            const communications = res.data.data || {};

            if (res.data.data) {
                communications.JOURNEY.forEach(com => {
                    const sID = com.shortcodes[0].shortCodeId;
                    com.shortcodes[0].shortCodeId = nshortcodes[sID] || sID;
                });
                communications.TRIGGER.forEach(com => {
                    com.shortCodeId = nshortcodes[com.shortCodeId] || com.shortCodeId;
                });
                communications.TRIVIA.forEach(com => {
                    com.shortCodeId = nshortcodes[com.shortCodeId] || com.shortCodeId;
                });
            }
            props.campaign.communications = communications;
            ["JOURNEY", "TRIVIA", "TRIGGER"].forEach(item => props.changeInactiveStatus(props.campaign.communications, item));
            ["TRIVIA", "TRIGGER"].forEach(item => props.getShortcodeMatch(props.campaign.communications, item));
            props.createNewArrayForCommunication(props.campaign.communications, "SIMPLE");
            props.createNewArrayForCommunication(props.campaign.communications, "CONTEST");                  
            setPanelCollapse("");
        })        
    }

    const renderCreateCommunicationModal = (evt, campaignId, campaignIndex) => {
        evt.stopPropagation();
        toggleModal(true);
        setModalWidth("modal-lg");
        setModalContent(CreateCommunicationModal({
            campaignId: campaignId,
            campaignInd: campaignIndex,
            hide: () => toggleModal(false),
            action: (comm) => {
                //console.log(url);
                toggleModal(false);
                //setRedirectTo(url);                           

                switch(comm) {
                    case 'simpleresponse':
                        renderSimpleResponseModal(campaignId, campaignIndex, 0, 'simpleresponse', 'new');
                        break;
                    case 'correspondence':
                        renderCorrespondenceModal(campaignId, campaignIndex);
                        break;
                    case 'contest':
                        renderContestModal(campaignId, campaignIndex);
                        break;
                    case 'trivia':
                        renderTriviaModal(campaignId, campaignIndex);
                        break;
                    case 'journey':
                        renderSimpleResponseModal(campaignId, campaignIndex, 0, 'journey', 'new');     
                        break;                                                                   
                }
            }
        }));
    }

    const renderSimpleResponseModal = (campaignId, campaignIndex, commId, type, mode) => {
        toggleModal(true);
        setModalWidth("modal-lg");
        setPanelCollapse('collapse')
        setModalContent(JourneyTypeForm({
            campaignId: campaignId,
            campaignInd: campaignIndex,
            journeyId: commId,
            editMode: mode,
            type: type,
            hide: () => {
                toggleModal(false);
                loadCommunications(campaignId);
            },
            action: (url) => {
                toggleModal(false);
            }
        }));
    }    

    const renderCorrespondenceModal = (campaignId, campaignIndex, commId) => {
        toggleModal(true);
        setModalWidth("modal-lg");
        setPanelCollapse('collapse')
        setModalContent(Correspondence({
            campaignId: campaignId,
            campaignInd: campaignIndex,
            triggerId: commId,
            hide: () => {
                toggleModal(false);
                loadCommunications(campaignId);
            },
            action: (url) => {
                toggleModal(false);
            }
        }));
    }     

    const renderContestModal = (campaignId, campaignIndex, commId) => {
        toggleModal(true);
        setModalWidth("modal-lg");
        setPanelCollapse('collapse')
        setModalContent(Contest({
            campaignId: campaignId,
            campaignInd: campaignIndex,
            contestId: commId,
            hide: () => {
                toggleModal(false);
                loadCommunications(campaignId);
            },
            action: (url) => {
                toggleModal(false);
            }
        }));
    }      

    const renderTriviaModal = (campaignId, campaignIndex, commId) => {
        toggleModal(true);
        setModalWidth("modal-lg");
        setPanelCollapse('collapse')
        setModalContent(Trivia({
            campaignId: campaignId,
            campaignInd: campaignIndex,
            triviaId: commId,
            hide: () => {
                toggleModal(false);
                loadCommunications(campaignId);
            },
            action: (url) => {
                toggleModal(false);
            }
        }));
    }          

    const renderDeleteCampaignModal = (data) => {
        setModalContent(DeleteCampaignModal({
            hide: () => toggleModal(false),
            action: ({type, payload}) => {
                switch(type) {
                    case 'setError':
                        props.setError(payload);
                        break;
                    case 'deleteCampaign':
                        CampaignsAPI().destroy(payload)
                            .then((rsp) => {
                                if (rsp.data.statusCode === 200) {
                                    props.deleteCampaign(payload);
                                    toggleModal(false);
                                } else {
                                    props.setError(rsp.data.message);
                                }
                            }).catch((rsp) => {
                                props.setError(rsp.data.message);
                            });
                        break;
                    default:
                        console.log('default', type, payload);
                }
            },
            campaign: {
                id: (data && data.id) || -1,
                name: (data && data.name) || ''
            },
            error: (data && data.error) || ''
        }));
        toggleModal(true);
    }

    const formatDateString = (timestamp, type) => {
        let dateObject = new Date(timestamp);
        const months = [null, 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let date = dateObject.getUTCDate();
        date = `${date}`.padStart(2, 0);
        let month = dateObject.getUTCMonth() + 1;
        let year = dateObject.getUTCFullYear()

        if (type === 1) { // format: 2020-11-30
            month = `${month}`.padStart(2, 0);
            return `${year}-${month}-${date}`;
        } else { // format: Nov 11, 2020
            return `${months[month]} ${date}, ${year}`;
        }
    }

    const setCommunicationStatus = (evt, communication, type) => {
        evt.stopPropagation();
        let paramType;
        if (type === 'SIMPLE') {
            paramType = 'JOURNEY';
        } else if (type === 'CONTEST') {
            paramType = 'TRIVIA';
        } else {
            paramType = type;
        }

        const params = {
            "id": communication._id,
            "type": paramType
        };

        CampaignsAPI().toggleCommunicationStatus(params)
            .then(rsp => {
                if (rsp.data.statusCode === 200) {
                    const status = (rsp.data.data.status === 'IN-ACTIVE') ? 'DISABLED' : rsp.data.data.status;
                    props.updateCommunicationStatus(communication.campaignId, communication._id, status, type);
                } else {
                    props.setError(rsp.data.message);
                }
            }, (rsp) => {
                props.setError(rsp.data.message);
            });
    }

    return (    
        <div className="panel-group accordion" id="collapse-panel">
            { redirectTo && <Redirect to={redirectTo} /> }

            <div className="panel panel-default">
                <div className={`panel-heading collapseable ${communicationStatusClasses[props.campaign.status.toUpperCase()]}`} onClick={() => onPanelClick((panelCollapse.length) ? "" : "collapse")} data-target={`#collapse-panel-body-${props.index}`}>
                    <div className="row record">
                        <div className="col-md-4 col-sm-8 col-xs-8">
                            { (panelCollapse.length) ? <i className="fa fa-lg fa-fw fa-angle-down"></i> : <i className="fa fa-lg fa-fw fa-angle-up"></i> }
                            <span className="highligted-text">{props.campaign.name}</span>
                        </div>
                        <div className="col-md-2 hidden-sm hidden-xs text-right">
                            {props.campaign.communications.SIMPLE.length + props.campaign.communications.JOURNEY.length + props.campaign.communications.TRIGGER.length + props.campaign.communications.TRIVIA.length + props.campaign.communications.CONTEST.length}
                        </div>
                        <div className="col-md-4 text-right hidden-sm hidden-xs">
                            {formatDateString(props.campaign.startDate)} &#8211; {formatDateString(props.campaign.endDate)}
                        </div>
                        <div className="col-md-2 col-sm-4 col-xs-4 text-right">
                            <span className="fa-wrap"><i className="fa fa-plus fa-lg" onClick={evt => renderCreateCommunicationModal(evt, props.campaign.campaignId, props.index)} title="Create Communication"></i></span>
                            <span className="fa-wrap">
                                <i 
                                    className="fa fa-pencil-square-o fa-lg" 
                                    onClick={_ => {
                                        _.stopPropagation();
                                        props.renderCampaignFormModal('edit', {
                                            campaign: props.campaign, 
                                            form: {
                                                name: props.campaign.name, 
                                                start: formatDateString(props.campaign.startDate, 1), 
                                                end: formatDateString(props.campaign.endDate, 1)
                                            }
                                        })}
                                    } 
                                    title="Edit Campaign"></i>
                            </span>
                            <span className="fa-wrap"><i className="fa fa-trash-o fa-lg" onClick={evt => renderDeleteCampaignModal({id: props.campaign._id, name: props.campaign.name})} title="Delete Campaign"></i></span>
                        </div>
                    </div>
                </div>

                <div id="collapse-panel-body-$index" data-parent="collapse-panel" className={`panel-collapse ${panelCollapse}`}>
                    <div className="panel-body panel-section-body">

                        <div className="panel panel-default row panel-section-record-header hidden-sm hidden-xs">
                            <div className="col-md-1"></div>
                            <div className="col-md-11">
                                <div className="panel-heading row">
                                    <div className="col-md-3 col-xs-8 highligted-text">Name</div>
                                    <div className="col-md-2 hidden-xs">Trigger</div>
                                    <div className="col-md-2 hidden-xs">Short Code</div>
                                    <div className="col-md-3 hidden-xs">Date Range</div>
                                    <div className="col-md-2 col-xs-4 text-right"></div>
                                </div>
                            </div>
                        </div>

                        { 
                            (props.campaign.communications.SIMPLE.length > 0) &&
                                <SimpleResponsePanel 
                                    data={props.campaign.communications.SIMPLE}
                                    communicationStatusClasses={communicationStatusClasses}
                                    campaignId={props.campaign.campaignId}
                                    index={props.index}
                                    renderCommunicationModal={renderSimpleResponseModal}
                                    setRedirectTo={setRedirectTo}
                                    setError={props.setError}
                                    formatDateString={formatDateString}
                                    expand={props.filterApplied ? 'non-expandable' : ''} 
                                    updateCommunicationStatus={props.updateCommunicationStatus}
                                    deleteCommunication={props.deleteCommunication}
                                    setCommunicationStatus={setCommunicationStatus} />
                        }
                        { 
                            (props.campaign.communications.TRIGGER.length > 0) &&
                                <TriggerPanel 
                                    data={props.campaign.communications.TRIGGER}
                                    communicationStatusClasses={communicationStatusClasses}
                                    campaignId={props.campaign.campaignId}
                                    index={props.index}
                                    renderCommunicationModal={renderCorrespondenceModal}
                                    setRedirectTo={setRedirectTo}
                                    setError={props.setError}
                                    formatDateString={formatDateString}
                                    expand={props.filterApplied ? 'non-expandable' : ''}
                                    updateCommunicationStatus={props.updateCommunicationStatus}
                                    deleteCommunication={props.deleteCommunication} 
                                    setCommunicationStatus={setCommunicationStatus} />
                        }
                        { 
                            (props.campaign.communications.TRIVIA.length > 0) && 
                                <TriviaPanel 
                                    data={props.campaign.communications.TRIVIA}
                                    setError={props.setError}
                                    communicationStatusClasses={communicationStatusClasses}
                                    renderCommunicationModal={renderTriviaModal}
                                    setRedirectTo={setRedirectTo}
                                    formatDateString={formatDateString}
                                    campaignId={props.campaign.campaignId}
                                    index={props.index}
                                    expand={props.filterApplied ? 'non-expandable' : ''}
                                    updateCommunicationStatus={props.updateCommunicationStatus}
                                    deleteCommunication={props.deleteCommunication} 
                                    setCommunicationStatus={setCommunicationStatus} /> 
                        }
                        { 
                            (props.campaign.communications.CONTEST.length > 0) &&
                                <ContestPanel 
                                    data={props.campaign.communications.CONTEST}
                                    setError={props.setError}
                                    communicationStatusClasses={communicationStatusClasses}
                                    renderCommunicationModal={renderContestModal}
                                    setRedirectTo={setRedirectTo}
                                    formatDateString={formatDateString}
                                    campaignId={props.campaign.campaignId}
                                    index={props.index}
                                    expand={props.filterApplied ? 'non-expandable' : ''}
                                    updateCommunicationStatus={props.updateCommunicationStatus}
                                    deleteCommunication={props.deleteCommunication} 
                                    setCommunicationStatus={setCommunicationStatus} />
                        }
                        { 
                            (props.campaign.communications.JOURNEY.length > 0) && 
                                <JourneyPanel 
                                    data={props.campaign.communications.JOURNEY}
                                    setError={props.setError}
                                    communicationStatusClasses={communicationStatusClasses}
                                    renderCommunicationModal={renderSimpleResponseModal}
                                    setRedirectTo={setRedirectTo}
                                    formatDateString={formatDateString}
                                    campaignId={props.campaign.campaignId}
                                    index={props.index}                                    
                                    expand={props.filterApplied ? 'non-expandable' : ''}
                                    updateCommunicationStatus={props.updateCommunicationStatus}
                                    deleteCommunication={props.deleteCommunication}
                                    setCommunicationStatus={setCommunicationStatus} />
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
