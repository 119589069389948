import React from 'react'

export default function ShortCodesModal(props) {
  const updateField = (event) => {
    let fieldValue = event.target.value;
    if (event.target.name === 'sendEmailFlag' || event.target.name === 'sendSmsFlag' ) {
      fieldValue = event.target.checked;
    }
    props.rerender({ new: props.new, data: { ...props.data, [event.target.name]: fieldValue} });
  }

  const validFields = () => {
    if (
      props.data.firstName &&
      props.data.lastName &&
      (props.data.email || props.data.phoneNumber) &&
      (props.data.sendEmailFlag ? props.data.email : true) &&
      (props.data.sendSmsFlag ? props.data.phoneNumber : true)
    ) return true;
    return false
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (validFields()) {
      props.action({event: event, data: props.data})
      .then((data) => {
        if (data.data.statusCode === 200) {
          props.success();
        } else {
          props.rerender({new: props.new, data: props.data, error: data.data.message});
        }
      })
      .catch(err => {
        props.rerender({new: props.new, data: props.data, error: err.message});
      });
    } else {
      props.rerender({ new: props.new, data: props.data, submitted: true });
    }
  }

  return (
    <React.Fragment>

      <div className="modal-header" data-testid="modal-window">
        <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
        <h3>{props.typemessage}Contact</h3>
        {props.error &&
          <div data-testid="modal-error-message" className="error-field">{props.error}</div>
        }
      </div>

      <form noValidate name="form" autoComplete="off" onSubmit={submitForm}>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>First Name</label>
              <input data-testid="modal-field-firstname" required name="firstName" className="form-control" placeholder="Enter a first name" value={props.data.firstName} onChange={updateField} />
              {props.submitted && !props.data.firstName &&
                <div data-testid="modal-error-firstname" className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Last Name</label>
              <input data-testid="modal-field-lastname" required name="lastName" className="form-control" placeholder="Enter a last name" value={props.data.lastName} onChange={updateField} />
              {props.submitted && !props.data.lastName &&
                <div data-testid="modal-error-lastname" className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Email</label>
              <input data-testid="modal-field-email" name="email" className="form-control" placeholder="Enter an email address" value={props.data.email} onChange={updateField} />
              {props.submitted && (!props.data.email && !props.data.phoneNumber) &&
                <div data-testid="modal-error-email" className="error-field">Please fill out either this or the phone field</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Phone</label>
              <input name="phoneNumber" className="form-control" placeholder="Enter a phone number" value={props.data.phoneNumber} onChange={updateField} />
              {props.submitted && (!props.data.email && !props.data.phoneNumber) &&
                <div className="error-field">Please fill out either this or the email field</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="checkbox-inline">
                <input type="checkbox" name="sendEmailFlag" checked={props.data.sendEmailFlag} onChange={updateField} />
                Send notification via email
                        </label>
              {props.submitted && (!props.data.email && props.data.sendEmailFlag) &&          
                <div className="error-field">Please fill out the email field if this is checked</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label className="checkbox-inline">
                <input type="checkbox" name="sendSmsFlag" checked={props.data.sendSmsFlag} onChange={updateField} />
                Send notification via SMS
                        </label>
              {props.submitted && (!props.data.phoneNumber && props.data.sendSmsFlag) &&   
                <div className="error-field">Please fill out out the phone number field if this is checked</div>
              }
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
          <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
        </div>

      </form>

    </React.Fragment>
  )
}
