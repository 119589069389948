import React from 'react'

export default function Header(props) {
	return (
    <React.Fragment>
      <div className="row row-top">
        <div className="col-md-4">
          <h3>{props.title}</h3>
        </div>
        <div className="col-md-4">
          <button data-testid="button-create-client" type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={props.click}>
            {props.add}
              </button>
        </div>
        <div className="col-md-4 text-right">
          {props.children}
        </div>
      </div>
    </React.Fragment>
	)
}
