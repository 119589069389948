import React, { useState, useEffect, useReducer } from 'react'
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import settings from '../../config/settings'
import { ModalContext } from '../../context/modal'
import SmartlistAPI from '../../models/smartlist'
import ConfirmModal from '../modals/ConfirmModal'
import ResponseMessage from '../../components/ResponseMessage'

import SmartListFilter from './SmartListFilter'
import SmartListPreview from './SmartListPreview'

const initialState = {
  smartData: {},
  preview: false,
  error: '',
  savedResponse: false,
  messageType: 'Edit',
  addCount: 0,
  saveData: 0
};

function reducer(state, action) {
  switch (action.type) {
    case 'setSmartData':
      return {
        ...state, 
        smartData: action.payload
      };
    case 'setListName':
      return {
        ...state, 
        smartData: {...state.smartData,
          smartListsName: action.name
        }
      };
    case 'setPreview':
      return {
        ...state, 
        preview: action.preview
      };
    case 'setError':
      return {
        ...state, 
        error: action.error
      };
    case 'setSavedResponse':
      return {
        ...state,
        savedResponse: action.response, 
        error: ''
      };
    case 'setMessageType':
      return {
        ...state,
        messageType: action.msg
      };
    case 'setAddCount':
      return {
        ...state,
        addCount: action.count
      };
    case 'setSaveData':
      return {
        ...state,
        saveData: action.save
      };
    default:
      throw new Error();
  }
}

export default function SmartListRules(props) {
  const [redirect, setRedirect] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { id } = props.match.params;
  const pageId = parseInt(id);
  const create = useRouteMatch(`${settings.subPath}/subscribers/smartlists/create`);
  const history = useHistory();

  let { toggleModal, setModalContent } = React.useContext(ModalContext);

  useEffect(() => {
    if(!create) {
      if (Number.isInteger(pageId)) {
        if (props.location.data) {
          setupData(props.location.data);
        } else {
          getSmartLists();
        }
      } else {
        setRedirect(true);
      }
    } else {
      dispatch({type:'setMessageType', msg: 'Create New'});
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findIdMatch(list) {
    let match = list.filter((item) => {
      if (item.smartListId === pageId) {
        return true;
      }
      return false; 
    });
    if (match.length === 1) {
      setupData(match[0]);
    } else {
      setRedirect(true);
    }
  }

  function getSmartLists() {
    SmartlistAPI().findAll()
    .then((data) => {
      if (data.data.data.smartLists) {
        findIdMatch(data.data.data.smartLists);
      }
    })
    .catch(err => {
      setRedirect(true);
    });
  };

  function setupData(obj) {
    let selectSmartlist = obj;
    let smartlist = {
      filterList: [],
      loadComplete: false
    };
    smartlist.smartListsName = selectSmartlist.name;
    smartlist.totalSubscribers = selectSmartlist.totalSubscribers;
    if (selectSmartlist.rules !== undefined && selectSmartlist.rules.length > 0) {
      smartlist.rules = selectSmartlist.rules;
    }
    smartlist.rulesFormula = selectSmartlist.rulesFormula;
    dispatch({type:'setSmartData', payload: smartlist});
  }

  function showSmartRules(event) {
    event.preventDefault();
    dispatch({type:'setPreview', preview: false});
  }

  function showPreview(event) {
    event.preventDefault();
    dispatch({type:'setPreview', preview: true});
    dispatch({type:'setSaveData', save: 0});
  }

  function updateName(event) {
    event.preventDefault();
    dispatch({type:'setListName', name: event.target.value});
  }

  function formSubmit(event) {
    event.preventDefault();
    dispatch({type:'setSaveData', save: (state.saveData + 1)});
  }

  function triggerError(msg) {
    dispatch({type:'setError', error: msg});
  }

  function goBack(event) {
    history.push(`${settings.subPath}/subscribers/smartlists`);
  }

  function saveSmartList(params) {
    let error = null;
    //let rulesError = false; //TODO
    let countError = false;
    let nameError = (state.smartData.smartListsName === undefined || state.smartData.smartListsName === '') ? true : false;
    let currentList = state.smartData;
    let validFilterCount = 0;
    // let success = false; //TODO

    //dispatch({type:'setSavedResponse', response: false}); //TODO - Check if needed

    if (Number.isInteger(pageId)) {
      currentList.smartListId = pageId;
    }
    currentList.name = state.smartData.smartListsName;
    currentList.rulesFormula = params.formula.rule;
    currentList.rules = [];

    for (var filter in params.rules) {
      var currentFilter = params.rules[filter];

      if (currentFilter.removed !== true && currentFilter.attr !== "" && (currentFilter.value.length !== 0 || currentFilter.operator.fieldValueType === "NOTNEEDED")) {
        var filterValue = [];
        if (currentFilter.operator.fieldValueType === 'DatePicker') {
          //parse date string to date milliseconds
          if (!isNaN(Date.parse(currentFilter.value))) {
            currentFilter.value = Date.parse(currentFilter.value); 
          }
        }
        filterValue.push(currentFilter.value[0]);
        if (currentFilter.operator.requiredValues === 2) {
          if (currentFilter.operator.fieldValueType === 'DatePicker') {
            //parse date string to date milliseconds
            if (!isNaN(Date.parse(currentFilter.othervalue))) {
              currentFilter.othervalue = Date.parse(currentFilter.othervalue); 
            }
          }
          filterValue.push(currentFilter.othervalue[0])
        } else if (currentFilter.operator.requiredValues === -2) {
          if (currentFilter.multiIn.length > 0) {
            filterValue = [];
            for (var val in currentFilter.value) {
              filterValue.push(currentFilter.value[val]); 
            }
          }
        }
        currentList.rules.push({
          "attributeDBName": currentFilter.attr.value,
          "attributeLabel": currentFilter.attr.label,
          "operatorName": currentFilter.operator.operatorName,
          "attributeType": currentFilter.input,
          "values": filterValue,
          "ignoreYear": currentFilter.ignoreYear
        }); 
        validFilterCount++;
      }
    }

    countError = validFilterCount > 0 ? false : true;

    if (!countError && !nameError && params.formula.status !== 'invalid' && validFilterCount === params.rules.length) {
      //$(window).scrollTop(0);
      SmartlistAPI().save(currentList)
        .then((data) => {
          dispatch({type:'setSavedResponse', response: true});
          setTimeout(function(){
            if(create) {
              setRedirect(true);
            } else {
              dispatch({type:'setSavedResponse', response: false});
            }
          },3000);
        })
        .catch(err => {
          dispatch({type:'setError', error: err});
        });

    } else {
      console.log('errors??')
      //TODO
      //rulesError = true;
      window.scrollTo(0,0);
    }

    if (validFilterCount !== params.rules.length) {
      error = "Please complete or delete unfilled filters(s)";
      dispatch({type:'setError', error: error});
    }

  };

  function downloadModal(event) {
    event.stopPropagation();
    event.preventDefault();
    toggleModal();
    setModalContent(ConfirmModal({
      message: `Are you sure you want to download the ${state.smartData.smartListsName} smart list?`,
      hide: () => toggleModal(false),
      action: () => downloadSmartlist()
    }));
  };

  function downloadSmartlist() {
    SmartlistAPI().download(pageId)
    .then((data) => {
      let file = new Blob([data.data], {type: 'application/zip'})
      let fileName = data.headers['file-name'];
      let url = URL.createObjectURL(file);
      let download = document.createElement('a');
      download.href = url;
      download.download = fileName;
      download.click();
      toggleModal(false);
    })
    .catch(err => {
      toggleModal(false);
      dispatch({type:'setError', error: err});
    });
  }  

  return (
    <div>
      {redirect &&
        <Redirect to={`${settings.subPath}/subscribers/smartlists`} />
      }

      {(create || (state.smartData)) &&
        <span>
          <h1 className="text-center">
            {state.messageType} Smart List
            {state.smartData && state.smartData.totalSubscribers > 0 &&
              <button type="button" onClick={downloadModal} className="pull-right" title="Download Smart List"><i className="fa fa-fw fa-download" data-testid="smartlist-download"></i></button>
            }
          </h1>

          <ResponseMessage
            validation={state.error}
            message={state.error}
            class="error-field" />

          <ResponseMessage
            validation={state.savedResponse}
            message="Smart List successfully saved!"
            class="success-field" />

          <form className="form-smartlist" onSubmit={formSubmit} noValidate name="form">
            <div className="record-title">
              <div className="row">
                <div className={`col-xs-12 form-group ${state.preview ? 'col-md-12' : 'col-md-8'}`}>
                  <label>Name</label>
                  <input
                    data-testid="smartlist-name" 
                    required
                    type="text" 
                    name="name" 
                    maxLength="30" 
                    className="form-control" 
                    placeholder="Enter a smart list name"
                    onChange={updateName} 
                    value={(state.smartData.smartListsName) ? state.smartData.smartListsName : ''} />
                </div>
                {!state.preview &&
                  <div className="col-md-4 col-xs-12 form-group">
                    <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                    <button
                      type="button" 
                      className="full-btn btn btn-primary btn-lg" 
                      onClick={() => dispatch({type:'setAddCount', count: state.addCount + 1})}>
                      <i className="fa fa-plus-circle fa-lg"></i> Add Smart Rule</button>
                  </div>
                }
              </div>
            </div>

            <div className="row row-smartListNav ng-isolate-scope">
              {!create && 
                <ul className="nav nav-tabs nav-justified" ng-class="{'nav-stacked': vertical, 'nav-justified': justified}">
                  <li className={`${state.preview ? '' : 'active'}`}>
                    <button
                      type="button" 
                      onClick={showSmartRules}>Smart List Rules</button>
                  </li>
                  <li className={`${state.preview ? 'active' : ''}`}>
                    <button
                      type="button" 
                      onClick={showPreview}>Preview</button>
                  </li>
                </ul>
              }
              <div className="tab-content">
                {!state.preview && ((create && state.messageType === 'Create New') || state.smartData.smartListsName) &&
                  <div className={(state.preview) ? 'hidden' : ''}>
                    <SmartListFilter
                      data={state.smartData}
                      add={state.addCount}
                      save={state.saveData}
                      saveFunc={saveSmartList}
                      errorFunc={triggerError}
                      show={!state.preview}
                      create={create}
                    />
                  </div>
                }
                {!create &&
                  <div className={(state.preview) ? '' : 'hidden'}>
                    <SmartListPreview
                      id={pageId}
                      errorFunc={triggerError}
                      show={state.preview}
                    />
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 form-group">
                <div className="col-xs-6 text-right pull-left">
                  <button type="submit" className="btn btn-primary btn-lg">Save</button>
                </div>
                <div className="col-xs-6 text-left pull-left">
                  <button type="button" className="btn btn-default btn-lg" onClick={goBack}>Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </span>
      }

    </div>
  )
}