import React from 'react'
import MultiSelect from 'react-multi-select-component'
import RadioButtons from '../../components/RadioButtons';

export default function ProgramFormModal(props) {

    const updateField = (event) => {
        props.rerender({
            ...props, 
            form: {
                ...props.form, 
                [event.target.name]: (event.target.type === 'checkbox') ? event.target.checked : event.target.value
            }
        });
    }

    const updateIrregularField = (key, value) => {
        if (key === 'clientName') {
            props.rerender({
                ...props, 
                form: {
                    ...props.form, 
                    [key]: value
                }, 
                gatewayAccounts: value[0].gatewayAccounts
            });
        } else {
            props.rerender({
                ...props, 
                form: {
                    ...props.form, 
                    [key]: value
                }
            });
        }
    }

    const isValidProvisionedCode = () => {
        const length = props.form.provisionedCode.length;
        const patternTest = /^\d+$/.test(props.form.provisionedCode);
        switch(props.form.codeType) {
            case 'SHORT':
                return patternTest && length >= 4 && length <= 8;
            case 'LONG':
                return patternTest && (length === 10 || (length === 11 && props.form.provisionedCode.startsWith("1")));
            default:
                return false;
        }
    }

    const validFields = () => {
        if (
            props.form.clientName.length &&
            props.form.accountNumber.length &&
            props.form.programName.length && props.form.programName.length >= 4 && props.form.programName.length <= 50 &&
            props.form.codeType.length &&
            props.form.provisionedCode.length && isValidProvisionedCode(props.form.provisionedCode) &&
            props.form.startDate.length &&
            props.form.rateType.length &&
            props.form.hostingCharge.length
        ) return true;
        return false
    }

    const submitForm = (event) => {
        event.preventDefault();

        if (validFields()) {
            const payload = getPayload();

            props.action(payload)
                .then(data => {
                    if (data.data.statusCode === 200) {
                        props.success(data.data.data.gatewayProgram);
                    } else {
                        props.rerender({
                            ...props, 
                            error: data.data.message
                        });
                    }
                })
                .catch(err => {
                    props.rerender({form: props.form, error: err.message});
                });
        } else {
            props.rerender({form: {...props.form, submitted: true}});
        }
    }

    const getPayload = () => ({
        gatewayClient: props.form.clientName[0].value.name,
        accountNumber: props.form.accountNumber[0].value,
        programName: props.form.programName,
        provisionedCode : props.form.provisionedCode,
        programStartDate: props.form.startDate, //'YYYY-MM-DD',
        programEndDate: props.form.endDate,
        mms: props.form.mmsProgram,
        rateType: props.form.rateType,
        hostingCharge: props.form.hostingCharge,
        programType: props.form.codeType,
        _id: props.form._id
        // status: props.type ? props.form.status : 'IN-ACTIVE'
    });

    return (
        <>
            <div className="modal-header">
                <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
                <h3>{ props.typemessage } Program</h3>
                { props.error && <div data-testid="modal-error-message" className="error-field">{ props.error }</div> } 
            </div>

            <form noValidate name="settingsClientsForm" onSubmit={submitForm}>
                <div className="modal-body">
                    <div className="row form-group">
                        <div className="col-sm-6">
                            <label>Gateway Account</label>
                            <MultiSelect
                                disabled={props.readonly}
                                options={props.gwclients}
                                value={props.form.clientName}
                                onChange={(val) => updateIrregularField('clientName', val.length ? [val[val.length-1]] : [])}
                                labelledBy={"Select a Gateway Account"}
                                hasSelectAll={false} 
                                overrideStrings={{"selectSomeItems": "Select a Gateway Account"}}/>
                            { props.form.submitted && !props.form.clientName.length && <div className="error-field">This field is required.</div> }
                        </div>
                        <div className="col-sm-6">
                            <label>Account Number</label>
                            <MultiSelect
                                options={props.accountNumbers}
                                disabled={!props.form.clientName.length || props.readonly}
                                value={props.form.accountNumber}
                                onChange={(val) => updateIrregularField('accountNumber', val.length ? [val[val.length-1]] : [])}
                                labelledBy={"Select an Account Number"}
                                hasSelectAll={false} 
                                overrideStrings={{"selectSomeItems": "Select an Account Number"}}/>
                            { props.form.submitted && !props.form.accountNumber.length && <div className="error-field">This field is required.</div> }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <label>Program Name</label>
                            <input 
                                disabled={props.readonly}
                                required 
                                name="programName" 
                                className="form-control" 
                                minLength={4} 
                                maxLength={50} 
                                placeholder="Enter a program name"
                                value={props.form.programName}
                                onChange={updateField}
                                data-testid="modal-field-name"/> 
                            { props.form.submitted && !props.form.programName.length && 
                                <div data-testid="modal-error-name" className="error-field">This field is required.</div> } 
                            { props.form.submitted && (props.form.programName.length < 4 || props.form.programName.length > 50) && props.form.programName.length > 0 && 
                                <div data-testid="modal-error-name" className="error-field">This field requires between 4 and 50 characters.</div> } 
                        </div>
                    </div>
                    <div className="row flex-row">
                        <div className="col-sm-6 form-group">
                            <label>Code type: </label>
                            <RadioButtons 
                                disabled={props.readonly}
                                values={['SHORT', 'LONG']} 
                                value={(props && props.form && props.form.codeType) || 'SHORT'} 
                                setValue={value => updateIrregularField('codeType', value) } />
                            { props.form.submitted && ! props.form.codeType && 
                                <div data-testid="modal-error-name" className="error-field">Please fill out this field (maximum 30 characters)</div> } 
                        </div>
                        <div className="col-sm-6 form-group">
                            <label className="checkbox-inline">
                                <input 
                                    disabled={props.readonly}
                                    type="checkbox" 
                                    name="mmsProgram" 
                                    checked={props.form.mmsProgram} 
                                    onChange={updateField} 
                                    />
                                MMS Program?
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <label>Provisioned Code</label>
                            <input 
                                required 
                                name="provisionedCode" 
                                disabled={props.readonly}
                                className="form-control" 
                                placeholder={`Enter a code ${(props.form.codeType === 'SHORT' ? 'between 4 to 8 digits' : 'between 10 to 11 digits')}`}
                                value={props.form.provisionedCode}
                                onChange={updateField}/> 
                            { props.form.submitted && !isValidProvisionedCode() && 
                                <div className="error-field">Please fill out this field ({props.form.codeType === 'SHORT' ? 'between 4 to 8 digits' : 'between 10 to 11 digits'}), no special characters</div>
                        } </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <label>Program Start Date</label>
                            <input 
                                type="date" 
                                required 
                                disabled={props.readonly}
                                name="startDate" 
                                className="form-control" 
                                //min={(new Date()).toISOString().substring(0,10)}
                                value={props.form.startDate}
                                onChange={updateField}/> 
                            { props.form.submitted && !props.form.startDate && <div className="error-field">Please fill out this field</div> } 
                        </div>
                        <div className="col-sm-6 form-group">
                            <label>CWTA End Date (if applicable)</label>
                            <input 
                                type="date" 
                                name="endDate" 
                                disabled={props.readonly}
                                className="form-control" 
                                value={props.form.endDate}
                                min={(new Date()).toISOString().substring(0,10)}
                                onChange={updateField}/> 
                            {/* {props.submitted && ! props.form.endDate && <div className="error-field">Please fill out this field</div>} */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <label>Rate type</label>
                            <div className="styled-select">
                                <select 
                                    name="rateType" 
                                    disabled={props.readonly}
                                    value={props.form.rateType} 
                                    onChange={updateField}>
                                    <option value="">Select rate type</option>
                                    <option value="STANDARD">STANDARD</option>
                                    <option value="PREMIUM">PREMIUM</option>
                                    <option value="FREE-TO-END USER">FREE-TO-END USER</option>
                                </select>
                            </div>
                            { props.form.submitted && !props.form.rateType && 
                                <div data-testid="modal-error-name" className="error-field">Please select a value</div> } 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <label>Hosting Charge</label>
                            <div className="styled-select">
                                <select 
                                    disabled={props.readonly}
                                    name="hostingCharge" 
                                    value={props.form.hostingCharge} 
                                    onChange={updateField}>
                                    <option value="">Select rate type</option>
                                    <option value="STANDARD">STANDARD</option>
                                    <option value="FREE HOSTING">FREE HOSTING</option>
                                </select>
                            </div>
                            { props.form.submitted && !props.form.hostingCharge && 
                                <div data-testid="modal-error-name" className="error-field">Please select a value</div> } 
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
                    <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
                </div>
            </form>
        </>
    )
}
