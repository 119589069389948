import React from "react"

export default function ListInputFilter(params) {

  return (
		<p className="input-group">
		  <input
		  	data-testid="input-list-filter"
		  	type="text" 
		  	className="form-control pull-right" 
		  	placeholder={params.placeholder}
			value={params.value}
		  	onChange={params.change} 
		  	onBlur={params.blur}
		  />
		  <span className="input-group-btn">
		    <button 
		    	type="button" 
		    	className="btn btn-default"
		    	onClick={params.click}>
		      <i className="fa fa-lg fa-search"></i>
		    </button>
		  </span>
		</p>
	);
}