import React from 'react'
import { Constants } from '../../helpers/constants'
import Helpers from '../../helpers/helpers'
import KeywordInput from '../../components/KeywordInput'

export default function ShortCodesModal(props) {
  const constantCountries = Constants.Countries;

  const updateField = (event) => {
    let fieldValue = event.target.value;
    if (event.target.name === 'startDate' || event.target.name === 'endDate' ) {
      let dateValue = new Date(event.target.value + 'T00:00:00').getTime();
      fieldValue = dateValue;
    }
    props.rerender({ 
      new: props.new, 
      data: { 
        ...props.data, 
        [event.target.name]: fieldValue
      },
      keywords: props.keywords
    });
  }

  const updateKeywords = (words) => {    
    props.rerender({ new: props.new, data: props.data, keywords: words });
  }

  const validFields = () => {
    if (
      props.data.number &&
      props.data.country &&
      props.data.startDate &&
      props.data.endDate
    ) return true;
    return false
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (validFields()) {
      props.action({event: event, data: props.data, keywords: props.keywords})
      .then((data) => {
        if (data.data.statusCode === 200) {
          props.success();
        } else {
          props.rerender({new: props.new, data: props.data, error: data.data.message});
        }
      })
      .catch(err => {
        props.rerender({new: props.new, data: props.data, error: err.message});
      });
    } else {
      props.rerender({ new: props.new, data: props.data, submitted: true });
    }
  }

  return (
    <React.Fragment>

      <div className="modal-header" data-testid="modal-window">
        <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
        <h3>{props.typemessage}Short Code</h3>
        {props.error &&
          <div data-testid="modal-error-message" className="error-field">{props.error}</div>
        }
      </div>

      <form noValidate name="form" autoComplete="off" onSubmit={submitForm}>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Short Code</label>
              <input data-testid="modal-field-code" type="text" required name="number" maxLength="30" className="form-control" placeholder="Enter a short code" value={props.data.number} onChange={updateField} />
              {props.submitted && !props.data.number &&
                <div data-testid="modal-error-code" className="error-field">Please fill out this field (maximum 30 digits)</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Country</label>
              <div className="styled-select">
                <select required name="country" value={(props.data) ? props.data.country : ""} onChange={updateField}>
                  <option value="" disabled>Select a country</option>
                  {Object.keys(constantCountries).map((country, key) => 
                    <option key={key} value={constantCountries[country]}>{constantCountries[country]}</option>
                  )}
                </select>
              </div>
              {props.submitted && !props.data.country &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Start Date</label>
              <input required 
                name="startDate" 
                placeholder="Select a start date" 
                type="date" 
                className="form-control"
                max={Helpers().FormatDate(props.data.endDate)} 
                value={Helpers().FormatDate(props.data.startDate)} onChange={updateField} />
              {props.submitted && !props.data.startDate &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>End Date</label>
              <input required 
                name="endDate" 
                placeholder="Select an end date" 
                type="date" 
                className="form-control"
                min={Helpers().FormatDate(props.data.startDate)} 
                value={Helpers().FormatDate(props.data.endDate)} 
                onChange={updateField} />
              {props.submitted && !props.data.endDate &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>

          <hr />

          <KeywordInput 
            readonly={false} 
            keywords={props.keywords} 
            formSubmitted={false}
            onUpdatedKeywords={updateKeywords} 
            hideHelper={true}
          /> 

        </div>

        <div className="modal-footer">
          <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
          <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
        </div>

      </form>

    </React.Fragment>
  )
}