import API from '../services/api'

export default function CampaignsAPI() {
    const Campaign = {};

    Campaign.findAll = () => {
        return API.findAll('getCampaigns')
    }

    Campaign.save = (campaignData) => {
        return API.save('saveCampaign', campaignData)
    }

    Campaign.destroy = (campaignId) => {
        return API.destroy('deleteCampaign', 'campaignId', campaignId)
    }

    Campaign.getCommunications = (campaignId) => {
        return API.findAll('getCampaignCommunications', campaignId)
    }

    Campaign.toggleCommunicationStatus = (communicationData) => {
        return API.findAll('toggleCommunicationStatus', communicationData)
    }

    Campaign.download = function(startDate, endDate) {
        var download = {
            action: "getInteractionsReport",
            params: {
                startDate: new Date(startDate + 'T00:00:00').getTime(),
                endDate: new Date(endDate + 'T00:00:00').getTime()
            }
        };

        return API.download(download, 'application/zip', 'download');
    };      
    

    return Campaign;
}
