import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../context/modal";

const Modal = () => {
  	let { isModalShowing, modalBody, modalSize } = React.useContext(ModalContext);
  	return (isModalShowing) ? (
		ReactDOM.createPortal(
			<React.Fragment>
				<div className="fade modal-backdrop in"></div>
				<div role="dialog" className="modal fade in">
					<div className={["modal-dialog", modalSize || ""].join(" ")}>
						<div className="modal-content">
							{modalBody}
						</div>
					</div>
				</div>
			</React.Fragment>, document.body
		)
	) : null;
};

export default Modal;