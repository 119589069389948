import React from 'react'
import Journey from './Journey'

export default function JourneyTypeForm(props) {
    const campaignId = parseInt(props.campaignId);
    const campaignInd = parseInt(props.campaignInd);
    const journeyId = parseInt(props.journeyId);
    const editMode = !(props.editMode);
    const type = (props.type === 'simpleresponse');

    return (
        <Journey 
            type={type ? 'Simple Response' : 'Journey'}
            mode={editMode ? 'edit' : 'new'}
            campaignId={campaignId} 
            campaignInd={campaignInd}
            journeyId={journeyId || null} 
            hide={props.hide} 
            action={props.action}/>
    )
}
