import React from 'react'
import Sidebar from './Sidebar'
import metadata from '../config/metadata.json';
import { NavLink } from 'react-router-dom'

const Header = () => (
	<nav className="navbar navbar-inverse navbar-static-top navbar-main fixed" role="navigation">
	    <div className="navbar-header">
	        <button type="button" className="navbar-toggle">
	            <span className="sr-only">Toggle navigation</span>
	            <i className="fa fa-fw fa-lg fa-bars"></i>
	        </button>
	        <NavLink to="/home" className="navbar-brand"></NavLink>
	        <div className="app-version">{`PLF v ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</div>
	    </div>
	    <Sidebar />
	</nav>
)

export default Header
