import React, { useState, useEffect, useRef } from 'react'
import Chart from 'chart.js';
//import moment from 'moment';
import Helpers from '../../helpers/helpers'
import ChartsAPI from '../../models/charts'
import ChartJS from '../../components/Chart'
import ResponseMessage from '../../components/ResponseMessage'
import DateRange from '../../components/DateRange'

//TODO - Convert to within React Function
class HomeCtrl {
	constructor() {
		this.timeFormat = 'MM/DD/YYYY HH:mm';
    this.oneDay = 24*60*60*1000;
    this.chart = {}; //STATE
    this.chart.line = [];
    this.chartChoice = '6'; //STATE
    this.isDatePicked = false; //STATE
    this.params = { //STATE
    	startDate: this.pastDays(this.chartChoice).getTime(),
    	endDate: new Date().getTime()
  	};

    /** Calendar **/
    this.chart.startDate = this.params.startDate;
    this.chart.endDate = this.params.endDate;
    this.dateFormat = 'MMM dd, yyyy';

    this.chart.status = {
      openedStart: false,
      openedEnd: false
    };
    this.dateOptionStart = {
      maxDate: this.params.endDate
    };
    this.dateOptionEnd = {
      maxDate: this.params.endDate
    };    	
	}

	init(props) {
		this.chartProps = props;
	}

  pastDays(int) {
    if(this.chart.endDate && typeof this.chart.endDate === 'object')
      return new Date(new Date().setDate(this.chart.endDate.getDate() - parseInt(int)) + 1);
    else if (this.chart.endDate && typeof this.chart.endDate !== 'object')
      return new Date(new Date().setDate(new Date(this.chart.endDate).getDate() - parseInt(int)) + 1);
    else
      return new Date(new Date().setDate(new Date().getDate() - parseInt(int)) + 1);
  };

  getGraphs() {
    var endDate = typeof this.chart.endDate === 'object' ?  this.chart.endDate.getTime() : this.chart.endDate,
    isToday = new Date().setHours(0,0,0,0) === new Date(endDate).setHours(0,0,0,0);

    this.error = '';

    if(this.chartChoice === 'other')
      this.chartStart = Math.round(Math.abs((this.chart.endDate - this.chart.startDate)/(this.oneDay)));
    else
      this.chartStart = this.chartChoice;

    this.chartChoice = (this.chartStart === 6 || this.chartStart === 29 || this.chartStart === 59) && isToday ? this.chartStart.toString() : this.chartChoice;

    if(!this.isDatePicked) {
      this.passedParams = {
        startDate: this.pastDays(this.chartStart).getTime(),
        endDate: endDate ? new Date().setDate(new Date(endDate).getDate()) : new Date().setDate(new Date().getDate())
      };
    } else {
      this.passedParams = {
        startDate: typeof this.chart.startDate === 'object' ? this.chart.startDate.getTime() : this.chart.startDate,
        endDate: typeof this.chart.endDate === 'object' ? this.chart.endDate.getTime() : this.chart.endDate
      };
    }
  };

  getMaxOfArray(a) {
    return Math.max.apply(null, a);
  };

  createGraph(data) {
    this.labels = [];
    this.chartData = data;

    //Clear Canvas
    if (this.myChart1 && this.myChart2) {
    	this.myChart1.destroy();
    	this.myChart2.destroy();
    }

    for (var d in this.chartData.labels) {
      this.labels.push(new Date(this.chartData.labels[d]));
    }

    //to fix graph breaking when first data point is null
    for (var v in this.chartData.values) {
      if (this.chartData.values[v][0] === null)
        this.chartData.values[v][0] = 0;
    }

    this.series1 = this.chartData.legend.slice(0, 2);
    this.data1 = this.chartData.values.slice(0, 2);
    this.maxdata1 = [this.getMaxOfArray(this.data1[0]), this.getMaxOfArray(this.data1[1])];


    this.series2 = this.chartData.legend.slice(2, 4);
    this.data2 = this.chartData.values.slice(2, 4);
    this.maxdata2 = [this.getMaxOfArray(this.data2[0]), this.getMaxOfArray(this.data2[1])];

    this.colors = ['#555252', '#FF8A80', 'rgba(85, 82, 82, 0.2)', 'rgb(255, 138, 128, 0.2)'];

    this.datasetOverride = [{ yAxisID: 'y-axis-1'}, { yAxisID: 'y-axis-2'}]; //TODO - Needed?

    this.options = {
      title: {
        display: true,
        text: ''
      },
      legend: {
        display: true,
        position: 'bottom'
      },
      tooltips: {
        enabled: true,
        mode: 'x-axis',
        backgroundColor: 'rgba(100,100,100,0.8)'
      },
      scales: {
        yAxes: [{
          //id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true,
            fontColor: '#555252',
              stepSize: 1 //this.maxdata1[0] < 10 === 1 else 0.1
            }
          },
        // {
        //     //id: 'y-axis-2',
        //     type: 'linear',
        //     display: true,
        //     position: 'right',
        //     gridLines: {
        //         display: false
        //     },
        //     ticks: {
        //         beginAtZero: true,
        //         fontColor: '#FF8A80',
        //         stepSize: 1 //this.maxdata1[0] < 10 === 1 else 0.1
        //     }
        // }
        ],
        xAxes: [{
          type: "time",
          time: {
            parser: this.timeFormat,
            tooltipFormat: 'll',
            unit: 'day'
          },
          scaleLabel: {
            display: true
          },
          ticks: {
            autoSkip: true,
          }
        }]
      },
      maintainAspectRatio: false,
      responsive: true
    };

    let ctx1 = document.getElementById(this.chartProps[0].id);
    this.myChart1 = this.initChart({
      elem: ctx1,
      data: [{
        label: this.series1[0],
        data: this.data1[0]
      }, {
        label: this.series1[1],
        data: this.data1[1]
      }]
    });

		let ctx2 = document.getElementById(this.chartProps[1].id);
    this.myChart2 = this.initChart({
      elem: ctx2,
      data: [{
        label: this.series2[0],
        data: this.data2[0]
      }, {
        label: this.series2[1],
        data: this.data2[1]
      }]
    });

  };

  initChart(params) {
    return new Chart(params.elem, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          label: params.data[0].label,
          backgroundColor: this.colors[2],
          borderColor: this.colors[0],
          //fill: false,
          data: params.data[0].data,
        },{
          label: params.data[1].label,
          backgroundColor: this.colors[3],
          borderColor: this.colors[1],
          //fill: false,
          data: params.data[1].data,
        }]
      },
      options: this.options
    });
  }

  changeChartView(choice) {
  	this.chartChoice = choice;
    this.isDatePicked = false;
    this.chart.endDate = null;
    let selectedDate = this.pastDays(this.chartChoice).getTime();
    this.params.endDate = new Date().getTime();
    this.chart.startDate = selectedDate;
    this.params.startDate = selectedDate;
    this.chart.endDate = this.params.endDate;
    this.getGraphs();
  };

  dateSelected(type) {
    this.isDatePicked = true;
    this.chart.status[type] = true;
    var selectedDate = new Date(this.chart[type]);
    var selectedDateTime = selectedDate.getTime();    
    if (this.params[type] !== selectedDateTime) {
      this.chartChoice = 'other';
      this.params[type] = selectedDateTime;
      this.getGraphs();
    }
  };
}

export default function Home() {

	const home = useRef(new HomeCtrl());
  const [startDate, setStartDate] = useState(Helpers().FormatDate(home.current.chart.startDate));
  const [endDate, setEndDate] = useState(Helpers().FormatDate(home.current.chart.endDate));
  const [chartChoice, setChartChoice] = useState(home.current.chartChoice);
  const [error, setError] = useState('');

  const chartProps = [{
    id: 'line1',
    name: 'New Subscribers vs. Unsubscribers'
  }, {
    id: 'line2',
    name: 'MT vs. MO'
  }];

  //Init
	useEffect(() => {
    let mounted = true;
		home.current.init(chartProps);
		home.current.getGraphs();
    async function drawGraph() {
  		await ChartsAPI().generateGraphData(home.current.passedParams)
      .then((data) => {
        try {
          if(mounted){
            home.current.createGraph(data.data.data);
          }
        } catch (e) {
          setError(data.data.message);
        }
      })
      .catch(err => {
        setError('Network error');
      });
    }
    drawGraph();

    return () => mounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  //Get Graph data with date dropdown changes
	function setDays(days) {
		setError('');
		setChartChoice(days);
		home.current.changeChartView(days);
    ChartsAPI().generateGraphData(home.current.passedParams)
    .then((data) => {
    	try {
    		home.current.createGraph(data.data.data);
    		setStartDate(Helpers().FormatDate(home.current.chart.startDate));
		    setEndDate(Helpers().FormatDate(home.current.chart.endDate));
    	} catch(e) {
    		setError(data.data.message);
    	}
    })
    .catch(err => {
      setError(err);
    });
	}

  //Get Graph data with date picker changes
	function dateSelected(evt) {
		setError('');
		if (evt.target.name === 'startDate') {
			setStartDate(evt.target.value);
			home.current.chart.startDate = new Date(evt.target.value).getTime();
    	home.current.chart.endDate = new Date(endDate).getTime();
    	home.current.dateSelected(evt.target.name);
		} else {
			setEndDate(evt.target.value);
			home.current.chart.startDate = new Date(startDate).getTime();
    	home.current.chart.endDate = new Date(evt.target.value).getTime();
    	home.current.dateSelected(evt.target.name);
		}
    ChartsAPI().generateGraphData(home.current.passedParams)
    .then((data) => {
    	if (data.data && data.data.data) {
    		home.current.createGraph(data.data.data);
    		setChartChoice(home.current.chartChoice);
    	} else if (data.data && !data.data.data) {
    		setError(data.data.message);
    	}
    })
    .catch(err => {
      setError(err);
    });
	}

	return (
    <div>
      <div className="row row-top">
        <div className="col-md-4">
          <h3>Home</h3>
        </div>
      </div>
      <ResponseMessage
        validation={error}
        message={error}
        class="error-field" />

      <DateRange
        start={startDate}
        end={endDate}
        choice={chartChoice}
        setChoice={setDays}
        setDate={dateSelected}
      />

      <div className="row">
        <div className="col-xs-12">
          <ChartJS 
          label={chartProps[0].name}
          id={chartProps[0].id}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <ChartJS 
          label={chartProps[1].name}
          id={chartProps[1].id}
          />
        </div>
      </div>

    </div>
	)
}
