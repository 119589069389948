import React, { useEffect, useReducer } from 'react';
import ListInputFilter from '../../components/ListInputFilter'
import { ModalContext } from '../../context/modal'
import ProgramFormModal from '../modals/ProgramFormModal'
import GatewayProgramsAPI from '../../models/gateway_programs'
import GatewayClientsAPI from '../../models/gwclients'
import ReactPaginate from 'react-paginate'
import Loader from '../../components/Loader'
import DownloadReportModal from '../modals/DownloadReportModal'

import MockAPIs from '../../models/mockdata'
import ProgramEditModal from '../modals/ProgramEditModal';

const initialState = {
    gwclients: [],
    gwAccounts: [],
    allPrograms: [],
    programs: [],
    filter: 'ALL',
    error: '',
    success: false,
    page: 1,
    forcedPage: 0,
    pageMax: 10,
    pageTotal: 1,
    showLoader: true
};
  
function reducer(state, action) {

    switch (action.type) {
        case 'setPrograms':
            return {
                ...state,
                allPrograms: action.payload,
                programs: action.payload,
                showLoader: false,
                pageTotal: Math.ceil(action.payload.length/state.pageMax),
                forcedPage: state.page
            };
        case 'setClients':
            return {
                ...state,
                gwclients: action.payload,
                gwAccounts: action.payload.gatewayAccounts
            }
        case 'setFilter':
            let updatedList;
            if (action.payload === 'ALL') 
                updatedList = [...state.allPrograms];
            else if (action.payload === 'ACTIVE') {
                updatedList = [...state.allPrograms].filter((e) => e.status === 'ACTIVE');
            } else if (action.payload !== 'ACTIVE') {
                updatedList = [...state.allPrograms].filter((e) => e.status !== 'ACTIVE');
            }

            return {
                ...state,
                programs: updatedList,
                pageTotal: Math.ceil(updatedList.length/state.pageMax),
                filter: action.payload,
                page: 1,
                forcedPage: 0,
            };
        case 'setError':
            return {
                ...state,
                error: action.payload
            };
        case 'removeProgram':
            return {
                ...state,
                allPrograms: state.allPrograms.filter(p => p._id !== action.payload),
                programs: state.programs.filter(p => p._id !== action.payload)
            };
        case 'addNewProgram':
            let totalPages = Math.ceil((state.allPrograms.length + 1) / state.pageMax);
            return {
                ...state,
                allPrograms: [...state.allPrograms, action.payload],
                programs: [...state.allPrograms, action.payload],
                pageTotal: totalPages,
                page: totalPages,
                forcedPage: totalPages - 1,
                filter: 'ALL'          
            }
        case 'editProgram':
            var programsList = state.programs;
            var foundIndex = programsList.findIndex(program => program._id === action.payload._id);
            programsList[foundIndex] = action.payload;
            return {
                ...state,
                //allPrograms: [...state.programs, action.payload],
                programs: programsList
            }
        case 'setPage':
            return {
                ...state,
                page: action.payload,
                forcedPage: action.payload - 1
            };
        case 'toggleLoader':
            return {
                ...state,
                showLoader: action.payload
            }
        case 'setEdit':
            return {
                ...state,
                gwAccounts: action.payload.accountNumbers
            }
        case 'getSearch':      
            const newPrograms = [...state.allPrograms].filter(p => p.provisionedCode.includes(action.payload) || (p.programName.toLowerCase()).includes(action.payload) || (p.gatewayClient.toLowerCase()).includes(action.payload));
            return {
                ...state,
                programs: newPrograms,
                page: 1,
                forcedPage: 0,
                pageTotal: Math.ceil(newPrograms.length/state.pageMax)
            }
        case 'sortPrograms':
            const sortMethod = field => {
                return (a, b) => {
                    const a1 = field === 'programStartDate' ? a[field] : a[field].toLowerCase();
                    const b1 = field === 'programStartDate' ? b[field] : b[field].toLowerCase();

                    if (a1 <= b1) {
                        return -1
                    } else if (b1 < a1) {
                        return 1
                    } else {
                        return 0
                    }
                };
            }

            const sortReverseMethod = field => {
                return (b, a) => {
                    const a1 = field === 'programStartDate' ? a[field] : a[field].toLowerCase();
                    const b1 = field === 'programStartDate' ? b[field] : b[field].toLowerCase();

                    if (a1 <= b1) {
                        return -1
                    } else if (b1 < a1) {
                        return 1
                    } else {
                        return 0
                    }
                };
            }

            let sortedPrograms = (action.payload.sortOrder === 'A') 
                                    ? [...state.programs].sort(sortMethod(action.payload.sortField)) 
                                    : [...state.programs].sort(sortReverseMethod(action.payload.sortField));

            return {
                ...state,
                programs: sortedPrograms
            }
        default:
            return {
                ...state
            }
    }
}

export default function ProgramList(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    let { toggleModal, setModalContent, setModalWidth} = React.useContext(ModalContext);
    const filterResults = (event) => {
        event.preventDefault();
        dispatch({type:'getSearch', payload: event.target.value.toLowerCase()})
    }

    const useMockData = false;

    useEffect(() => {
        (useMockData ? MockAPIs.programs.findAll : GatewayProgramsAPI().findAll())
            .then((data) => {
                if (data.data.statusCode === 200) {
                    dispatch({type: 'setPrograms', payload: data.data.data.gatewayPrograms})
                } else {
                    dispatch({type: 'setError', payload: data.data.message});
                }
            })
            .catch(err => {
                dispatch({type: 'setError', payload: err});
            });

        (useMockData ? MockAPIs.programs.getGatewayClients : GatewayClientsAPI().findAll())
            .then((data) => {
                if (data.data.statusCode === 200) {
                    dispatch({type: 'setClients', payload: data.data.data.gatewayClients.map(gwc => ({...gwc, label: gwc.name, value: gwc}))})
                } else {
                    dispatch({type: 'setError', payload: data.data.message});
                }
            })
            .catch(err => {
                dispatch({type: 'setError', payload: err});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatDate = (d) => (new Date(d)).toISOString().substring(0,10);

    const renderCreateProgramModal = (data, type) => {
        setModalContent(ProgramFormModal({
            typemessage: type === 'edit' ? 'Edit' : `Create New `,
            hide: () => toggleModal(false),
            error: (data && data.error) ? data.error : '',
            success: (newProgram) => {
                toggleModal(false);
                dispatch({type: 'addNewProgram', payload: newProgram})
            },
            rerender: (p) => renderCreateProgramModal(p, type),
            action: (payload) => GatewayProgramsAPI().save(payload),
            form: {
                clientName: (data && data.form && data.form.clientName) || [],
                accountNumber: (data && data.form && data.form.accountNumber) || [],
                programName: (data && data.form && data.form.programName) || '',
                codeType: (data && data.form && data.form.codeType) || 'SHORT',
                mmsProgram: (data && data.form && data.form.mmsProgram) || false,
                provisionedCode: (data && data.form && data.form.provisionedCode) || '',
                startDate: (data && data.form && data.form.startDate) || '',
                endDate: (data && data.form && data.form.endDate) || '',
                rateType: (data && data.form && data.form.rateType) || '',
                hostingCharge: (data && data.form && data.form.hostingCharge) || '',
                submitted: (data && data.form && data.form.submitted) || false,
                _id: (data && data.form && data.form._id) || null
            },
            gwclients: state.gwclients.filter(gwc => gwc.status === 'ACTIVE'),
            accountNumbers: (data && data.gatewayAccounts && data.gatewayAccounts.map(acc => ({label: acc, value: acc}))) || [],
            readonly: (data && data.status && isActiveOrComplete(data)) || false
        }));
        setModalWidth("modal-lg");
        toggleModal(true);
    }

    const renderEditProgramModal = (data) => {
        setModalContent(ProgramEditModal({
            hide: () => toggleModal(false),
            error: (data && data.error) ? data.error : '',
            success: (newProgram) => {
                toggleModal(false);
                dispatch({type: 'editProgram', payload: newProgram})
            },
            rerender: (program) => renderEditProgramModal(program),
            action: (payload) => GatewayProgramsAPI().save(payload),
            form: {
                clientName: (data && data.form && data.form.clientName) || [],
                accountNumber: (data && data.form && data.form.accountNumber) || [],
                programName: (data && data.form && data.form.programName) || '',
                codeType: (data && data.form && data.form.codeType) || 'SHORT',
                mmsProgram: (data && data.form && data.form.mmsProgram) || false,
                provisionedCode: (data && data.form && data.form.provisionedCode) || '',
                startDate: (data && data.form && data.form.startDate) || '',
                endDate: (data && data.form && data.form.endDate) || '',
                rateType: (data && data.form && data.form.rateType) || '',
                hostingCharge: (data && data.form && data.form.hostingCharge) || '',
                submitted: (data && data.form && data.form.submitted) || false,
                _id: (data && data.form && data.form._id) || null,
                endProgram: (data && data.form && data.form.endProgram) || false,
                status: (data && data.form && data.form.status) || '',
            },
            gwclients: state.gwclients.filter(gwc => gwc.status === 'ACTIVE'),
            accountNumbers: (data && data.gatewayAccounts && data.gatewayAccounts.map(acc => ({label: acc, value: acc}))) || [],
            readonly: (data && data.status && isActiveOrComplete(data)) || false
        }));
        setModalWidth("modal-lg");
        toggleModal(true);
    }

    const editProgram = (evt, program) => {
        evt.stopPropagation();
        const gwclient = state.gwclients.filter(gwc => gwc.name === program.gatewayClient);
        const accountNumbers = gwclient[0].gatewayAccounts.map(acc => ({label: acc, value: acc}));
        const accountNumber = accountNumbers.filter(gwa => gwa.label === program.accountNumber);
        dispatch({type: 'setEdit', payload: {accountNumbers}})
        
        renderEditProgramModal({
            form: {
                clientName: gwclient || [],
                accountNumber: accountNumber || [],
                programName: program.programName || '',
                codeType: program.programType || 'SHORT',
                mmsProgram: program.mms,
                provisionedCode: program.provisionedCode || '',
                startDate: (program.programStartDate && formatDate(program.programStartDate)) || '',
                endDate: (program.programEndDate && formatDate(program.programEndDate)) || '',
                rateType: program.rateType || '',
                hostingCharge: program.hostingCharge || '',
                submitted: false,
                _id: program._id,
                status: program.status
            },
            gwclients: state.gwclients.filter(gwc => gwc.status === 'ACTIVE'),
            accountNumbers: accountNumbers || [],
            readonly: (program && program.status && isActiveOrComplete(program)) || false
        })
    }

    function changeProgramListPage(page) {
        dispatch({type: 'setPage', payload: page.selected + 1})
    }

    function deleteProgram(evt, id) {
        evt.stopPropagation();
        GatewayProgramsAPI().destroy(id)
            .then(rsp => {
                if (rsp.data.statusCode === 200) {
                    dispatch({type: 'removeProgram', payload: id});
                } else {
                    dispatch({type: 'setError', payload: rsp.data.data.message});
                }
            })
            .catch(rsp => {
                dispatch({type: 'setError', payload: 'SERVICE ERROR'})
            })

    }

    const printDate = date => {
        const isoDate = (new Date(date)).toISOString().substring(0,10);
        const [YYYY, MM, DD] = isoDate.split("-");
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${month[MM-1]} ${DD}, ${YYYY}`;
    };

    const changeFilter = (event) => {
        event.preventDefault();
        dispatch({ type: 'setFilter', payload: event.target.value });
      }

    const listingInRange = (index) => index >= ((state.page - 1) * state.pageMax) && index < (state.page * state.pageMax);
    const isActiveOrComplete = p => p.status === 'ACTIVE' || p.status === 'COMPLETE';

    const sortPrograms = (sortOrder, sortField) => {
        dispatch({type: 'sortPrograms', payload: {sortField, sortOrder}});
    }

    const renderdownloadProgram = (data) => {
        setModalContent(DownloadReportModal({
            hide: () => toggleModal(false),
            error: '',
            success: () => {
                toggleModal(false);
            },
            rerender: (p) => renderdownloadProgram(p),
            action: (payload) => GatewayProgramsAPI().download(payload),
            form: {
                startDate: (data && data.form && data.form.startDate) || '',
                endDate: (data && data.form && data.form.endDate) || '',                
                rateType: (data && data.form && data.form.rateType) || 'All',
                hostingCharge: (data && data.form && data.form.hostingCharge) || 'All',
                codeType: (data && data.form && data.form.codeType) || 'All',
                messageType: (data && data.form && data.form.messageType) || 'All',

            },
          }));
        setModalWidth("modal-lg");
        toggleModal(true);
    }

    return (
        <>
            {state.showLoader && <Loader />}
        
            <div className="row row-top">
                <div className="col-md-5">
                    <h3>ICF Wireless Account Programs</h3>
                </div>
                <div className="col-md-1">
                    <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={_ => renderCreateProgramModal()} title="Create new program">
                        <i className="fa fa-plus font-md" data-testid="clients-download"></i>
                    </button>
                </div>
                <div className="col-md-2 text-right">
                    <div className="styled-select">
                        <select data-testid="programs-filter" name="programsFilter" value={state.filter} onChange={changeFilter}>
                            <option disabled value=''>Filter by Status</option>
                            <option value='ALL'>All</option>
                            <option value='ACTIVE'>Active</option>
                            <option value='IN-ACTIVE'>Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3 text-right">
                    <ListInputFilter
                        change={filterResults}
                        placeholder="Enter code, account or program name"
                    />
                </div>
                <div className="col-md-1 text-right">
                    <button type="button" title="Download Clients" onClick={renderdownloadProgram}>
                        <i className="fa fa-fw fa-download font-lg" data-testid="clients-download"></i>
                    </button>
                </div>
            </div>

            {state.error &&
                <div className="error-field form-group">{state.error}</div>
            }

            <div className="row table-header">
                <div className="col-md-2 col-xs-4">
                    Code
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'provisionedCode')}>
                        <i className="up-arrow">&larr;</i>
                    </span>
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'provisionedCode')}>
                        <i className="down-arrow">&larr;</i>
                    </span>
                </div>
                <div className="col-md-2 col-xs-4">
                    Account
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'gatewayClient')}>
                        <i className="up-arrow">&larr;</i>
                    </span>
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'gatewayClient')}>
                        <i className="down-arrow">&larr;</i>
                    </span>
                </div>
                <div className="col-md-3">
                    Program Name
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'programName')}>
                        <i className="up-arrow">&larr;</i>
                    </span>
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'programName')}>
                        <i className="down-arrow">&larr;</i>
                    </span>
                </div>
                <div className="col-md-2 hidden-sm hidden-xs">
                    Start Date
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'programStartDate')}>
                        <i className="up-arrow">&larr;</i>
                    </span>
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'programStartDate')}>
                        <i className="down-arrow">&larr;</i>
                    </span>
                </div>
                <div className="col-md-2 hidden-sm hidden-xs">
                    Status
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('A', 'status')}>
                        <i className="up-arrow">&larr;</i>
                    </span>
                    <span className="fa-wrap sort-icon" onClick={() => sortPrograms('D', 'status')}>
                        <i className="down-arrow">&larr;</i>
                    </span>
                </div>
                <div className="col-md-2 col-xs-4 text-right"></div>
            </div>

            <div className="panel-group accordion" data-testid="program-list">
            {state.programs.length > 0 && state.programs.map((program, key) => (listingInRange(key)) ? (
                <div className="panel panel-default" key={key}>
                    <div data-testid="data-list-0" className={`${isActiveOrComplete(program) ? 'record-active':'record-draft'} panel-heading`} onClick={(event) => editProgram( event, program)}>
                        <div className="row record">
                            <div className="col-md-2 col-xs-6">{program.provisionedCode}</div>
                            <div className="col-md-2 ">{program.gatewayClient}</div>
                            <div className="col-md-3 col-xs-6">{program.programName}</div>
                            <div className="col-md-2 hidden-sm hidden-xs">
                                {
                                    (program.programEndDate) ? 
                                        // `${printDate(program.programStartDate)} - ${printDate(program.programEndDate)}` : 
                                        printDate(program.programStartDate) :
                                        printDate(program.programStartDate)
                                }
                            </div>
                            <div className="col-md-1 col-xs-6">{program.status}</div>
                            <div className="col-md-2 col-xs-6 text-right">
                                {/* <span className="fa-wrap">
                                    { 
                                        isActiveOrComplete(program) ? 
                                        <i className="fa fa-eye fa-lg" title="View Program"  onClick={evt => editProgram(evt, program)}></i> : 
                                        <i className="fa fa-pencil-square-o fa-lg" title="Edit Program"  onClick={evt => editProgram(evt, program)}></i> 
                                    }
                                </span> */}
                                {/* <span className={`fa-wrap highligted-text-${program.status === 'ACTIVE'?'red':'green'}`} onClick={evt => toggleProgramStatus(evt, program, program._id)}>
                                    <i data-testid={`data-list-${key}-active`} className={`fa fa-${program.status === 'ACTIVE'?'stop':'play'} fa-lg`} title={`${program.status === 'ACTIVE'?'Deactivate':'Activate'} Program`}></i>
                                </span> */}
                                <span className="fa-wrap" onClick={evt => deleteProgram(evt, program._id)}>
                                    <i className="fa fa-trash-o fa-lg" title="Delete Program"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
          ) : ''
        )}

                {state.programs.length === 0 &&
                <div className="panel panel-default" data-testid="no-sms">
                    <div className="panel-heading empty">
                    <div className="row record">
                        <div className="col-xs-12">There are no programs.</div>
                    </div>
                    </div>
                </div>
                }

            </div>

            <ReactPaginate
                previousLabel={'‹'}
                nextLabel={'›'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={state.pageTotal}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={state.forcedPage}
                onPageChange={changeProgramListPage}
                containerClassName={['pagination', state.pageTotal > 1 ? '' : 'hidden'].join(' ')}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                /> 
        </>
    )
}