import React from 'react'

const smsGateways = [
  {
    "value":0,
    "name": "Momentum"
  },
  {
    "value":1,
    "name": "Infobip"
  }
];

export default function ClientsModal(props) {

  const updateField = (event) => {
    props.rerender({new: props.new, client: {...props.client, [event.target.name]:event.target.value}});
  }

  const validFields = () => {
    if (
      props.client.name &&
      props.client.code &&
      props.client.email &&
      props.client.phone &&
      props.client.street &&
      props.client.city &&
      props.client.state &&
      props.client.country &&
      props.client.smsGatewayCode !== ""
    ) return true;
    return false
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (validFields()) {
      props.action({event: event, data: props.client})
      .then((data) => {
        if (data.data.statusCode === 200) {
          props.success();
        } else {
          props.rerender({new: props.new, client: props.client, error: data.data.message});
        }
      })
      .catch(err => {
        props.rerender({new: props.new, client: props.client, error: err.message});
      });
    } else {
      props.rerender({new: props.new, client: props.client, submitted: true});
    }
  }

  return (
    <React.Fragment>
      <div className="modal-header" data-testid="modal-window">
        <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
        <h3>{props.typemessage}Client</h3>
        {props.error &&
          <div data-testid="modal-error-message" className="error-field">{props.error}</div>
        }
      </div>

      <form noValidate name="settingsClientsForm" onSubmit={submitForm}>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Name</label>
              <input required name="name" className="form-control" maxLength="30" placeholder="Enter a client name" value={props.client.name} onChange={updateField} data-testid="modal-field-name" />
              {props.submitted && !props.client.name &&
                <div data-testid="modal-error-name" className="error-field">Please fill out this field (maximum 30 characters)</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Code</label>
              <input required name="code" className="form-control" maxLength="30" placeholder="Enter a client code" value={props.client.code} onChange={updateField} />
              {props.submitted && !props.client.code &&
                <div className="error-field">Please fill out this field (maximum 30 characters)</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Email</label>
              <input data-testid="modal-field-email" required name="email" className="form-control" placeholder="Enter an email address" value={props.client.email} onChange={updateField} />
              {props.submitted && !props.client.email &&
                <div data-testid="modal-error-email" className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Phone</label>
              <input required name="phone" className="form-control" placeholder="Enter a phone number" value={props.client.phone} onChange={updateField} />
              {props.submitted && !props.client.phone &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Street</label>
              <input required name="street" className="form-control" placeholder="Enter a street address" value={props.client.street} onChange={updateField} />
              {props.submitted && !props.client.street &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>City</label>
              <input required name="city" className="form-control" placeholder="Enter a city" value={props.client.city} onChange={updateField} />
              {props.submitted && !props.client.city &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Province/State</label>
              <input required name="state" className="form-control" placeholder="Enter a province or state" value={props.client.state} onChange={updateField} />
              {props.submitted && !props.client.state &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-sm-6 form-group">
              <label>Country</label>
              <input required name="country" className="form-control" placeholder="Enter a country" value={props.client.country} onChange={updateField} />
              {props.submitted && !props.client.country &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>SMS Gateway</label>
              <div className="styled-select">
                <select required name="smsGatewayCode" value={props.client.smsGatewayCode} onChange={updateField}>
                  <option value="" disabled>Select a SMS gateway</option>
                  {smsGateways.map((gateway, key) => 
                    <option value={gateway.value} key={key}>{gateway.name}</option>
                  )}
                </select>
              </div>
              {props.submitted && props.client.smsGatewayCode === "" &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
          <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
        </div>
      </form>
    </React.Fragment>
  )
}
