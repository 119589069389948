import React, { useEffect, useReducer } from 'react'
import KeywordsAPI from '../models/keyword'

const initialState = {
    label: '',
    labelAdded: true,
    placeholder: '',
    errorMatch: '',
    showInfo: false,
    keywords: [],
    keywordExists: false,
    newKeyword: "",
    readonly: true,
    requiredError: false,
    allKeywords: [],
    mandatoryKeywords: [],
    addEmpty: false,
    removingKeywords: [],
    rule: false,
    ruleShow: false,
    errorRule: '',
    classAdded : '',
    shortcode: '',
    shortcodeId: '',
    addedKeywords: [],
    swappedKeywords: []
}

function reducer(state, action) {
    switch(action.type) {
        case 'initial':
            return {
                ...state,
                label: action.payload.label,
                labelAdded: action.payload.labelAdded,
                placeholder: action.payload.placeholder,
                errorMatch: action.payload.errorMatch,
                keywords: action.payload.keywords,
                readonly: action.payload.readonly,
                mandatoryKeywords: action.payload.mandatoryKeywords || [],
                rule: action.payload.rule,
                errorRule: action.payload.errorRule,
                classAdded: action.payload.classAdded
            }
        case 'setReadOnly':
            return {
                ...state,
                readonly: action.payload
            }
        case 'setFormSubmitted':
            return {
                ...state,
                formSubmitted: action.payload
            }
        case 'setMandatoryKeywords':
            return {
                ...state,
                mandatoryKeywords: action.payload
            }
        case 'setKeywords':
            return {
                ...state,
                keywords: action.payload,
                addedKeywords: action.payload
            }
        case 'setShortcode':
            return {
                ...state,
                shortcode: action.payload,
                mandatoryKeywords: action.payload.length ? (action.payload[0].mandatoryKeywords || []) : [],
                shortcodeId: action.payload.length ? action.payload[0].shortcodeId : []
            }
        case 'setNewKeyword':
            return {
                ...state,
                newKeyword: action.payload
            }
        case 'setInfoDisplay':
            return {
                ...state,
                showInfo: action.payload
            }
        case 'setAllKeywords':
            return {
                ...state,
                allKeywords: action.payload
            }
        case 'addEmpty':
            return {
                ...state,
                addEmpty: true
            }
        case 'setError':
            return {
                ...state,
                ruleShow: action.payload.ruleShow,
                keywordExists: action.payload.keywordExists
            }
        case 'addKeyword':
            return {
                ...state,
                keywords: action.payload.keywords,
                addedKeywords: [...state.addedKeywords, action.payload.keywords[action.payload.keywords.length - 1]],
                newKeyword: action.payload.newKeyword,
                requiredError: action.payload.requiredError,
                keywordExists: action.payload.keywordExists,
                addEmpty: action.payload.addEmpty,
                ruleShow: action.payload.ruleShow
            }
        case 'duplicateKeywordError':
            return {
                ...state,
                addEmpty: action.payload.addEmpty,
                ruleShow: action.payload.ruleShow,
                keywordExists: action.payload.keywordExists
            }
        case 'removeKeyword':
            return {
                ...state,
                addedKeywords: state.addedKeywords.filter(nkw => nkw !== action.payload.keyword),
                keywords: state.keywords.filter(nkw => nkw !== action.payload.keyword),
            }
        case 'addSwapKeyword':
            return {
                ...state,
                keywords: [...state.keywords, action.payload.keyword],
                addedKeywords: [...state.addedKeywords, action.payload.keyword]
            }
        default:
            return {
                ...state
            }
    }
}

export default function KeywordInput(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => { 
        dispatch({type: 'initial', payload: {
            label: (props.label) ? props.label : 'Keywords',
            labelAdded: (props.labelAdded) ? props.labelAdded : false, 
            placeholder: (props.placeholder) ? props.placeholder : 'Enter a keyword and press \'+\' to submit',
            errorMatch: (props.errorMatch) ? props.errorMatch : 'The keyword already exists',
            keywords: props.keywords ? props.keywords : [],
            readonly: props.readonly,
            mandatoryKeywords: (props.shortcode && props.shortcode.length) ? props.shortcode[0].mandatoryKeywords : [],
            rule: (props.rule) ? new RegExp(props.rule) : false,
            errorRule: (props.rule && props.errorRule) ? (props.errorRule) : '',
            classAdded : (props.classAdded) ? props.classAdded : '',
            shortcode : props.shortcode
        }})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.readonly !== undefined) {
            dispatch({type: 'setReadOnly', payload: props.readonly});
        }
    }, [props.readonly]);

    useEffect(() => {
        if (props.formSubmitted !== undefined) {
            dispatch({type: 'setFormSubmitted', payload: props.formSubmitted});
        }
    }, [props.formSubmitted]);

    useEffect(() => {
        if (props.keywords !== undefined) {
            dispatch({type: 'setKeywords', payload: props.keywords || []});
        }
    }, [props.keywords]);

    useEffect(() => {
        if (props.shortcode !== undefined) {
            dispatch({type: 'setShortcode', payload: props.shortcode});
        }
    }, [props.shortcode]);

    function handleChange(evt) {
        dispatch({type: 'setNewKeyword', payload: evt.target.value})
    }

    function toggleHelperDisplay() {
        dispatch({type: 'setInfoDisplay', payload: !state.showInfo})
        if (!state.showInfo) {
            getAllKeywords();
        }
    }

    function addKeyword() {
        const newKeyword = state.newKeyword.trim().toUpperCase();

        if (newKeyword.length) {
            if ((state.rule && newKeyword.match(state.rule) !== null) || !state.rule) {
                if (!state.keywords.includes(newKeyword) && state.mandatoryKeywords.filter(mk => mk.text === newKeyword).length === 0) {
                    dispatch({type: 'addKeyword', payload: {
                        keywords: [...state.keywords, newKeyword],
                        newKeyword: '', 
                        requiredError: state.keywords.length === 0,
                        keywordExists: false,
                        addEmpty: false,
                        ruleShow: false
                    }});
                    props.onUpdatedKeywords([...state.keywords, newKeyword]);
                } else {
                    dispatch({type: 'duplicateKeywordError', payload: {
                        addEmpty: false,
                        ruleShow: false,
                        keywordExists: true
                    }})
                }
            } else {
                dispatch({type: 'setError', payload: {ruleShow: true, keywordExists: false}})
            }
        } else {
            dispatch({type: 'addEmpty', payload: true})
        }
    }

    function keyPressed(event) {
        event.stopPropagation();
        if (event.key === "Enter") {
            event.preventDefault();
            addKeyword();
        }
    }

    function getAllKeywords() {
        const payload = {
            shortcode: state.shortcode[0].label
        };

        KeywordsAPI().findAll(payload)
            .then(rsp => {
                if (rsp.data.statusCode === 200) {
                    dispatch({type: 'setAllKeywords', payload: rsp.data.data.allKeywords})
                } else {
                    console.log('error', rsp);
                }
            }).catch(rsp => {
                console.log(rsp);
            });
    }
 
    function removeKeyword(keyword) {
        const updatedKeywords = state.keywords.filter((kw) => kw !== keyword);
        dispatch({type: 'removeKeyword', payload: {keyword}})
        props.onUpdatedKeywords(updatedKeywords);
    }

    function swapKeyword(keyword) {
        dispatch({type: 'addSwapKeyword', payload: {keyword}})
        props.onUpdatedKeywords(state.addedKeywords);
    }

    const checkKeyword = keyword => !(state.addedKeywords.indexOf(keyword) !== -1);

    function formatDate(input) {
        let dateObj = new Date(input);
        dateObj = dateObj.toDateString();
        const [month, date, year] = dateObj.split(" ").filter((_, index) => index !== 0);
        return `${month} ${date}, ${year}`;
    }

    return (
        <div className="row">
            <div className={`${state.labelAdded ? 'col-xs-6' : 'col-xs-12'}`}>
                <label>{state.label} {state.readonly}</label>
            </div>
            { 
                state.labelAdded && 
                    <div className="col-xs-6">
                        <label>{state.labelAdded}</label>
                    </div> 
            }
            <div className="col-md-6 form-group">
                <div className="input-group">

                    <input 
                        disabled={props.readonly}
                        type="text"
                        data-testid="keyword-input" 
                        className="keyword-input form-control" 
                        placeholder={state.placeholder} 
                        value={state.newKeyword} 
                        onChange={handleChange} 
                        onKeyPress={keyPressed} />
                    <span className="input-group-btn">
                        <button data-testid="keyword-add" disabled={props.readonly} type="button" className="btn btn-default" onClick={addKeyword}>
                            <i className="fa fa-lg fa-plus"></i>
                        </button>
                        {
                            !props.hideHelper &&
                                <button disabled={state.readonly} type="button" className="btn btn-default" onClick={toggleHelperDisplay}>
                                    <i className="fa fa-lg fa-info"></i>
                                </button>
                        }
                    </span>
                </div>
                { (state.addEmpty) && <div className="error-field">Please fill out this field</div>}
                { (state.keywordExists) && <div className="error-field">{state.errorMatch}</div>}
                { (state.ruleShow) && <div className="error-field">{state.errorRule}</div> }
            </div>

            <div className="form-group hidden-xs"></div>
            
            <div data-testid="keyword-tags" className={`col-md-6 ${state.classAdded}`}>
                {
                    state.mandatoryKeywords.map(kw => (
                        <span title={kw.message} key={kw.text} className="keyword-tag keyword-tag-fixed">
                            <span>{kw.text}</span>
                        </span>
                    ))
                }
                {
                    state.addedKeywords.map(kw => (
                        <span key={kw} className={["keyword-tag", state.readonly ? 'keyword-tag-fixed' : 'keyword-tag-new'].join(" ")} onClick={_ => removeKeyword(kw)}>
                            <span>{kw}</span>
                            { !state.readonly && <span className="fa fa-close delete-icon"></span> }
                        </span>
                    ))
                }
            </div>

            {
                state.showInfo && (
                    <div className="form-group col-xs-12">
                        <div className="row row-bordered scrolling">
                            <div className="col-xs-12">
                                <div className="row table-header">
                                    <div className="col-md-3 hidden-sm hidden-xs">Campaign</div>
                                    <div className="col-md-3 col-xs-6">Communication</div>
                                    <div className="col-md-4 col-xs-6">Keywords</div>
                                    <div className="col-md-2 hidden-sm hidden-xs">Start Date</div>
                                </div>

                                <div className="panel-group accordion" id="collapse-panel">
                                    {state.allKeywords.map((keyword, keywordIndex) => (
                                        (keyword.keywords.length > 0 && keyword.communicationId !== props.communicationId) && (
                                            <div className="panel panel-default" key={keyword.communicationName + keyword.communicationId}>
                                                {
                                                    //.filter(kw => kw.includes(state.newKeyword)).length > 0)
                                                }

                                                <div className={["panel-heading", "no-click", (keyword.status === 'ACTIVE') ? 'record-active' : 'record-draft'].join(" ")}>
                                                    <div className="row record">
                                                        <div className="col-md-3 hidden-sm hidden-xs highligted-text">{keyword.campaignName}</div>
                                                        <div className="col-md-3 col-xs-6">{keyword.communicationName}</div>
                                                        <div className="col-md-4 col-xs-6 keyword-column">{
                                                            //.filter(kw => (kw.includes(state.newKeyword)))
                                                            keyword.keywords.map((kw, index) => (
                                                                <span key={kw.communicationId + kw.communicationName}>
                                                                    {(keyword.status !== 'ACTIVE') && (
                                                                        checkKeyword(kw) ? (
                                                                            <span key={index} className="keyword-tag keyword-tag-new" onClick={ _ => swapKeyword(kw) }>
                                                                                {kw}
                                                                            </span>
                                                                        ) : (
                                                                            <span key={index} className="keyword-tag keyword-tag-fixed">
                                                                                {kw}
                                                                            </span>
                                                                        )
                                                                    )}
                                                                </span>
                                                            ))
                                                        }</div>
                                                        <div className="col-md-2 hidden-sm hidden-xs">{formatDate(keyword.campaignStartDate)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            
        </div>
    );
}
