const ProgramsListApi = {
	getGatewayPrograms: {
		"statusCode": 200,
		"requestId": 576488,
		"data": {
		  "gatewayPrograms": [
			{
			  "_id": "5fbbf70ea5494a0ffe14e112",
			  "accountNumber": "0099-9999-8888-55",
			  "gatewayClient": "nov20",
			  "status": "DRAFT",
			  "clientId": 1,
			  "programName": "nov23-3",
			  "programType": "LONG",
			  "provisionedCode": "0000099999",
			  "programStartDate": 1606262400000,
			  "programEndDate": 32535129600000,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fbc040ba5494a0ffe14f28b",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov23-1",
			  "programType": "SHORT",
			  "provisionedCode": "11112222",
			  "programStartDate": 1606089600000,
			  "programEndDate": 32535129600000,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fbc0588a5494a0ffe14f3c4",
			  "accountNumber": "0000-99-99-0000-1-1",
			  "gatewayClient": "nov20",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov23",
			  "programType": "SHORT",
			  "provisionedCode": "0000099999",
			  "programStartDate": 1606089600000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fbc15a4a5494a0ffe151646",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov23-1",
			  "programType": "SHORT",
			  "provisionedCode": "11112222",
			  "programStartDate": 1606089600000,
			  "programEndDate": null,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fbc15b0a5494a0ffe15165a",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov23-1",
			  "programType": "SHORT",
			  "provisionedCode": "11112222",
			  "programStartDate": 1606089600000,
			  "programEndDate": null,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fbe7d52a5494a0ffe175040",
			  "accountNumber": "0000-99-99-0000-1-1",
			  "gatewayClient": "nov20",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "program-nov25",
			  "programType": "LONG",
			  "provisionedCode": "2222200000",
			  "programStartDate": 1606262400000,
			  "programEndDate": null,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fbe8811a5494a0ffe175eb0",
			  "accountNumber": "1",
			  "gatewayClient": "gwClient1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "program name",
			  "programType": null,
			  "provisionedCode": "123-1234",
			  "programStartDate": 1605484800000,
			  "programEndDate": 1605830400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fbe9de5a5494a0ffe17777e",
			  "accountNumber": "1111--1111-1111-22",
			  "gatewayClient": "nov25demo",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov25-program-1",
			  "programType": "LONG",
			  "provisionedCode": "1111122222",
			  "programStartDate": 1606348800000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fbfcbfea5494a3b65ea3f52",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov26-1",
			  "programType": "SHORT",
			  "provisionedCode": "11112222",
			  "programStartDate": 1606435200000,
			  "programEndDate": null,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc65ebaa5494a3b65ef0a32",
			  "accountNumber": "56787878678987",
			  "gatewayClient": "TEST!",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "dec 1",
			  "programType": "SHORT",
			  "provisionedCode": "11112222",
			  "programStartDate": 1606780800000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc67a9ea5494a3b65ef2c1d",
			  "accountNumber": "41344567898767",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "OLSON QA TWO",
			  "programType": "SHORT",
			  "provisionedCode": "554455",
			  "programStartDate": 1607299200000,
			  "programEndDate": 1607644800000,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc67adaa5494a3b65ef2c58",
			  "accountNumber": "41344567898767",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "OLSON QA TWO",
			  "programType": "SHORT",
			  "provisionedCode": "554455",
			  "programStartDate": 1607299200000,
			  "programEndDate": 1607644800000,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc67b26a5494a3b65ef3062",
			  "accountNumber": "41344567898767",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "OLSON QA TWO",
			  "programType": "SHORT",
			  "provisionedCode": "554455",
			  "programStartDate": 1607385600000,
			  "programEndDate": 1607644800000,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc68612a5494a3b65ef51a3",
			  "accountNumber": "67567856456788",
			  "gatewayClient": "A",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Olson Tester",
			  "programType": "SHORT",
			  "provisionedCode": "5544",
			  "programStartDate": 1607990400000,
			  "programEndDate": 1608595200000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc6b879a5494a3b65efa646",
			  "accountNumber": "45456576879878",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVX",
			  "programType": "SHORT",
			  "provisionedCode": "55448",
			  "programStartDate": 1606867200000,
			  "programEndDate": 1608163200000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc6b8a4a5494a3b65efa667",
			  "accountNumber": "45456576879878",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVX",
			  "programType": "SHORT",
			  "provisionedCode": "554455",
			  "programStartDate": 1608076800000,
			  "programEndDate": 1608681600000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc7eb5ea5494a0768687a22",
			  "accountNumber": "45888999898978",
			  "gatewayClient": "Test@",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Acchi",
			  "programType": "SHORT",
			  "provisionedCode": "647575",
			  "programStartDate": 1607990400000,
			  "programEndDate": 1609286400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc7eb99a5494a0768687a4f",
			  "accountNumber": "56787878678987",
			  "gatewayClient": "TEST!",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Acchi",
			  "programType": "SHORT",
			  "provisionedCode": "5509",
			  "programStartDate": 1607472000000,
			  "programEndDate": 1609286400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc7ef76a5494a0768687d29",
			  "accountNumber": "123-345-456-566-56",
			  "gatewayClient": "Nov 24 530",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Te",
			  "programType": "SHORT",
			  "provisionedCode": "675",
			  "programStartDate": 1610409600000,
			  "programEndDate": 1613606400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc7efbca5494a0768687d66",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "yu",
			  "programType": "SHORT",
			  "provisionedCode": "66",
			  "programStartDate": 1609804800000,
			  "programEndDate": 1696204800000,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc7f026a5494a0768687dc5",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "yu",
			  "programType": "SHORT",
			  "provisionedCode": "66",
			  "programStartDate": 1606867200000,
			  "programEndDate": 1696204800000,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc7f031a5494a0768687dd0",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "yu",
			  "programType": "LONG",
			  "provisionedCode": "66",
			  "programStartDate": 1606867200000,
			  "programEndDate": 1696204800000,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc8036ca5494a0768688b4e",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AD",
			  "programType": "SHORT",
			  "provisionedCode": "34",
			  "programStartDate": 1608336000000,
			  "programEndDate": 1612915200000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc80703a5494a0768688ddf",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Av",
			  "programType": "SHORT",
			  "provisionedCode": "hjghfg",
			  "programStartDate": 1609977600000,
			  "programEndDate": 1609200000000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc80789a5494a0768688e44",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Av",
			  "programType": "SHORT",
			  "provisionedCode": "hjghfg!!$",
			  "programStartDate": 1609977600000,
			  "programEndDate": 1609200000000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc8078da5494a0768688e48",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Av",
			  "programType": "SHORT",
			  "provisionedCode": "hjghfg!!$",
			  "programStartDate": 1609977600000,
			  "programEndDate": 1609200000000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc808cfa5494a0768688f2d",
			  "accountNumber": "22223333-444411",
			  "gatewayClient": "nov23-client",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Alkn",
			  "programType": "LONG",
			  "provisionedCode": "67yuhgf!@",
			  "programStartDate": 1610064000000,
			  "programEndDate": 1609718400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc80965a5494a0768688f99",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "CLIENT Nov 26 12 49",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVX",
			  "programType": "SHORT",
			  "provisionedCode": "554455",
			  "programStartDate": 1608681600000,
			  "programEndDate": null,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc80b5aa5494a0768689108",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "6547",
			  "programType": "SHORT",
			  "provisionedCode": "eresf",
			  "programStartDate": 1609977600000,
			  "programEndDate": null,
			  "rateType": "STANDARD",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fc80bb9a5494a0768689151",
			  "accountNumber": "45456576879878",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "OLN QA TWO",
			  "programType": "SHORT",
			  "provisionedCode": "0000099999",
			  "programStartDate": 1608076800000,
			  "programEndDate": 1607817600000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fc8fe9ca5494a076869409e",
			  "accountNumber": "41344567898767",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Lt",
			  "programType": "SHORT",
			  "provisionedCode": "5544",
			  "programStartDate": 1607558400000,
			  "programEndDate": 1608854400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fca85c3a5494a6f57f2cf72",
			  "accountNumber": "11112222333322",
			  "gatewayClient": "nov23-client",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "dec4-2",
			  "programType": "SHORT",
			  "provisionedCode": "11112222",
			  "programStartDate": 1607040000000,
			  "programEndDate": null,
			  "rateType": "FREE-TO-END USER",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcaa667a5494a6f57f2e6b5",
			  "accountNumber": "11112222333322",
			  "gatewayClient": "nov23-client",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "dec4-3",
			  "programType": "SHORT",
			  "provisionedCode": "22",
			  "programStartDate": 1607040000000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fcaa939a5494a6f57f2e8c5",
			  "accountNumber": "account1",
			  "gatewayClient": "Gateway Client1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov23-2",
			  "programType": "LONG",
			  "provisionedCode": "1111222233",
			  "programStartDate": 1607126400000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fcaac41a5494a6f57f2eaf8",
			  "accountNumber": "account1",
			  "gatewayClient": "Gateway Client1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "nov23-2",
			  "programType": "LONG",
			  "provisionedCode": "1111222233",
			  "programStartDate": 1607126400000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "FREE HOSTING",
			  "mms": false
			},
			{
			  "_id": "5fcea147a5494a675ac8ee59",
			  "accountNumber": "56787878678990",
			  "gatewayClient": "IDG ",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "RCHI",
			  "programType": "SHORT",
			  "provisionedCode": "111222",
			  "programStartDate": 1607904000000,
			  "programEndDate": 1608249600000,
			  "rateType": "PREMIUM",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcea20ea5494a675ac8eefe",
			  "accountNumber": "56787878678987",
			  "gatewayClient": "Olson Qa",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "QADHJ",
			  "programType": "SHORT",
			  "provisionedCode": "111220",
			  "programStartDate": 1609718400000,
			  "programEndDate": 1608595200000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcea2b5a5494a675ac8ef7e",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "Av89",
			  "programType": "SHORT",
			  "provisionedCode": "55090",
			  "programStartDate": 1607644800000,
			  "programEndDate": null,
			  "rateType": "PREMIUM",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcf9161a5494a675ac99840",
			  "accountNumber": "41344567898767",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVX",
			  "programType": "SHORT",
			  "provisionedCode": "647574",
			  "programStartDate": 1607990400000,
			  "programEndDate": 1609200000000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcf91a9a5494a675ac9987e",
			  "accountNumber": "41344567898767",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVX",
			  "programType": "SHORT",
			  "provisionedCode": "647570",
			  "programStartDate": 1607472000000,
			  "programEndDate": 1609286400000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcfebfaa5494a675ac9e307",
			  "accountNumber": "11110000-0000-22",
			  "gatewayClient": "nov19-1",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "test name",
			  "programType": "SHORT",
			  "provisionedCode": "112233",
			  "programStartDate": 1607385600000,
			  "programEndDate": 1609372800000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fcfec2fa5494a675ac9e33a",
			  "accountNumber": "56787878678987",
			  "gatewayClient": "TEST!",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "test name",
			  "programType": "SHORT",
			  "provisionedCode": "1122334",
			  "programStartDate": 1607385600000,
			  "programEndDate": 1608681600000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fd0e3baa5494a51c76bdd10",
			  "accountNumber": "45456576879878",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVY",
			  "programType": "SHORT",
			  "provisionedCode": "75894",
			  "programStartDate": 1607558400000,
			  "programEndDate": 1608768000000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fd0e406a5494a51c76bdd52",
			  "accountNumber": "45456576879878",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVYD",
			  "programType": "SHORT",
			  "provisionedCode": "550987",
			  "programStartDate": 1607904000000,
			  "programEndDate": 1607904000000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			},
			{
			  "_id": "5fd0e531a5494a51c76bde28",
			  "accountNumber": "45456576879878",
			  "gatewayClient": "PVR TEST",
			  "status": "ACTIVE",
			  "clientId": 1,
			  "programName": "AGVO",
			  "programType": "SHORT",
			  "provisionedCode": "647573",
			  "programStartDate": 1607904000000,
			  "programEndDate": 1608508800000,
			  "rateType": "STANDARD",
			  "hostingCharge": "STANDARD",
			  "mms": false
			}
		  ]
		},
		"message": "getGatewayPrograms was executed successfully"
	  },
	getGatewayClients: {
		"statusCode": 200,
		"requestId": 576487,
		"data": {
		  "gatewayClients": [
			{
			  "_id": "5fb45c76a5494a2fae7cc4ba",
			  "clientId": 1,
			  "code": "Gateway Client1",
			  "status": "DISABLED",
			  "name": "Gateway Client1",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047247,
			  "gatewayAccounts": [
				"account1"
			  ]
			},
			{
			  "_id": "5fb4b273a5494a2fae7d0483",
			  "clientId": 1,
			  "code": "gwclient-nov18",
			  "status": "DISABLED",
			  "name": "gwclient-nov18",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047247,
			  "gatewayAccounts": []
			},
			{
			  "_id": "5fb80766a5494a0dcb22d01f",
			  "clientId": 1,
			  "code": "NOV20",
			  "status": "DISABLED",
			  "name": "nov20",
			  "phone": null,
			  "email": null,
			  "createdDate": 1606843059085,
			  "gatewayAccounts": [
				"0000-99-99-0000-1-1",
				"0099-9999-8888-55"
			  ]
			},
			{
			  "_id": "5fb80a68a5494a0dcb22d280",
			  "clientId": 1,
			  "code": "nov19-1",
			  "status": "ACTIVE",
			  "name": "nov19-1",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047248,
			  "gatewayAccounts": [
				"11110000-0000-22"
			  ]
			},
			{
			  "_id": "5fbc0546a5494a0ffe14f381",
			  "clientId": 1,
			  "code": "nov23-client",
			  "status": "ACTIVE",
			  "name": "nov23-client",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047248,
			  "gatewayAccounts": [
				"11112222333322",
				"22223333-444411"
			  ]
			},
			{
			  "_id": "5fbd2190a5494a0ffe15ff0d",
			  "clientId": 1,
			  "code": "5677",
			  "status": "ACTIVE",
			  "name": "Client Nov24 105",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047248,
			  "gatewayAccounts": [
				"41234356567876",
				"41887765656767"
			  ]
			},
			{
			  "_id": "5fbd5dc6a5494a0ffe1642ff",
			  "clientId": 1,
			  "code": "PVR01",
			  "status": "ACTIVE",
			  "name": "PVR TEST",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047248,
			  "gatewayAccounts": [
				"41344567898767",
				"45456576879878"
			  ]
			},
			{
			  "_id": "5fbd7cb6a5494a0ffe16655a",
			  "clientId": 1,
			  "code": "TESTAA",
			  "status": "ACTIVE",
			  "name": "AA",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047249,
			  "gatewayAccounts": [
				"12223456787878"
			  ]
			},
			{
			  "_id": "5fbd7d00a5494a0ffe166593",
			  "clientId": 1,
			  "code": "TestA",
			  "status": "ACTIVE",
			  "name": "A",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047249,
			  "gatewayAccounts": [
				"67567856456788"
			  ]
			},
			{
			  "_id": "5fbd7d23a5494a0ffe1665b4",
			  "clientId": 1,
			  "code": "66788",
			  "status": "ACTIVE",
			  "name": "TEST!",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047249,
			  "gatewayAccounts": [
				"56787878678987"
			  ]
			},
			{
			  "_id": "5fbd7eaba5494a0ffe1666cd",
			  "clientId": 1,
			  "code": "Test56",
			  "status": "ACTIVE",
			  "name": "Test@",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047250,
			  "gatewayAccounts": [
				"45888999898978"
			  ]
			},
			{
			  "_id": "5fbd8852a5494a0ffe1672bc",
			  "clientId": 1,
			  "code": "@@@$",
			  "status": "ACTIVE",
			  "name": "Nov 24 523",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047250,
			  "gatewayAccounts": [
				"12345678789896"
			  ]
			},
			{
			  "_id": "5fbd8918a5494a0ffe16734c",
			  "clientId": 1,
			  "code": "Nov 24 5 27 Edit",
			  "status": "ACTIVE",
			  "name": "Nov 24 526 Edit",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047250,
			  "gatewayAccounts": [
				"45678909876543",
				"23456789098765",
				"45678876567898",
				"45667876789898",
				"56787898909876",
				"23459678909876"
			  ]
			},
			{
			  "_id": "5fbd899fa5494a0ffe1673ce",
			  "clientId": 1,
			  "code": "Nov24530",
			  "status": "ACTIVE",
			  "name": "Nov 24 530",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047250,
			  "gatewayAccounts": [
				"123-345-456-566-56"
			  ]
			},
			{
			  "_id": "5fbe7ceba5494a0ffe174fed",
			  "clientId": 1,
			  "code": "nov25gwclient",
			  "status": "ACTIVE",
			  "name": "nov25gwclient",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047251,
			  "gatewayAccounts": [
				"1111-1111-1111-11",
				"2222-1111-22-22-11"
			  ]
			},
			{
			  "_id": "5fbe7de3a5494a0ffe1750b5",
			  "clientId": 1,
			  "code": "nov25demo",
			  "status": "ACTIVE",
			  "name": "nov25demo",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047251,
			  "gatewayAccounts": [
				"1111--1111-1111-22"
			  ]
			},
			{
			  "_id": "5fbee9eca5494a0ffe17c307",
			  "clientId": 1,
			  "code": "nov25-test-2",
			  "status": "ACTIVE",
			  "name": "nov25-test-2",
			  "phone": null,
			  "email": null,
			  "createdDate": 1608564047254,
			  "gatewayAccounts": [
				"11112222333344"
			  ]
			},
			{
			  "_id": "5fbfeabba5494a3b65ea5ba6",
			  "clientId": 1,
			  "code": "@@$$^&##",
			  "status": "ACTIVE",
			  "name": "CLIENT Nov 26 12 49",
			  "phone": null,
			  "email": null,
			  "createdDate": 1606412987871,
			  "gatewayAccounts": [
				"34565678765432"
			  ]
			},
			{
			  "_id": "5fc93546a5494a0550efd631",
			  "clientId": 1,
			  "code": "OlQ01",
			  "status": "ACTIVE",
			  "name": "Olson Qa",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607021893951,
			  "gatewayAccounts": [
				"56787878678988",
				"56787878678987"
			  ]
			},
			{
			  "_id": "5fc935eca5494a0550efd6d8",
			  "clientId": 1,
			  "code": "QT01",
			  "status": "ACTIVE",
			  "name": "QA Tester",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607022060808,
			  "gatewayAccounts": [
				"56787878678987",
				"50787878678987"
			  ]
			},
			{
			  "_id": "5fc94b4ba5494a0550efe8e8",
			  "clientId": 1,
			  "code": "DT01",
			  "status": "ACTIVE",
			  "name": "D@D",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607027531675,
			  "gatewayAccounts": [
				"90785634123456"
			  ]
			},
			{
			  "_id": "5fc94c77a5494a0550efe9d4",
			  "clientId": 1,
			  "code": "@##",
			  "status": "ACTIVE",
			  "name": "!@#$%",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607027831154,
			  "gatewayAccounts": [
				"90785634123493"
			  ]
			},
			{
			  "_id": "5fc94cf7a5494a0550efea39",
			  "clientId": 1,
			  "code": "D3",
			  "status": "ACTIVE",
			  "name": "D",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607027959313,
			  "gatewayAccounts": [
				"99775533223345"
			  ]
			},
			{
			  "_id": "5fcab02aa5494a6f57f2ef3c",
			  "clientId": 1,
			  "code": "s",
			  "status": "ACTIVE",
			  "name": "dfd",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607118890764,
			  "gatewayAccounts": [
				"82479-23749-2372"
			  ]
			},
			{
			  "_id": "5fcea066a5494a675ac8ed89",
			  "clientId": 1,
			  "code": "ID01",
			  "status": "ACTIVE",
			  "name": "IDG ",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607376998622,
			  "gatewayAccounts": [
				"56787878678990"
			  ]
			},
			{
			  "_id": "5fcea3fea5494a675ac8f0ae",
			  "clientId": 1,
			  "code": "MP01",
			  "status": "ACTIVE",
			  "name": "MPI",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607377918650,
			  "gatewayAccounts": [
				"98654321568794"
			  ]
			},
			{
			  "_id": "5fcea46fa5494a675ac8f118",
			  "clientId": 1,
			  "code": "A03",
			  "status": "ACTIVE",
			  "name": "SLR",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607378031207,
			  "gatewayAccounts": [
				"98766534892367"
			  ]
			},
			{
			  "_id": "5fcea557a5494a675ac8f1c4",
			  "clientId": 1,
			  "code": "@%^$",
			  "status": "ACTIVE",
			  "name": "Asf",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607378263377,
			  "gatewayAccounts": [
				"90876578945308"
			  ]
			},
			{
			  "_id": "5fcf96eba5494a675ac99c59",
			  "clientId": 1,
			  "code": "QT01",
			  "status": "ACTIVE",
			  "name": "QA Two",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607440107492,
			  "gatewayAccounts": [
				"34565678765437",
				"34505678765432"
			  ]
			},
			{
			  "_id": "5fcf981fa5494a675ac99d6b",
			  "clientId": 1,
			  "code": "ST01",
			  "status": "ACTIVE",
			  "name": "STR TRIVIA",
			  "phone": null,
			  "email": null,
			  "createdDate": 1607440415558,
			  "gatewayAccounts": [
				"45884999898978"
			  ]
			}
		  ]
		},
		"message": "getGatewayClients was executed successfully"
	  }
}

const MockAPIs = {
	programs: {
		findAll: new Promise((resolve, reject) => {
            resolve({data: ProgramsListApi.getGatewayPrograms});
		}),
		getGatewayClients: new Promise((resolve, reject) => {
            resolve({data: ProgramsListApi.getGatewayClients});
        })
	}
};


export default MockAPIs;
