import React from 'react'

export default function SmsFolderModal(props) {
  let folderName = '';
  let fieldError = true;
  let fieldRegex = /^[A-Za-z0-9-_ ]+$/;
  
  const getName = (event) => {
    folderName = event.target.value;
    fieldError = (folderName.match(fieldRegex) === null) ? true : false;
    if (!fieldError && props.error) {
      props.rerender();
    }
  }

  const submitForm = (event) => {
    event.preventDefault();
    if (!fieldError) {
      props.action({event: event, name: folderName})
      .then((data) => {
        if (data.data.statusCode === 200) {
          props.success();
        } else {
          props.rerender({error: data.data.message});
        }
      })
      .catch(err => {
        props.rerender({error: err.message});
      });
    } else {
      props.rerender({error: 'Invalid folder name! Folder name: . Acceptable characters are any number and letters as well as space and following special characters:_-'});
    }
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <i className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close" onClick={props.hide}></i>
        <h3>{props.typemessage}Folder</h3>
        {props.error &&
          <div className="error-field">{props.error}</div>
        }
      </div>
      <form noValidate name="form">
        <div className="modal-body">
          <div className="form-horizontal">
            <div className="form-group">
              <div className="col-sm-12">
                <label>Name</label>
                <input required name="name" onKeyUp={getName} maxLength="30" className="form-control" placeholder="Enter a folder name" />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary btn-block-xs btn-lg" onClick={submitForm}>Save</button>
          <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
        </div>
      </form>
    </React.Fragment>
  )
}

