const Constants = {
	Users: {
		analyst : "Analyst",
		manager : "Manager",
		admin : "Administrator"
	},
	Countries: {
		CA: "Canada",
		US: "United States"
	},
	calendarSettings: {
		weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		monthlyDay: [
			{ 
				numeric:1,
				relative:"First day of the month"
			},
			{ 
				numeric:31,
				relative:"Last day of the month"
			},
			{ 
				numeric:-1,
				relative:"Other"
			}
		]
	}
};

export { Constants };