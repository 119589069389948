import React from 'react'

const ProgramEditModal = (props) => {

    const updateField = (event) => {
        props.rerender({
            ...props, 
            form: {
                ...props.form, 
                [event.target.name]: (event.target.type === 'checkbox') ? event.target.checked : event.target.value
            }
        });
    }

    const isValidProvisionedCode = () => {
        const length = props.form.provisionedCode.length;
        const patternTest = /^\d+$/.test(props.form.provisionedCode);
        switch(props.form.codeType) {
            case 'SHORT':
                return patternTest && length >= 4 && length <= 8;
            case 'LONG':
                return patternTest && (length === 10 || (length === 11 && props.form.provisionedCode.startsWith("1")));
            default:
                return false;
        }
    }

    const validFields = () => {
        if (
            props.form.clientName.length &&
            props.form.accountNumber.length &&
            props.form.programName.length && props.form.programName.length >= 4 && props.form.programName.length <= 50 &&
            props.form.codeType.length &&
            props.form.provisionedCode.length && isValidProvisionedCode(props.form.provisionedCode) &&
            props.form.startDate.length &&
            props.form.rateType.length &&
            props.form.hostingCharge.length
        ) return true;
        return false
    }

    const submitForm = (event) => {
        event.preventDefault();

        if (validFields()) {
            const payload = getPayload();

            props.action(payload)
                .then(data => {
                    if (data.data.statusCode === 200) {
                        props.success(data.data.data.gatewayProgram);
                    } else {
                        props.rerender({
                            ...props, 
                            error: data.data.message
                        });
                    }
                })
                .catch(err => {
                    props.rerender({form: props.form, error: err.message});
                });
        } else {
            props.rerender({form: {...props.form, submitted: true}});
        }
    }

    const formatDate = (date) => {
        const isoDate = (new Date(date)).toISOString().substring(0,10);
        const [YYYY, MM, DD] = isoDate.split("-"); 
        return `${MM}/${DD}/${YYYY}`;
    };

    const getPayload = () => ({
        gatewayClient: props.form.clientName[0].value.name,
        accountNumber: props.form.accountNumber[0].value,
        programName: props.form.programName,
        provisionedCode : props.form.provisionedCode,
        programStartDate: props.form.startDate, //'YYYY-MM-DD',
        programEndDate: props.form.endDate,
        mms: props.form.mmsProgram,
        rateType: props.form.rateType,
        hostingCharge: props.form.hostingCharge,
        programType: props.form.codeType,
        _id: props.form._id
    });

    const displayEndProgramModal = () => {
        props.rerender({
            ...props, 
            ["error"]: "",
            form: {
                ...props.form, 
                ["endProgram"]: !props.form.endProgram
            }
        });
    }

    const onEndPrograms = () => {
        if (validFields()) {
            const payload = getPayload();
            payload.programEndDate = new Date().toISOString().slice(0, 10);
            props.action(payload)
                .then(data => {
                    if (data.data.statusCode === 200) {
                        props.success(data.data.data.gatewayProgram);
                    } else {
                        props.rerender({
                            ...props, 
                            error: data.data.message
                        });
                    }
                })
                .catch(err => {
                    props.rerender({form: props.form, error: err.message});
                });
        } else {
            props.rerender({form: {...props.form, submitted: true}});
        }
    }

    return (
        <>
        {props.form.endProgram && props.form.status === 'ACTIVE' ? (
            <>
            <div className="modal-header">
                <i data-testid="modal-close" onClick={displayEndProgramModal} className="btn-close fa fa-times" aria-label="Close"></i>
                <h5>CONFIRM PROGRAM END</h5>
                { props.error && <div data-testid="modal-error-message" className="error-field">{ props.error }</div> } 
                <div style={{padding:"30px"}}>
                    <label>Please confirm expiry of program and move to inactive status : </label>
                </div>
                </div>
                    <div className="modal-footer">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={displayEndProgramModal}>Back</button>
                            <button type="button" className="btn btn-primary btn-block-xs btn-lg" onClick={onEndPrograms}>End Program</button>
                        </div>
                 </div>
            </>
            ) : 
           (<> 
           <div className="modal-header">
                <i data-testid="modal-close" onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
                <h3>Edit Program</h3>
                { props.error && <div data-testid="modal-error-message" className="error-field">{ props.error }</div> } 
            </div>

            <form noValidate name="settingsClientsForm" onSubmit={submitForm}>
                <div className="modal-body">
                <div className="row form-group">
                        <div className="col-sm-2 padding-0 text-right" style={{color:"red"}}>
                            <label>Provisioned Code:</label>
                        </div>
                        <div className="col-sm-10"> 
                            <label>{props.form.provisionedCode}</label>
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-2 padding-0 text-right">
                            <label>Account:</label>
                        </div>
                        <div className="col-sm-10"> 
                            <label>{props.form.clientName[0].value.name}</label>
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-2 padding-0 text-right">
                            <label>Account #:</label>
                        </div>
                        <div className="col-sm-10"> 
                            <label>{props.form.accountNumber[0].label}</label>
                        </div>
                    </div>                    
                    <div className="row form-group">
                        <div className="col-sm-2 padding-0 text-right">
                            <label>Program Start Date:</label>
                        </div>
                        <div className="col-sm-10"> 
                            <label>{formatDate(props.form.startDate)}</label>
                        </div>
                    </div>
                    <div className="row top-buffer">
                        <div className="col-sm-2 form-group padding-0 text-right ">
                            <label>Program Name:</label>                            
                        </div>
                        <div className="col-sm-8">
                            <input 
                                disabled={props.readonly}
                                required 
                                name="programName" 
                                className="form-control" 
                                minLength={4} 
                                maxLength={50} 
                                placeholder="Enter a program name"
                                value={props.form.programName}
                                onChange={updateField}
                                data-testid="modal-field-name"/> 
                            { props.form.submitted && !props.form.programName.length && 
                                <div data-testid="modal-error-name" className="error-field">This field is required.</div> } 
                            { props.form.submitted && (props.form.programName.length < 4 || props.form.programName.length > 50) && props.form.programName.length > 0 && 
                                <div data-testid="modal-error-name" className="error-field">This field requires between 4 and 50 characters.</div> } 
                        </div>
                    </div>
                    <div className="row flex-row top-buffer">
                        <div className="col-sm-2 padding-0 text-right">
                            <label>Program End Date:<div><small>(if applicable)</small></div></label>
                        </div>
                        <div className="col-sm-3"> 
                        <input 
                                type="date" 
                                name="endDate" 
                                disabled={props.readonly}
                                className="form-control" 
                                value={props.form.endDate}
                                min={(new Date()).toISOString().substring(0,10)}
                                onChange={updateField}/> 
                        </div>
                        <div className="col-sm-6 form-group">
                            <label className="checkbox-inline">
                                <input 
                                    disabled={props.readonly}
                                    type="checkbox" 
                                    name="mmsProgram" 
                                    checked={props.form.mmsProgram} 
                                    onChange={updateField} 
                                    />
                                MMS Program?
                            </label>
                        </div>
                    </div>
                    <div className="row top-buffer">
                        <div className="col-sm-2 padding-0 text-right form-group">
                            <label>Rate type:</label>
                            </div>
                            <div className="col-sm-5">
                                <div className="styled-select">
                                <select 
                                    name="rateType" 
                                    disabled={props.readonly}
                                    value={props.form.rateType} 
                                    onChange={updateField}>
                                    <option value="">Select rate type</option>
                                    <option value="STANDARD">STANDARD</option>
                                    <option value="PREMIUM">PREMIUM</option>
                                    <option value="FREE-TO-END USER">FREE-TO-END USER</option>
                                </select>
                                </div>
                            </div>
                            { props.form.submitted && !props.form.rateType && 
                                <div data-testid="modal-error-name" className="error-field">Please select a value</div> } 
                        
                    </div>
                    <div className="row top-buffer">
                        <div className="col-sm-2 padding-0 text-right col-sm-6 form-group">
                            <label>Hosting Charge:</label>
                        </div>
                        <div className="col-sm-5">    
                            <div className="styled-select">
                                    <select 
                                        disabled={props.readonly}
                                        name="hostingCharge" 
                                        value={props.form.hostingCharge} 
                                        onChange={updateField}>
                                        <option value="">Select rate type</option>
                                        <option value="STANDARD">STANDARD</option>
                                        <option value="FREE HOSTING">FREE HOSTING</option>
                                    </select>
                            </div>
                        </div>
                            { props.form.submitted && !props.form.hostingCharge && 
                                <div data-testid="modal-error-name" className="error-field">Please select a value</div> } 
                        
                    </div>
                </div>

                <div className="modal-footer">
                    {props.form.status === 'ACTIVE' ?
                    (
                    <div className="modal-footer">
                        <div className="col-sm-2" >
                            <button type="button" className="btn btn-primary btn-block-xs btn-lg" onClick={displayEndProgramModal}>End Program</button>
                        </div>
                        <div className="col-sm-10" >
                            <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
                            <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
                        </div>
                    </div>
                     ) : (
                        <div className="modal-footer">
                            <button data-testid="modal-action" type="submit" className="btn btn-primary btn-block-xs btn-lg">Save</button>
                            <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
                        </div>
                     )}
                </div>
            </form>
            </>)
}
        </>
    )
}

export default ProgramEditModal;