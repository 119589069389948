import React, { useReducer, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import Header from './CompHeader'
import NoResults from './CompNoResults'
import { ModalContext } from '../../context/modal'
import ClientsAPI from '../../models/clients'
import ClientsModal from '../modals/ClientsModal'
import DeleteModal from '../modals/DeleteModal'

import ResponseMessage from '../../components/ResponseMessage'

const initialState = {
  defaultClients: [],
  clients: [],
  filter: 'All',
  error: '',
  success: false,
  submitted: false,
  page: 1,
  pageMax: 10,
  pageTotal: 1
};

function reducer(state, action) {
  const defaults = [...state.defaultClients];
  const current = [...state.clients];
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        defaultClients: action.payload,
        clients: action.payload,
        pageTotal: Math.ceil(action.payload.length/state.pageMax),
      };
    case 'setStatus':
      const statusIndexDefaults = defaults.findIndex(o => o.cltId === action.id);
      const statusIndexCurrent = current.findIndex(o => o.cltId === action.id);
      defaults[statusIndexDefaults].status = action.status;
      current[statusIndexCurrent].status = action.status;
      let filteredCurrent = current;
      if (state.filter !== 'All') {
        filteredCurrent = current.filter((e) => e.status === state.filter);
      }
      return {
        ...state,
        defaultClients: defaults,
        clients: filteredCurrent,
        pageTotal: Math.ceil(filteredCurrent.length/state.pageMax),
      };
    case 'setRemove':
      const removeIndexDefaults = defaults.findIndex(o => o.cltId === action.id);
      const removeIndexCurrent = current.findIndex(o => o.cltId === action.id);
      defaults.splice(removeIndexDefaults, 1);
      current.splice(removeIndexCurrent, 1);
      return {
        ...state,
        defaultClients: defaults,
        clients: current,
        pageTotal: Math.ceil(current.length/state.pageMax),
      };
    case 'setFilter':
      let updatedList = (action.payload === 'All') ? state.defaultClients : state.defaultClients.filter((e) => e.status === action.payload);
      return {
        ...state,
        clients: updatedList,
        pageTotal: Math.ceil(updatedList.length/state.pageMax),
        filter: action.payload,
        page: ((updatedList.length/10) < state.page) ? 1 : state.page
      };
    case 'setError':
      return {
        ...state,
        error: action.payload
      };
    case 'setPage':
      return {
        ...state,
        page: action.page
      };
    default:
      throw new Error();
  }
}

export default function SettingsClients() {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { toggleModal, setModalContent, setModalWidth } = React.useContext(ModalContext);

  /** Get Client Data **/
  const getClientData = useCallback(() => {
    ClientsAPI().findAll()
    .then((data) => {
      dispatch({type: 'setData', payload: data.data.data.clients});
    })
    .catch(err => {
      dispatch({type: 'setError', payload: err});
    });
  }, [])

  useEffect(() => {
    getClientData();
  }, [getClientData]);

  const changeFilter = (event) => {
    event.preventDefault();
    dispatch({type: 'setFilter', payload: event.target.value});
  }

  const setClientStatus = (params) => {
    params.event.preventDefault();
    params.event.stopPropagation();
    let param = params.client;
    param.status = params.status ? "ACTIVE" : "DISABLED";
    ClientsAPI().save(param)
    .then((data) => {
      if(data.data.statusCode === 200) {
        dispatch({type: 'setStatus', id: param.cltId, status: param.status});
      } else {
        dispatch({type: 'setError', payload: 'ERROR'});
      }
    })
    .catch(err => {
      dispatch({type: 'setError', payload: 'SERVICE ERROR'});
    });
  };

  /** Add Client */
  const addClient = (event) => {
    event.stopPropagation();
    event.preventDefault();
    toggleModal();
    setModalWidth("modal-lg");
    renderAddEditClient({new: true});

  };

  /** Edit Client */
  const editClient = (params) => {
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalWidth("modal-lg");
    renderAddEditClient({client: params.client});
  };

  const renderAddEditClient = (data) => {
    setModalContent(ClientsModal({
      new: (data && data.new) ? true : false,
      typemessage: (data && data.new) ? "Create New " : "Edit ",
      hide: () => toggleModal(false),
      client: (data && data.client) ? data.client : '',
      error: (data && data.error) ? data.error : '',
      submitted: (data && data.submitted) ? true : false,
      success: () => {
        toggleModal(false);
        getClientData();
      },
      rerender: (p) => renderAddEditClient(p),
      action: (params) => {
        params.event.preventDefault();
        return ClientsAPI().save(params.data)
      }
    }));
  }

  /** Delete Client */
  const deleteClient = (params) => {
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalWidth("modal-md");
    setModalContent(DeleteModal({
      message: `Are you sure you want to delete the '${params.client.name}' Client?`,
      hide: () => toggleModal(false),
      action: () => initDeleteClient(params)
    }));
  };

  const initDeleteClient = (params) => {
    ClientsAPI().destroy(params.client.cltId)
    .then((data) => {
      if(data.data.statusCode === 200) {
        dispatch({type: 'setRemove', id: params.client.cltId});
      } else {
        dispatch({type: 'setError', payload: 'ERROR'});
      }
    })
    .catch(err => {
      dispatch({type: 'setError', payload: 'SERVICE ERROR'});
    })
    .finally(() => {
      toggleModal(false);
    });
  }

  //Checks if index is within the current SMS page view
  const listingInRange = (index) => {
    return (index >= ((state.page - 1) * state.pageMax) && index < (state.page * state.pageMax))
  }

  //Change current SMS page view
  const changeSmsPage = (page) => {
    dispatch({type:'setPage', page: (page.selected + 1)});
  }

	return (
    <React.Fragment>

      <Header
        title="Clients"
        add="CREATE NEW CLIENT"
        click={addClient}
      >
        <div className="form-horizontal">
          <label className="sr-only control-label" htmlFor="clientsFilter">Status:</label>
          <div className="styled-select">
            <select data-testid="clients-filter" name="clientsFilter" onChange={changeFilter}>
              <option value="All">All</option>
              <option value="ACTIVE">Active</option>
              <option value="DISABLED">Inactive</option>
            </select>
          </div>
        </div>
      </Header>

      {state.error && 
        <ResponseMessage
          validation={state.error}
          message={state.error}
          class="error-field" />
      }

      <div className="row table-header">
        <div className="col-md-2 hidden-sm hidden-xs">Code</div>
        <div className="col-md-2 col-xs-4">Name</div>
        <div className="col-md-4 col-xs-4">Email</div>
        <div className="col-md-2 hidden-sm hidden-xs">Country</div>
        <div className="col-md-2 col-xs-4 text-right"></div>
      </div>

      <div className="panel-group accordion" id="collapse-panel" data-testid="data-list">
        {state.clients && state.clients.map((client, key) => (listingInRange(key)) ? (
          <div className="panel panel-default" key={key}>
            <div data-testid={`data-list-${key}`} className={`${client.status === 'DISABLED' ? 'record-draft' : ''} ${client.status === 'ACTIVE' ? 'record-active' : ''} panel-heading`} onClick={(event) => editClient({event: event, client: client})}>
                <div className="row record">
                  <div className="col-md-2 hidden-sm hidden-xs">{client.code}</div>
                  <div className="col-md-2 col-xs-4 highligted-text">{client.name}</div>
                  <div data-testid={`data-list-${key}-email`} className="col-md-4 col-xs-4">{client.email}</div>
                  <div className="col-md-2 hidden-sm hidden-xs">{client.country}</div>
                  <div className="col-md-2 col-xs-4 text-right">
                    {client.status==='ACTIVE' &&
                      <span className="fa-wrap highligted-text-red">
                          <i data-testid={`data-list-${key}-active`} className="fa fa-stop fa-lg" onClick={(event) => setClientStatus({event: event, status: false, client: client})} title="Deactivate Client"></i>
                      </span>
                    }
                    {client.status==='DISABLED' &&
                      <span className="fa-wrap highligted-text-green">
                          <i data-testid={`data-list-${key}-disabled`} className="fa fa-play fa-lg" onClick={(event) => setClientStatus({event: event, status: true, client: client})} title="Activate Client"></i>
                      </span>
                    }
                    <span className="fa-wrap"><i data-testid={`data-list-${key}-delete`} className="fa fa-trash-o fa-lg" onClick={(event) => deleteClient({event: event, client: client})} title="Delete Client"></i></span>
                  </div>
                </div>
            </div>
          </div>
          ) : null
        )}
        {state.clients.length === 0 &&
          <NoResults
            message="There are no clients to display yet." />
        }
      </div>

      {state.clients.length !== 0 &&
        <ReactPaginate
            previousLabel={'‹'}
            nextLabel={'›'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={state.pageTotal}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={changeSmsPage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
      }

    </React.Fragment>
	)
}
