import API from '../services/api'

export default function UsersAPI() {

    var Users = {};

    Users.findAll = function () {
        return API.findAll('getUsers');
    };

    Users.save = function (userData) {
        return API.save('saveUser', userData);
    };

    Users.destroy = function (userId) {
        return API.destroy('deleteUser', 'usrId', userId);
    };

    return Users;

};
