import React from 'react'

export default function NoResults(props) {
	return (
    <React.Fragment>
      <div data-testid="no-data" className="panel panel-default">
        <div className="panel-heading empty">
          <div className="row record">
            <div className="col-xs-12">{props.message}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
	)
}
