import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import settings from '../config/settings'
import API from '../services/api'
import { AmplifySignOut } from '@aws-amplify/ui-react';

function CustomMenuLink(props) {
	let match = useRouteMatch({
  	path: props.to,
  	exact: false
	});

	return (
		<li className={match ? "active" : ""}>
			<NavLink to={props.to}>
				{props.icon &&
					<i className={props.icon + " fa fa-fw fa-2x"}></i>
				}
				<span className="nav-text">{props.label}</span>
			</NavLink>
		</li>
	);
}

function CustomExpandableMenu(props) {
	const match = useRef(false);
	const [toggled, setToggled] = useState(false);
	
	useEffect(() => {
		const url = window.location.pathname.toLowerCase();
		props.paths.map((item) => {
			if (url.match(item) !== null) {
				match.current = true;
				setToggled(true);
				return true;
			} 
			return false;
		});
	});

	const toggle = (event) => {
		const url = window.location.pathname.toLowerCase();
		const shouldToggle = props.paths.find((item) => url.match(item) !== null);
		if(!shouldToggle) {
			setToggled(!toggled);
		}
	}

	return (
		<li className={`parent ${toggled ? 'active' : ''}`} data-collapsevar="1">
			<button type="button" onClick={toggle}>
				<i className="fa fa-fw icon-Subscribers_Icon fa-2x"></i>
				<span className="nav-text">{props.name}</span>
				<span className="fa arrow text-right"></span>
			</button>
			<ul className={`nav nav-second-level collapse ${toggled ? 'in' : ''}`}>
				{props.children}
			</ul>
		</li>
	);
}

function GetMenu() {
	let params = {};
	if (settings.name === "development") {
    	params = {
      		role: 'admin'
    	}
  	}
  	return API.call('getRoleAccess', params);
}

// function signOut() {
	// Auth.signOut()
	// 	.then(data => console.log(data))
	// 	.catch(err => console.log(err));
// }

export default function Sidebar(props) {

	//const [page, setPage] = useState(1);
	const [featureAccess, setFeatureAccess] = useState('');
	//const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GetMenu()
		.then((data) => {
			setFeatureAccess(data.data.data.featureAccess);
			//setIsLoading(false);
		})
		.catch(console.log)
  }, []);

  return (
		<div className="navbar-default sidebar hidden-xs" role="navigation">
		  <div className="sidebar-nav navbar-collapse">
		    <ul className="nav in" id="side-menu">

        	<CustomMenuLink 
        		to={`${settings.subPath}/home`}
        		label="Home"
        		icon="icon-Home_Icon"
        	/>

          {featureAccess.analytics !== 'N' &&
	          <CustomMenuLink 
	        		to={`${settings.subPath}/reports`}
	        		label="Reports"
	        		icon="icon-Reports_Icon"
	        	/>
	        }

	        {featureAccess.campaigns !== 'N' &&
	          <CustomMenuLink 
	        		to={`${settings.subPath}/campaigns`}
	        		label="Campaigns"
	        		icon="icon-Campaigns_Icon"
	        		paths={[
	        			`${settings.subPath}/campaigns`,
	        			`${settings.subPath}/editJourney`,
	        			`${settings.subPath}/newJourney`,
	        			`${settings.subPath}/edittrivia`,
	        			`${settings.subPath}/newtrivia`,
	        			`${settings.subPath}/edittrigger`,
	        			`${settings.subPath}/newtrigger`,
	        			`${settings.subPath}/editSimpleResponse`,
	        			`${settings.subPath}/newSimpleResponse`
	        		]}
	        	/>
        	}

        	{(featureAccess.smartlist !== 'N' || featureAccess.subscribers !== 'N') &&
            <CustomExpandableMenu
            	name="Subscribers"
            	paths={[
            		`${settings.subPath}/subscribers/smartlists`,
            		`${settings.subPath}/subscribers/subscriptions`]}>
              	{featureAccess.smartlist !== 'N' &&
					<CustomMenuLink 
						to={`${settings.subPath}/subscribers/smartlists`}
						label="Smart Lists"
						paths={[
							`${settings.subPath}/subscribers/smartlists`,
							`${settings.subPath}/subscribers/smartlistsrules`,
							`${settings.subPath}/subscribers/smartlistscreate`
						]}
					/>
              	}
              	{featureAccess.subscribers !== 'N' &&
                	<CustomMenuLink 
						to={`${settings.subPath}/subscribers/subscriptions`}
						label="Subscriptions"
						paths={[
							`${settings.subPath}/subscribers/subscriptions`,
							`${settings.subPath}/subscribers/subscriptionsview`
						]}
					/>
              	}
            </CustomExpandableMenu>
	        }

			{featureAccess.gateway_clients !== 'N' && featureAccess.gateway_programs !== 'N' &&
				<CustomExpandableMenu
					name="Gateway"
					paths={[
						`${settings.subPath}/accounts`,
						`${settings.subPath}/programs`]}>
					{featureAccess.gateway_clients !== 'N' && 
						<CustomMenuLink 
							to={`${settings.subPath}/gateway/accounts`}
							label="Accounts"
						/>}
					{featureAccess.gateway_programs !== 'N' && 
						<CustomMenuLink 
							to={`${settings.subPath}/gateway/programs`}
							label="Programs"
						/>}
				</CustomExpandableMenu>}
			
			

	        {featureAccess.sms_library !== 'N' &&
            <CustomMenuLink 
	        		to={`${settings.subPath}/smslibrary`}
	        		label="SMS Library"
	        		icon="icon-Libraries_Icon"
	        		paths={[
	        			`${settings.subPath}/smslibrary`,
	        			`${settings.subPath}/editsms`,
	        			`${settings.subPath}/newsms`
	        		]}
        		/>
	        }

	        {(featureAccess.clients !== 'N' || featureAccess.shortcodes !== 'N' || featureAccess.users !== 'N' || featureAccess.contacts !== 'N') &&
            <CustomExpandableMenu
            	name="Settings"
            	paths={[
            		`${settings.subPath}/clients`,
            		`${settings.subPath}/shortcodes`,
            		`${settings.subPath}/users`,
            		`${settings.subPath}/contacts`]}>
              	{featureAccess.clients !== 'N' &&
                  <CustomMenuLink
		            		to={`${settings.subPath}/settings/clients`}
		        				label="Clients"
		        			/>
                }
                {featureAccess.shortcodes !== 'N' &&
                  <CustomMenuLink
		            		to={`${settings.subPath}/settings/shortcodes`}
		        				label="Short Codes"
		        			/>
                }
                {featureAccess.users !== 'N' &&
                  <CustomMenuLink
			            	to={`${settings.subPath}/settings/users`}
			        			label="Users"
			        		/>
                }
                {featureAccess.contacts !== 'N' &&
                  <CustomMenuLink
			            	to={`${settings.subPath}/settings/contacts`}
			        			label="Notification Contacts"
			        		/>
                }
            </CustomExpandableMenu>
	        }
          
          <li>
			<AmplifySignOut buttonText="Logout" />
            {/* <button onClick={signOut}>
              <i className="icon-MyAccount_Icon fa fa-fw fa-2x"></i>
              <span className="nav-text">Logout</span>
            </button> */}
			{/* <AmplifySignOut /> */}
          </li>
          
		    </ul>
		  </div>
		</div>
	);
}
