import API from '../services/api'

export default function ChartsAPI() {

  let Chart = {};

  Chart.generateGraphData = function(chartData) {
    return API.findAll("generateGraphData", chartData);
  };

  return Chart;

}
