import React, { useReducer, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'
import settings from '../../config/settings'

import { ModalContext } from '../../context/modal'
import SmsAPI from '../../models/sms'
import DeleteModal from '../modals/DeleteModal'
import DeleteFolderModal from '../modals/DeleteFolderModal'
import SmsFolderModal from '../modals/SmsFolderModal'
import SmsUploadModal from '../modals/SmsUploadModal'

import ListInputFilter from '../../components/ListInputFilter'
// import ResponseMessage from '../../components/ResponseMessage'

const initialState = {
  defaultSms: [],
  sms: [],
  defaultFolders: [],
  folders: [],
  selectedFolder: null,
  search: '',
  error: '',
  page: 1,
  pageMax: 10,
  pageTotal: 1
};

function reducer(state, action) {
  switch (action.type) {
    case 'setData':
      const setDataSMS = action.sms.filter(sms => sms.folderId <= 0);
      return {
        ...state,
        defaultSms: action.sms,
        sms: setDataSMS,
        defaultFolders: action.folders,
        folders: action.folders,
        selectedFolder: null,
        pageTotal: Math.ceil(setDataSMS.length/state.pageMax),
        search: '',
        error: ''
      };
    case 'setFolder':
      const setFolderSms = state.defaultSms.filter((e) => e.folderId === action.id);
      return {
        ...state,
        sms: setFolderSms,
        folders: state.defaultFolders.filter((e) => e.folderId === action.id),
        selectedFolder: state.defaultFolders.filter((e) => e.folderId === action.id)[0],
        search: '',
        pageTotal: Math.ceil(setFolderSms.length/state.pageMax)
      };
    case 'setRoot':
      const setRootSMS = state.defaultSms.filter(sms => sms.folderId <= 0);
      return {
        ...state,
        sms: setRootSMS,
        folders: state.defaultFolders,
        selectedFolder: null,
        search: '',
        pageTotal: Math.ceil(setRootSMS.length/state.pageMax)
      };
    case 'getSearch':
      let sms = state.defaultSms.filter((e) => {
        if (state.selectedFolder) {
          return (e.folderId === state.selectedFolder.folderId && e.name.toLowerCase().includes(action.term));
        }
        return e.name.toLowerCase().includes(action.term);
      });
      let folders = state.defaultFolders.filter((e) => {
        if (state.selectedFolder) {
          return (e.folderId === state.selectedFolder.folderId && e.name.toLowerCase().includes(action.term));
        }
        return e.name.toLowerCase().includes(action.term);
      });
      return {
        ...state,
        sms: sms,
        folders: folders,
        search: action.term
      };
    case 'setError':
      return {
        ...state,
        error: action.error
      };
    case 'setPage':
      return {
        ...state,
        page: action.page
      };
    default:
      throw new Error();
  }
}

export default function SmsLibrary() {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { toggleModal, setModalContent } = React.useContext(ModalContext);
  
  useEffect(() => {
    getSmsData();
  }, []);

  const getSmsData = () => {
    const axios = require('axios');
    return axios.all([SmsAPI().findAll(true), SmsAPI().findAllFolder()]).then((data) => {
      const sms = data[0].data.data.smsTemplate;
      let folders = data[1].data.data.folders;
      folders.forEach((folder) => {
        folder.count = 0;
        sms.forEach((template) => {
          if (template.folderId === folder._id) {
            folder.count++;
          }
        })
      })
      dispatch({type: 'setData', sms: sms, folders: folders});
    })
    .catch(err => {
      dispatch({type:'setError', error: err});
    });
  }

  /** Update data after modal action success **/
  const dataSuccess = () => {
    getSmsData()
    .then((data) => {
      toggleModal(false);
    });
  }
  
  /** Get SMS Library **/
  const getSmsLibrary = (id) => {
    dispatch({type: 'setFolder', id: id});
  }

  /** Get Back to Root Listings **/
  const getRoot = (event) => {
    event.preventDefault();
    dispatch({type: 'setRoot'});
  } 

  /** Show Search Results **/
  const filterResults = (event) => {
    event.preventDefault();
    dispatch({type:'getSearch', term: event.target.value.toLowerCase()})
  }

  /** Upload SMS Library */
  const uploadSmsLibrary = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('upload');
    toggleModal();
    renderUploadSmsLibraryModal();
  };

  /** Render/rerender Uploader SMS Library modal */
  const renderUploadSmsLibraryModal = (data) => {
    setModalContent(SmsUploadModal({
      typemessage: `Create New `,
      hide: () => toggleModal(false),
      error: (data && data.error) ? data.error : '',
      success: () => dataSuccess(),
      rerender: (p) => renderUploadSmsLibraryModal(p),
      action: (params) => {
        params.event.preventDefault();
        return SmsAPI().uploadLibrary(params.file)
      }
    }));
  }

  /** Create SMS folder */
  const createSmsFolder = (event) => {
    event.preventDefault();
    event.stopPropagation();
    toggleModal();
    renderSmsFolderModal();
  };

  /** Render/rerender SMS folder modal */
  const renderSmsFolderModal = (data) => {
    setModalContent(SmsFolderModal({
      typemessage: `Create New `,
      hide: () => toggleModal(false),
      error: (data && data.error) ? data.error : '',
      success: () => dataSuccess(),
      rerender: (p) => renderSmsFolderModal(p),
      action: (params) => {
        params.event.preventDefault();
        const param = {
          name: params.name
        };
        return SmsAPI().saveFolder(param)
      }
    }));
  }

  /** Delete SMS Library */
  const deleteSms = (params) => {
    params.event.preventDefault();
    params.event.stopPropagation();
    toggleModal();
    setModalContent(DeleteModal({
      message: `Are you sure you want to delete the ${params.name} SMS?`,
      hide: () => toggleModal(false),
      action: (event) => {
        event.preventDefault();
        SmsAPI().destroy(params.id)
        .then((data) => {
          dataSuccess();
        })
        .catch(err => {
          dispatch({type:'setError', error: err});
        });
      }
    }));
  };

  /** Delete SMS Folder */
  const deleteSmsFolder = (params) => {
    params.event.preventDefault();
    params.event.stopPropagation();
    toggleModal();
    setModalContent(DeleteFolderModal({
      message: `Are you sure you want to delete the ${params.name} folder?`,
      hide: () => toggleModal(false),
      action: (evt) => {
        evt.event.preventDefault();
        const param = {
          folderId: params.id,
          deleteSMS: evt.checkbox
        };
        SmsAPI().destroyFolder(param)
        .then((data) => {
          dataSuccess();
        })
        .catch(err => {
          dispatch({type:'setError', error: err});
        });
      }
    }));
  };

  //Checks if index is within the current SMS page view
  const listingInRange = (index) => {
    return (index >= ((state.page - 1) * state.pageMax) && index < (state.page * state.pageMax))
  }

  //Change current SMS page view
  const changeSmsPage = (page) => {
    dispatch({type:'setPage', page: (page.selected + 1)});
  }

	return (
    <React.Fragment>
      <div className="row row-top">
        <div className="col-md-3">
          <h3>SMS Library</h3>
        </div>
        <div className="col-md-2">
          <Link 
            to={{
              pathname: `${settings.subPath}/smslibrary/create`,
              sms: state.defaultSms,
              folders: state.defaultFolders
            }}
            className="btn btn-secondary btn-md btn-block-xs btn-block" >
            CREATE NEW SMS
          </Link>
        </div>
        <div className="col-md-2">
          <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={createSmsFolder}>
            CREATE NEW FOLDER
              </button>
        </div>
        <div className="col-md-2">
          <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn" onClick={uploadSmsLibrary}>
            UPLOAD SMS LIBRARY
              </button>
        </div>
        <div className="col-md-3 text-right">
          <ListInputFilter
            value={state.search}
            change={filterResults}
            placeholder="Search for a SMS"
          />
        </div>
      </div>

      {state.error &&
        <div className="error-field form-group">{state.error}</div>
      }

      {state.selectedFolder &&
        <div className="record-title">
          <div className="row">
            <div className="col-xs-12 form-group">You are currently in <b>{state.selectedFolder.name}</b>. <i className="fa fa-fw fa-level-up fa-flip-horizontal"></i><a onClick={event => getRoot(event)} href="/">Go back to root folder</a>.</div>
          </div>
        </div>
      }

      {!state.selectedFolder && state.folders.length > 0 && 
        <div className="row table-header">
          <div className="col-md-6 col-xs-4">Folder Name</div>
          <div className="col-xs-4 text-right">SMS</div>
          <div className="col-md-2 col-xs-4 text-right"></div>
        </div>
      }

      <div className="panel-group accordion" data-testid="sms-folders">
        {!state.selectedFolder && state.folders.length > 0 && state.folders.map((folder, key) =>
          <div className="panel panel-default" key={key}>
            <div className="panel-heading" data-testid={`sms-folder-${key}`} onClick={event => getSmsLibrary(folder.folderId)}>
              <div className="row record">
                <div className="col-md-6 col-xs-4"><i className="fa fa-fw fa-folder"></i>{folder.name}</div>
                <div className="col-xs-4 text-right">{folder.count}</div>
                <div className="col-md-2 col-xs-4 text-right">
                  <span className="fa-wrap"><i title="Delete SMS" className="fa fa-trash-o fa-lg" onClick={event => deleteSmsFolder({event: event, id: folder.folderId, name: folder.name})}></i></span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="row table-header">
        <div className="col-md-3 col-xs-4">Name</div>
        <div className="col-md-3 col-xs-4">Code</div>
        <div className="col-md-4 hidden-sm hidden-xs">Default Message</div>
        <div className="col-md-2 col-xs-4 text-right"></div>
      </div>

      <div className="panel-group accordion" data-testid="sms-list">
        {state.sms.length > 0 && state.sms.map((sms, key) => (listingInRange(key)) ? (
            <div className="panel panel-default" key={key}>
              <Link 
                to={{
                  pathname: `${settings.subPath}/smslibrary/${sms.smsId}`,
                  sms: state.defaultSms,
                  folders: state.defaultFolders
                }}
                className="panel-heading">
                <div className="row record">
                  <div className="col-md-3 col-xs-4">{sms.name}</div>
                  {sms.code && !sms.groupCode &&
                    <div className="col-md-3 col-xs-4">{sms.code}</div>
                  }
                  {sms.groupCode &&
                    <div className="col-md-3 col-xs-4">{sms.groupCode}</div>
                  }
                  <div className="col-md-4 hidden-sm hidden-xs">{sms.defaultMessage}</div>
                  <div className="col-md-2 col-xs-4 text-right">
                    <span className="fa-wrap"><i title="Delete SMS" className="fa fa-trash-o fa-lg" onClick={event => deleteSms({event: event, id: sms._id, name: sms.name})}></i></span>
                  </div>
                </div>
              </Link>
            </div>
          ) : ''
        )}

        {state.sms.length === 0 &&
          <div className="panel panel-default" data-testid="no-sms">
            <div className="panel-heading empty">
              <div className="row record">
                <div className="col-xs-12">There are no SMSs to display.</div>
              </div>
            </div>
          </div>
        }

      </div>

      <ReactPaginate
          previousLabel={'‹'}
          nextLabel={'›'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={state.pageTotal}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={changeSmsPage}
          containerClassName={['pagination', state.pageTotal > 1 ? '' : 'hidden'].join(' ')}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />

    </React.Fragment>
	)
}
