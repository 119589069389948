import React from 'react'
import CorrespondenceForm from './CorrespondenceForm'

export default function Correspondence(props) {
    const campaignId = parseInt(props.campaignId);
    const campaignInd = parseInt(props.campaignInd);
    const triggerId = parseInt(props.triggerId);

    return (
        <CorrespondenceForm 
            campaignId={campaignId} 
            campaignInd={campaignInd}
            triggerId ={triggerId || null} 
            hide={props.hide} 
            action={props.action}/>
    )
}
