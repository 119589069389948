import API from '../services/api'

export default function ReportsAPI() {

  let Reports = {};

  Reports.download = function(data) {
    var download = {
      action: data.action,
      params: {
        startDate: data.startDate,
        endDate: data.endDate
      }
    };
    return API.download(download, 'application/zip', 'download');
  };

  Reports.getPlugins = function() {
    return API.findAll('getPluginsForUI');
  };

  return Reports;

}
