import React from 'react'

const formatDate = (d) => (new Date(d)).toISOString().substring(0,10);

export default function DownloadReportModal(props) {

    const updateDateRange = (event) => {
        const endDate = event === '' ? '' : formatDate(new Date());
        const startDate = event === '' ? '' : formatDate(new Date(new Date().setDate(new Date(endDate).getDate() - parseInt(event)) + 1));

        props.rerender({
            ...props, 
            form: {
                ...props.form, 
                ["startDate"]: startDate,
                ["endDate"]: endDate
            }
        });
    }

    const updateStartDate = (value) => {        
        props.rerender({
            ...props, 
            form: {
                ...props.form, 
                ["startDate"]: value === '' ? props.form.startDate : value
            }
        });
    }

    const updateEndDate = (value) => {
        props.rerender({
            ...props, 
            form: {
                ...props.form, 
                ["endDate"]: value === '' ? props.form.endDate : value
            }
        });
    }    

    const generateReport = (event) => {
        const payload = getPayload();
        props.action(payload)
        .then(data => {
            let file = new Blob([data.data], {type: 'application/zip'})
            let fileName = data.headers['file-name'];
            let url = URL.createObjectURL(file);
            let download = document.createElement('a');
            download.href = url;
            download.download = fileName;
            download.click();
            props.hide();
        })
        .catch(err => {
            props.rerender({form: props.form, error: err.message});
        });        
    }

    const getPayload = () => ({
        startDate: props.form.startDate,
        endDate: props.form.endDate,   
    });    

	return (
		<React.Fragment>
            <div className="modal-header" data-testid="modal-window">
				<i data-testid="modal-close" className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close"
					onClick={props.hide}
				></i>
                <h3>Report filter : </h3>
                { props.error && <div data-testid="modal-error-message" className="error-field">{ props.error }</div> }
			</div>

			<div className="modal-body">
            
			</div>
            <div className="row">
                <div className="col-sm-4 form-group">
                    <label>Date Range : </label>
                    <div className="styled-select">
                        <select
                            name="daterange"
                            value={props.choice} 
                            onChange={(event) => updateDateRange(event.target.value)}>
                                <option value=''>All</option>
                                <option value="29">Last 30 days</option>
                                <option value="59">Last 60 days</option>
                                <option value="89">Last 90 days</option>
                        </select>
                     </div>
                </div>                
                <div className="col-sm-4 form-group">
                     <label>From :</label>
                        <input 
                            type="date" 
                            name="startDate"
                            value={props.form.startDate}
                            onChange={(event) => updateStartDate(event.target.value)}
                            className="form-control" 
                        /> 
                </div>
                <div className="col-sm-4 form-group">
                    <label>To : </label>
                        <input 
                            type="date" 
                            name="endDate" 
                            value={props.form.endDate}
                            onChange={(event) => updateEndDate(event.target.value)}
                            className="form-control" 
                        />
                </div>
            </div>

			<div className="modal-footer top-buffer">
				<button data-testid="modal-action" type="button" className="btn btn-primary btn-lg btn-block-xs" onClick={generateReport}>Generate Report</button>
				<button type="button" className="btn btn-default btn-lg btn-block-xs" onClick={props.hide}>Cancel</button>            
			</div>
		</React.Fragment>
	)
}