import React, {useEffect} from 'react';
import TriviaAPI from '../../models/trivia'
import settings from '../../config/settings'
import { ModalContext } from '../../context/modal'
import DeleteCommunicationModal from '../modals/campaigns/DeleteCommunicationModal'

export default function ContestPanel(props) {
    let { toggleModal, setModalContent } = React.useContext(ModalContext);

    useEffect(() => {
        // console.log('SimpleResponsePanel', props.expand);
    }, [props.expand]);

    const renderDeleteContestModal = (data) => {
        setModalContent(DeleteCommunicationModal({
            hide: () => toggleModal(false),
            success: () => {toggleModal(false);},
            rerender: p => renderDeleteContestModal(p),
            action: (type, payload) => {
                TriviaAPI().destroy(data.id)
                    .then(res => {
                        if (res.data.statusCode === 200) {
                            toggleModal(false);
                            props.deleteCommunication(data.cId, data.id, 'CONTEST');
                        } else {
                            props.setError(res.data.message);
                        }
                    })
                    .catch(res => {
                        props.setError(res.data.message);
                    });
            },
            name: (data && data.name) || '',
            type: 'contest',
            error: (data && data.error) || '',
            campaign: (data && data.campaign) || {}
        }));
        toggleModal(true);
    }

    return (
        <div className="panel-section-content">
            <div className="panel panel-default panel-section-header">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-md-4 col-xs-12">Contests</div>
                        <div className="col-md-8 hidden-xs"></div>
                    </div>
                </div>
            </div>
            <div className="panel panel-default panel-section-records row">
                {
                    props.data.map(contest => (
                        <div key={'contest' + contest.triviaId} className={["row panel-section-record", props.communicationStatusClasses[contest.status], props.expand].join(" ")}>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 panel-section-record-row">
                                {/* <div className="panel-heading panel-heading-campaign row record" onClick={_ => props.setRedirectTo(`${settings.subPath}/contest/${contest.triviaId}/${props.campaignId}/${props.index}`)}> */}
                                <div className="panel-heading panel-heading-campaign row record" onClick={_ => props.renderCommunicationModal(props.campaignId, props.index, contest.triviaId)}>
                                    <div className="col-md-3 col-xs-8 highligted-text">{contest.name}</div>
                                    <div className="col-md-2 hidden-sm hidden-xs nowrap">
                                        <span>{contest.keywordList.join(", ")}</span>
                                    </div>
                                    <div className="col-md-2 hidden-sm hidden-xs">{contest.shortCodeId}</div>
                                    <div className="col-md-3 hidden-sm hidden-xs">{props.formatDateString(contest.startDate)} &#8211; {props.formatDateString(contest.endDate)}</div>
                                    <div className="col-md-2 col-xs-4 text-right">
                                        {
                                            (contest.status === 'DISABLED') && (
                                                <span className="fa-wrap highligted-text-green">
                                                    <i className="fa fa-play fa-lg" onClick={evt => props.setCommunicationStatus(evt, contest, 'CONTEST')} title="Activate Contest"></i>
                                                </span>
                                            )
                                        }
                                        {
                                            (contest.status === 'DISABLED') && (
                                                <span className="fa-wrap">
                                                    <i className="fa fa-trash-o fa-lg" onClick={evt => {evt.stopPropagation(); renderDeleteContestModal({name: contest.name, id:contest._id, cId: contest.campaignId})}} title="Delete Contest"></i>
                                                </span>
                                            )
                                        }
                                        {
                                            (contest.status === 'ACTIVE') && (
                                                <span className="fa-wrap highligted-text-red">
                                                    <i className="fa fa-stop fa-lg" onClick={evt => props.setCommunicationStatus(evt, contest, 'CONTEST')} title="Deactivate Contest"></i>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                
            </div>
        </div>
    )
}
