import React from 'react';

export default function DeleteCampaignModal(props) {
    return (
        <div>
            <div className="modal-header">
                <i onClick={props.hide} className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close"></i>
            </div>
            <div className="modal-body">
                <p>Are you sure you want to delete the '{props.name}' {props.type}?</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-lg btn-block-xs" onClick={_ => props.action('delete', props.id)} >Delete</button>
                <button type="button" className="btn btn-default btn-lg btn-block-xs" onClick={props.hide}>Cancel</button>
            </div>
        </div>
    )
}
