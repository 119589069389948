import React from 'react'

export default function SmsUploadModal(props) {

  const submitForm = (event) => {
    event.preventDefault();
    let fd = new FormData();
    fd.append('file', document.getElementById('file').files[0]);
    console.log(fd);

    props.action({event: event, file: document.getElementById('file').files[0] })
    .then((data) => {

      console.log(data);
      // if (data.data.statusCode === 200) {
      //   props.success();
      // } else {
      //   props.rerender({error: data.data.message});
      // }
    })
    .catch(err => {
      console.log(err);
      //props.rerender({error: err.message});
    });
  }

  const triggerUploadDialog = () => {
    document.getElementById('file').click();
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <i className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close" onClick={props.hide}></i>
        <h3>Upload SMS Library</h3>
        {props.error &&
          <div className="error-field">{props.error}</div>
        }
      </div>

      <form noValidate name="form">
        <div className="modal-body">
          {!props.uploadSuccess &&
            <div ng-hide="uploadSuccess" className="form-horizontal">
              <div className="form-group">
                <div className="col-sm-12">
                  <label>File</label>
                  <div className="file-upload-wrapper input-group">
                    <input id="file" className="file-upload" type="file" tabIndex="-1" style={{ position: 'absolute', left: '-9999px' }} />
                    <input type="text" name="file" className="file-upload-input form-control" placeholder="Select a SMS library (XLS file)" onClick={triggerUploadDialog} autoComplete="off"/>
                    <span className="input-group-btn">
                      <button type="button" className="btn btn-default" onClick={triggerUploadDialog}>
                        <i className="fa fa-lg fa-upload"></i>
                      </button>
                    </span>
                  </div>
                  {/* <div className="error-field" ng-show="formError === true">Please fill out this field</div> */}
                </div>
              </div>
            </div>
          }
          {props.uploadSuccess &&
            <React.Fragment>
              <p>** response.message **</p>
              <ul>
                <li>Total Records: ** response.totalRecords **</li>
                <li>Updated Records: ** response.totalUpdates **</li>
                <li>New Records: ** response.totalNew **</li>
                <li>Invalid Records: ** response.totalInvalid **</li>
              </ul>
            </React.Fragment>
          }
        </div>

        {!props.uploadSuccess &&
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary btn-block-xs btn-lg" onClick={submitForm}>Upload</button>
            <button type="button" className="btn btn-default btn-block-xs btn-lg" onClick={props.hide}>Cancel</button>
          </div>
        }
      </form>
    </React.Fragment>
  )
}