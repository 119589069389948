import React, { useState, useEffect } from "react";
import useAuth from "../hooks/auth";
import { Auth } from 'aws-amplify';
import UsersAPI from '../models/users'

let AuthContext;
let { Provider } = (AuthContext = React.createContext({}));

let AuthProvider = ({ children }) => {
  const { authenticated, assignUser } = useAuth();
  const [ currentUser, setCurrentUser ] = useState();

  useEffect(() => {

      let userId;
      Auth.currentAuthenticatedUser()
        .then((user) => { 
            userId = user.attributes["custom:userId"];
        })
        .catch(() => { 
            console.log('Error getting current user.');
        });

        const users = null;
        UsersAPI().findAll()
        .then((data) => {
            let users = data.data.data.users;            
            const appUser = users.find((user) => { return user.userId == userId; });
            if(appUser) {
                setCurrentUser(appUser);
            }
        })
        .catch(err => {
            console.log('Error getting current user.');
        });        
  }, [])

  return (
    <Provider value={{ authenticated, assignUser, currentUser }}>
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };