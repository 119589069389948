import React, { useEffect, useReducer, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import settings from '../../config/settings'
import { ModalContext } from '../../context/modal'
import SmartlistAPI from '../../models/smartlist'
import DeleteModal from '../modals/DeleteModal'
import ListInputFilter from '../../components/ListInputFilter'
import ResponseMessage from '../../components/ResponseMessage'

const initialState = {
  all: [],
  active: [],
  page: 1,
  forcedPage: 0,
  pageMax: 10,
  pageTotal: 1,
  error: ''
};

function reducer(state, action) {
  switch (action.type) {
    case 'all':
      return {
        ...state,
        all: action.payload,
        active: action.payload,
        pageTotal: Math.ceil(action.payload.length/state.pageMax),
      };
    case 'active':
      return {
        ...state,
        active: action.payload,
        pageTotal: Math.ceil(action.payload.length/state.pageMax),
      };
    case 'error':
      return {
        ...state,
        error: action.payload
      };
    case 'remove':
      return {
        ...state,
        all: state.all.filter((e,i) => i !== action.key),
        active: state.active.filter((e,i) => i !== action.key)
      };
      case 'setPage':
        return {
          ...state,
          page: action.page,
          forcedPage: action.page - 1
        };
    default:
      throw new Error();
  }
}

export default function SmartLists() {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { toggleModal, setModalContent } = React.useContext(ModalContext);

  const getData = useCallback(() => {
    SmartlistAPI().findAll()
    .then((data) => {
      dispatch({type: 'all', payload: data.data.data.smartLists});
    })
    .catch(err => {
      dispatch({type: 'error', payload: err});
    });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  function confirmRemoveSmartlist(params) {
    params.event.stopPropagation();
    params.event.preventDefault();
    toggleModal();
    setModalContent(DeleteModal({
      message: `Are you sure you want to delete the '${params.name}' smart list?"`,
      hide: () => toggleModal(false),
      action: () => initRemoveSmartlist(params)
    }));
  }

  function initRemoveSmartlist(params) {
    SmartlistAPI().destroy(params.id)
    .then((data) => {
      dispatch({type: 'remove', key: params.key});
      toggleModal(false);
    })
    .catch(err => {
      dispatch({type: 'error', payload: err});
    });
  }

  function filterResults(event) {
    const filtered = state.all.filter(smartlist =>
      smartlist.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    dispatch({type:'active', payload:filtered})
  }

  //Checks if index is within the current SMS page view
  const listingInRange = (index) => {
      return (index >= ((state.page - 1) * state.pageMax) && index < (state.page * state.pageMax))
  }

  //Change current SMS page view
  const changePage = (page) => {
      dispatch({ type: 'setPage', page: (page.selected + 1) });
  }

  return (
    <div>
      <div className="row row-top">
        <div className="col-md-4">
          <h3>Smart Lists</h3>
        </div>
        <div className="col-md-4">
          <Link to={`${settings.subPath}/subscribers/smartlists/create`}>
            <button type="button" className="btn btn-secondary btn-md btn-block-xs spacer-btn">
              CREATE NEW SMART LIST
            </button>
          </Link>
        </div>
        <div className="col-md-4 text-right">
          <ListInputFilter
            change={filterResults}
            placeholder="Search for a smart list"
          />
        </div>
      </div>

      <ResponseMessage
        validation={state.error}
        message={state.error}
        class="error-field" />

      <div className="row table-header">
        <div className="col-xs-5">Name</div>
        <div className="col-md-5 col-xs-3 text-right">Subscribers</div>
      </div>

      <div data-testid="listings" className="panel-group accordion" id="collapse-panel">
        {state.active.length > 0 && state.active.map((item, key) => (listingInRange(key)) ? (
          <div className="panel panel-default" key={key}>
            <Link
              to={{
                pathname: `${settings.subPath}/subscribers/smartlists/${item.smartListId}`,
                data: item
              }}
              className="panel-heading">
              <div className="row record">
                <div className="col-xs-5">{item.name}</div>
                <div className="col-md-5 col-xs-3 text-right">{item.totalSubscribers}</div>
                <div className="col-md-2 col-xs-4 text-right">
                  <span className="fa-wrap">
                    <i title="Delete Smart Lists"
                      className="fa fa-trash-o fa-lg"
                      onClick={(event) => confirmRemoveSmartlist({
                        event: event,
                        id: item.smartListId,
                        name: item.name,
                        key: key
                      })}>
                    </i>
                  </span>
                </div>
              </div>
            </Link>
          </div>
          ) : ''
        )}
        {state.active.length === 0 &&
          <div className="panel panel-default">
            <div className="panel-heading empty">
              <div className="row record">
                <div className="col-xs-12">There are no smart lists to display.</div>
              </div>
            </div>
          </div>
        }
      </div>
      {state.active.length !== 0 &&
        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={state.pageTotal}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={state.forcedPage}
            onPageChange={changePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
      }

    </div>
  )
}