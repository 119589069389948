import API from '../services/api'

export default function SmartlistAPI() {

  let Smartlist = {};

  Smartlist.findAll = function () {
    return API.findAll("getSmartLists");
  };

  Smartlist.findMetadata = function() {
    return API.findAll("getSmartListMetadata");
  };

  Smartlist.findPreview = function(smartData) {
    return API.findAll("getSmartListPreview", smartData);
  };

  Smartlist.findSubscriberAttributeValues = function(smartData) {
    return API.findAll("getSubscriberAttributeValues", smartData);
  };

  Smartlist.findSubscriberAttributes = function() {
    return API.findAll("getSubscriberAttributes");
  };

  Smartlist.save = function (smartData) {
    return API.save('saveSmartList', smartData);
  };

  Smartlist.destroy = function (smartId) {
    return API.destroy('deleteSmartList', 'smartListId', smartId);
  };

  Smartlist.download = function(id) {
    var download = {
      action: "downloadSmartListData",
      params: {
        smartListId: id
      }
    };

    return API.download(download, 'application/zip', 'download');
  };

  Smartlist.validateExpression = function(expression) {
    return API.findAll("validateSegmentExpression", expression);
  }

  return Smartlist;

}
