import React, { useState } from 'react';

export default function Questions(props){
    const [questionError, setQuestionError] = useState("You must create at least two answers for this question");

    function viewPreview(index) {
        props.showPreview(index);
    }

    function addAnswer(index) {
        let newQuestions = [...props.questions];
        newQuestions[index].answerList = [
            ...newQuestions[index].answerList,
            {
                key: 'answer' + (new Date()).getTime(),
                points: 0,
                answer: ""
            }
        ];
        props.setQuestionsObject(newQuestions);
    }

    const formatDateString = (timestamp, type) => {
        if(timestamp==""){
            return " ";
        }
        let dateobj = timestamp ? new Date(timestamp) : new Date();

        if (type === 1) {
            const [m, d, y] = dateobj.toDateString().split(" ").slice(1);
            return `${m} ${d}, ${y}`; // Jan 01, 2020
        } else if (type === 2) {
            return dateobj.toISOString().substr(0,10); // yyyy-mm-dd
        } else if (type === 3) {
            return dateobj.toISOString().substr(0, 10); // 2020-12-31
        }
    }

    function updatePreview(index) {
        let preview = props.questions[index].question;
        props.questions[index].answerList.forEach((value, key) => {
            preview += "\n" + String.fromCharCode(65+parseInt(key)) + ") " + value.answer;
        });

        const newQuestions = [...props.questions];
        newQuestions[index].preview = preview;
        newQuestions[index].charCount = newQuestions[index].question.length + newQuestions[index].answerList.reduce((acc, item) => acc + item.answer.length, 0);
        // console.log(newQuestions[index].question.length + newQuestions[index].answerList.reduce((acc, item) => acc + item.answer.length, 0));

        props.setQuestionsObject(newQuestions);
    }

    function deleteAnswer(questionIndex, answerIndex) {
        let newQuestions = [...props.questions];
        let cloneAnswers = [...newQuestions[questionIndex].answerList];
        cloneAnswers.splice(answerIndex, 1);
        newQuestions[questionIndex].answerList = cloneAnswers;
        props.setQuestionsObject(newQuestions);
    }

    return (
        <div>
            {
                props.questions && props.questions.map((question, questionIndex) => (
                    <div className="row row-bordered question-wrap" data-question-index="{{questionIndex}}" key={question.key}>
                        <div className="col-xs-12">
                            { (props.formSubmitted && question.answerList.filter(answer => !answer.answer.length).length) ? <div className="error-field form-group">{questionError}</div> : "" }
                        </div>
                    
                        <div className="col-xs-12">
                            <div className="row">
                                <div className={["form-group", props.status ? 'col-md-6' : '', props.questions.length === 1 && !props.status ? 'col-md-4' : '', props.questions.length > 1 && !props.status ? 'col-md-3' : ''].join(" ")}>
                                    <label>Character Count</label>
                                    <input value={`${140 - question.preview.length}/140`} type="text" className="form-control" disabled />
                                    {
                                        (question.preview.length < 0) ? <div className="error-field">Exceeded character count</div> : ""
                                    }
                                </div>
                                <div className={["form-group", props.status ? 'col-md-6' : '', props.questions.length === 1 && !props.status ? 'col-md-4' : '', props.questions.length > 1 && !props.status ? 'col-md-3' : ''].join(" ")}>
                                    <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                                    <div className="full-btn btn btn-primary btn-lg" 
                                    onClick={_ => {
                                        const newQuestion = {...question};
                                        newQuestion.showPreview = !newQuestion.showPreview;
                                        const newQuestions = [...props.questions];
                                        newQuestions[questionIndex] = newQuestion;
                                        props.setQuestionsObject(newQuestions);
                                    }}>
                                        <i className="fa fa-eye fa-lg"></i> Toggle Preview
                                    </div>
                                </div>
                                {
                                    (props.status) ? "" : (
                                        <div className={["form-group", props.questions.length === 1 && !props.status ? 'col-md-4' : '', props.questions.length > 1 && !props.status ? 'col-md-3' : ''].join(" ")}>
                                            <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                                            <div className="full-btn btn btn-primary btn-lg" onClick={_ => addAnswer(questionIndex)}>
                                                <i className="fa fa-plus-circle fa-lg"></i> Add Answer
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (props.questions.length > 1 && !props.status) ? (
                                        <div className="col-md-3 form-group">
                                            <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                                            <div className="full-btn btn btn-primary btn-lg" onClick={_ => props.deleteQuestion(questionIndex)}>
                                                <i className="fa fa-trash fa-lg"></i> Delete Question
                                            </div>
                                        </div>
                                    ) : ""
                                }
                            </div>
                        </div>
                        {
                            (question.showPreview) ? (
                                <div className="col-xs-12 form-group">
                                    <label>Preview</label>
                                    <textarea name="preview{{questionIndex}}" value={question.preview} className="form-control" rows="4" maxLength="140" disabled></textarea>
                                </div>
                            ) : ""
                        }
                        
                        <div className="col-xs-12 form-group">
                            <label>Question</label>
                            <textarea 
                                disabled={props.status} 
                                required 
                                name={`question${questionIndex}`} 
                                value={question.question} 
                                onKeyUp={_ => updatePreview(questionIndex)} 
                                onChange={evt => {
                                    const newQuestion = {...question};
                                    newQuestion.question = evt.target.value;
                                    const newQuestions = [...props.questions];
                                    newQuestions[questionIndex] = newQuestion;
                                    props.setQuestionsObject(newQuestions);
                                }}
                                className="form-control" 
                                rows="1" 
                                placeholder="Enter a question"></textarea>
                            {
                                (props.formSubmitted && !question.question.length) ? <div className="error-field">Please fill out this field</div> : ""
                            }
                            
                        </div>
                        <div className="col-md-6 col-xs-12 form-group">
                            <label>Timing</label>
                            <div className={["styled-select", props.status ? 'disabled' : ''].join(" ")}>
                                <select 
                                    disabled={props.status} 
                                    required 
                                    name={"delayType" + questionIndex} 
                                    onChange={evt => {
                                        const newQuestion = {...question};
                                        newQuestion.delayType = evt.target.value;
                                        const newQuestions = [...props.questions];
                                        newQuestions[questionIndex] = newQuestion;
                                        props.setQuestionsObject(newQuestions);
                                    }}
                                    value={question.delayType}>
                                    <option value="" disabled>Select when the question will be sent</option>
                                    {
                                        //!questionIndex ? <option value="RESPONSE_DELAY">Wait for Response</option> : ""
                                    }
                                    <option value="DATE_DELAY">Fixed Date/Time</option>
                                    <option value="TIMED_DELAY">Relative Time</option>
                                </select>
                                <div className="arrow"></div>
                            </div>
                            {
                                (props.formSubmitted && question.delayType.length === 0 ) ? <div className="error-field">Please fill out this field</div> : ""
                            }
                        </div>
                        {
                            (question.delayType === 'DATE_DELAY') ? (
                                <span>
                                    <div className="col-md-2 col-xs-4 form-group">
                                        <label>Hours</label>
                                        <div className="styled-select" ng-class="{'disabled': props.status}">
                                            <select
                                                disabled={props.status}
                                                required={question.delayType === 'DATE_DELAY'} 
                                                name={"hours" + questionIndex} 
                                                value={question.time.hours}
                                                onChange={evt => {
                                                    const newQuestion = {...question};
                                                    newQuestion.time.hours = evt.target.value;
                                                    const newQuestions = [...props.questions];
                                                    newQuestions[questionIndex] = newQuestion;
                                                    props.setQuestionsObject(newQuestions);
                                                }}>
                                                <option value="" disabled>Select an hour</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                            <div className="arrow"></div>
                                        </div>
                                        {
                                            (props.formSubmitted && question.time.hours === "") ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                        
                                    </div>
                                    <div className="col-md-2 col-xs-4 form-group">
                                        <label>Minutes</label>
                                        <div className="styled-select" ng-class="{'disabled': props.status}">
                                            <select 
                                                disabled={props.status} 
                                                required={question.delayType === 'DATE_DELAY'} 
                                                name={"minutes" + questionIndex} 
                                                value={question.time.minutes}
                                                onChange={evt => {
                                                    const newQuestion = {...question};
                                                    newQuestion.time.minutes = evt.target.value;
                                                    const newQuestions = [...props.questions];
                                                    newQuestions[questionIndex] = newQuestion;
                                                    props.setQuestionsObject(newQuestions);
                                                }}>
                                                <option value="" disabled>Select a minute</option>
                                                <option value="0">00</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="40">40</option>
                                                <option value="50">50</option>
                                            </select>
                                            <div className="arrow"></div>
                                        </div>
                                        {
                                            // form.$submitted && form.minutes{{questionIndex}}.$error.required
                                            (props.formSubmitted && question.time.minutes === "") ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                        
                                    </div>
                                    <div className="col-md-2 col-xs-4 form-group">
                                        <label>AM/PM</label>
                                        <div className="styled-select" ng-class="{'disabled': props.status}">
                                            <select 
                                                disabled={props.status} 
                                                required={question.delayType === 'DATE_DELAY'} 
                                                name={"meridian"+ {questionIndex}} 
                                                value={question.time.meridian}
                                                onChange={evt => {
                                                    const newQuestion = {...question};
                                                    newQuestion.time.meridian = evt.target.value;
                                                    const newQuestions = [...props.questions];
                                                    newQuestions[questionIndex] = newQuestion;
                                                    props.setQuestionsObject(newQuestions);
                                                }}>
                                                <option value="" disabled>Select AM or PM</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                            <div className="arrow"></div>
                                        </div>
                                        {
                                            // form.meridian{{questionIndex}}.$error.required"
                                            (props.formSubmitted && question.time.meridian === "") ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                    </div>
                                    
                                    <div className="col-md-6 col-xs-12 form-group">
                                        <label>Date</label>
                                        <span date-picker="questionDatePicker[questionIndex]"></span>
                                        <input 
                                            type="date" 
                                            placeholder="Select a date"
                                            className="form-control"
                                            min={props.minDate}
                                            //max={props.maxDate}
                                            value={formatDateString(question.time.date,3)} 
                                            onChange={evt => {
                                                const newQuestion = {...question};
                                                newQuestion.time.date = evt.target.value;
                                                const newQuestions = [...props.questions];
                                                newQuestions[questionIndex] = newQuestion;
                                                props.setQuestionsObject(newQuestions);
                                            }}/>
                                        {
                                            // form.date{{questionIndex}}.$error.required
                                            (props.formSubmitted && question.time.date === "") ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                        
                                    </div>
                                </span>
                            ) : ''
                        }
                        {
                            (question.delayType === 'TIMED_DELAY') ? (
                                <span>
                                    <div className="col-md-2 col-xs-4 form-group">
                                        <label>Days</label>
                                        <input disabled={props.status} required={question.delayType === 'TIMED_DELAY'} type="number" min="0" 
                                        value={question.rdays} 
                                        name={`rdays{questionIndex}`} 
                                        className="form-control" 
                                        placeholder="Enter number of days"
                                        onChange={evt => {
                                            const newQuestion = {...question};
                                            newQuestion.rdays = evt.target.value;
                                            const newQuestions = [...props.questions];
                                            newQuestions[questionIndex] = newQuestion;
                                            props.setQuestionsObject(newQuestions);
                                        }} />
                                        {
                                            (props.formSubmitted && !question.rdays.length) ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                    </div>
                                    <div className="col-md-2 col-xs-4 form-group">
                                        <label>Hours</label>
                                        <input disabled={props.status} required={question.delayType === 'TIMED_DELAY'} type="number" min="0" 
                                        value={question.rhours} 
                                        name={`rhours{questionIndex}`} 
                                        className="form-control" 
                                        placeholder="Enter number of hours"
                                        onChange={evt => {
                                            const newQuestion = {...question};
                                            newQuestion.rhours = evt.target.value;
                                            const newQuestions = [...props.questions];
                                            newQuestions[questionIndex] = newQuestion;
                                            props.setQuestionsObject(newQuestions);
                                        }} />
                                        {
                                            (props.formSubmitted && !question.rhours.length) ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                    </div>
                                    <div className="col-md-2 col-xs-4 form-group">
                                        <label>Minutes</label>
                                        <input disabled={props.status} required={question.delayType === 'TIMED_DELAY'} type="number" min="0" 
                                        value={question.rminutes} 
                                        name={`rminutes{questionIndex}`} 
                                        className="form-control" 
                                        placeholder="Enter number of minutes" 
                                        onChange={evt => {
                                            const newQuestion = {...question};
                                            newQuestion.rminutes = evt.target.value;
                                            const newQuestions = [...props.questions];
                                            newQuestions[questionIndex] = newQuestion;
                                            props.setQuestionsObject(newQuestions);
                                        }}/>
                                        {
                                            (props.formSubmitted && !question.rminutes.length) ? <div className="error-field">Please fill out this field</div> : ""
                                        }
                                    </div>
                                </span>
                            ) : ''
                        }
                        <div className="col-xs-12">
                            <hr/>
                        </div>
                        {
                            question.answerList.map((answer, answerIndex) => (
                                <div className="col-xs-12" key={answer.key}>
                                    <div className="row">
                                        <div className={["col-xs-12 form-group", question.answerList.length <= 2 || props.status ? 'col-md-7' : '', question.answerList.length > 2 && !(props.status) ? 'col-md-6' : ''].join(" ")}>
                                            <label>Answer</label>
                                            <textarea 
                                                disabled={props.status} 
                                                required 
                                                onKeyUp={evt => updatePreview(questionIndex)} 
                                                name={`answer_${answerIndex}_q${questionIndex}`} 
                                                value={answer.answer}
                                                onChange={evt => {
                                                    const newQuestion = {...question};
                                                    newQuestion.answerList = [...newQuestion.answerList];
                                                    newQuestion.answerList[answerIndex].answer = evt.target.value;
                                                    const newQuestions = [...props.questions];
                                                    newQuestions[questionIndex] = newQuestion;
                                                    props.setQuestionsObject(newQuestions);
                                                }}
                                                className="form-control" 
                                                rows="1" 
                                                placeholder="Enter an answer"></textarea>
                                            {
                                                (props.formSubmitted && !answer.answer.length) ? <div className="error-field">Please fill out this field</div> : ''
                                            }
                                        </div>
                                        <div className={["form-group", question.answerList.length <= 2 || props.status ? 'col-md-4 col-xs-10' : '', question.answerList.length > 2 && !props.status ? 'col-md-3 col-xs-5' : ''].join(" ")}>
                                            <label>Score</label>
                                            <input 
                                                disabled={props.status} 
                                                type="number" 
                                                min="0"
                                                value={answer.points} 
                                                onChange={evt => {
                                                    const newQuestion = {...question};
                                                    newQuestion.answerList = [...newQuestion.answerList];
                                                    newQuestion.answerList[answerIndex].points = evt.target.value;
                                                    const newQuestions = [...props.questions];
                                                    newQuestions[questionIndex] = newQuestion;
                                                    props.setQuestionsObject(newQuestions);
                                                }}
                                                name={`score${answerIndex}_q${questionIndex}`}
                                                className="form-control" 
                                                placeholder="Enter the score" />
                                        </div>
                                        <div className="col-md-1 col-xs-2 form-group text-center checkbox-inline">
                                            <br />
                                            <input 
                                                disabled={props.status} 
                                                type="checkbox" 
                                                checked={answer.correctAnswer}
                                                value={answer.correctAnswer}
                                                onChange={evt => {
                                                    const newQuestion = {...question};
                                                    newQuestion.answerList = [...newQuestion.answerList];
                                                    newQuestion.answerList[answerIndex].correctAnswer = evt.target.checked;
                                                    const newQuestions = [...props.questions];
                                                    newQuestions[questionIndex] = newQuestion;
                                                    props.setQuestionsObject(newQuestions);
                                                }}
                                                name={`correct${answerIndex}_q${answerIndex}`} />
                                        </div>
                                        {
                                            (question.answerList.length > 2 && !(props.status)) ? (
                                                <div className="col-md-2 col-xs-5 form-group">
                                                    <label className="col-xs-12">&nbsp;</label>
                                                    <div className="full-btn btn btn-lg btn-no-min btn-primary" onClick={evt => deleteAnswer(questionIndex,answerIndex)}><i className="fa fa-lg fa-trash"></i></div>
                                                </div>
                                            ) : ""
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        
                    
                    </div>
                ))
            }
            </div>
        )
}