import React from 'react'

export default function DeleteModal(props) {

	return (
		<React.Fragment>
			<div className="modal-header" data-testid="modal-window">
				<i data-testid="modal-close" className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close"
					onClick={props.hide}
				></i>
			</div>

			<div className="modal-body">
				<p>{props.message}</p>
			</div>

			<div className="modal-footer">
				<button data-testid="modal-action" type="button" className="btn btn-primary btn-lg btn-block-xs" 
					onClick={props.action}
				>Delete</button>
				<button type="button" className="btn btn-default btn-lg btn-block-xs" 
					onClick={props.hide}
				>Cancel</button>
			</div>
		</React.Fragment>
	)
}
