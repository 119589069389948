import React, { useEffect, useReducer, useRef } from 'react'
import MultiSelect from 'react-multi-select-component'
import SmartlistAPI from '../../models/smartlist'
import Helpers from '../../helpers/helpers'

const axios = require('axios');

const initialState = {
  attributeData: [],
  smartListData: {},
  smartruletoggle: false,
  smartListRules: [],
  smartFormula: {},
  smartFormulaError: '0'
};

function reducer(state, action) {
  switch (action.type) {
    case 'setAttributeData':
      return {
        ...state, 
        attributeData: action.payload
      };
    case 'setSmartListData':
      return {
        ...state, 
        smartListData: action.payload
      };
    case 'setSmartruletoggle':
      return {
        ...state, 
        smartruletoggle: action.payload
      };
    case 'setSmartListRules':
      const formula = (action.formula) ? action.formula : {...state.smartFormula};
      return {
        ...state, 
        smartListRules: action.payload,
        smartFormula: formula
      };
    case 'createSmartListRules':
      return {
        ...state, 
        smartListRules: [action.payload]
      };
    case 'setAttributeSmartListRules':
      let listAttr = [...state.smartListRules];
      listAttr[action.key] = action.payload;
      return {
        ...state,
        smartListRules: [...listAttr]
      };
    case 'setOperatorSmartListRules':
      let listOper = [...state.smartListRules];
      listOper[action.key] = {
        ...state.smartListRules[action.key],
        ...action.payload
      };
      return {
        ...state,
        smartListRules: [...listOper]
      };
    case 'setValueSmartListRules':
      let listValue = [...state.smartListRules];
      listValue[action.key][action.name] = action.payload;
      return {
        ...state,
        smartListRules: [...listValue]
      };
    case 'getMultiSmartListRules':
      let listMulti = [...state.smartListRules];
      listMulti[action.key]['value'] = action.value;
      listMulti[action.key]['multiValue'] = action.multi;    
      return {
        ...state,
        smartListRules: [...listMulti]
      };
    case 'setSmartFormula':
      return {
        ...state, 
        smartFormula: action.payload
      };
    case 'addSmartListRules':
      return {
        ...state, 
        smartListRules: [...state.smartListRules, action.add],
        smartFormula: action.formula
      };
    case 'removeSmartListRules':
      return {
        ...state, 
        smartListRules: state.smartListRules.filter((e,i) => i !== action.key),
        smartFormula: action.formula
      };
    case 'setSmartFormulaError':
      return {
        ...state, 
        smartFormulaError: action.payload
      };
    default:
      throw new Error();
  }
}

export default function SmartListFilter(props) {
  const gettingMetaData = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  /** Calendar */
  // TODO
  // const dateFormat = 'MMM dd, yyyy';
  // const status = useRef({
  //     openedStart: [],
  //     openedEnd: []
  // });

  useEffect(() => {
    if (state.attributeData.length === 0 && !gettingMetaData.current) {
      getSmartListMetadata();
    }
    return () => { gettingMetaData.current = true };
  });

  useEffect(() => {
    if (state.attributeData.length > 0 && state.smartListData.smartListsName) {
      setExistingRules(state.smartListData);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.smartListData, state.attributeData]);

  useEffect(() => {
    if(props.add) {
      addFilter()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.add]);

  useEffect(() => {
    if (props.save) {
      save();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.save]);

  useEffect(() => {
    if (!props.create && props.data.smartListsName) {
      dispatch({type: 'setSmartListData', payload: props.data});
    } else if (props.create && state.smartListRules.length === 0) {
      addFilter();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.create, props.data]);

  function getSmartListMetadata() {
    //gettingMetaData.current = true;
    SmartlistAPI().findMetadata()
    .then((data) => {
      let subscriberAttributes = data.data.data.subscriberAttributes;
      let list = [];
      for (var attr in subscriberAttributes) {
        list.push({
          value: subscriberAttributes[attr].dbLabel,
          label: subscriberAttributes[attr].uiLabel,
          input: subscriberAttributes[attr].type,
          operators: subscriberAttributes[attr].operators
        });
      }
      let sortedList = list.sort((a, b) => a.label.localeCompare(b.label));
      dispatch({type: 'setAttributeData', payload: sortedList});
    })
    .catch(err => {
      props.errorFunc(err);
    });
  }

  /** delete filter row */
  function deleteFilter(params) {
    params.event.preventDefault();
    params.event.stopPropagation();
    dispatch({type: 'removeSmartListRules', key: params.key, formula: setupFormula(state.smartListRules.length-1)});
  };

  /** add filter row */
  function addFilter() {
    dispatch({type: 'addSmartListRules', add: initializeRow(), formula: setupFormula(true)});
  };

  /** set up filter when attribute changes */
  function attributeListChange(params) {
    let attrData = state.attributeData[getIndexOf(state.attributeData, params.event.target.value, 'value')]
    let payload = initializeRow(params.key, attrData.operators[0], attrData, attrData.input);
    payload.operator = {
      operatorName: 'select'
    };
    payload.value = [];
    payload.multiValue = [];
    payload.othervalue = [];
    payload.multiIn = [];
    dispatch({type: 'setAttributeSmartListRules', payload: payload, key: params.key});
  };

  /** set up filter when operator changes */
  function operatorChange(params) {
    let operator = state.smartListRules[params.key].attr.operators[params.event.target.selectedIndex - 1];
    let payload = {
      operator : operator,
      value : [],
      multiValue : [],
      othervalue : [],
      multiIn : []
    }
    if (operator.requiredValues < -1) {
      setMultiValues({current: payload, key: params.key, change: true });
    } else {
      dispatch({type: 'setOperatorSmartListRules', payload: payload, key: params.key});
    }
  };

  function setMultiValues(params) {
    let rules = params.current;
    let key = params.key;
    let calls = [];

    if (Array.isArray(key)) {
      for(let x in key) {
        calls.push(getSubAttrValues(rules[x]));
      }
    } else {
      if (params.change) {
        calls.push(getSubAttrValues(state.smartListRules[key]));
      } else {
        calls.push(getSubAttrValues(rules[key]));
      }
    }

    axios.all(calls).then((data) => {
      if (params.change) {
        rules.multiIn = returnSubAttrValues(data[0].data.data, state.smartListRules[key]);
        rules.multiValue = [];
        rules.multiIn.map((item) => {
          if (item.ticked) {
            rules.multiValue.push(item);
          }
          return rules.multiValue;
        });
        dispatch({type: 'setOperatorSmartListRules', payload: rules, key: params.key});
      } else {
        for(let y in data){
          let k = (Array.isArray(key)) ? key[y] : key;
          rules[k].multiIn = returnSubAttrValues(data[y].data.data, rules[k]);
          rules[k].multiValue = [];
          rules[k].multiIn.map((item) => {
            if (item.ticked) {
              rules[k].multiValue.push(item);
            }
            return rules[k].multiValue;
          });
        }
        if (params.formula) {
          dispatch({type: 'setSmartListRules', payload: rules, formula: params.formula});
        } else {
          dispatch({type: 'setSmartListRules', payload: rules});
        }        
      }
    })
    .catch(err => {
      props.errorFunc(err);
    });
  }

  function valueChange(params) {
    let key = 'value';
    let value = params.event.target.value;
    if (params.other) {
      key = 'othervalue';
    }
    if (params.date) {
      value = new Date(`${params.event.target.value}T00:00:00`).getTime()
    }
    dispatch({type: 'setValueSmartListRules', payload: [value], key: params.key, name: key});
  };

  function ignoreYearChange(params) {
    let key = 'ignoreYear';
    let value = params.event.target.checked;
    dispatch({type: 'setValueSmartListRules', payload: value, key: params.key, name: key});
  };

  /** Set the saved rules for the current filter */
  function setExistingRules(existing) {
    if (existing.rules !== undefined) {
      let ruleLength = existing.rules.length;
      let currentRules = [];
      let multipleOptions = [];

      if (state.attributeData.length > 0 && ruleLength > 0) {
        for (var rule in existing.rules) {
          /** gets current rule and populates fields */
          var currentRule = existing.rules[rule];
          var objAttr = state.attributeData[getIndexOf(state.attributeData, currentRule.attributeDBName, 'value')];
          var objOper = objAttr.operators[getIndexOf(objAttr.operators, currentRule.operatorName, 'operatorName')];
          var objValue = currentRule.values; //currentRule.values[0]
          var objIgnoreYear = currentRule.ignoreYear;
          var objValueOther = [];

          /** checks if rules has a 'between' condition */
          if (objOper && objOper.requiredValues && objOper.requiredValues === 2) {
            objValue = [currentRule.values[0]];
            objValueOther = [currentRule.values[1]];
          }

          /** initialize filter row with data */
          currentRules.push(initializeRow(rule, objOper, objAttr, objAttr.input, objValue, objValueOther, objIgnoreYear));
        }
      } else {
        /** blank initialize of filter row */
        currentRules.push(initializeRow(0)); 
      }

      currentRules.map((item, key) => {
        if (item.operator.requiredValues && item.operator.requiredValues === -2) {
          return multipleOptions.push(key);
        }
        return false;
      });

      const existingFormula = {
        status: 'valid',
        rule: existing.rulesFormula
      }
      
      if (multipleOptions.length === 0) {
        dispatch({type: 'setSmartListRules', payload: currentRules, formula: existingFormula});
      } else if (multipleOptions.length === 1) {
        setMultiValues({current: currentRules, key: multipleOptions[0], formula: existingFormula});
      } else {
        setMultiValues({current: currentRules, key: multipleOptions, formula: existingFormula});
      }
    }
  };

  function setupFormula(added) {
    let ruleFormula = state.smartFormula.rule;
    let totalRules = (added !== true) ? added : state.smartListRules.length;
    let rule = '';

    if (added !== true || ruleFormula === undefined) {
      rule = '1';
      if (totalRules > 0) {
        for (var x=1;x<totalRules;x++) {
          rule += ' AND ';
          rule += (x + 1);
        }
      }
    } else if (added === true) {
      rule = ruleFormula + ' AND ' + (totalRules+1);
    } else {
      rule = state.smartListData.rulesFormula;
    }

    return {
      status: 'valid',
      rule: rule
    }
  };

  function addFormula(event) {
    event.preventDefault();
    let formula = state.smartFormula;
    formula.rule = event.target.value;
    dispatch({type: 'setSmartFormula', payload: formula});
  }

  function checkRuleFormula(event) {
    event.preventDefault();
    let params = {
      "maxRuleNumber": state.smartListRules.length,
      "segmentExpression": event.target.value.replace(/&nbsp;/gi,'')
    }
    SmartlistAPI().validateExpression(params)
    .then((data) => {
      let formula = state.smartFormula;
      let errormsg = '';
      let checkstatus = 'valid';
      if (data.data.data.valid === true && data.data.data.messages.length > 0) {
        checkstatus = 'warning';
        errormsg = data.data.data.messages[0];
      } else if (data.data.data.valid === false) {
        checkstatus = 'invalid';
        errormsg = data.data.data.messages[0];
      }
      formula.status = checkstatus;
      //setSmartFormula(formula);
      dispatch({type: 'setSmartFormula', payload: formula});
      //setSmartFormulaError(errormsg);
      dispatch({type: 'setSmartFormulaError', payload: errormsg});
    })
    .catch(err => {
      params.errorFunc(err);
    });
  }

  /** set up filter row with options */
  function initializeRow(current, operator, attr, type, value, othervalue, ignoreYear) {
    return {
      operator: operator || '',
      attr: attr || {value: 'select'},
      input: type || 'TEXT',
      removed: false,
      ignoreYear: ignoreYear,
      value: value || [],
      othervalue: othervalue || [],
      multiIn: []
    }
  };

  /** make the call to get object for multiselect */
  function getSubAttrValues(obj) {
    var params = { attributeName: obj.attr.value };
    return SmartlistAPI().findSubscriberAttributeValues(params);
  };

  /** return the object for the multiselect */
  function returnSubAttrValues(obj, select) {
    if (obj.values[0] !== null) {
      let newObj = [];
      let tickedObjs = select.value;
      for (var subAttr in obj.values) {
        let ticked = false;
        for (var tickedObj in tickedObjs) {
          if (tickedObjs[tickedObj] === obj.values[subAttr]) {
            ticked = true;  
          } else if (typeof tickedObjs[tickedObj] === "object") {
            if (tickedObjs[tickedObj].name === obj.values[subAttr].name && tickedObjs[tickedObj].type === obj.values[subAttr].type)
              ticked = true;
          }
        }
        newObj.push({
          label: select.input === "CATEGORY" ? obj.values[subAttr].type + ": " + obj.values[subAttr].name : obj.values[subAttr],
          value: obj.values[subAttr],
          ticked: ticked
        });
      }
      return newObj.sort((a, b) => a.label.localeCompare(b.label));
    }
    return false;
  };

  // function openStart($event, current) {
  //   status.openedStart[current] = true;
  // };

  // function openEnd($event, current) {
  //   status.openedEnd[current] = true;
  // };

  /** Helpers */
  function getIndexOf(arr, val, prop) {
    var l = arr.length;
    for (var k = 0; k < l; k = k + 1) {
      if (arr[k][prop] === val)
        return k;
    }
    return false;
  }

  function save() {
    props.saveFunc({rules:state.smartListRules, formula: state.smartFormula});
  }

  function getMulti(params) {
    let keyValueData = [];
    let keyMultiData = params.value;
    params.value.map((item) => {
      return keyValueData.push(item.value);
    });
    dispatch({type: 'getMultiSmartListRules', value: keyValueData, multi: keyMultiData, key: params.key});
  }

  return (
    <React.Fragment>
      <div data-testid="smartlist-rule" className="form-group smartrule-input">
        {!state.smartruletoggle && state.smartListRules.length > 1 &&
          <div className="text-right smartrule-input-toggle">
            <label className="checkbox-inline">
              <input 
                type="checkbox" 
                onClick={() => dispatch({type: 'setSmartruletoggle', payload: true })}
                defaultValue="false"
                name="smartruletoggle" /> 
                Show Advanced Rule Setup
            </label>
          </div>
        }
        {state.smartruletoggle &&
          <div className="smartrule-input-field">
            <label htmlFor="rule">Rule</label> <span className="pull-right">Use row numbers with 'AND', 'OR' and brackets '()'</span>

            {state.smartFormula && state.smartFormula.rule &&
              <div className={`form-control textinput fa ${state.smartFormula.status === 'valid' ? 'fa-check' : ''} ${state.smartFormula.status === 'invalid' ? 'fa-close' : ''} ${state.smartFormula.status === 'warning' ? 'fa-warning' : ''}`} >
              <input type="text" className="form-control textinput fa"
                value={state.smartFormula.rule}
                onChange={addFormula}
                onBlur={checkRuleFormula} />
              </div>
            }

            {(state.smartFormula.status === 'invalid' || state.smartFormula.status === 'warning') &&
              <div className={`error-field ${(state.smartFormula.status === 'warning' ? 'error-warning' : '')}`}>{state.smartFormulaError}</div>
            }
            <p>Last saved rule: {state.smartListData.rulesFormula}</p>
          </div>
        }
      </div>

      <div data-testid="filter-rules">
        {state.smartListRules && state.smartListRules.length > 0 && state.smartListRules.map((item, rulesKey) =>
          <div className="row row-bordered smartlist-rules clearfix" key={rulesKey}>
            <div className="col-md-1 smartlist-rules-row smartlist-rules-row-number">
              {rulesKey + 1}
            </div>
            <div className="col-md-11 smartlist-rules-row smartlist-rules-row-input">
              <div className="row">
                {state.smartListRules.length > 1 &&
                  <div className="col-md-2 col-xs-12 text-right pull-right form-group">
                    <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                    <button
                      type="button"
                      className="full-btn btn btn-lg btn-no-min btn-primary" 
                      onClick={(event) => deleteFilter({event: event, key: rulesKey})}>
                        <i className="fa fa-lg fa-trash"></i>
                    </button>
                  </div>
                }
                <div className={`form-group ${state.smartListRules.length > 1 ? 'col-md-3' : ''} ${state.smartListRules.length <= 1 ? 'col-md-4' : ''}`}>
                  {state.attributeData.length > 0 &&
                    <div data-testid="meta-attributes" ng-class="{'empty': smartListRules[count].attr === ''}">
                      <label htmlFor={`smartlistrulestype-${rulesKey}`}>Attribute</label>
                      <div className="styled-select">
                        <select 
                          onChange={(event) => attributeListChange({event: event, key: rulesKey})}
                          name={`smartlistrulestype-${rulesKey}`}
                          value={item.attr.value}>
                          <option value="select" disabled>Select an attribute</option>
                          {state.attributeData.length > 0 && state.attributeData.map((i, key) =>
                            <option value={i.value} key={key}>{i.label}</option>
                          )}
                        </select>
                      </div>
                    </div>
                  }
                </div>
                <div className={`form-group ${state.smartListRules.length > 1 ? 'col-md-3' : ''} ${state.smartListRules.length <= 1 ? 'col-md-4' : ''}`}>
                  <label htmlFor={`smartlistrulescompare-${rulesKey}`}>Operator</label>
                    {item.operator.operatorName &&
                      <div className="styled-select">
                        <select
                          data-testid="smartlist-filter-operator-select"
                          onChange={(event) => operatorChange({event: event, key: rulesKey})}
                          name={`smartlistrulescompare-${rulesKey}`} 
                          value={item.operator.operatorName}>
                          <option value="select" disabled defaultValue>Select an operator</option>
                          {item.attr.operators.map((i, key) => 
                            <option value={i.operatorName} key={key}>{i.operatorName}</option>
                          )}
                        </select>
                      </div>
                    }
                    {!item.operator.operatorName &&
                      <input 
                        data-testid="smartlist-filter-operator-none"
                        type="text" 
                        className="form-control" 
                        disabled />
                    }
                </div>
                <div className={`form-group ${item.value === '' ? 'empty' : ''} ${item.operator.requiredValues === 2 ? 'col-md-2' : ''} ${item.operator.requiredValues !== 2 ? 'col-md-4' : ''}`}>
                  <label htmlFor="campaignsFilter">Value{item.operator.requiredValues === 2 ? 's' : ''}</label>
                  {item.operator.fieldValueType === 'DatePicker' &&
                    <div className="">
                      <input required 
                        name="startDate" 
                        placeholder="Select a date" 
                        type="date" 
                        className="form-control" 
                        value={Helpers().FormatDate(item.value[0])}
                        onChange={(event) => valueChange({event: event, date: true, key: rulesKey})}
                        is-open="status.openedStart[count]" 
                        datepicker-options="dateOptions" 
                        ng-required="true" 
                        close-text="Close" 
                        ng-focus="openStart($event, count)" 
                        show-weeks="false" 
                        show-button-bar="false" />
                    </div>
                  }
                  {item.operator.fieldValueType === 'Number' &&
                    <div className="sstyled-select">
                      {item.multiIn.length > 0 && item.operator.requiredValues === -2 &&
                        <MultiSelect
                          options={item.multiIn}
                          value={item.multiValue}
                          onChange={(val) => getMulti({value: val, key: rulesKey})}
                          labelledBy={"Select"}
                        />
                      }
                      {item.operator.requiredValues !== -2 &&
                        <input
                          type="number" 
                          min="1" 
                          value={item.value[0]}
                          onChange={(event) => valueChange({event: event, key: rulesKey})}
                          className="form-control ng-pristine ng-valid ng-touched" 
                          placeholder={(item.operator.suffix !== '') ? item.operator.suffix : 'Enter a number'} 
                        />
                      }
                    </div>
                  }
                  {item.operator.fieldValueType === 'Text' &&
                    <div className="sstyled-select">
                      {item.multiIn.length > 0 && item.operator.requiredValues === -2 &&
                        <MultiSelect
                          data-testid="smartlist-filter-text-multi"
                          options={item.multiIn}
                          value={item.multiValue}
                          onChange={(val) => getMulti({value: val, key: rulesKey})}
                          labelledBy={"Select"}
                        />
                      }
                      {item.operator.requiredValues !== -2 &&
                        <input 
                          type="text" 
                          value={item.value[0]}
                          onChange={(event) => valueChange({event: event, key: rulesKey})}
                          className="form-control ng-pristine ng-valid ng-touched" 
                          placeholder="Enter a value" 
                        />
                      }
                    </div>
                  }
                  {(item.operator.fieldValueType !== 'DatePicker' && item.operator.fieldValueType !== 'Number' && item.operator.fieldValueType !== 'Text') &&
                    <input 
                      data-testid="smartlist-filter-value-none"
                      type="text" 
                      className="form-control" 
                      disabled />
                  }
                  {item.operator.showIgnoreYear &&
                    <label className="checkbox-inline">
                      <input 
                        type="checkbox"
                        checked={item.ignoreYear}
                        onChange={(event) => ignoreYearChange({event: event, key: rulesKey})}/>
                      Ignore Year
                    </label>
                  }
                </div>
                {item.operator.requiredValues === 2 &&
                  <div className="col-md-2 form-group" ng-class="{'empty': smartListRules[count].othervalue === ''}">
                    <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                    {item.operator.fieldValueType === 'DatePicker' &&
                      <div className="">
                        <input required 
                          name="endDate" 
                          placeholder="Select a date" 
                          type="date" 
                          className="form-control" 
                          value={Helpers().FormatDate(item.othervalue[0])}
                          onChange={(event) => valueChange({event: event, date: true, key: rulesKey, other: true})}
                          is-open="status.openedEnd[count]" 
                          datepicker-options="dateOptions" 
                          ng-required="true" 
                          close-text="Close" 
                          ng-focus="openEnd($event, count)" 
                          show-weeks="false" 
                          show-button-bar="false" />
                      </div>
                    }
                    {item.operator.fieldValueType === 'Number' &&
                      <div className="styled-select">
                        <input 
                          type="number" 
                          min="1" 
                          value={item.othervalue[0]} 
                          onChange={(event) => valueChange({event: event, key: rulesKey, other: true})}
                          className="form-control ng-pristine ng-valid ng-touched" 
                          placeholder={(item.operator.suffix !== '') ? item.operator.suffix : 'Enter a number'} />
                      </div>
                    }
                    {item.operator.fieldValueType === 'Text' &&
                      <div className="styled-select">
                        <input 
                          type="text" 
                          value={item.othervalue[0]}  
                          onChange={(event) => valueChange({event: event, key: rulesKey, other: true})}
                          className="form-control ng-pristine ng-valid ng-touched" 
                          placeholder="Enter a value" />
                      </div>
                    }
                    {(item.operator.fieldValueType !== 'DatePicker' && item.operator.fieldValueType !== 'Number' && item.operator.fieldValueType !== 'Text') &&
                      <input 
                        data-testid="smartlist-filter-other-none"
                        className="form-control" 
                        type="text" 
                        disabled />
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>

  );
}