import React from 'react'

export default function ResponseMessage(props) {
  return (
    <React.Fragment>
      {props.validation &&
        <div data-testid="response-message" className={`${props.class} form-group`}>
          {props.message}
        </div>
      }
    </React.Fragment>
  )
}
