import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import settings from "../config/settings"

import Home from '../pages/dashboard/Home'
import Reports from '../pages/reports/Reports'
import Subscriptions from '../pages/subscribers/Subscriptions'
import SubscriptionDetails from '../pages/subscribers/SubscriptionDetails'
import SmartLists from '../pages/subscribers/SmartLists'
import SmartListRules from '../pages/subscribers/SmartListRules'
import Campaigns from '../pages/campaigns/Campaigns'
import JourneyTypeForm from '../pages/journey/JourneyTypeForm'
import CorrespondenceForm from '../pages/correspondence/CorrespondenceForm'
import ContestForm from '../pages/contest/ContestForm'
import TriviaForm from '../pages/trivia/TriviaForm'
import SmsLibrary from '../pages/sms/Library'
import SmsMessage from '../pages/sms/Message'
import SettingsClients from '../pages/settings/Clients'
import SettingsUsers from '../pages/settings/Users'
import SettingsShortCodes from '../pages/settings/ShortCodes'
import SettingsContacts from '../pages/settings/Contacts'
import GatewayClients from '../pages/gw_clients/GatewayClients'
import ProgramList from '../pages/programs/ProgramList'

export default function Routes() {
	return (
	  <Switch>
			<Route exact path={`${settings.subPath}/home`} component={Home} />
			<Route exact path={`${settings.subPath}/campaigns`} component={Campaigns} />
			<Route exact path={`${settings.subPath}/reports`} component={Reports} />
			<Route exact path={`${settings.subPath}/subscribers/subscriptions`} component={Subscriptions} />
			<Route exact path={`${settings.subPath}/subscribers/subscriptions/:id`} component={SubscriptionDetails} />
			<Route exact path={`${settings.subPath}/subscribers/smartlists`} component={SmartLists} />
			<Route exact path={`${settings.subPath}/subscribers/smartlists/:id`} component={SmartListRules} />
			<Route exact path={`${settings.subPath}/subscribers/smartlists/create`} component={SmartListRules} />
			<Route exact path={`${settings.subPath}/campaigns`} component={Campaigns} />
			<Route exact path={`${settings.subPath}/simpleresponse/new/:campaignId/:campaignInd`} component={JourneyTypeForm} />
			<Route exact path={`${settings.subPath}/simpleresponse/:journeyId/:campaignId/:campaignInd`} component={JourneyTypeForm} />
			<Route exact path={`${settings.subPath}/journey/new/:campaignId/:campaignInd`} component={JourneyTypeForm} />
			<Route exact path={`${settings.subPath}/journey/:journeyId/:campaignId/:campaignInd`} component={JourneyTypeForm} />
			<Route exact path={`${settings.subPath}/correspondences/new/:campaignId/:campaignInd`} component={CorrespondenceForm} />
			<Route exact path={`${settings.subPath}/correspondences/:triggerId/:campaignId/:campaignInd`} component={CorrespondenceForm} />
			<Route exact path={`${settings.subPath}/contest/new/:campaignId/:campaignInd`} component={ContestForm} />
			<Route exact path={`${settings.subPath}/contest/:contestId/:campaignId/:campaignInd`} component={ContestForm} />
			<Route exact path={`${settings.subPath}/trivia/new/:campaignId/:campaignInd`} component={TriviaForm} />
			<Route exact path={`${settings.subPath}/trivia/:triviaId/:campaignId/:campaignInd`} component={TriviaForm} />
			<Route exact path={`${settings.subPath}/smslibrary`} component={SmsLibrary} />
			<Route exact path={`${settings.subPath}/smslibrary/:messageId`} component={SmsMessage} />
			<Route exact path={`${settings.subPath}/smslibrary/create`} component={SmsMessage} />
			<Route exact path={`${settings.subPath}/settings/clients`} component={SettingsClients} />
			<Route exact path={`${settings.subPath}/settings/users`} component={SettingsUsers} />
			<Route exact path={`${settings.subPath}/settings/shortcodes`} component={SettingsShortCodes} />
			<Route exact path={`${settings.subPath}/settings/contacts`} component={SettingsContacts} />
			<Route exact path={`${settings.subPath}/gateway/accounts`} component={GatewayClients} />
			<Route exact path={`${settings.subPath}/gateway/programs`} component={ProgramList} />
			<Redirect to={`${settings.subPath}/home`} />
	  </Switch>
	)
}
