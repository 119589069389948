import React from "react";
import useModal from "../hooks/modal";
import Modal from "../components/Modal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let { isModalShowing, toggleModal, modalBody, setModalContent, modalSize, setModalWidth } = useModal();
  return (
    <Provider value={{ isModalShowing, toggleModal, modalBody, setModalContent, modalSize, setModalWidth }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
