import React from 'react'

export default function RadioButton(props) {
    const items = props.values.map(item => ({value: item, selected: props.value === item}));

    const updateSelected = (event) => {
        event.preventDefault();
        const el = event.target;
        if (el.nodeName === "LABEL" && !el.classList.contains('selected')) {
            const labels = el.closest('.radio-button').querySelectorAll('label');
            labels.forEach(lbl => lbl.classList.remove('selected'));
            el.classList.add('selected');
            props.setValue(el.textContent);
        }
    }

    return (
        <span className="radio-button">
            {
                items.map(val => (
                    <label key={val.value} className={val.selected ? 'selected' : ''} onClick={updateSelected}>
                        <input name="codeType" type="radio" value={val.value} />
                        {val.value}
                    </label>
                ))
            }
        </span>
    )
}
