import API from '../services/api'

export default function ContactsAPI() {

    var Contact = {};

    Contact.findAll = function () {
        return API.findAll('getAdminNotificationContacts');
    };

    Contact.save = function (contactData) {
        return API.save('saveAdminNotificationContact', contactData);
    };

    Contact.destroy = function (contactId) {
        return API.destroy('deleteAdminNotificationContact', 'adminNotificationContactId', contactId);
    };

    return Contact;

}
