import React from 'react'

export default function DeleteFolderModal(props) {
  let check = false;
  const setCheck = (event) => {
    check = event.target.checked;
  }
  return (
    <React.Fragment>
			<div className="modal-header">
				<i className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close"
					onClick={props.hide}
				></i>
			</div>

      <form noValidate name="form">
        <div className="modal-body">
          <p>{props.message}</p>
          <label className="checkbox-inline">
            <input type="checkbox" onClick={setCheck} />
            Remove all contents of the folder as well.
          </label>
        </div>

        <div className="modal-footer">
          <button type="submit" className="btn btn-primary btn-lg btn-block-xs" onClick={event => props.action({event: event, checkbox: check})}>Delete</button>
          <button type="button" className="btn btn-default btn-lg btn-block-xs" onClick={props.hide}>Cancel</button>
        </div>
      </form>
    </React.Fragment>
  )
}