import React, { useEffect } from 'react';
import TriviaAPI from '../../models/trivia'
import settings from '../../config/settings'
import { ModalContext } from '../../context/modal'
import DeleteCommunicationModal from '../modals/campaigns/DeleteCommunicationModal'

export default function TriviaPanel(props) {
    let { toggleModal, setModalContent } = React.useContext(ModalContext);

    useEffect(() => {
        // console.log('SimpleResponsePanel', props.expand);
    }, [props.expand]);
    
    const renderDeleteTriviaModal = (data) => {
        setModalContent(DeleteCommunicationModal({
            hide: () => toggleModal(false),
            success: () => {toggleModal(false);},
            rerender: p => renderDeleteTriviaModal(p),
            action: (type, payload) => {
                TriviaAPI().destroy(data.id)
                    .then(res => {
                        if (res.data.statusCode === 200) {
                            toggleModal(false);
                            props.deleteCommunication(data.cId, data.id, 'TRIVIA');
                        } else {
                            props.setError(res.data.message);
                        }
                    })
                    .catch(res => {
                        props.setError(res.data.message);
                    });
            },
            name: (data && data.name) || '',
            type: 'Trivia',
            error: (data && data.error) || '',
            campaign: (data && data.campaign) || {}
        }));
        toggleModal(true);
    }


    return (
        <div className="panel-section-content">
            <div className="panel panel-default panel-section-header">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-md-4 col-xs-12">Trivia</div>
                        <div className="col-md-8 hidden-xs"></div>
                    </div>
                </div>
            </div>
            <div className="panel panel-default panel-section-records row">
                {
                    props.data.map(trivia => (
                        <div key={'trivia' + trivia.triviaId} className={["row panel-section-record", props.communicationStatusClasses[trivia.status], props.expand].join(" ")}>
                            <div className="col-md-1"></div>
                            <div className="col-md-11 panel-section-record-row">
                                {/* <div className="panel-heading panel-heading-campaign row record" onClick={_ => props.setRedirectTo(`${settings.subPath}/trivia/${trivia.triviaId}/${props.campaignId}/${props.index}`)}> */}
                                <div className="panel-heading panel-heading-campaign row record" onClick={_ => props.renderCommunicationModal(props.campaignId, props.index, trivia.triviaId)}>
                                    <div className="col-md-3 col-xs-8 highligted-text">{trivia.name}</div>
                                    <div className="col-md-2 hidden-sm hidden-xs nowrap">
                                        <span>{ trivia.keywordList.join(", ")}</span>
                                    </div>
                                    <div className="col-md-2 hidden-sm hidden-xs">{trivia.shortCodeId}</div>
                                    <div className="col-md-3 hidden-sm hidden-xs">{props.formatDateString(trivia.startDate)} &#8211; {props.formatDateString(trivia.endDate)}</div>
                                    <div className="col-md-2 col-xs-4 text-right">
                                        {
                                            (trivia.status === 'DISABLED') && (
                                                <span className="fa-wrap highligted-text-green">
                                                    <i className="fa fa-play fa-lg" onClick={evt => props.setCommunicationStatus(evt, trivia, 'TRIVIA')} title="Activate Trivia"></i>
                                                </span>
                                            )
                                        }
                                        {
                                            (trivia.status === 'DISABLED') && (
                                                <span className="fa-wrap">
                                                    <i className="fa fa-trash-o fa-lg" onClick={evt => {evt.stopPropagation(); renderDeleteTriviaModal({name: trivia.name, id:trivia._id, cId: trivia.campaignId})}} title="Delete Trivia"></i>
                                                </span>
                                            )
                                        }
                                            {(trivia.status === 'ACTIVE') && (
                                                <span className="fa-wrap highligted-text-red">
                                                    <i className="fa fa-stop fa-lg" onClick={evt => props.setCommunicationStatus(evt, trivia, 'TRIVIA')} title="Deactivate Trivia"></i>
                                                </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
