import React from 'react'

export default function ConfirmModal(props) {

	return (
		<span>
			<div className="modal-header">
			    <i onClick={props.hide} className="btn-close fa fa-times" aria-label="Close"></i>
			</div>

			<div className="modal-body">
			    <p>{props.message}</p>
			</div>

			<div className="modal-footer">
			    <button type="button" className="btn btn-primary btn-lg btn-block-xs" onClick={props.action}>Yes</button>
			    <button type="button" className="btn btn-default btn-lg btn-block-xs" onClick={props.hide}>No</button>
			</div>
		</span>
	)
}