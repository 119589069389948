import { useState } from 'react'

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState();

  function assignUser(user) {
    setAuthenticated(user);
  }

  return { authenticated, assignUser }
};