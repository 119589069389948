import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import settings from "./config/settings"
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import { AuthProvider } from "./context/auth"
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import './styles/bootstrap.min.css';
import './styles/font-awesome.min.css';
import './styles/main.css';
import './styles/momentum.css';
import './styles/modal.css';
import './styles/retina.css';
import './_sass/main_scss.scss';

Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Region
      region: settings.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: settings.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: settings.userPoolWebClientId
  }
});

ReactDOM.render(
  <AmplifyAuthenticator>
      <React.StrictMode>
        <AuthProvider>
          <App />
        </AuthProvider>
      </React.StrictMode>
    </AmplifyAuthenticator>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
