import React, {useReducer, useEffect } from 'react';
import MultiSelect from 'react-multi-select-component'
import KeywordInput from '../../components/KeywordInput'
import Question from './Question'
import ShortcodesAPI from '../../models/shortcode'
import TriviaAPI from '../../models/trivia';
import CampaignsAPI from '../../models/campaign';
import KeywordsAPI from '../../models/keyword';
import settings from '../../config/settings'
import { Redirect } from 'react-router-dom';

const initialState = {
            campaignId: '',
            triviaId: '',
            messagetype: '',
            error: "",
            success: "",
            isLoading: true,
            trivia: {
                name: "",
                status: "DRAFT",
                startDate: "",
                endDate: "",
                shortcode: [],
                correctResponse: "",
                incorrectResponse: "",
                invalidResponse: "",
                finalMessage: "",
                questionList: [],
                addedKeywords: []
            },
            form: {
                submitted: false,
                questionError: false
            },
            showrules: true,
            campaign: {
                minStartDate: "",
                maxStartDate: "",
                minEndDate: "",
                maxEndDate: ""
            },
            config: {
                shortcodes: []
            },
            shortCodes: [],
            removingKeywords: [],
            redirect: '',
            questions: [
                {
                    key: 'question001',
                    question: "",
                    time: {
                        meridian: "",
                        hours: '',
                        minutes: '',
                        date: ''
                    },
                    preview: "",
                    showPreview: false,
                    rdays: "",
                    rhours: "",
                    rminutes: "",
                    answerList: [
                        {
                            key: 'answer' + ((new Date()).getTime() - 1),
                            points: 0,
                            answer: "",
                            correctAnswer:false                               
                        },
                        {
                            key: 'answer' + (new Date()).getTime(),
                            points: 0,
                            answer: "",
                            correctAnswer:false                               
                        }
                    ],
                    delayType: ""
                }
            ]
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'setNew':
            return {
                ...state,
                campaign : action.payload.campaign,
                triviaDatePicker : action.payload.triviaDatePicker,
                campaignId: action.payload.campaignId,
                campaignInd : action.payload.campaignInd,
                config: {
                    ...state.config,
                    shortcodes: action.payload.config.shortcodes,
                },
                form: {
                    submitted: false,
                    questionError: false
                },
                showrules: true,
                removingKeywords: [],
                redirect: '',
                trivia: {
                    name: "",
                    status: "DRAFT",
                    startDate: "",
                    endDate: "",
                    shortcode: [],
                    correctResponse: "",
                    incorrectResponse: "",
                    invalidResponse: "",
                    finalMessage: "",
                    questionList: [],
                    addedKeywords: []
                },
                questions: [
                    {
                        key: 'question001',
                        question: "",
                        time: {
                            meridian: "",
                            hours: '',
                            minutes: '',
                            date: ''
                        },
                        preview: "",
                        showPreview: false,
                        rdays: "",
                        rhours: "",
                        rminutes: "",
                        answerList: [
                            {
                                key: 'answer' + ((new Date()).getTime() - 1),
                                points: 0,
                                answer: "",
                                correctAnswer:false                               
                            },
                            {
                                key: 'answer' + (new Date()).getTime(),
                                points: 0,
                                answer: "",
                                correctAnswer:false                               
                            }
                        ],
                        delayType: ""
                    }
                ]
            }
        case 'updateTriviaID':
            return{
                ...state,
                triviaId: action.payload
            }
        case 'updateForm':
            return {
                ...state,
                form:{
                    ...state.form,
                    [action.payload.key]: action.payload.value
                }
            }
        case 'updateTrivia':
            return {
                ...state,
                trivia: {
                    ...state.trivia,
                    [action.payload.key]: action.payload.value
                }
            }
        case 'updateQuestions':
            return {
                ...state,
                questions: action.payload.value
            }
        case 'deleteQuestions':
            const newQuestions = [...state.questions];
            newQuestions.splice(action.payload.questionIndex, 1);
            return {
                ...state,
                questions: newQuestions
            }
        case 'setError':
            return {
                ...state,
                success: '',
                error: action.payload,
                form: {
                    ...state.form,
                    submitted: true
                }
            }
        case 'setShowRules':
            return {
                ...state,
                showrules: action.payload.value
            }
        case 'setSuccess':
            return {
                ...state,
                success: 'Trivia successfully saved!',
                error: '',
                form: {
                    ...state.form,
                    submitted: false
                }
            }
        case 'addQuestions':
            var questionList = [...state.questions];
            questionList.push(action.payload.questions);
            return {
                ...state,
                questions: questionList
            }
        case 'updateUrl':
            return {
                ...state,
                redirect: action.payload
            }
        case 'setTrivia':
            return {
                ...state,
                trivia: {
                    ...state.trivia,
                    name: action.payload.trivia.name,
                    status: "DRAFT",
                    startDate: action.payload.trivia.startDate,
                    endDate: action.payload.trivia.endDate,
                    shortcode: action.payload.trivia.shortcode,
                    correctResponse: action.payload.trivia.correctResponse,
                    incorrectResponse: action.payload.trivia.incorrectResponse,
                    invalidResponse: action.payload.trivia.invalidResponse,
                    finalMessage: action.payload.trivia.finalMessage,
                    questionList: [],
                    addedKeywords: action.payload.trivia.addedKeywords,
                },
                campaignId: action.payload.campaignId,
                campaignInd: action.payload.campaignInd,
                triviaId: action.payload.triviaId,
                config: {
                    ...state.config,
                    shortcodes: action.payload.config.shortcodes,
                },
                questions: action.payload.questions,
                messagetype: action.payload.messagetype
            }
    }
}

export default function TriviaForm(props){
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const params = props;
        const payload = {
            isNew: (props.triviaId === 0 || props.triviaId === null) ? true : false,
            campaignId: parseInt(params.campaignId),
            campaignInd: parseInt(params.campaignInd),
            triviaId: parseInt(params.triviaId)
        };

        if (payload.isNew) {
            getMetaData(payload);
        } else {
            getTrivia(payload);
        }
    },[])

    const getMetaData = (params) => {
        Promise.all([
                ShortcodesAPI().findAll(), 
                CampaignsAPI().findAll()
            ]).then(resolves => {
                const newState = {
                    ...params, 
                    config: {
                        shortcodes: resolves[0].data.data.shortCodes.map(sc => {
                            sc.value = sc.number; 
                            sc.label = sc.number; 
                            return sc;
                        })
                    },
                    triviaDatePicker: [{}, {}],
                    kwInput: {},
                    campaign: {
                        minStartDate: "",
                        maxStartDate: "",
                        minEndDate: "",
                        maxEndDate: ""
                    }
                };
                let campaign = resolves[1].data.data.campaigns.filter(campaign => params.campaignId === campaign._id);
                if (campaign.length) {
                campaign = campaign[0];
                newState.campaign = {
                    startDate: campaign.startDate,
                    endDate: campaign.endDate
                }
            }

            newState.campaign.minStartDate = formatDateString(Math.max(newState.campaign.startDate, new Date()), 3);
            newState.campaign.maxStartDate = formatDateString(newState.campaign.endDate, 3);
            newState.campaign.minEndDate = newState.campaign.minStartDate;
            newState.campaign.maxEndDate = formatDateString(newState.campaign.endDate, 3);

            dispatch({type: 'setNew', payload: newState});
            }).catch(rsp => {
                dispatch({type: 'setError', payload: rsp && rsp.data && rsp.data.message});
                console.log(rsp);
            });
    }

    const getTrivia = (params) => {
        Promise.all([
            ShortcodesAPI().findAll(), 
            CampaignsAPI().findAll(),
            TriviaAPI().findAll()
        ]).then(resolves => {
            const newState = {
                ...state, 
                campaignId: params.campaignId,
                campaignInd: params.campaignInd,
                triviaId: params.triviaId,
                config: {
                    shortcodes: resolves[0].data.data.shortCodes.map(sc => {
                        sc.value = sc.number; 
                        sc.label = sc.number; 
                        return sc;
                    })
                },
                triviaDatePicker: [{}, {}],
                kwInput: {},
                questions: [],
                campaign: {
                    minStartDate: "",
                    maxStartDate: "",
                    minEndDate: "",
                    maxEndDate: ""
                }
            };
            let campaign = resolves[1].data.data.campaigns.filter(campaign => params.campaignId === campaign._id);
            if (campaign.length) {
            campaign = campaign[0];
            newState.campaign = {
                startDate: campaign.startDate,
                endDate: campaign.endDate
                }
            }

        newState.campaign.minStartDate = formatDateString(Math.max(newState.campaign.startDate, new Date()), 3);
        newState.campaign.maxStartDate = formatDateString(newState.campaign.endDate, 3);
        newState.campaign.minEndDate = newState.campaign.minStartDate;
        newState.campaign.maxEndDate = formatDateString(newState.campaign.endDate, 3);

        const trivia = resolves[2].data.data.allTrivia.filter(trivia => trivia.triviaId === params.triviaId);

        if (trivia.length) {
            //newState.data.trivia = trivia[0];
            newState.campaignId = trivia[0].campaignId;

            //set datepicker dates
            newState.trivia = {
                name: trivia[0].name,
                startDate: formatDateString(trivia[0].startDate,3),
                endDate: formatDateString(trivia[0].endDate,3),
                disabled: trivia[0].status === 'ACTIVE' || trivia[0].status === 'COMPLETED',
                shortcode: selectValue(newState.config.shortcodes, trivia[0].shortCodeId, "shortCodeId"),
                correctResponse: trivia[0].correctResponse,
                incorrectResponse: trivia[0].incorrectResponse,
                finalMessage: trivia[0].finalMessage,
                invalidResponse: trivia[0].invalidResponse,
                addedKeywords: trivia[0].keywordList
            }
            
            /** format data to fit in form */
            newState.messagetype = (trivia[0].status === 'ACTIVE' || trivia[0].status === 'COMPLETED') ? 'View ' : 'Edit '; 

            trivia[0].questionList.forEach((q, qkey) => {
                q.time = {
                    hours: "",
                    minutes: "",
                    meridian: "",
                    date: ""
                };
                q.rhours = "";
                q.rdays = "";
                q.rminutes = "";
                if (q.delayType === "TIMED_DELAY") {
                    q.rdays = parseInt(q.delayValue.substring(0, q.delayValue.indexOf("d")));
                    q.rhours = parseInt(q.delayValue.substring(q.delayValue.indexOf("d") + 1, q.delayValue.indexOf("h")));
                    q.rminutes = parseInt(q.delayValue.substring(q.delayValue.indexOf("h") + 1, q.delayValue.indexOf("m")));
                } else if (q.delayType === "DATE_DELAY") {
                    let hours = new Date(parseInt(q.delayValue)).getHours();
                    var d = new Date(parseInt(q.delayValue));
                    var month = '' + (d.getMonth() + 1);
                    var day = '' + d.getDate();
                    var year = d.getFullYear();

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    var str =  [year, month, day].join('-');
                    q.time = {
                        hours: hours > 12 ? (hours - 12).toString() : hours.toString(),
                        minutes: new Date(parseInt(q.delayValue)).getMinutes().toString(),
                        meridian: hours > 12 ? "PM" : "AM",
                        date: str
                    }
                    if(hours == 0){
                        q.time.hours = 12;
                        q.time.meridian = "PM";
                    }
                }
                
                q.preview = q.question;

                /** update previews and create as many question and answer rows as needed */
                if (qkey > 0) {
                    addQuestion();
                }

                q.answerList.forEach((a, akey) => {
                    //setTimeout(function() { $scope.$broadcast('addAnswer',{current:qkey}); }, 0);
                    q.preview += "\n" + String.fromCharCode(65 + parseInt(akey)) + ") " + a.answer;
                    if (a.points === -1)
                        a.points = undefined;
                });
            })

            newState.questions = trivia[0].questionList;
            newState.readonly = trivia.status === 'ACTIVE' || trivia.status === 'COMPLETED';
            newState.kwInput.readonly = newState.readonly;

            //for (let i = newState.trivia.addedKeywords.length - 1; i >= 0; i--) {
                //for (let j = 0; j < newState.kwInput.mandatoryKeywords.length; j++) {
                //    if (newState.trivia.addedKeywords[i] === newState.kwInput.mandatoryKeywords[j].text) {
                //        newState.trivia.addedKeywords.splice(i, 1);
                //    }
               // }
            //}
        }
        dispatch({type: 'setTrivia', payload: newState});
        }).catch(rsp => {
            dispatch({type: 'setError', payload: rsp && rsp.data && rsp.data.message});
            console.log(rsp);
        });
    }

    const showPreview = (index) => {
        dispatch({type: 'showPreview', payload: index});
    }

    const selectValue = (list, id) => {
        const value = list.filter(item => item._id === id);
        return value || '';
    }

    const formatDateString = (timestamp, type) => {
        let dateobj = timestamp ? new Date(timestamp) : new Date();

        if (type === 1) {
            const [m, d, y] = dateobj.toDateString().split(" ").slice(1);
            return `${m} ${d}, ${y}`; // Jan 01, 2020
        } else if (type === 2) {
            return dateobj.toISOString().substr(0,10); // yyyy-mm-dd
        } else if (type === 3) {
            return dateobj.toISOString().substr(0, 10); // 2020-12-31
        }
    }

    const isActiveOrComplete = _ => ["ACTIVE", "COMPLETED"].includes(state.trivia.status);

    const addQuestion = () => {
        const questionObject = {
            key: 'question' + (new Date()).getTime(),
            question: "",
            preview: "",
            time: {
                meridian: "",
                hours: '',
                minutes: '',
                date: ''
            },
            answerList: [
                {
                    key: 'answer' + ((new Date()).getTime() - 1),
                    points: 0,
                    answer: "",
                    correctAnswer: false                                
                },
                {
                    key: 'answer' + (new Date()).getTime(),
                    points: 0,
                    answer: "",
                    correctAnswer: false                                
                }
            ],
            delayType: ""
        };

        dispatch({type: 'addQuestions', payload: {questions: questionObject}})

        console.log('addQuestion');
    }

    const goToDashboard = () => {
        dispatch({type: 'updateUrl', payload: `${settings.subPath}/campaigns`});
    }

    const deleteQuestion = (questionIndexToRemove) => {
        dispatch({type: 'deleteQuestions', payload: {questionIndex: questionIndexToRemove}})
    }

    const setQuestionsObject = (newQuestionsObject) => {
        dispatch({type: 'updateQuestions', payload: {value: newQuestionsObject}})
    }

    const runKeywordRemove = () => {
        const p = new Promise((resolve, reject) => {
            if (state.removingKeywords.length) {
                const removeParams = {
                    keywords: state.removingKeywords
                }

                KeywordsAPI().removeKeyword(removeParams).then(_ => resolve()).catch(_ => reject())
            } else {
                resolve();
            }
        });

        return p;
    }

    const validateQuestionsTab = () => {
        var ret = true;
        if(state.questions.length){
            state.questions.forEach(question => {
                if(question.delayType === "DATE_DELAY" && question.time.date.length === 0){
                    if(question.time.meridian === "" ||
                       question.time.minutes === "" || 
                       question.time.hours === ""){
                            ret = false;}
                }
                if(question.delayType === "TIMED_DELAY"){
                    if(question.rdays === "" ||
                       question.rhours === "" ||
                       question.rminutes === ""){
                           ret = false;
                       }
                }
                    question.answerList.forEach((a) => {
                        if(a.answer.length === 0){
                            ret = false;
                        }
            })});
        }else{
            ret = false;
        }
        return ret;
    }

    const validateTriviaRulesTab = () => {
        var ret = true;
        if(state.trivia.name.length && 
            state.trivia.shortcode.length && 
            state.trivia.startDate.length && 
            state.trivia.endDate.length &&
            state.trivia.addedKeywords.length &&
            state.trivia.correctResponse.length &&
            state.trivia.incorrectResponse.length &&
            state.trivia.invalidResponse.length &&
            state.trivia.finalMessage.length ){
                dispatch({type: 'setShowRules', payload: {value:false}})
            }else{
                ret = false;
                dispatch({type: 'setShowRules', payload: {value:true}})
            }
            return ret;
    }

    const valid = () => {
        if(validateTriviaRulesTab()){
            return validateQuestionsTab();
        } else {
            return false;
        }
    }

    const ok = () => {
        dispatch({type: 'updateForm', payload: {key: "submitted", value: true}});
        const newState = {
            form: {
                ...state.form, 
                submitted: true
            }
        };

        if (valid()) {
            newState.success = "";

            const params = {
                type: "TRIVIA",
                campaignId: state.campaignId,
                name: state.trivia.name,
                startDate: (new Date(state.trivia.startDate)).getTime(),
                correctResponse: state.trivia.correctResponse,
                incorrectResponse: state.trivia.incorrectResponse,
                invalidResponse: state.trivia.invalidResponse,
                finalMessage: state.trivia.finalMessage,
                endDate: (new Date(state.trivia.endDate)).getTime(),
                shortCodeId: state.trivia.shortcode[0]._id,
                keywordList: state.trivia.addedKeywords,
                questionList: []
            };

            if (state.triviaId) {
                params.triviaId = state.triviaId;
            }

            state.questions.forEach(questionItem => {
                const question = {
                    question: questionItem.question,
                    delayType: questionItem.delayType,
                    answerList: [],
                    questionCode: questionItem.questionCode,
                    time: {
                        meridian: "",
                        hours: '',
                        minutes: '',
                        date: ''
                    },
                };

                if (question.delayType === "DATE_DELAY") {
                    if(questionItem.time.date.length > 0){
                        var date = new Date(questionItem.time.date).toISOString().substr(0,10).split("-");
                        question.time.date = (new Date(date[1] + '-' + date[2] + '-' + date[0]));
                        question.time.date.setHours((questionItem.time.meridian === "PM" ? (parseInt(questionItem.time.hours) + 12) : parseInt(questionItem.time.hours)));
                        question.time.date.setMinutes(parseInt(questionItem.time.minutes))
                        question.delayValue = question.time.date.getTime();
                    }
                }

                if (question.delayType === "RESPONSE_DELAY")
                    question.delayValue = null;
                else if (question.delayType === "TIMED_DELAY")
                    question.delayValue = `${questionItem.rdays}d${questionItem.rhours}h${questionItem.rminutes}m`;

                let answersCount = 0;
                questionItem.answerList.forEach((a, key) => {
                answersCount++;

                    question.answerList.push({
                        answer: a.answer,
                        points: a.points === null ? undefined : a.points,
                        prefix: String.fromCharCode(65 + parseInt(key)),
                        correctAnswer: a.correctAnswer,
                        answerCode: a.answerCode,
                    });
                });

                if (answersCount < 2) {
                    newState.form.questionError = true;
                    newState.showrules = false;
                } else {
                    newState.form.questionError = false;
                }

                params.questionList.push(question);
            });

            if(!newState.form.questionError) {
                runKeywordRemove()
                    .then(() => {
                        TriviaAPI().save(params)
                            .then(data => {
                                if (data.data.statusCode === 200) {
                                    dispatch({type: 'setSuccess'})
                                    if (state.triviaId === "") {
                                        dispatch({type: 'updateTriviaID', payload: data.data.data.triviaId});
                                        //dispatch({type: 'updateUrl', payload: `${settings.subPath}/trivia/${data.data.data.triviaId}/${state.campaignId}/${state.campaignInd}`});
                                    } 
                                } else {
                                    dispatch({type: 'setError', payload: data.data.message})
                                }       
                            }, (data) => {
                                dispatch({type: 'setError', payload: data.data.message})
                            }).finally(_ => {
                                //this.setState(newState);
                            });
                    });
            }
        }

        //this.setState(newState);
    }

    return(
        <div>
            { state.redirect && <Redirect to={state.redirect} /> }

            <h1 className="text-center">{state.messagetype}Trivia</h1>
            { state.error && ((state.error.length) ? <div className="error-field form-group">{state.error}</div> : "" )}
            { state.success && ((state.success.length) ? <div className="success-field form-group">{state.success}</div> : "" )}

            <form noValidate name="form" onSubmit={evt => {evt.preventDefault();}}>
                    <div className="record-title">
                        <div className="row">

                            <div className={["form-group", state.showrules || isActiveOrComplete() ? 'col-md-12' : 'col-md-8'].join(" ")}>
                                <label>Name</label>
                                <input 
                                    disabled={isActiveOrComplete()} 
                                    required 
                                    maxLength="30" 
                                    value={state.trivia.name} 
                                    onChange={evt => dispatch({type: 'updateTrivia', payload: {key: evt.target.name, value: evt.target.value}})}
                                    name="name" 
                                    className="form-control" 
                                    placeholder="Enter a trivia name" />
                                { (state.form.submitted && !state.trivia.name.length) ? <div className="error-field">Please  fill out this field (maximum 30 characters)</div> : "" }
                                
                            </div>

                            {
                                (state.showrules || isActiveOrComplete()) ? "" : (
                                    <div className="col-md-4 form-group">
                                        <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                                        <div className="full-btn btn btn-primary btn-lg" onClick={evt => {addQuestion()}}>
                                            <i className="fa fa-plus-circle fa-lg"></i> Add Question
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="row row-smartListNav" justified="true">
                    
                        <ul className={["nav nav-tabs", window.width < 768 ? 'nav-stacked': 'nav-justified'].join(" ")}>
                            <li active="true" onClick={_ => { if (!state.showrules) dispatch({type: 'setShowRules', payload: {value:true}})}} className={state.showrules ? "active" : ""}>
                                <a href="/" onClick={evt => evt.preventDefault()}>Trivia Rules</a>
                            </li>
                            <li onClick={_ => { if (state.showrules) dispatch({type: 'setShowRules', payload: {value:false}})}} className={state.showrules ? "" : "active"}>
                                <a href="/" onClick={evt => evt.preventDefault()}>Questions</a>
                            </li>
                        </ul>
                        
                        {
                            state.showrules ? (
                                <div id="rulesTab" heading="Trivia Rules" active="true">
                                    <div className="row row-bordered">
                                        <div className="col-md-4 form-group">
                                            <label>Short Code</label>
                                            <MultiSelect
                                                disabled={isActiveOrComplete()}
                                                options={state.config.shortcodes}
                                                value={state.trivia.shortcode}                                              
                                                onChange={selected => dispatch({type: 'updateTrivia', payload: {key: "shortcode", value: selected.length ? [selected[selected.length - 1]] : []}})}
                                                labelledBy={"Select a short code"}
                                                hasSelectAll={false}
                                                required
                                                name="shortCodeId" />
                                            { state.form.submitted && !state.trivia.shortcode.length ? <div className="error-field">Please fill out this field</div> : "" }
                                        </div>

                                        <div className="col-md-4 form-group">
                                            <label>Start Date</label>
                                            <input 
                                                type="date"
                                                value={state.trivia.startDate} 
                                                className="form-control"
                                                onChange={evt => dispatch({type: 'updateTrivia', payload: {key: "startDate", value: evt.target.value}})}
                                                min={state.campaign.minStartDate}
                                                //max={state.campaign.maxStartDate}                                                                                                           
                                            />
                                            {/* <span date-picker="triviaDatePicker[0]"></span> */}
                                            { state.form.submitted && !state.trivia.startDate.length ? <div className="error-field">Please fill out this field</div> : "" }
                                            {/* { this.state.form.submitted && !this.state.trivia.startDate ? <div className="error-field">Please select a valid  date</div> : "" } */}
                                            
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>End Date</label>
                                            <input 
                                                type="date"
                                                className="form-control"
                                                min={state.campaign.minEndDate}
                                                //max={state.campaign.maxEndDate}
                                                value={state.trivia.endDate}
                                                onChange={evt => dispatch({type: 'updateTrivia', payload: {key: "endDate", value: evt.target.value}})}
                                            />
                                            {/* <span date-picker="triviaDatePicker[1]"></span> */}
                                            { state.form.submitted && !state.trivia.endDate.length ? <div className="error-field">Please fill out this field</div> : "" }
                                            {/* { this.state.form.submitted && !this.state.trivia.endDate ? <div className="error-field">Please select a valid  date</div> : "" } */}
                                        </div>

                                        <div className="col-xs-12">
                                            <KeywordInput 
                                                readonly={state.trivia.shortcode.length ? false : true} 
                                                keywords={state.trivia.addedKeywords}
                                                shortcode={state.config.shortcodes} 
                                                mandatoryKeywords={(state.trivia.shortcode && state.trivia.shortcode.length) ? state.trivia.shortcode[0].mandatoryKeywords : []} 
                                                formSubmitted={state.form.submitted} 
                                                communicationId={state.triviaId} 
                                                onUpdatedKeywords={(input) => dispatch({type: 'updateTrivia', payload: {key: "addedKeywords", value: input}})} /> 
                                            
                                            { state.form.submitted && !state.trivia.addedKeywords.length ? <div className="error-field">Please add atleast one keyword!</div> : "" }
                                        </div>
                                    </div>
                                    <div className="row row-bordered">
                                        <div className="col-xs-12 form-group">
                                            <label>Correct Answer Response ({state.trivia.correctResponse.length}/140)</label>
                                            <textarea 
                                                disabled={isActiveOrComplete()} 
                                                required 
                                                name="correctResponse" 
                                                value={state.trivia.correctResponse} 
                                                onChange={evt => dispatch({type: 'updateTrivia', payload: {key: "correctResponse", value: evt.target.value}})}
                                                className="form-control" 
                                                rows="2" 
                                                placeholder="Enter a response to be sent when a subscriber enters a correct answer" 
                                                maxLength="140"></textarea>
                                                {
                                                    (state.form.submitted && !state.trivia.correctResponse.length) ? <div className="error-field">Please fill out this field</div> : ""
                                                }
                                        </div>

                                        <div className="col-xs-12 form-group">
                                            <label>Incorrect Answer Response ({state.trivia.incorrectResponse.length}/140)</label>
                                            <textarea 
                                                disabled={isActiveOrComplete()} 
                                                required 
                                                name="incorrectResponse" 
                                                value={state.trivia.incorrectResponse} 
                                                onChange={evt => dispatch({type: 'updateTrivia', payload: {key: "incorrectResponse", value: evt.target.value}})}
                                                className="form-control" 
                                                rows="2" 
                                                placeholder="Enter a response to be sent when a subscriber enters an incorrect answer" 
                                                maxLength="140"></textarea>
                                            { (state.form.submitted && !state.trivia.incorrectResponse.length) ? <div className="error-field">Please fill out this field</div> : ""}
                                        </div>

                                        <div className="col-xs-12 form-group">
                                            <label>Invalid Answer Response ({state.trivia.invalidResponse.length}/140)</label>
                                            <textarea 
                                                disabled={isActiveOrComplete()} 
                                                required 
                                                name="invalidResponse" 
                                                value={state.trivia.invalidResponse} 
                                                onChange={evt => dispatch({type: 'updateTrivia', payload: {key: "invalidResponse", value: evt.target.value}})}
                                                className="form-control" 
                                                rows="2" 
                                                placeholder="Enter a response to be sent when a subscriber enters an invalid answer" 
                                                maxLength="140"></textarea>
                                            { (state.form.submitted && !state.trivia.invalidResponse.length) ? <div className="error-field">Please fill out this field</div> : ""}
                                        </div>

                                        <div className="col-xs-12 form-group">
                                            <label>Completed Trivia Message ({state.trivia.finalMessage.length}/140)</label>
                                            <textarea 
                                                disabled={isActiveOrComplete()} 
                                                required 
                                                name="completedResponse" 
                                                value={state.trivia.finalMessage} 
                                                onChange={evt => dispatch({type: 'updateTrivia', payload: {key: "finalMessage", value: evt.target.value}})}
                                                className="form-control" 
                                                rows="2" 
                                                placeholder="Enter a response to be sent when a subscriber completes the trivia" 
                                                maxLength="140"></textarea>
                                            { (state.form.submitted && !state.trivia.finalMessage.length) ? <div className="error-field">Please fill out this field</div> : ""}
                                        </div>

                                    </div>
                                </div>
                                
                            ) : (
                                <div id="questionsTab" heading="Questions">
                                    <p>{state.form.submitted}</p>
                                    <Question 
                                        questions={state.questions} 
                                        status={isActiveOrComplete()} 
                                        formSubmitted={state.form.submitted}
                                        deleteQuestion={deleteQuestion} 
                                        setQuestionsObject={setQuestionsObject}
                                        minDate={state.campaign.minStartDate}
                                        maxDate={state.campaign.maxStartDate}/>
                                </div>
                            )
                        }
                    </div>
                    {
                        !isActiveOrComplete() ? (
                            <div className="row button-row">
                                <div className="col-xs-12 form-group">
                                    <div className="col-xs-6 text-right pull-left">
                                        <button type="submit" className="btn btn-primary btn-lg" onClick={evt => {ok()}}>Save</button>
                                    </div>
                                    <div className="col-xs-6 text-left pull-left">
                                        <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        ) : ""
                    }   
                    
                    {
                        isActiveOrComplete() ? (
                            <div className="row button-row">
                                <div className="col-xs-12 form-group text-center">
                                    <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Back</button>
                                </div>
                            </div>
                        ) : ""
                    }
            </form>
        </div>
    )
}
