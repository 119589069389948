import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import settings from '../../config/settings'
import SubscriberAPI from '../../models/subscriber'
import ListInputFilter from '../../components/ListInputFilter'
import ResponseMessage from '../../components/ResponseMessage'

export default function Subscriptions() {
	
	//const { toggleModal, setModalContent } = React.useContext(ModalContext);
	const [subscribers, setSubscribers] = useState([]);
	const [error, setError] = useState('');
	const [search, setSearch] = useState('');
	const [updatedSearch, setUpdatedSearch] = useState(false);
	
	//Get Subscribers
	useEffect(() => {
		if (search.length) {
			var params = {
				subscriberCriteria: search
			};
			SubscriberAPI().findAll(params)
				.then((data) => {
					setSubscribers(data.data.data.subscribers);
					setUpdatedSearch(true);
				})
				.catch(err => {
					setError(err);
				});
		}
	}, [search]);

	//Show Download Modal - TODO
	// const downloadModal = (event) => {
	//   event.stopPropagation();
	//   event.preventDefault();
	//   toggleModal();
	//   setModalContent(ConfirmModal({
	//     message: `Are you sure you want to download a list of all subscribers?`,
	//     hide: () => toggleModal(false),
	//     action: () => downloadSubscribers()
	//   }));
	// };
	
	//Get Download File - TODO
	// const downloadSubscribers = () => {
	//   SubscriberAPI().download()
	//   .then((data) => {
	//     console.log(data.data)
	//   })
	//   .catch(err => {
	//     setError(err);
	//   });
	// }
	
	return (
		<div>
			<div className="row row-top">
				<div className="col-md-8">
				<h3>Subscriptions</h3>
				</div>
				<div className="col-md-4 text-right">
				<ListInputFilter
					change={(event) => setSearch(event.target.value)}
					// click={_ => {
					// 	if (search !== "") loadSubscribers();
					// }}
					placeholder="Search for a subscriber"
				/>
				</div>
			</div>

			<ResponseMessage validation={error} message={error} class="error-field" />

			<div className="row table-header">
				<div className="col-md-4 col-xs-6">First Name</div>
				<div className="col-md-4 col-xs-6">Last Name</div>
				<div className="col-md-4 hidden-sm hidden-xs">Phone</div>
			</div>

			<div className="panel-group accordion" id="collapse-panel">
				<div
				className="panel panel-default"
				dir-paginate="subscriber in getSubscribers | itemsPerPage: 10"
				data-testid="subscriptions"
				>
				{subscribers.length > 0 &&
					subscribers.map((item, key) => (
					<Link
						to={{
							pathname: `${settings.subPath}/subscribers/subscriptions/${item.MOBILE}`,
							data: [item],
						}}
						className="panel-heading"
						key={key}
					>
						<div className="row record">
						<div className="col-md-4 col-xs-6">{item.FIRSTNAME}</div>
						<div className="col-md-4 col-xs-6">{item.LASTNAME}</div>
						<div className="col-md-4 hidden-sm hidden-xs">
							{item.MOBILE}
						</div>
						</div>
					</Link>
					))}
				</div>

				{subscribers.length === 0 && (
				<div className="panel panel-default">
					<div className="panel-heading empty">
					<div className="row record">
						<div data-testid="instructions" className="col-xs-12">
						{updatedSearch
							? "There are no subscribers to display given the search criteria."
							: "Enter a full phone number into the search bar and press enter to view results."}
						</div>
						{/*
							<div data-testid="nosubs" className="col-xs-12">There are no subscribers to search from.</div>
						*/}
					</div>
					</div>
				</div>
				)}
			</div>
		</div>
  	)
}
		