import API from '../services/api'

export default function GatewayClientsAPI() {

  const GatewayClients = {};

  GatewayClients.findAll = () => {
    return API.findAll('getGatewayClients');
  };

  GatewayClients.save = (clientData) => {
    return API.save('saveGatewayClient', clientData);
  };

  GatewayClients.destroy = (clientId) => {
    return API.destroy('deleteGatewayClient', '_id', clientId);
  };

  GatewayClients.download = () => {
    const download = {
      action: 'downloadGatewayClientData',
      params: {}
    };
    return API.download(download, 'application/zip', 'download');
  };

  return GatewayClients;
}
