import { useState } from 'react'

export default function useModal() {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalSize, setModalSize] = useState('');

	function toggleModal(override) {
		if (override !== undefined) {
			setIsModalShowing(override);
		} else {
	  	setIsModalShowing(!isModalShowing);
		}
	}

	function setModalContent(content) {
	  	setModalBody(content);
	}

	function setModalWidth(content) {
		setModalSize(content);
	}

  return { isModalShowing, toggleModal, modalBody, setModalContent, modalSize, setModalWidth }

};