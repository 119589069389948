import React from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import Routes from './routes'
import Header from './components/Header'
import { ModalProvider } from "./context/modal"
import { Hub } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import settings from './config/settings' 

Hub.listen('auth', rsp => {
  Auth.currentAuthenticatedUser()
      .then(() => { 
        if (rsp.payload.data !== null) {
          window.location.href = `${settings.subPath}`;
        }
      })
      .catch(() => { 
        // not a successful login event
      });
}); // listen for login/signup events

export default function App() {
  return (
      <Router>
        <ModalProvider>
          <div id="wrapper">
            <Header />
            <div id="page-wrapper">
              <Routes />
            </div>
          </div>
        </ModalProvider>
      </Router>
  )
}
