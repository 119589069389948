import React from 'react'
import settings from "../../config/settings"
 
export default function CreateCommunicationModal({campaignId, campaignInd, hide, action}) {
    console.log(settings.subPath);
    const redirectToSimpleResponseForm = `${settings.subPath}/simpleresponse/new/${campaignId}/${campaignInd}`;
    const redirectToJourneyForm = `${settings.subPath}/journey/new/${campaignId}/${campaignInd}`;
    const redirectToCorrespondenceForm = `${settings.subPath}/correspondences/new/${campaignId}/${campaignInd}`;
    const redirectToContestForm = `${settings.subPath}/contest/new/${campaignId}/${campaignInd}`;
    const redirectToTriviaForm = `${settings.subPath}/trivia/new/${campaignId}/${campaignInd}`;

    return (
        <div>
            <div className="modal-header">
                <i onClick={hide} className="btn-close fa fa-times" data-dismiss="modal" aria-label="Close"></i>
                <h3>Create Communication</h3>
            </div>

            <div>
                <div className="modal-body create-communication-modal">
                    <div className="row">
                        {/* <div className="col-md-6 col-xs-12 form-group" onClick={() => {action(redirectToSimpleResponseForm)}}> */}
                        <div className="col-md-6 col-xs-12 form-group" onClick={() => {action('simpleresponse')}}>
                            <div className="btn-default btn-block-sm btn-lg text-center" ui-sref="dashboard.newSimpleResponse({campaignId:campaignId, campaignInd:campaignInd})">
                                <div className="fa fa-exchange fa-flip-horizontal fa-4x"></div>
                                <h4>Simple Response</h4>
                                <p>Used to send a simple response to User when the user texts in a Keyword.</p>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-xs-12 form-group" onClick={() => {action(redirectToCorrespondenceForm)}}> */}
                        <div className="col-md-6 col-xs-12 form-group" onClick={() => {action('correspondence')}}>
                            <div className="btn-default btn-block-sm btn-lg text-center" ui-sref="dashboard.newtrigger({campaignId:campaignId, campaignInd:campaignInd})">
                                <div className="fa fa-envelope-o fa-flip-horizontal fa-4x"></div>
                                <h4>Correspondence</h4>
                                <p>A powerful SMS blast tool. Ideal for sending one time or recurring SMS promotion to a specific Smart List</p>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-xs-12 form-group last-row" onClick={() => {action(redirectToContestForm)}}> */}
                        <div className="col-md-4 col-xs-12 form-group last-row" onClick={() => {action('contest')}}>
                            <div className="btn-default btn-block-sm btn-lg text-center" ui-sref="dashboard.newcontest({campaignId:campaignId, campaignInd:campaignInd})">
                                <div className="fa fa-trophy fa-flip-horizontal fa-4x"></div>
                                <h4>Contest</h4>
                                <p>A tool which allows users to register for a contest.</p>
                            </div>   
                        </div>
                        {/* <div className="col-md-4 col-xs-12 form-group last-row" onClick={() => {action(redirectToTriviaForm)}}> */}
                        <div className="col-md-4 col-xs-12 form-group last-row" onClick={() => {action('trivia')}}>
                            <div className="btn-default btn-block-sm btn-lg text-center" ui-sref="dashboard.newtrivia({campaignId:campaignId, campaignInd:campaignInd})">
                                <div className="fa fa-puzzle-piece fa-flip-horizontal fa-4x"></div>
                                <h4>Trivia</h4>
                                <p>A powerful survey/ quiz tool. Enables to conduct surveys or quiz. Correspondence can be used to promote Trivia.</p>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-xs-12 form-group last-row" onClick={() => {action(redirectToJourneyForm)}}> */}
                        <div className="col-md-4 col-xs-12 form-group last-row" onClick={() => {action('journey')}}>
                            <div className="btn-default btn-block-sm btn-lg text-center" ui-sref="dashboard.newJourney({campaignId:campaignId, campaignInd:campaignInd})">
                                <div className="fa fa-sliders fa-flip-horizontal fa-4x"></div>
                                <h4>Journey</h4>
                                <p>Customer Engagement Tool, creates a personalized &amp; interactive user experience. Also facilitates A/B testing.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="modal-footer hidden">
                    <button type="button" className="btn btn-default btn-block-xs btn-lg" ng-click="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    );
}
