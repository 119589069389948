import React from "react"

export default function ChartJS(props) {
    return (
		<div className="form-group chart-container">
		    <label>{props.label}</label>
		    <div className="form-group chart-background">
		        <canvas data-testid={props.id} id={props.id} className="chart chart-line"></canvas>
		    </div>
		</div>
	);
}
