import React, { useReducer, useEffect, useState } from 'react'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import settings from '../../config/settings'

import SmsAPI from '../../models/sms'
import ResponseMessage from '../../components/ResponseMessage'

const langs = {
  "EN" : "English",
  "FR" : "French",
  "ES" : "Spanish",
};

const initialState = {
  defaultSms: [],
  defaultFolders: [],
  sms: {
    code: '',
    defaultLanguage: '',
    folderId: 0,
    groupCode: '',
    messages: {NEW: {message: '', fallBackMessage: '', language: 'NEW'}},
    name: '',
    tokenGenUrl: '',
    url: '',    
  },
  folderType: '',
  newFolderName: '',
  codeType: '',
  messages: [{message: '', fallBackMessage: '', language: 'NEW'}],
  error: '',
  success: false,
  submitted: false,
};

function reducer(state, action) {
  let messages = {...state.sms.messages};
  let tempMsg = [...state.messages];
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        defaultSms: action.sms,
        defaultFolders: action.folders
      };
    case 'setSms':
      const currentMsg = Object.keys(action.data.messages).map((msg) => action.data.messages[msg]);
      return {
        ...state,
        sms: action.data,
        folderType: (action.data.folderId > 0) ? 'existing' : (action.data.folderId === -1) ? 'none' : '',
        codeType: action.data.groupCode ? 'group' : (action.data.code) ? 'individual' : '',
        messages: currentMsg
      };
    case 'updateForm':
      if (action.key === 'code' && state.sms.groupCode) {
        delete state.sms.groupCode;
      } else if (action.key === 'groupCode' && state.sms.code) {
        delete state.sms.code;
      }
      return {
        ...state,
        sms: {
          ...state.sms,
          [action.key]: action.payload
        }
      };
    case 'setFolderType':
      return {
        ...state,
        folderType: action.payload
      };
    case 'setCodeType':
      return {
        ...state,
        codeType: action.payload
      };
    case 'setNewFolderName':
      return {
        ...state,
        newFolderName: action.payload
      };
    case 'updateMessage':
      messages[action.current] = tempMsg[action.key] = {
        language: action.current,
        message: (action.message || action.message === '') ? action.message : (action.language) ? '' : messages[action.current].message,
        fallBackMessage: (action.fallBackMessage || action.fallBackMessage === '') ? action.fallBackMessage : (action.language) ? '' :  messages[action.current].fallBackMessage,
      }
      if (action.language) {
        delete messages[action.language];
      }
      return {
        ...state,
        sms: {
          ...state.sms,
          messages: messages
        },
        messages: tempMsg
      };
    case 'addMessage':
      messages['NEW'] = tempMsg[tempMsg.length] = {
        language: 'NEW',
        message: '',
        fallBackMessage: '',
      }
      return {
        ...state,
        sms: {
          ...state.sms,
          messages: messages
        },
        messages: tempMsg
      };
    case 'removeMessage':
      delete messages[action.lang];
      tempMsg.splice(action.key, 1);
      return {
        ...state,
        sms: {
          ...state.sms,
          messages: messages
        },
        messages: tempMsg
      };
    case 'setSubmit':
      return {
        ...state,
        submitted: action.payload
      };
    case 'setResponse':
      return {
        ...state,
        [action.key]: action.payload
      };
    default:
      throw new Error();
  }
}

export default function SmsMessage(props) {
  const [redirect, setRedirect] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const create = useRouteMatch(`${settings.subPath}/smslibrary/create`);

  useEffect(() => {
    const { messageId } = props.match.params;
    const pageId = parseInt(messageId);
    if (pageId && state.defaultSms.length > 0 && create === null) {
      let match = state.defaultSms.filter((sms) => {
        if (sms.smsId === pageId) {
          dispatch({type: 'setSms', data: sms});
          return true;
        }
        return false; 
      });
      if (match.length !== 1 || isNaN(pageId)) {
        setRedirect(true);
      }
    } else if (isNaN(pageId) && create === null) {
      setRedirect(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params, state.defaultSms]);

  useEffect(() => {
    if (!props.location.sms && !props.location.folders) {
      getSmsData();
    } else {
      dispatch({type: 'setData', sms: props.location.sms, folders: props.location.folders});
    }
  }, [props.location.sms, props.location.folders]);

  useEffect(() => {
    validateForm();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submitted]);

  useEffect(() => {
    window.scrollTo(0,0);
    setTimeout(function(){
      if (state.error) {
        dispatch({type:'setResponse', key:'error', payload: ''});
      } else if (state.success) {
        dispatch({type:'setResponse', key:'success', payload: false});
      }
    }, 5000);
  }, [state.error, state.success]);

  //Gets SMS data
  const getSmsData = () => {
    const axios = require('axios');
    return axios.all([SmsAPI().findAll(true), SmsAPI().findAllFolder()]).then((data) => {
      const sms = data[0].data.data.smsTemplate;
      let folders = data[1].data.data.folders;
      dispatch({type: 'setData', sms: sms, folders: folders});
    })
    .catch(err => {
      dispatch({type:'setResponse', key:'error', payload: err});
    });
  };

  const updateForm = (data) => {
    dispatch({type:'updateForm', payload: data.event.target.value, key: data.key});
  }

  const updateMessage = (obj) => {
    if (obj.type === 'language') {
      dispatch({type:'updateMessage', current: obj.event.target.value, key: obj.key, language: obj.lang, });
    } else {
      dispatch({type:'updateMessage', current: obj.lang, key: obj.key, [obj.type]: obj.event.target.value});
    }
  };

  const addMessage = (event) => {
    if (!state.sms.messages['NEW']) {
      dispatch({type:'addMessage'});
    }
  };

  const submitForm = (form) => {
    form.preventDefault();
    dispatch({type:'setSubmit', payload: true});
    validateForm();
  };

  const validateForm = () => {
    const error = document.querySelector('.error-field');
    if (error) {
      error.parentElement.scrollIntoView();
    } else if (state.submitted) {
      processForm();
    }
  }

  //Creates a new folder or else makes request to create/save
  const processForm = () => {
    if (state.folderType === 'new') {
      SmsAPI().saveFolder({name:state.newFolderName})
      .then((data) => {
        const fId = data.data.data.folderId;
        apiRequest({...state.sms, folderId: fId});
      })
      .catch(err => {
        dispatch({type:'setResponse', key:'error', payload: err});
      });
    } else {
      apiRequest(state.sms)
    }
  };

  //API to create/save 
  const apiRequest = (data) => {
    const updatedDate = {...data};
    if (state.codeType === 'group') { 
      delete updatedDate.code } 
    else { 
      delete updatedDate.group 
    }
    if(state.folderType === "none"){
      updatedDate.folderId = 0;
    }
    SmsAPI().save(updatedDate)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type:'setResponse', key:'success', payload:true});
        } else {
          dispatch({type:'setResponse', key:'error', payload: data.data.message});
        }
      })
      .catch(err => {
        dispatch({type:'setResponse', key:'error', payload: err});
      });
  }
  
	return (
    <React.Fragment>
      {redirect &&
        <Redirect to={`${settings.subPath}/smslibrary`} />
      }

      <h1 className="text-center" data-testid="page-title">{create ? (`CREATE`) : (`EDIT`)} SMS</h1>

      {(state.defaultSms.length === 0 || state.defaultFolders.length === 0) &&
        <div data-testid="loading">LOADING...</div>
      }

      {state.error && 
        <ResponseMessage
          validation={state.error}
          message={state.error}
          class="error-field" />
      }

      {state.success &&
        <ResponseMessage
          validation='SMS successfully saved!'
          message='SMS successfully saved!'
          class="success-field" />
      }

      <form noValidate name="form" onSubmit={submitForm}>
        <div className="record-title">
          <div className="row">
            <div className="col-md-12 form-group">
              <label>Name</label>
              <input data-testid="form-name" required name="name" maxLength="30" className="form-control" value={state.sms.name} placeholder="Enter a SMS name" onChange={(event) => updateForm({event: event, key:'name'})} />
              {state.submitted && !state.sms.name &&
                <div className="error-field">Please fill out this field (maximum 30 characters)</div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Folder</label>
              <div className="styled-select">
                <select required name="folder" value={state.folderType} onChange={(event) => dispatch({type:'setFolderType', payload: event.target.value})}>
                  <option value="" disabled>Select a folder</option>
                  <option value="none">No Folder</option>
                  <option value="new">New Folder</option>
                  {state.defaultFolders.length > 0 &&
                    <option value="existing">Existing Folder</option>
                  }
                </select>
              </div>
              {state.submitted && !state.folderType &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            {state.folderType === 'new' &&
              <div className="col-md-6 form-group">
                <label>Folder Name</label>
                <input name="newfolderName" maxLength="30" className="form-control" placeholder="Enter a folder name" value={state.newFolderName} onChange={(event) => dispatch({type:'setNewFolderName', payload: event.target.value})} />
                {state.submitted && !state.newFolderName &&
                  <div className="error-field">Please fill out this field (maximum 30 characters)</div>
                }
              </div>
            }
            {state.folderType === 'existing' &&
              <div className="col-md-6 form-group">
                <label>Folder Name</label>
                <div className="styled-select">
                  <select required name="folderName" value={state.sms.folderId} onChange={(event) => updateForm({event: event, key:'folderId'})}>
                    <option value="-1" disabled>Select a folder</option>
                    {state.defaultFolders.map((folder, key) => 
                      <option value={folder._id} key={key}>{folder.name}</option>
                    )}
                  </select>
                </div>
                {state.submitted && !state.sms.folderId &&
                  <div className="error-field">Please fill out this field</div>
                }
              </div>
            }
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4 col-xs-12 form-group">
              <label>Code Type</label>
              <div className="styled-select">
                <select required name="codeType" value={state.codeType} onChange={(event) => dispatch({type:'setCodeType', payload: event.target.value})}>
                  <option value="" disabled>Select a code type</option>
                  <option value="individual">Individual Code</option>
                  <option value="group">Group Code</option>
                </select>
              </div>
              {state.submitted && !state.codeType &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-md-4 col-xs-12 form-group">
              <label>Code</label>
              <input required name="code" maxLength="30" className="form-control" placeholder="Enter a code" value={(state.sms.groupCode) ? state.sms.groupCode : state.sms.code} onChange={(event) => updateForm({event: event, key: ((state.codeType === 'individual') ? 'code' : 'groupCode')})} />
              {state.submitted && (!state.sms.code && !state.sms.groupCode)  &&
                <div className="error-field">Please fill out this field (maximum 30 characters)</div>
              }
            </div>
            <div className="col-md-4 form-group">
              <label>Default Language</label>
              <div className={`${(state.sms.messages['NEW']) ? 'disabled' : ''} styled-select`}>
                <select required name="default" value={state.sms.defaultLanguage} onChange={(event) => updateForm({event: event, key:'defaultLanguage'})} disabled={state.sms.messages['NEW']}>
                  <option value="" disabled>Select a default language</option>
                  {state.messages && state.messages.map((msg, key) => 
                    <option value={msg.language} key={key}>{langs[msg.language]}</option>
                  )}
                </select>
              </div>
              {state.submitted && !state.sms.defaultLanguage &&
                <div className="error-field">Please make sure you select minimum one language below and fill out this field</div>
              }
            </div>
          </div>
          <div className="row">
            <div className={`${(state.messages.length === Object.keys(langs).length) ? 'col-md-6' : 'col-md-4' } col-xs-12 form-group`}>
              <label>Token API</label>
              <input name="tokenGenUrl" maxLength="200" className="form-control" placeholder="Enter a Token API URL" value={state.sms.tokenGenUrl} onChange={(event) => updateForm({event: event, key:'tokenGenUrl'})} />
            </div>
            <div className={`${(state.messages.length === Object.keys(langs).length) ? 'col-md-6' : 'col-md-4' } col-xs-12 form-group`}>
              <label>URL</label>
              <input name="url" maxLength="200" className="form-control" placeholder="Enter a URL" value={state.sms.url} onChange={(event) => updateForm({event: event, key:'url'})} />
            </div>
            {state.messages.length !== Object.keys(langs).length &&
              <div className="col-md-4 col-xs-12 text-right form-group">
                <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                <div data-testid="add-language" className={`${(state.sms.messages['NEW']) ? 'disabled' : ''} full-btn btn btn-primary btn-lg`} onClick={addMessage}>
                  <i className="fa fa-plus-circle fa-lg"></i> Add Language
                      </div>
              </div>
            }
          </div>
        </div>
        <span data-testid="languages">
        {state.messages.map((msg, key) =>
          <div className="row row-bordered" key={key}>
            {state.messages.length > 1 &&
              <div className="col-md-2 col-xs-12 form-group text-right pull-right">
                <label className="col-xs-12 hidden-sm hidden-xs">&nbsp;</label>
                <div data-testid={`remove-language-${key}`} className="full-btn btn btn-lg btn-no-min btn-primary" onClick={(event) => dispatch({type:'removeMessage', lang: msg.language, key: key})}><i className="fa fa-lg fa-trash"></i></div>
              </div>
            }
            <div className={`${(state.messages.length > 1) ? 'col-md-10' : 'col-md-12'} col-xs-12 form-group`}>
              <label>Language</label>
              <div className="styled-select">
                <select required name={`lang${msg.language}`} value={msg.language} onChange={(event) => updateMessage({event: event, lang: msg.language,  key: key, type: 'language'})}>
                  <option value="NEW" disabled>Select a language</option>
                  {Object.keys(langs).map((l, k) => 
                    <option value={l} key={k} disabled={state.sms.messages[l]}>{langs[l]}</option>
                  )}
                </select>
              </div>
              {state.submitted && !msg.language &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-xs-12 form-group">
              <label>Message 
                {msg.message &&
                  <span> ({msg.message.length} characters used)</span>
                }
              </label>
              <textarea ng-disabled="!count.language" ng-keyup="getCount(count.language, count.message)" required name={`message${msg.language}`} value={msg.message} className="form-control" rows="2" placeholder="Enter a message" onChange={(event) => updateMessage({event: event, lang: msg.language, key: key, type: 'message'})}></textarea>
              {state.submitted && !msg.message &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
            <div className="col-xs-12 form-group">
              <label>Fallback Message 
              {msg.fallBackMessage &&
                <span> ({msg.fallBackMessage.length} characters used)</span>
              }  
              </label>
              <textarea ng-disabled="!count.language" ng-keyup="getCountFallback(count.language, count.fallBackMessage)" required name={`fallback${msg.language}`} value={msg.fallBackMessage} className="form-control" rows="2" placeholder="Enter a fallback message" onChange={(event) => updateMessage({event: event, lang: msg.language, key: key, type: 'fallBackMessage'})}></textarea>
              {state.submitted && !msg.fallBackMessage &&
                <div className="error-field">Please fill out this field</div>
              }
            </div>
          </div>
        )}
        </span>
        <div className="row button-row">
          <div className="col-xs-12 form-group">
            <div className="col-xs-6 text-right pull-left">
              <button type="submit" className="btn btn-primary btn-lg">Save</button>
            </div>
            <div className="col-xs-6 text-left pull-left">
              <Link
                to={{
                  pathname: `${settings.subPath}/smslibrary`
                }}
                className="btn btn-default btn-lg">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
	)
}
