import React from 'react'
import TriviaForm from './TriviaForm'

export default function Trivia(props) {
    const campaignId = parseInt(props.campaignId);
    const campaignInd = parseInt(props.campaignInd);
    const triviaId = parseInt(props.triviaId);

    return (
        <TriviaForm 
            campaignId={campaignId} 
            campaignInd={campaignInd}
            triviaId ={triviaId || null} 
            hide={props.hide} 
            action={props.action}/>
    )
}
