import API from '../services/api'

export default function JourneyAPI() {
    var Journey = {};

    Journey.findAll = function () {
        return API.findAll("getJourneys");
    };

    Journey.save = function (journeyData) {
        return API.save('saveJourney', journeyData);
    };

    Journey.destroy = function (journeyId) {
        return API.destroy('deleteJourney', 'journeyId', journeyId);
    };

    return Journey;
}
