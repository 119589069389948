import React, {useReducer, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import settings from '../../config/settings'

import MultiSelect from 'react-multi-select-component'
import KeywordInput from '../../components/KeywordInput'

import ShortcodeAPI from '../../models/shortcode'
import JourneyAPI from '../../models/journey';
import TriviaAPI from '../../models/trivia';
import CampaignsAPI from '../../models/campaign';

const initialState = {
    isNew: true,
    contestId: 0,
    campaignId: 0,
    campaignInd: 0,
    error: '',
    success: '',
    form: {
        name: '',
        shortcode: [],
        startDate: '',
        endDate: '',
        thankyoumessage: '',
        requiresubscription: false,
        subscriptionmessage: '',
        submitted: false,
        keywords: [],
        optinjourneys: []
    },
    config: {
        shortcodes: [],
        journeys: [],
        start: {
            min: '',
            max: ''
        },
        end: {
            min: '',
            max: ''
        },
        kwInput: {
            readonly: false
        }
    },
    redirectTo: '',
    status: 'DRAFT'
}

const reducer = (state, action) => {
    console.log(action);
    switch(action.type) {
        case 'setMeta':
            return {
                ...state,
                isNew: action.payload.isNew,
                contestId: action.payload.contestId,
                campaignId: action.payload.campaignId,
                campaignInd: action.payload.campaignInd,
                config: {
                    ...state.config,
                    journeys: action.payload.config.journeys,
                    shortcodes: action.payload.config.shortcodes,
                },
                campaign: {
                    ...state.campaign,
                    startDate: action.payload.campaign.startDate,
                    endDate: action.payload.campaign.endDate
                }
            }
        case 'setContest':
            return {
                ...state,
                isNew: action.payload.isNew,
                contestId: action.payload.contestId,
                campaignId: action.payload.campaignId,
                campaignInd: action.payload.campaignInd,
                form: {
                    name: action.payload.form.name,
                    shortcode: action.payload.form.shortcode,
                    startDate: (new Date(action.payload.form.startDate)).toISOString().substring(0,10),
                    endDate: (new Date(action.payload.form.endDate)).toISOString().substring(0,10),
                    thankyoumessage: action.payload.form.thankyoumessage,
                    requiresubscription: action.payload.form.requiresubscription,
                    subscriptionmessage: action.payload.form.subscriptionmessage,
                    submitted: action.payload.form.submitted,
                    keywords: action.payload.form.keywords,
                    optinjourneys: action.payload.optinjourneys
                },
                config: {
                    ...state.config,
                    journeys: action.payload.config.journeys,
                    shortcodes: action.payload.config.shortcodes,
                },
                status: action.payload.status
                // campaign: {
                //     ...state.campaign,
                //     startDate: action.payload.campaign.startDate,
                //     endDate: action.payload.campaign.endDate
                // }
            }
        case 'setError':
            return {
                ...state,
                success: '',
                error: action.payload,
                form: {
                    ...state.form,
                    submitted: true
                }
            }
        case 'invalidForm':
            return {
                ...state,
                error: '',
                success: '',
                form: {
                    ...state.form,
                    submitted: true
                }
            }
        case 'setField': 
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.key]: action.payload.value
                }
            }
        case 'updateShortCode':
            return {
                ...state,
                form: {
                    ...state.form,
                    shortcode: action.payload
                }
            }
        case 'updateOptInJourneys':
            return {
                ...state,
                form: {
                    ...state.form,
                    optinjourneys: action.payload
                }
            }
        case 'updateKeyword':
            return {
                ...state,
                form: {
                    ...state.form,
                    keywords: action.payload
                }
            }
        case 'setSuccess':
            return {
                ...state,
                isNew: false,
                success: 'Contest successfully saved!',
                error: '',
                contestId: action.payload.contestId,
                redirectTo: action.payload.redirectTo,
                form: {
                    ...state.form,
                    submitted: true
                }
            }
        case 'redirect':
            return {
                ...state,
                redirectTo: action.payload === 'dashboard' ? `${settings.subPath}/campaigns` : '',
            }
        default:
            return {
                ...state
            }
    }
}

export default function ContestForm(props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const params = props;//.match.params;
        const payload = {
            isNew: (props.contestId === 0 || props.contestId === null) ? true : false,
            contestId: props.contestId, // ? parseInt(params.contestId) : params.contestId,
            campaignId: props.campaignId,
            campaignInd: props.campaignInd //parseInt(params.campaignInd),
        };

        if (payload.isNew) {
            getMetaData(payload);
        } else {
            getContest(payload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getMetaData = (params) => {
        Promise.all([
                ShortcodeAPI().findAll(), 
                JourneyAPI().findAll(), 
                CampaignsAPI().findAll()
            ]).then(resolves => {
                const newState = {
                    ...params, 
                    config: {
                        shortcodes: resolves[0].data.data.shortCodes.map(sc => {
                            sc.value = sc.number; 
                            sc.label = sc.number; 
                            return sc;
                        }),
                        start: {},
                        end: {}
                    },
                    campaign: {}
                };
                newState.config.journeys = resolves[1].data.data.journeys
                                            .filter(journey => journey.isOptIn && journey.status === "ACTIVE")
                                            .map(journey => {
                                                journey.value = journey.journeyId; 
                                                journey.label = journey.name; 
                                                return journey
                                            });
                const campaign = resolves[2].data.data.campaigns.filter(campaign => params.campaignId === campaign.campaignId)[0];
                newState.campaign.startDate = campaign.startDate;
                newState.campaign.endDate = campaign.endDate;

                newState.config.start.min = Math.max(campaign.startDate, new Date());
                newState.config.start.max = campaign.endDate;
                newState.config.end.min = campaign.startDate;
                newState.config.end.max = campaign.endDate

                dispatch({type: 'setMeta', payload: newState});
            }).catch(rsp => {
                dispatch({type: 'setError', payload: rsp && rsp.data && rsp.data.message});
                console.log(rsp);
            });
    }

    const getContest = (params) => {
        Promise.all([
            TriviaAPI().findAll(), 
            ShortcodeAPI().findAll(), 
            JourneyAPI().findAll(), 
            CampaignsAPI().findAll()
        ]).then(resolves => {
                const contest = resolves[0].data.data.allTrivia.filter(contest => contest.triviaId === params.contestId)[0];
                const errors = resolves.filter(resolve => resolve.data.statusCode !== 200);

                if (!errors.length) {

                    let campaign = resolves[3].data.data.campaigns.filter(campaign => params.campaignId === campaign.campaignId)[0];
                    campaign = {
                        startDate: campaign.startDate,
                        endDate: campaign.endDate
                    };

                    const shortcodes = resolves[1].data.data.shortCodes.map(sc => ({...sc, value: sc.number, label: sc.number}));
                    console.log(contest);
                    dispatch({type: 'setContest', payload: {
                        optinjourneys: (contest.subscriptionJourneyId === -1) ? [] : selectValue(resolves[2].data.data.journeys, contest.subscriptionJourneyId),
                        form: {
                            name: contest.name,
                            shortcode: selectValue(shortcodes, contest.shortCodeId),
                            startDate: contest.startDate,
                            endDate: contest.endDate,
                            thankyoumessage: contest.thankyouResponse,
                            requiresubscription: contest.subscriptionRequired,
                            subscriptionmessage: contest.subscriptionResponse,
                            submitted: false,
                            keywords: contest.keywordList
                        },
                        config: {
                            shortcodes: shortcodes,
                            journeys: resolves[2].data.data.journeys
                                        .filter(journey => journey.isOptIn && journey.status === "ACTIVE")
                                        .map(journey => {
                                            journey.value = journey.journeyId; 
                                            journey.label = journey.name; 
                                            return journey
                                        }),
                            start: {
                                min: Math.max(campaign.startDate, new Date()),
                                max: campaign.endDate
                            },
                            end: {
                                min: campaign.startDate,
                                max: campaign.endDate
                            }
                        },
                        status: contest.status
                    }});

                    // TODO: saving with subscription not required, then switching to required, does not update the database with the new value
                } else {
                    dispatch({type: 'setError', payload: errors[0].data.message});
                }                    
            }).catch(rsp => {
                dispatch({type: 'setError', payload: rsp && rsp.data && rsp.data.message});
            });
    }

    /** Find selected item for MultiSelect */
    const selectValue = (list, id) => {
        const value = list.filter(item => item._id === id);
        return value || '';
    }

    const isStatusActiveOrComplete = () => (state.status === 'ACTIVE' || state.status === 'COMPLETE');

    const formatDateString = (timestamp, type) => {
        let dateobj = timestamp ? new Date(timestamp) : new Date();
        const [m, d, y] = dateobj.toDateString().split(" ").slice(1);
        const months = [null, 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if (type === 1) {
            return `${m} ${d}, ${y}`; // Jan 01, 2020
        } else if (type === 2) {
            return dateobj.toISOString().substr(0,10); // yyyy-mm-dd
        } else if (type === 3) {
            return `${y}-${months.indexOf(m)}-${d}`; // 2020-12-31
        }
    }

    const valid = () => {
        const {name, shortcode, startDate, endDate, keywords, thankyoumessage, requiresubscription, optinjourneys, subscriptionmessage} = state.form;
        return  name.length && 
                shortcode.length && 
                startDate.length && 
                endDate.length &&
                keywords.length &&
                thankyoumessage.length &&
                (requiresubscription ? optinjourneys.length && subscriptionmessage.length : true);
    }

    const ok = (evt) => {
        evt.preventDefault();
        
        if (valid()) {
            const params = getSaveParams();
            TriviaAPI().save(params)
                .then(rsp => {
                    if (rsp.data.statusCode === 200) {
                        dispatch({type: 'setSuccess', payload: {
                            //redirectTo: `${settings.subPath}/contest/${rsp.data.data.triviaId}/${state.campaignId}/${state.campaignInd}`,
                            contestId: rsp.data.data.triviaId
                        }})
                    } else {
                        dispatch({type: 'setError', payload: rsp.data.message})
                    }
                }).catch(rsp => {
                    dispatch({type: 'setError', payload: rsp.data.message})
                });
        } else {
            dispatch({type: 'invalidForm'})
        }

    
    }

    const getSaveParams = () => {
        const params = {
            name: state.form.name,
            shortcode: state.form.shortcode[0],
            thankyouResponse: state.form.thankyoumessage,
            keywordList:state.form.keywords,
            campaignId: state.campaignId,
            type: "CONTEST",
            shortCodeId: state.form.shortcode[0]._id,
            startDate: (new Date(state.form.startDate)).getTime(),
            endDate: (new Date(state.form.endDate)).getTime()
        };

        if (state.form.requiresubscription) {
            params.subscriptionRequired = true;
            params.subscriptionJourneyId = state.form.optinjourneys[0]._id;
            params.subscriptionResponse = state.form.subscriptionmessage;
            params.journey = state.form.optinjourneys[0];
        }

        if (state.contestId) {
            params.triviaId = state.contestId;
        }

        return params;
    }

    return (
        <div data-testid="Contest.Root">
            { state.redirectTo && <Redirect to={state.redirectTo} /> }

            <h1 className="text-center" data-testid="Contest.Title">
                {state.isNew ? 'Create New' : (isStatusActiveOrComplete() ? 'View' : 'Edit') } Contest
            </h1>

            { state.error && <div className="error-field form-group">{state.error}</div> }
            { state.success && <div className="success-field form-group">{state.success}</div> }

            <form noValidate name="form" onSubmit={ok}>
                <div className="record-title">
                    <div className="row">
                        <div className="form-group col-xs-12">
                            <label>Name</label>
                            <input 
                                disabled={isStatusActiveOrComplete()} 
                                required 
                                maxLength="30" 
                                value={state.form.name}
                                onChange={evt => dispatch({type: 'setField', payload: {key: evt.target.name, value: evt.target.value}})}
                                name="name" 
                                className="form-control" 
                                placeholder="Enter a contest name" />
                            {
                                (state.form.submitted && (state.form.name.length > 30 || state.form.name.length === 0)) &&
                                    <div className="error-field">Please fill out this field (maximum 30 characters)</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row row-bordered">
                    <div className="col-md-4 form-group">
                        <label>Short Code</label>
                        <MultiSelect
                            disabled={isStatusActiveOrComplete()}
                            options={state.config.shortcodes}
                            value={state.form.shortcode}
                            onChange={selected => dispatch({type: 'updateShortCode', payload: selected.length ? [selected[selected.length - 1]] : []})}
                            labelledBy={"Select a short code"}
                            hasSelectAll={false}
                            required
                            name="shortCodeId" />
                        { (state.form.submitted && !state.form.shortcode.length) && 
                            <div className="error-field">Please fill out this field</div> }
                    </div>

                    <div className="col-md-4 form-group">
                        <label>Start Date</label>
                        <input 
                            disabled={isStatusActiveOrComplete()}
                            name='startDate'
                            type="date" 
                            value={state.form.startDate} 
                            onChange={evt => dispatch({type: 'setField', payload: {key: evt.target.name, value: evt.target.value}})}
                            className="form-control" 
                            // min={formatDateString(state.config.start.min, 2)} 
                            // max={formatDateString(state.config.start.max, 2)} 
                            />

                        { (state.form.submitted && !state.form.startDate.length)
                            && <div className="error-field">Please fill out this field</div> }
                    </div>
                    <div className="col-md-4 form-group">
                        <label>End Date</label>
                        <input 
                            disabled={isStatusActiveOrComplete()}
                            type="date" 
                            name='endDate'
                            value={state.form.endDate} 
                            onChange={evt => dispatch({type: 'setField', payload: {key: evt.target.name, value: evt.target.value}})}
                            className="form-control" 
                            // min={formatDateString(state.config.end.min, 2)}
                            // max={formatDateString(state.config.end.max, 2)} 
                            />

                        { (state.form.submitted && !state.form.endDate.length) && <div className="error-field">Please fill out this field</div> }
                    </div>

                    <div className="col-xs-12">
                        {
                            (state.form.keywords.length > -1) && (
                                <KeywordInput 
                                    readonly={!(state.form.shortcode.length) || isStatusActiveOrComplete()}
                                    keywords={state.form.keywords}
                                    shortcode={state.form.shortcode} 
                                    formSubmitted={state.form.submitted} 
                                    communicationId={state.contestId} 
                                    onUpdatedKeywords={input => dispatch({type: 'updateKeyword', payload: input})} /> 
                            )
                        }
                        
                    </div>
                    <div className="col-xs-12 form-group">
                        <label>Thank You Message</label> <span>({state.form.thankyoumessage.length}/140)</span>
                        <textarea 
                            disabled={isStatusActiveOrComplete()} 
                            required
                            name="thankyoumessage" 
                            value={state.form.thankyoumessage}
                            onChange={evt => dispatch({type: 'setField', payload: {key: evt.target.name, value: evt.target.value}})}
                            className="form-control" 
                            rows="2" 
                            placeholder="Enter a response to be sent as a thank you to a subscriber for entering the contest" 
                            maxLength="140"></textarea>
                        { (state.form.submitted && !state.form.thankyoumessage.length) && <div className="error-field">Please fill out this field</div> }
                    </div>
                    <div className="col-xs-12 form-group">
                        <label className="checkbox-inline">
                            <input 
                                type="checkbox" 
                                name="requiresubscription" 
                                checked={state.form.requiresubscription} 
                                onChange={evt => dispatch({type: 'setField', payload: {key: evt.target.name, value: evt.target.checked}})}
                                disabled={isStatusActiveOrComplete()} />
                            Require Subscription
                        </label>
                    </div>
                    {
                        state.form.requiresubscription && (
                            <div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-xs-12 form-group">
                                            <label>Opt In Journeys</label>
                                            <MultiSelect
                                                disabled={isStatusActiveOrComplete()}
                                                options={state.config.journeys}
                                                value={state.form.optinjourneys}
                                                onChange={selected => dispatch({type: 'updateOptInJourneys', payload: selected.length ? [selected[selected.length - 1]] : []})}
                                                labelledBy={"Select a journey"}
                                                hasSelectAll={false}
                                                required={state.form.requiresubscription}
                                                name="shortCodeId" />
                                            { state.form.submitted && !state.form.optinjourneys.length && <div className="error-field">Please fill out this field</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 form-group">
                                    <label>Subscription Message</label> <span>({state.form.subscriptionmessage.length}/140)</span>
                                    <textarea 
                                        disabled={isStatusActiveOrComplete()} 
                                        required={state.form.requiresubscription}
                                        name="subscriptionmessage" 
                                        value={state.form.subscriptionmessage}
                                        onChange={evt => dispatch({
                                            type: 'setField', 
                                            payload: {
                                                key: evt.target.name, 
                                                value: evt.target.value
                                            }
                                        })}
                                        className="form-control" 
                                        rows="2" 
                                        placeholder="Enter a subscription message" 
                                        maxLength="140"></textarea>
                                    {
                                        (state.form.submitted && state.form.requiresubscription && !state.form.subscriptionmessage.length) &&
                                            <div className="error-field">Please fill out this field</div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    
                </div>

                {
                    (isStatusActiveOrComplete()) ? (
                        <div className="row button-row">
                            <div className="col-xs-12 form-group text-center">
                                <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Back</button>
                            </div>
                        </div>
                    ) : (
                        <div className="row button-row">
                            <div className="col-xs-12 form-group">
                                <div className="col-xs-6 text-right pull-left">
                                    <button type="submit" className="btn btn-primary btn-lg">Save</button>
                                </div>
                                <div className="col-xs-6 text-left pull-left">
                                    <button type="button" className="btn btn-default btn-lg" onClick={props.hide}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </form>
        </div>
    )
}
